import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useOnPageError from '../../hooks/use-on-page-error';
import useVehicleProfileInternalChangeRequest from '../../hooks/use-vehicle-profile-internal-change-request';
import positiveNumericValidator from '../../validators/positive-numeric';
import TrackedSelectInput from '../atoms/TrackedSelectInput';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import { AlignDoubleColChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import SelectInput from '~lib/frontend/design-system/components/input/molecules/SelectInput';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { fullMonthsRange, fullYearsRange, getYearByMonth } from '~lib/platform/ltm/helpers/time-owned';

const rangeOptions = {
	less1Month: 'Less than a month ago',
	less5Years: 'Less than 5 years ago',
	more5Years: 'More than 5 years ago',
};

const TimeOwned: React.FunctionComponent = () => {
	const g = useContentKey('strings');

	const { value: owner } = useVehicleProfileInternalChangeRequest('owner');
	const shouldValidate = ['policyholder', void 0].includes(owner?.type);
	const {
		value: initialValue,
		validation,
		onChange: setDurationMonths,
	} = useVehicleProfileInternalChangeRequest(
		'owner',
		'durationMonths',
		shouldValidate ? positiveNumericValidator : void 0
	);
	const error = useOnPageError(validation, 'time_owned_car_input');
	const ltmConfig = useSelector(state => state.platform.ltm.config.response);
	const { min: minMonth, max: maxMonth } = ltmConfig?.vehicle?.owner?.duration || {};

	const [showDatePicker, setShowDatePicker] = useState(void 0);

	const [timeRange, setTimeRange] = useState(() => {
		if (initialValue >= 0) {
			if (initialValue === minMonth) return rangeOptions.less1Month;
			if (initialValue === maxMonth) return rangeOptions.more5Years;

			setShowDatePicker(true);

			return rangeOptions.less5Years;
		}

		return void 0;
	});

	const yearOptions = fullYearsRange(ltmConfig?.vehicle?.owner?.duration);

	const [year, setYear] = useState(() => {
		if (initialValue > minMonth && initialValue < maxMonth) return getYearByMonth(initialValue);

		return void 0;
	});

	const monthOptions = useMemo(() => {
		if (!year) return [];

		return fullMonthsRange(ltmConfig?.vehicle?.owner?.duration, year);
	}, [ltmConfig?.vehicle?.owner?.duration, year]);

	const handleTimeRangeChanged = (range: string) => {
		setTimeRange(range);

		switch (range) {
			case rangeOptions.less1Month:
				setDurationMonths(minMonth);
				setYear(void 0);
				setShowDatePicker(false);
				break;
			case rangeOptions.less5Years:
				setDurationMonths(void 0);
				setYear(void 0);
				setShowDatePicker(true);
				break;
			case rangeOptions.more5Years:
				setDurationMonths(maxMonth);
				setYear(void 0);
				setShowDatePicker(false);
				break;
			default:
				break;
		}
	};

	const handleYearChanged = (value: string) => {
		setDurationMonths(void 0);
		setYear(value);
	};

	const getOptionEventName = (option: string) =>
		option === rangeOptions.less1Month || option === rangeOptions.more5Years ? 'time_owned_car_selected' : '';

	const getOptionEventValue = (option: string) => {
		if (option === rangeOptions.less1Month) return minMonth;

		if (option === rangeOptions.more5Years) return maxMonth;

		return void 0;
	};

	const timeOwnedCarError = !timeRange && error ? error : void 0;
	const monthOwnedCarError = timeRange === rangeOptions.less5Years && !initialValue && error ? error : void 0;

	return (
		<React.Fragment>
			<QuoteInputDecorator
				key={'timeRange'}
				label={g('motor.long_term.questions.vehicle.duration.v2.question', 'When was the car bought?')}
				error={timeOwnedCarError}
			>
				{Object.values(rangeOptions).map(option => (
					<Margin key={`time_range_${option}`} $marginBottom={'small'}>
						<RadioInput
							name={'timeRange'}
							checked={option === timeRange}
							value={option}
							onChange={() => handleTimeRangeChanged(option)}
							data-event-onfocus-name={'radio_selected'}
							data-event-onfocus-value={'time_owned_car'}
							data-event-onchange-name={getOptionEventName(option)}
							data-event-onchange-value={getOptionEventValue(option)}
						/>
					</Margin>
				))}
			</QuoteInputDecorator>

			{showDatePicker && (
				<QuoteInputDecorator key={'timeRangePicker'} label={'Roughly, what date?'} error={monthOwnedCarError}>
					<AlignDoubleColChildren>
						<SelectInput
							key={'year'}
							value={year}
							options={yearOptions}
							placeholder={'Year'}
							onChange={option => handleYearChanged(option.value)}
						/>

						<TrackedSelectInput
							key={'month'}
							value={initialValue}
							options={monthOptions}
							placeholder={'Month'}
							onChange={option => setDurationMonths(option.value)}
							data-event-onfocus-name={'dropdown_selected'}
							data-event-onfocus-value={'time_owned_car'}
							data-event-onchange-name={'time_owned_car_selected'}
						/>
					</AlignDoubleColChildren>
				</QuoteInputDecorator>
			)}
		</React.Fragment>
	);
};

export default TimeOwned;
