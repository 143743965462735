import React, { useCallback, useEffect, useRef, useState } from 'react';

import QuoteInputDecorator from '../../../components/atoms/QuoteInputDecorator';
import LocateMe, { LocationUpdate } from './LocateMe';
import PostcodeCoordsLookup from './PostcodeCoordsLookup';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import { AlignDoubleColChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import useOnPageError from '~website/features/quote/hooks/use-on-page-error';
import useProfileInternalChangeRequest from '~website/features/quote/hooks/use-profile-internal-change-request';
import useReversiblePostcodeLookup from '~website/features/quote/hooks/use-reversible-postcode-lookup';
import useVehicleProfileInternalChangeRequest from '~website/features/quote/hooks/use-vehicle-profile-internal-change-request';
import validCoordinatesValidator from '~website/features/quote/validators/valid-coordinates-validator';

const options = [
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },
];

const StorageLocationWithLookup: React.FunctionComponent = () => {
	const {
		onLookupPostcode,
		onLookupCoords,

		postcodeResult,
		postcodeResultStatus,

		reverseGeocodingResult,
		reverseGeocodingResultStatus,
	} = useReversiblePostcodeLookup();

	const [sameAddress, setSameAddress] = useState<boolean>();
	const selected = useRef(false);
	const [impliedPostcode, setImpliedPostcode] = useState('');
	const { value: residentialAddress } = useProfileInternalChangeRequest('residentialAddress');
	const {
		value: storageLocation,
		validation,
		onChange: onChangeStorageLocation,
		setFieldError,
	} = useVehicleProfileInternalChangeRequest('storage', 'location', validCoordinatesValidator);
	const error = useOnPageError(validation, 'vehicle_location_lookup_input');
	const residentialPostcode = residentialAddress?.postalCode;
	const shouldShowExtended = Boolean(sameAddress === false || (sameAddress === void 0 && impliedPostcode));

	const onRadioClick = (value: boolean) => {
		setSameAddress(value);
		selected.current = true;

		if (value && residentialPostcode) onLookupPostcode(residentialPostcode);
	};

	const onLocateMeChange = useCallback(
		(update: LocationUpdate) => {
			if (!update) {
				onChangeStorageLocation(void 0);
				setImpliedPostcode('');

				return;
			}

			const { postcode, ...coords } = update;

			onChangeStorageLocation(coords);
			setImpliedPostcode(postcode);
		},
		[onChangeStorageLocation]
	);

	useEffect(() => {
		if (residentialPostcode) onLookupPostcode(residentialPostcode);
	}, [residentialPostcode, onLookupPostcode]);

	useEffect(() => {
		if (storageLocation && !impliedPostcode) onLookupCoords(storageLocation);
	}, [storageLocation, impliedPostcode, onLookupCoords]);

	useEffect(() => {
		if (reverseGeocodingResultStatus !== 'response') return;

		setImpliedPostcode(reverseGeocodingResult.response.postcode);
	}, [reverseGeocodingResult, reverseGeocodingResultStatus]);

	useEffect(() => {
		if (!selected.current) return void 0;

		if (postcodeResultStatus === 'error') return void onChangeStorageLocation(void 0);

		if (postcodeResultStatus !== 'response') return void 0;

		onChangeStorageLocation({
			latitude: postcodeResult.response.latitude,
			longitude: postcodeResult.response.longitude,
		});

		return void 0;
	}, [postcodeResult, postcodeResultStatus, onChangeStorageLocation]);

	useEffect(() => {
		if (!residentialPostcode || !impliedPostcode) return;

		const cleanup = (string: string) => string.replace(/\s/g, '').toLowerCase();

		setSameAddress(cleanup(residentialPostcode) === cleanup(impliedPostcode));
	}, [residentialPostcode, impliedPostcode]);

	return (
		<React.Fragment>
			<QuoteInputDecorator
				label={'Is the car usually parked at your home address?'}
				error={typeof sameAddress === 'undefined' ? error : void 0}
			>
				<AlignDoubleColChildren>
					{options.map(o => (
						<RadioInput
							key={o.label}
							name={'same-as-residential'}
							checked={o.value === sameAddress}
							disabled={!residentialPostcode && o.value}
							value={o.label}
							onChange={() => onRadioClick(o.value)}
						/>
					))}
				</AlignDoubleColChildren>
			</QuoteInputDecorator>

			{/* SC: This should never happen but never say never */}
			{sameAddress === true && error && postcodeResultStatus === 'error' && (
				<Banner.Wrapper icon={'ic_alert'} type={'error'} muted>
					<div>{"We couldn't find that postcode."}</div>
					<div>{'Please try again'}</div>
				</Banner.Wrapper>
			)}

			{shouldShowExtended && (
				<div>
					<PostcodeCoordsLookup
						onChange={onChangeStorageLocation}
						impliedPostcode={impliedPostcode}
						setFieldError={setFieldError}
						error={error}
						label={'What postcode is it parked at overnight?'}
					/>

					<LocateMe onLocationChange={onLocateMeChange} key={error} />
				</div>
			)}
		</React.Fragment>
	);
};

export default StorageLocationWithLookup;
