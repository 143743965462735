import styled, { css } from 'styled-components';
import Hint from './Hint';
const LabelContainer = styled.div `
	display: flex;
	flex-direction: row;
	margin-bottom: ${p => p.theme.spacing.extraSmall};

	& > :first-child {
		flex: 1;
	}

	${p => p.$hintPosition === 'end-of-line' &&
    css `
			justify-content: space-between;
			align-items: flex-end;

			${Hint} {
				justify-content: flex-end;
			}
		`};

	${p => p.$hintPosition === 'inline' &&
    css `
			& > :first-child {
				flex: 0 0;
				white-space: nowrap;
			}
		`};

	${p => p.$hintPosition === 'below-label' &&
    css `
			flex-direction: column;
			margin-left: 0;
		`};
`;
export default LabelContainer;
