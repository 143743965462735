import React from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import Spinner from '~lib/frontend/design-system/components/spinner';
import VibrantScreen from '~website/components/VibrantScreen';
import VibrantScreenCard from '~website/components/VibrantScreen/VibrantScreenCard';

const Loading = () => (
	<>
		<Helmet defer>
			<title>{'Loading | Cuvva'}</title>
			<meta name={'robots'} content={'noindex, follow'} />
		</Helmet>
		<VibrantScreen>
			<VibrantScreenCard maxWidth={436}>
				<SpinnerContainer>
					<Spinner $color={'primaryFill'} $size={'30px'} />
				</SpinnerContainer>
			</VibrantScreenCard>
		</VibrantScreen>
	</>
);

export default Loading;

const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 340px;
`;
