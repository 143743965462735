import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageCtx } from '../contexts/PageCtx';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';

type PageErrorReturn<T = string> = T;

const useOnPageError = <T = string>(error: T, field?: string): PageErrorReturn<T | undefined> => {
	const dispatch = useDispatch();
	const page = useContext(PageCtx);
	const pageError = useSelector(s => s.internal.quote.pages?.[page]?.error);

	useEffect(() => {
		if (pageError && field) {
			const errorString = typeof error === 'undefined' ? 'empty field' : String(error);

			dispatch(
				trackQuoteEvent({
					action: 'formatting_error_displayed',
					properties: {
						field,
						error: errorString,
					},
				})
			);
		}
	}, [dispatch, error, pageError, field]);

	if (!error) return void 0;

	if (pageError) return error;

	return void 0;
};

export default useOnPageError;
