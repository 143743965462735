import React from 'react';
import styled from 'styled-components';

const Column = styled.div<{ $size: number }>`
	flex: ${p => p.$size ?? 1};

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	margin: 0 auto;
	max-width: 1280px;

	${Column}:nth-child(2) {
		margin-top: ${p => p.theme.spacing.extraLarge};
	}

	@media (min-width: 768px) {
		flex-direction: row;

		${Column}:nth-child(2) {
			margin-top: 0;
		}
	}
`;

interface TwoColumnsFixedLayoutProps {
	children: [React.ReactNode, React.ReactNode];
}

type ComponentType = React.FCWithChildren<TwoColumnsFixedLayoutProps>;

const TwoColumnsFixedLayout: ComponentType = ({ children }) => (
	<Wrapper>
		<Column $size={3}>{children[0]}</Column>
		<Column $size={2}>{children[1]}</Column>
	</Wrapper>
);

export default TwoColumnsFixedLayout;
