import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { PageCtx } from '../contexts/PageCtx';
import useFormEventTracking from '../hooks/use-form-events-tracking';
import useLockedDetails from '../hooks/use-locked-details';
import Form from './atoms/Form';
import HorizontalRule from './atoms/HorizontalRule';
import BirthDate from './binded-fields/BirthDate';
import EmailAddressWrapper from './binded-fields/EmailAddressWrapper';
import FamilyName from './binded-fields/FamilyName';
import PersonalName from './binded-fields/PersonalName';
import SexOnDVLA from './binded-fields/SexOnDVLA';
import LockedOutCallout from './molecules/LockedOutCallout';
import PageError from './molecules/PageError';
import ProceedButton from './molecules/ProceedButton';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';

const AboutYou: React.FunctionComponent = () => {
	const get = useContentKey('string');

	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);

	const locked = useLockedDetails();
	const formTracking = useFormEventTracking();

	return (
		<Form {...formTracking}>
			<div>
				<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
					{'About you'}
				</Typography>
				<Typography $type={'Body.Medium'} $marginTop={'small'}>
					{'Details must match your driving licence'}
				</Typography>

				{locked && <LockedOutCallout />}
				<PageError page={page} />
			</div>

			<PersonalName />
			<FamilyName />
			<BirthDate />
			<SexOnDVLA />

			<HorizontalRule />

			<EmailAddressWrapper />

			<ProceedButton path={'/quote/your-licence'}>{'Continue'}</ProceedButton>
		</Form>
	);
};

export default AboutYou;
