import React, { useEffect, useState } from 'react';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useOnPageError from '../../hooks/use-on-page-error';
import useVehicleUseClass from '../../hooks/use-vehicle-use-class';
import BusinessUseDialogHint from '../molecules/dialogs/BusinessUseDialogHint';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import { AlignDoubleColChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';

const options = [
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },
];

const BusinessUse = () => {
	const [isBusinessUse, setIsBusinessUse] = useState<boolean>(void 0);

	const { value, validation, onChange } = useVehicleUseClass();
	const error = useOnPageError(validation, 'vehicle_business_use_input');

	const businessUse = value === 'class1';

	useEffect(() => {
		if (typeof value !== 'undefined') setIsBusinessUse(businessUse);

		return () => {
			setIsBusinessUse(void 0);
		};
	}, [businessUse, value]);

	const handleUseClassChange = (value: boolean) => {
		setIsBusinessUse(value);
		onChange(value ? 'class1' : 'sdpc');
	};

	return (
		<div>
			<QuoteInputDecorator label={'Apart from commuting, will you use the car for work?'} error={error}>
				<AlignDoubleColChildren>
					{options.map(o => (
						<RadioInput
							key={o.label}
							name={'businessUse'}
							checked={o.value === isBusinessUse}
							value={o.label}
							onChange={() => handleUseClassChange(o.value)}
							data-event-onchange-name={'vehicle_business_use_input_selected'}
						/>
					))}
				</AlignDoubleColChildren>
			</QuoteInputDecorator>

			{!error && (
				<Margin $marginTop={'small'}>
					<BusinessUseDialogHint />
				</Margin>
			)}
		</div>
	);
};

export default BusinessUse;
