import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { trackAccountsScreenView } from '../analytics/store/actions';
import { MeCardContainer, SinglePolicyWrapper } from './atoms/Card';
import { MeHeader, MeSinglePolicyHero } from './atoms/MeBackground';
import PolicyDetailsHeading from './atoms/PolicyDetailsHeading';
import SinglePolicyHero from './atoms/SinglePolicyHero';
import PolicyContainer from './containers/PolicyContainer';
import SinglePolicyContext from './context/SinglePolicyContext';
import useSingleSubscription from './hooks/use-single-subscription';
import CancelSubscription from './molecules/CancelSubscription';
import DownloadAppBanner from './molecules/DownloadAppBanner';
import FailedPaymentCallout from './molecules/FailedPaymentCallout';
import MakeAClaim from './molecules/MakeAClaim';
import PolicyBilling from './molecules/PolicyBilling';
import PolicyDetails from './molecules/PolicyDetails';
import PolicyDocuments from './molecules/PolicyDocuments';
import RestartSubscription from './molecules/RestartSubscription';
import { pastEndDate } from './utils/formatDateTime';
import { Anchor } from '~lib/frontend/atoms';
import Icon from '~lib/frontend/design-system/components/Icon';
import Spinner from '~lib/frontend/design-system/components/spinner';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { abiCodes } from '~lib/platform/code-mapping/store/actions';

const SinglePolicy = () => {
	const dispatch = useDispatch();
	const { policyId } = useParams<{ policyId: string }>();
	const { loading, policyDetails, subscription, refetch } = useSingleSubscription(policyId);

	const abiStore = useSelector(s => s.platform.codeMapping.abiCodes);
	const abiStatus = useAsyncStateStatus(abiStore).status !== 'response';

	useEffect(() => {
		dispatch(
			trackAccountsScreenView({
				screenName: 'policy_managePolicy_viewed',
			})
		);
	}, [dispatch]);

	useEffect(() => {
		dispatch(abiCodes.request());
	}, [dispatch]);

	const isLoading = loading || abiStatus;
	const ended = pastEndDate(policyDetails.endDate) ?? true;

	const autocycleOff = !subscription?.state?.autocycle;

	return (
		<React.Fragment>
			<Helmet defer>
				<title>{'Policy | Cuvva'}</title>
			</Helmet>

			<SinglePolicyContext.Provider value={policyDetails}>
				<PolicyContainer>
					<MeHeader>
						<MeSinglePolicyHero>
							<Block>
								<Anchor href={'/me/policies'}>
									<Icon $size={'24px'} $color={'blankFill'} icon={'ic_chevron_left_small'} />
									<Typography $type={'Label.Small'} $color={'textOnFill'}>
										{'Back'}
									</Typography>
								</Anchor>

								{!ended && !isLoading && <FailedPaymentCallout />}
								<SinglePolicyHero loading={isLoading} />
							</Block>
						</MeSinglePolicyHero>
					</MeHeader>

					<MeCardContainer>
						{isLoading && (
							<SpinnerContainer>
								<Spinner $size={'50px'} />
							</SpinnerContainer>
						)}

						{!isLoading && (
							<SinglePolicyWrapper>
								<SinglePolicyContentContainer>
									<div>
										<MakeAClaim />
										{!ended && !autocycleOff && <CancelSubscription />}
										{!ended && autocycleOff && <RestartSubscription />}
										<PolicyDocuments />
										<DownloadAppBanner />
									</div>
									{ended ? <div /> : <PolicyDetailsHeading />}
									<div>
										{!ended && <PolicyDetails />}
										<PolicyBilling />
									</div>
								</SinglePolicyContentContainer>
							</SinglePolicyWrapper>
						)}
					</MeCardContainer>
				</PolicyContainer>
			</SinglePolicyContext.Provider>
		</React.Fragment>
	);
};

export default SinglePolicy;

const SpinnerContainer = styled.div`
	margin: 300px auto;
	text-align: center;
`;

const Block = styled.div`
	width: 100%;

	a {
		display: flex;
		align-items: center;
		text-decoration: none;
		justify-content: flex-start;
		margin-bottom: ${p => p.theme.spacing.large};

		&:hover {
			text-decoration: underline;
			color: ${p => p.theme.ui.blankFill};
		}
	}
`;

const SinglePolicyContentContainer = styled.div`
	display: grid;
	row-gap: ${p => p.theme.spacing.large};
	column-gap: ${p => p.theme.spacing.extraLarge};
	grid-template-columns: 1fr;
	grid-template-areas:
		'a'
		'b'
		'c';

	& > :nth-child(1) {
		grid-area: a;

		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${p => p.theme.spacing.large};
	}
	& > :nth-child(2) {
		grid-area: b;
		margin-top: ${p => p.theme.spacing.large};
	}
	& > :nth-child(3) {
		grid-area: c;

		width: 100%;
		display: flex;
		flex-direction: column;
		gap: ${p => p.theme.spacing.extraLarge};
	}

	@media (min-width: 940px) {
		grid-template-columns: 1fr 400px;
		grid-template-areas:
			'b .'
			'c a'
			'c a';

		& > :nth-child(2) {
			margin-top: 0;
		}
	}
`;
