import React from 'react';
import { useDispatch } from 'react-redux';

import QuoteInputDecorator from '../../../../components/atoms/QuoteInputDecorator';
import Button from '~lib/frontend/design-system/components/button/Button';
import SelectInput from '~lib/frontend/design-system/components/input/molecules/SelectInput';
import { SelectOption } from '~lib/frontend/design-system/components/input/types';
import { ProfileAddress } from '~lib/platform/ltm/types';
import { Address } from '~lib/platform/profile/types';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';

interface SearchResultProps {
	error: string | undefined;
	dataset: ProfileAddress[];
	onAddressSelected: (address: Address | undefined) => void;
	onEnterManually: () => void;
}

const SearchResult: React.FCWithChildren<SearchResultProps> = ({
	error,
	dataset,
	onAddressSelected,
	onEnterManually,
}) => {
	const dispatch = useDispatch();

	const selectOptions: SelectOption<Address>[] = dataset.map((address: Address) => ({
		label: address.lines.join(', '),
		value: address,
	}));

	return (
		<div>
			<QuoteInputDecorator label={'Address'} error={error && 'Please select your address'}>
				<SelectInput
					value={void 0}
					options={selectOptions}
					placeholder={'Choose an option'}
					onChange={({ value }) => {
						onAddressSelected(value);

						dispatch(
							trackQuoteEvent({
								action: 'dropdown_option_selected',
								value: 'suggested_address',
							})
						);
					}}
				/>
			</QuoteInputDecorator>

			{onEnterManually && (
				<Button $type={'text'} $size={'medium'} onClick={onEnterManually}>
					{'Enter address manually'}
				</Button>
			)}
		</div>
	);
};

export default SearchResult;
