import React from 'react';
import styled, { css } from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';
import { CuvvaPeep } from '~website/store/about/types';

interface PersonCardProps {
	easterMode: boolean;
	peep: CuvvaPeep;
}

const PersonCard: React.FCWithChildren<PersonCardProps> = props => {
	const { easterMode, peep } = props;
	const imageUrl = pickImage(peep, easterMode);

	return (
		<Wrapper $imageUrl={imageUrl} $emoji={peep.emoji}>
			<Typography
				$type={'Body.Medium'}
				$color={'textOnFill'}
				$marginTop={'extraLarge'}
				$marginBottom={'extraLarge'}
				$marginLeft={'large'}
				$marginRight={'large'}
				$align={'left'}
			>
				{peep.about.bio}
			</Typography>
			<Banner showBlur={Boolean(imageUrl)}>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $bold>
					{peep.emoji && `${peep.emoji} `}
					{peep.about.name}
				</Typography>
				<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
					{peep.about.role}
				</Typography>
			</Banner>
		</Wrapper>
	);
};

function pickImage(peep: CuvvaPeep, easterMode: boolean) {
	const images = peep.about.images;

	if (easterMode && images?.easter) return images?.easter;

	return images?.normal;
}

const Banner = styled.div<{ showBlur: boolean }>`
	text-align: left;
	padding: 16px 18px;
	background: ${p => p.theme.ui.surfaceFillMuted}CC;
	border-radius: 0 0 ${p => p.theme.borderRadius.regular} ${p => p.theme.borderRadius.regular};
	${p => (p.showBlur ? 'backdrop-filter: blur(50px);' : '')};
`;

const Wrapper = styled.div<{ $imageUrl?: string; $emoji: string }>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 265px;
	height: 320px;
	border-radius: ${p => p.theme.borderRadius.regular};
	box-shadow: 6px 8px 30px rgba(102, 102, 255, 0.08);
	overflow: hidden;
	margin-bottom: 40px;

	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	${p =>
		p.$imageUrl
			? `background-image: url(${p.$imageUrl});`
			: css`
					&::before {
						position: absolute;
						content: '${p.$emoji}';
						font-size: 120px;
						line-height: 240px;
						width: 100%;
					}
				`}

	transition: all .3s ease;

	&:hover {
		background: ${p => p.theme.ui.primaryFill};

		${Typography} {
			opacity: 1;
			color: ${p => p.theme.ui.textOnFill} !important; /* forgive me father */
		}

		> ${Banner} {
			background: ${p => p.theme.ui.primaryFill};
		}

		${p =>
			p.$imageUrl
				? ''
				: css`
						&::before {
							display: none;
						}
					`}
	}

	& > ${Typography} {
		opacity: 0;
	}
`;

export default PersonCard;
