import { createSlice } from '@reduxjs/toolkit';

import { pageActions, stepActions } from '../actions';
import { PageItem } from '../types';

export default createSlice({
	name: '@@internal/quote/pages',
	initialState: {} as Record<string, PageItem>,
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(pageActions.newChangeRequest, (state, action) => {
				const { pageId, internalChangeRequestId, pagePath } = action.payload;

				state[pageId] = {
					...state[pageId],
					internalChangeRequestId,
					pageId,
					fetching: false,
					error: void 0,
					completed: false,
					pagePath,
				};
			})
			.addCase(pageActions.registerField, (state, action) => {
				const { pageId, field } = action.payload;

				state[pageId] = {
					...state[pageId],
					fields: {
						...state[pageId].fields,
						[field]: {
							// explicitly keeping the error
							error: state[pageId].fields?.[field]?.error,
						},
					},
				};
			})
			.addCase(pageActions.deregisterField, (state, action) => {
				const { pageId, field } = action.payload;

				state[pageId] = {
					...state[pageId],
					fields: {
						...state[pageId].fields,
						[field]: void 0,
					},
				};
			})
			.addCase(stepActions.setFieldError, (state, action) => {
				const { pageId, field, error } = action.payload;

				state[pageId] = {
					...state[pageId],
					fields: {
						...state[pageId].fields,
						[field]: {
							error,
						},
					},
				};
			})
			.addCase(pageActions.submitPage.request, (state, action) => {
				const { ident } = action.meta;

				state[ident] = {
					...state[ident],
					fetching: true,
				};
			})
			.addCase(pageActions.submitPage.success, (state, action) => {
				const { ident } = action.meta;

				state[ident] = {
					...state[ident],
					fetching: false,
					error: void 0,
					completed: true,
				};
			})
			.addCase(pageActions.submitPage.failure, (state, action) => {
				const error = action.payload;
				const { ident } = action.meta;

				state[ident] = {
					...state[ident],
					fetching: false,
					completed: false,
					error,
				};
			}),
});
