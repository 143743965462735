import * as Sentry from '@sentry/browser';
export default async function configureSentry(_app, dsn, userId) {
    // eslint-disable-next-line no-process-env
    if (process.env.REACT_CUVVA_DEVELOPMENT)
        return; // No Sentry locally
    try {
        const options = {
            dsn,
            environment: window.cuvva.client.env,
            release: window.cuvva.deployment.gitCommit,
            tracesSampleRate: 0.2,
            tracePropagationTargets: ['api.prod.cuv-prod.com', /api\.[a-z0-9]+\.cuv-nonprod\.com/],
            integrations: [Sentry.browserTracingIntegration()],
            replaysOnErrorSampleRate: _app === 'ops' ? 0.1 : void 0,
        };
        Sentry.init(options);
        if (userId)
            Sentry.setUser({ id: userId });
    }
    catch (error) {
        // ignore
    }
}
