import moment from 'moment';

export const pastEndDate = (endDate: string) => endDate && moment(endDate).isBefore(moment());

export const policyTimeString = (date: string) => {
	const now = moment();
	const relevantDate = moment(date);
	const diff = relevantDate.diff(now);
	const duration = moment.duration(Math.abs(diff));

	let timeString: string;

	if (duration.asHours() < 1)
		timeString = `${Math.floor(duration.asMinutes())} minute${Math.floor(duration.asMinutes()) > 1 ? 's' : ''}`;
	else if (duration.asHours() < 24)
		timeString = `${Math.floor(duration.asHours())} hour${Math.floor(duration.asHours()) > 1 ? 's' : ''}`;
	else timeString = `${Math.floor(duration.asDays())} day${Math.floor(duration.asDays()) > 1 ? 's' : ''}`;

	return {
		timeString,
		duration,
	};
};

export const formatDate = (dateStr: string) => {
	if (!dateStr) return { date: void 0, time: void 0 };

	const date = new Date(dateStr);

	if (isNaN(date.getTime())) return { date: void 0, time: void 0 };

	const optionsDate: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	};
	const optionsTime: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
	};

	const formattedDate = new Intl.DateTimeFormat('en-GB', optionsDate).format(date);
	const formattedTime = new Intl.DateTimeFormat('en-GB', optionsTime).format(date);

	return { date: formattedDate, time: formattedTime };
};

export const createTimeline = (start: string, end?: string) => {
	if (end) {
		const cleanedStart = start.split(' ').slice(0, 2).join(' ');

		return `${cleanedStart} to ${end}`;
	}

	return start;
};

export function toDaysMinutesSeconds(totalSeconds: number) {
	const seconds = Math.floor(totalSeconds % 60);
	const minutes = Math.floor((totalSeconds % 3600) / 60);
	const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
	const days = Math.floor(totalSeconds / (3600 * 24));

	const secondsStr = makeHumanReadable(seconds, 'second');
	const minutesStr = makeHumanReadable(minutes, 'minute');
	const hoursStr = makeHumanReadable(hours, 'hour');
	const daysStr = makeHumanReadable(days, 'day');

	return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '');
}

type Singular = 'second' | 'minute' | 'hour' | 'day';

function makeHumanReadable(num: number, singular: Singular) {
	return num > 0 ? `${num} ${num > 1 ? `${singular}s ` : singular}` : '';
}
