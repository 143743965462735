import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { BuilderHeroDetail, DesignSystemAsset, HeroAlignment, HeroTheme } from '../types';
import { subHeroInitialState } from '.';
import { getField } from './field';
import { FieldWrap } from './styled';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import { EmbeddedVideoProps } from '~website/components/atoms/EmbeddedVideo';
import { TitleButtonType } from '~website/components/atoms/TitleSegment';

interface HeroBuilderProps {
	state: BuilderHeroDetail;
	setState: (state: BuilderHeroDetail) => void;
}

const HeroBuilder: React.FCWithChildren<HeroBuilderProps> = ({ state, setState }) => {
	const hasSubHero = Boolean(state.sub_hero);

	const toggleSubHero = () => {
		if (!hasSubHero) {
			setState({
				...state,
				sub_hero: cloneDeep(subHeroInitialState),
			});

			return;
		}

		setState({
			...state,
			sub_hero: void 0,
		});
	};

	const updateSubHeroTitleSegmentField = (field: string, value: unknown) => {
		setState({
			...state,
			sub_hero: {
				...state.sub_hero,
				title_segment: {
					...state.sub_hero.title_segment,
					[field]: value,
				},
			},
		});
	};

	return (
		<div>
			{getField({ type: 'option', options: ['dark', 'light'] }, 'theme', state.theme, (theme: HeroTheme) =>
				setState({ ...state, theme })
			)}
			{getField(
				{ type: 'option', options: ['left', 'center'] },
				'alignment',
				state.alignment,
				(alignment: HeroAlignment) => setState({ ...state, alignment })
			)}
			{getField(
				{ type: 'design_system_asset' },
				'main_image',
				state.main_image,
				(main_image: DesignSystemAsset) => setState({ ...state, main_image })
			)}
			{getField(
				{ type: 'design_system_asset' },
				'mobile_image',
				state.mobile_image,
				(mobile_image: DesignSystemAsset) => setState({ ...state, mobile_image })
			)}
			{getField(
				{ type: 'embedded_video' },
				'embedded_video',
				state.embedded_video,
				(embedded_video: EmbeddedVideoProps) => setState({ ...state, embedded_video })
			)}

			<Typography
				$type={'Heading.XSmall'}
				$marginTop={'large'}
				$marginBottom={'regular'}
				$color={'textOnSurfaceBackground'}
			>
				{'Title segment'}
			</Typography>

			{getField({ type: 'string' }, 'title', state.title_segment.title, (title: string) =>
				setState({
					...state,
					title_segment: { ...state.title_segment, title },
				})
			)}

			{getField({ type: 'string' }, 'subtitle', state.title_segment.subtitle, (subtitle: string) =>
				setState({
					...state,
					title_segment: { ...state.title_segment, subtitle },
				})
			)}
			{getField({ type: 'text' }, 'body', state.title_segment.body, (body: string) =>
				setState({
					...state,
					title_segment: { ...state.title_segment, body },
				})
			)}
			<div>
				<Typography $type={'Label.Medium'} $marginBottom={'small'}>
					{'Checklist:'}
				</Typography>
				{getField({ type: 'string[]' }, 'checklist', state.title_segment.checklist ?? [], value => {
					setState({
						...state,
						title_segment: {
							...state.title_segment,
							checklist: value,
						},
					});
				})}
			</div>

			{getField(
				{ type: 'boolean' },
				'show_trustpilot',
				state.title_segment.show_trustpilot,
				(show_trustpilot: boolean) =>
					setState({
						...state,
						title_segment: { ...state.title_segment, show_trustpilot },
					})
			)}
			{getField(
				{ type: 'boolean' },
				'show_app_store_buttons',
				state.title_segment.show_app_store_buttons,
				(show_app_store_buttons: boolean) =>
					setState({
						...state,
						title_segment: { ...state.title_segment, show_app_store_buttons },
					})
			)}
			{getField(
				{ type: 'boolean' },
				'show_app_store_buttons_with_review',
				state.title_segment.show_app_store_buttons_with_review,
				(show_app_store_buttons_with_review: boolean) =>
					setState({
						...state,
						title_segment: { ...state.title_segment, show_app_store_buttons_with_review },
					})
			)}
			{getField({ type: 'cta' }, 'show_button', state.title_segment.show_button, (showButton: TitleButtonType) =>
				setState({
					...state,
					title_segment: {
						...state.title_segment,
						show_button: showButton
							? {
									text: showButton.text,
									url: showButton.url,
									type: showButton.type,
									leading_icon: showButton.leading_icon,
									trailing_icon: showButton.trailing_icon,
									variant: showButton.variant,
									track_click: showButton.track_click,
									event_name: showButton.event_name,
								}
							: void 0,
					},
				})
			)}
			{getField(
				{ type: 'cta[]' },
				'allow_more_buttons',
				state.title_segment.allow_more_buttons ?? [],
				(allow_more_buttons: TitleButtonType[]) =>
					setState({
						...state,
						title_segment: {
							...state.title_segment,
							allow_more_buttons: allow_more_buttons?.map(button => ({
								text: button.text,
								url: button.url,
								type: button.type,
								leading_icon: button.leading_icon,
								trailing_icon: button.trailing_icon,
								variant: button.variant,
								track_click: button.track_click,
								event_name: button.event_name,
							})),
						},
					})
			)}

			<FieldWrap>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{`${hasSubHero ? 'Hide sub hero' : 'Show sub hero'}`}
				</Typography>
				<Button $type={'neutral'} $size={'small'} onClick={toggleSubHero}>
					{'Toggle'}
				</Button>
			</FieldWrap>
			{hasSubHero && (
				<React.Fragment>
					{getField({ type: 'string' }, 'title', state.sub_hero.title_segment.title, (title: unknown) =>
						updateSubHeroTitleSegmentField('title', title)
					)}
					{getField({ type: 'text' }, 'body', state.sub_hero.title_segment.body, (body: unknown) =>
						updateSubHeroTitleSegmentField('body', body)
					)}
					<div>
						<Typography $type={'Label.Medium'} $marginBottom={'small'}>
							{'Checklist:'}
						</Typography>
						{getField({ type: 'string[]' }, 'checklist', state.sub_hero.title_segment.checklist, value => {
							updateSubHeroTitleSegmentField('checklist', value);
						})}
					</div>

					{getField(
						{ type: 'boolean' },
						'show_trustpilot',
						state.sub_hero.title_segment.show_trustpilot,
						(show_trustpilot: unknown) => updateSubHeroTitleSegmentField('show_trustpilot', show_trustpilot)
					)}
					{getField(
						{ type: 'cta' },
						'show_button',
						state.sub_hero.title_segment.show_button,
						(show_button: unknown) => updateSubHeroTitleSegmentField('show_button', show_button)
					)}
					{getField(
						{ type: 'design_system_asset' },
						'asset',
						state.sub_hero.asset,
						(asset: DesignSystemAsset) => setState({ ...state, sub_hero: { ...state.sub_hero, asset } })
					)}
				</React.Fragment>
			)}
		</div>
	);
};

export default HeroBuilder;
