import React from 'react';

import { AdapterProps } from '../types';
import { DesignSystemAsset } from '~lib/frontend/types/content';
import HowItWorks from '~website/components/atoms/HowItWorksSegment';
import TwoColumnsLayout, { TwoColumnsImage } from '~website/components/atoms/layout/TwoColumns';
import TrustSegment from '~website/components/atoms/TrustSegment';

interface ExtraProps {
	id?: string;
	flip?: boolean;
	asset?: DesignSystemAsset;
	title?: string;
}

const adapter: React.FCWithChildren<AdapterProps> = ({ content, getter }) => {
	const { id, flip, asset } = content as ExtraProps;

	return (
		<TwoColumnsLayout flip={flip} id={id}>
			<HowItWorks get={getter} />
			<div>
				<TwoColumnsImage asset={asset} />
				<TrustSegment trustProvider={'trustpilot'} />
			</div>
		</TwoColumnsLayout>
	);
};

export default {
	type: 'how_it_works',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			flip: { type: 'boolean' },
			asset: { type: 'design_system_asset' },
			title: { type: 'string' },
			steps: {
				type: 'object[]',
				fields: {
					title: { type: 'string' },
					body: { type: 'string' },
				},
			},
			show_trustpilot: { type: 'boolean' },
		},
		default: {
			flip: false,
			asset: {
				type: 'image',
				value: 'iphone-12-pro-graphite.png',
				description: '',
			},
			title: 'How it works',
			steps: [
				{
					title: 'Generate a fresh link',
					body: 'To shout about Cuvva',
				},
				{
					title: 'Share with a friend',
					body: 'Who needs a short or long policy',
				},
				{
					title: 'Get between £10 and £60',
					body: 'When they hit the road!',
				},
			],
			show_trustpilot: true,
		},
	},
};
