import styled from 'styled-components';

import { UIColors } from '~lib/frontend/design-system/types';

interface SubStepsProps {
	/** @param $color Color token from the UI theme */
	$color?: keyof UIColors;
	/** @param $style Style of the number indicator */
	$style?: 'solid' | 'outline';
}

export const SubSteps = styled.ol<SubStepsProps>`
	list-style: none;

	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.semiLarge};
	padding-inline-start: 52px;
	margin: ${p => p.theme.spacing.extraLarge} 0;

	counter-reset: my-counter;

	li {
		counter-increment: my-counter;
		position: relative;
		min-height: 36px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&::before {
			content: counter(my-counter);

			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: ${p => (p.$style === 'outline' ? p.theme.ui[p.$color || 'blackFill'] : p.theme.ui.textOnFill)};
			background: ${p => (p.$style === 'outline' ? 'transparent' : p.theme.ui[p.$color || 'blackFill'])};
			box-shadow: ${p =>
				p.$style === 'outline' ? `inset 0 0 0 2px ${p.theme.ui[p.$color || 'blackFill']}` : 'none'};
			border-radius: 50%;
			text-align: center;

			${p => p.theme.typography['Label.Large']()};

			--size: 36px;
			left: calc(-1 * var(--size) - 16px);
			line-height: var(--size);
			width: var(--size);
			height: var(--size);
		}
	}

	a {
		&,
		&:visited,
		&:hover,
		&:active {
			text-decoration: underline;
			color: ${props => props.theme.ui.textHighlight};
		}
	}
`;
