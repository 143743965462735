import { ErrorCode } from '~lib/platform/code-mapping/types';

const getCustomCodes = (name: string, code: string): ErrorCode[] => [
	{
		code: 'email_already_exists',
		message: `Please sign in the app and add your personal code "${code?.toUpperCase()}" from your account section.`,
		category: void 0,
	},
	{
		code: 'invalid_code',
		message: `Something went wrong. Please ask ${name} to generate a new referral code to claim your £40.`,
		category: void 0,
	},
];

export default getCustomCodes;
