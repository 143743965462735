import React from 'react';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';
import EverybodyGets5Stars from '~website/assets/svgs/referral/EverybodyGets5Stars';
import TrustpilotInline from '~website/assets/svgs/referral/TrustpilotInline';
import { useWebsiteCoreContent } from '~website/hooks/use-segment';

const WhatCustomerSaySection = styled.div`
	margin: 170px auto;
	max-width: 1100px;
	padding: 0 25px;
	text-align: center;

	@media (max-width: 767px) {
		margin: 75px auto;
	}

	> div {
		white-space: nowrap;

		@media (max-width: 767px) {
			white-space: normal;
		}

		> * {
			display: inline;
		}

		> svg {
			max-width: 250px;
			vertical-align: bottom;
		}
	}

	> * + * {
		margin-top: 55px;
	}
`;

const ReviewDescription = styled.div`
	text-align: center;

	> div {
		display: inline-block;

		> * {
			margin-left: 5px;
		}
	}

	@media (min-width: 768px) {
		> div + div {
			margin-left: 48px;
		}
	}
`;

const ReviewsGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 42px;
	margin-left: 20px;
	margin-right: 20px;

	@media (max-width: 767px) {
		overflow-x: scroll;
		scroll-snap-type: x mandatory;
	}
`;

const ReviewItem = styled.div`
	white-space: normal;
	text-align: left;

	> svg {
		width: 178px;
	}

	@media (max-width: 767px) {
		width: 60vw;
		max-width: 330px;
		scroll-snap-align: start;
	}
`;

const WidgetTrustpilot: React.FunctionComponent = () => {
	const coreSegment = useWebsiteCoreContent();

	const content = coreSegment && coreSegment.content.widgets.trustpilot;
	const globalTrustpilot = coreSegment && coreSegment.content.global && coreSegment.content.global.trustpilot;

	if (!content || !globalTrustpilot) return null;

	return (
		<WhatCustomerSaySection>
			<div>
				<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
					{content.inline_title}
				</Typography>
				<TrustpilotInline />
			</div>
			<ReviewDescription>
				<div>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $display={'inline'}>
						{'Our customers say '}
					</Typography>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $display={'inline'} $bold>
						{content.rating}
					</Typography>
				</div>
				<div>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $display={'inline'} $bold>
						{content.stars}
					</Typography>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $display={'inline'}>
						{'stars out of 5 based on'}
					</Typography>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $display={'inline'} $bold>
						{content.review_count}
					</Typography>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $display={'inline'}>
						{'reviews'}
					</Typography>
				</div>
			</ReviewDescription>
			<ReviewsGrid>
				{globalTrustpilot.testimonials.map((r, i) => (
					<ReviewItem key={i}>
						<EverybodyGets5Stars />
						<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
							{r.review}
						</Typography>
						<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $bold>
							{r.name}
						</Typography>
					</ReviewItem>
				))}
			</ReviewsGrid>
		</WhatCustomerSaySection>
	);
};

export default WidgetTrustpilot;
