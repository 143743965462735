import { createSlice } from '@reduxjs/toolkit';
import { createAsyncMapAction } from '../actions';
export default function createAsyncMapSlice(name, transform, initialState = {}) {
    const actions = createAsyncMapAction(name, transform);
    const slice = createSlice({
        name,
        initialState,
        reducers: {},
        extraReducers: builder => builder
            .addCase(actions.request, (state, action) => ({
            ...state,
            [action.meta.ident]: {
                ...state[action.meta.ident],
                fetching: true,
            },
        }))
            .addCase(actions.success, (s, action) => {
            const state = s;
            return {
                ...state,
                [action.meta.ident]: {
                    fetching: false,
                    response: action.payload,
                },
            };
        })
            .addCase(actions.failure, (s, action) => {
            const state = s;
            return {
                ...state,
                [action.meta.ident]: {
                    fetching: false,
                    error: action.payload,
                },
            };
        })
            .addCase(actions.reset, (s, action) => {
            const state = s;
            return {
                ...state,
                [action.meta.ident]: {
                    fetching: false,
                },
            };
        }),
    });
    return {
        ...slice,
        actions,
    };
}
