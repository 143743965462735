import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import IncidentMiniCard from '../../molecules/IncidentMiniCard';
import { IncidentsProps } from '../Incidents';
import IncidentEditCard from './IncidentEditCard';
import IncidentMiniCardCR from './IncidentMiniCardCR';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import useIncidentsInternalChangeRequest from '~website/features/quote/hooks/use-incident-change-request';

const IncidentsList: React.FCWithChildren<IncidentsProps> = ({ setDisable }) => {
	const {
		beenDeclared,
		beenInvolved,
		totalCount,
		addIncident,
		deleteIncident,
		onChange: onChangeRadio,
	} = useIncidentsInternalChangeRequest();
	const [openCard, setOpenCard] = useState<number>(void 0);

	const userId = useSelector(s => s.internal.auth.user.response);
	const latestIncidents = useSelector(s => s.platform.incidents.listLatestIncidents[userId]);

	useEffect(() => {
		if (beenInvolved === false) {
			setOpenCard(void 0);

			if (totalCount > 0) {
				Array.from({ length: totalCount }).forEach((_, i) => deleteIncident(i));
			}
		}

		if (beenInvolved === true && totalCount === 0) {
			addIncident();
			setOpenCard(0);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [beenInvolved, totalCount]);

	useEffect(() => {
		if (!beenDeclared && openCard === void 0) setDisable(false);

		if (!beenDeclared && openCard >= 0) setDisable(true);
	}, [beenDeclared, openCard, setDisable]);

	const onNewIncident = (e: React.MouseEvent<HTMLButtonElement>) => {
		addIncident();
		setOpenCard(totalCount);

		e.stopPropagation();
		e.preventDefault();
	};

	const onSave = (_index: number) => {
		setOpenCard(void 0);
	};

	const onCancel = (_index: number) => {
		if (totalCount === 1) onChangeRadio(false);

		setOpenCard(void 0);
	};

	return (
		<Fragment>
			{!beenDeclared &&
				Array.from({ length: totalCount }).map((_, i) => {
					if (openCard === i) {
						return (
							<Margin
								$marginTop={'extraLarge'}
								$marginBottom={'extraLarge'}
								key={`incident:${openCard}:${totalCount}`}
							>
								<IncidentEditCard
									key={`incident:${openCard}:${totalCount}`}
									index={i}
									onSave={() => onSave(openCard)}
									onCancel={() => onCancel(openCard)}
								/>
							</Margin>
						);
					}

					return (
						<Margin $marginTop={'extraLarge'} $marginBottom={'large'}>
							<IncidentMiniCardCR
								key={`mini-card:${i}:${totalCount}`}
								index={i}
								frosted={openCard !== void 0}
								onEdit={() => setOpenCard(i)}
							/>
						</Margin>
					);
				})}

			{beenDeclared &&
				latestIncidents?.response?.incidents?.map(i => (
					<Margin key={i.id} $marginTop={'extraLarge'}>
						<IncidentMiniCard key={`mini-card:${i.id}`} category={i.category} date={i.date} />
					</Margin>
				))}

			{!beenDeclared && totalCount > 0 && openCard === void 0 && (
				<Margin $marginTop={'large'}>
					<Button $type={'primaryMuted'} $size={'large'} $stretch={true} onClick={onNewIncident}>
						{'Add another incident'}
					</Button>
				</Margin>
			)}
		</Fragment>
	);
};

export default IncidentsList;
