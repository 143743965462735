import { toHexAlpha } from './utils';
import { TypedObject } from '~lib/shared/helpers/typed';
const lightThemeMapping = {
    primaryFill: 'indigo500',
    primaryFillMuted: 'indigo050',
    successFill: 'green500',
    successFillMuted: 'green050',
    warningFill: 'orange500',
    warningFillMuted: 'orange050',
    infoFill: 'blue500',
    infoFillMuted: 'blue050',
    dangerFill: 'red500',
    dangerFillMuted: 'red050',
    blankFill: 'white',
    blankFillMuted: 'white',
    surfaceFill: 'white',
    surfaceFillMuted: 'grey050',
    neutralFill: 'indigo1000',
    neutralFillMuted: 'grey600',
    neutralFillMinor: 'grey500',
    neutralFillMinorMuted: 'grey300',
    blackFill: 'indigo1000',
    background: 'grey050',
    secondaryBackground: 'indigo1000',
    blankBackground: 'white',
    textOnAction: 'white',
    textOnFill: 'white',
    textOnFillMuted: 'white',
    textHighlight: 'indigo500',
    textSuccess: 'green700',
    textDanger: 'red700',
    textWarning: 'orange700',
    textInfo: 'blue700',
    textBlack: 'indigo1000',
    textOnSurfaceBackground: 'indigo1000',
    textOnSurfaceBackgroundMuted: 'grey600',
    textMinor: 'grey500',
    textMinorMuted: 'grey300',
    primaryAction: 'indigo500',
    primaryActionMuted: 'indigo050',
    dangerAction: 'red500',
    dangerActionMuted: 'red050',
    neutralAction: 'white',
    neutralActionMuted: 'grey100',
    neutralActionMutedOnFill: 'white',
    surface: 'white',
    surfaceHighlight: 'indigo500',
    surfaceDark: 'indigo900',
    secondarySurface: 'white',
    borderSeparator: 'grey100',
    borderSeparatorOnFill: 'white',
    borderInteractiveDefault: 'grey300',
    borderInteractiveHover: 'indigo1000',
    borderInteractiveFocus: 'indigo500',
    borderInteractiveSelected: 'indigo200',
    borderInteractiveDanger: 'red500',
    borderInteractiveDisabled: 'grey200',
    violetGradientLeading: 'violetGradientLeading',
    violetGradientCentre: 'violetGradientCentre',
    violetGradientTrailing: 'violetGradientTrailing',
    modifiers: {
        blankFillMuted: { type: 'opacity', value: 0.2 },
        textOnFillMuted: { type: 'opacity', value: 0.8 },
        neutralActionMutedOnFill: { type: 'opacity', value: 0.2 },
        borderSeparatorOnFill: { type: 'opacity', value: 0.2 },
    },
};
const darkThemeMapping = {
    primaryFill: 'indigo300',
    primaryFillMuted: 'indigo500',
    successFill: 'green500',
    successFillMuted: 'green500',
    warningFill: 'orange500',
    warningFillMuted: 'orange500',
    infoFill: 'blue500',
    infoFillMuted: 'blue500',
    dangerFill: 'red500',
    dangerFillMuted: 'red500',
    blankFill: 'white',
    blankFillMuted: 'white',
    surfaceFill: 'grey850',
    surfaceFillMuted: 'grey500',
    neutralFill: 'white',
    neutralFillMuted: 'grey050',
    neutralFillMinor: 'grey050',
    neutralFillMinorMuted: 'grey050',
    blackFill: 'indigo1000',
    background: 'black',
    secondaryBackground: 'black',
    blankBackground: 'black',
    textOnAction: 'white',
    textOnFill: 'white',
    textOnFillMuted: 'grey050',
    textHighlight: 'indigo300',
    textSuccess: 'green500',
    textDanger: 'red500',
    textWarning: 'orange500',
    textInfo: 'blue500',
    textBlack: 'indigo1000',
    textOnSurfaceBackground: 'white',
    textOnSurfaceBackgroundMuted: 'grey050',
    textMinor: 'grey050',
    textMinorMuted: 'grey050',
    primaryAction: 'indigo400',
    primaryActionMuted: 'indigo500',
    dangerAction: 'red500',
    dangerActionMuted: 'red500',
    neutralAction: 'white',
    neutralActionMuted: 'grey500',
    neutralActionMutedOnFill: 'white',
    surface: 'grey900',
    surfaceHighlight: 'indigo500',
    surfaceDark: 'indigo900',
    secondarySurface: 'grey900',
    borderSeparator: 'grey500',
    borderSeparatorOnFill: 'white',
    borderInteractiveDefault: 'grey050',
    borderInteractiveHover: 'white',
    borderInteractiveFocus: 'indigo300',
    borderInteractiveSelected: 'indigo600',
    borderInteractiveDanger: 'red500',
    borderInteractiveDisabled: 'grey500',
    violetGradientLeading: 'violetGradientLeading',
    violetGradientCentre: 'violetGradientCentre',
    violetGradientTrailing: 'violetGradientTrailing',
    modifiers: {
        primaryFillMuted: { type: 'opacity', value: 0.2 },
        successFillMuted: { type: 'opacity', value: 0.2 },
        surfaceFillMuted: { type: 'opacity', value: 0.15 },
        warningFillMuted: { type: 'opacity', value: 0.2 },
        infoFillMuted: { type: 'opacity', value: 0.2 },
        blankFillMuted: { type: 'opacity', value: 0.2 },
        dangerFillMuted: { type: 'opacity', value: 0.2 },
        neutralFillMuted: { type: 'opacity', value: 0.7 },
        neutralFillMinor: { type: 'opacity', value: 0.5 },
        neutralFillMinorMuted: { type: 'opacity', value: 0.3 },
        textOnFillMuted: { type: 'opacity', value: 0.7 },
        textOnSurfaceBackgroundMuted: { type: 'opacity', value: 0.7 },
        textMinor: { type: 'opacity', value: 0.5 },
        textMinorMuted: { type: 'opacity', value: 0.3 },
        primaryActionMuted: { type: 'opacity', value: 0.2 },
        dangerActionMuted: { type: 'opacity', value: 0.2 },
        neutralActionMuted: { type: 'opacity', value: 0.2 },
        neutralActionMutedOnFill: { type: 'opacity', value: 0.2 },
        borderSeparator: { type: 'opacity', value: 0.15 },
        borderSeparatorOnFill: { type: 'opacity', value: 0.2 },
        borderInteractiveDefault: { type: 'opacity', value: 0.3 },
        borderInteractiveFocus: { type: 'opacity', value: 0.3 },
        borderInteractiveDisabled: { type: 'opacity', value: 0.3 },
    },
};
function createUiColors(brand, theme) {
    const mapping = theme === 'light' ? lightThemeMapping : darkThemeMapping;
    const colors = TypedObject.keys(mapping)
        .filter(k => k !== 'modifiers')
        .reduce((acc, key) => {
        const value = mapping[key];
        return {
            ...acc,
            [key]: brand[value],
        };
    }, {});
    if (mapping.modifiers) {
        TypedObject.keys(mapping.modifiers).forEach(k => {
            const modifier = mapping.modifiers[k];
            if (!modifier)
                return;
            switch (modifier.type) {
                case 'opacity':
                    colors[k] = toHexAlpha(colors[k], modifier.value);
                    break;
                default:
                    break;
            }
        });
    }
    return colors;
}
export { createUiColors };
