import React from 'react';
import styled from 'styled-components';

import { IconType } from '~lib/frontend/design-system/assets/SvgIcon';
import IconWrapped from '~lib/frontend/design-system/components/IconWrapped';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';

const WhatsCovered: React.FunctionComponent = () => {
	const g = useContentKey('website_core');
	const whatsCovered: [IconType, string, string][] = g('pages.quote.whats_covered', []);

	return (
		<CoverDetails>
			{whatsCovered.map(([icon, title, text]) => (
				<CoverDetail key={title}>
					<IconWrapped $size={'medium'} $type={'square'} $style={'primary'} icon={icon} />
					<div>
						<Typography
							$type={'Body.Medium'}
							$bold
							$color={'textOnSurfaceBackground'}
							$marginBottom={'small'}
						>
							{title}
						</Typography>
						<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
							{text}
						</Typography>
					</div>
				</CoverDetail>
			))}
		</CoverDetails>
	);
};

const CoverDetails = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.extraLarge};
`;

const CoverDetail = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};

	& > :first-child {
		flex: 0 0 40px;
	}

	@media screen and (min-width: 1024px) {
		flex-direction: row;
	}
`;

export default WhatsCovered;
