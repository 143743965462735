import React from 'react';
import styled from 'styled-components';

import { IlloWrapper } from './MakeAClaim';
import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as Alert from '~lib/frontend/design-system/components/dialog/molecules/Alert';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

interface CancelASubscriptionModalProps {
	open: boolean;
	onClose: () => void;
}

const CancelSubscriptionModal: React.FCWithChildren<CancelASubscriptionModalProps> = props => {
	const { open, onClose } = props;

	const helpHead = useDesignSystemAsset({ type: 'illustration', value: 'il_help_header.svg' });

	return (
		<Alert.Dialog
			centered
			open={open}
			onClose={onClose}
			title={'Cancel your subscription'}
			illustration={
				<ImgWrapper>
					<picture>
						<img src={helpHead} alt={'chat head'} />
					</picture>
				</ImgWrapper>
			}
		>
			<Alert.Content>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
					{'Need to stop your cover? We can help – just chat to the team and they’ll be able to sort it out.'}
				</Typography>
			</Alert.Content>
			<Alert.Actions>
				<Button
					as={Anchor}
					$type={'primary'}
					// @ts-ignore
					href={'mailto:support@cuvva.com'}
					className={'intercom-launcher'}
					onClick={onClose}
				>
					{'Chat with us'}
				</Button>
				<Button $type={'neutralMuted'} onClick={onClose}>
					{'Close'}
				</Button>
			</Alert.Actions>
		</Alert.Dialog>
	);
};

export default CancelSubscriptionModal;

const ImgWrapper = styled(IlloWrapper)`
	img {
		width: 100px;
	}
`;
