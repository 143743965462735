import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Icon from '~lib/frontend/design-system/components/Icon';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import { GetterType } from '~lib/frontend/types/content';

export interface EmbeddedVideoProps {
	provider: string;
	source: string;
	autoplay: boolean;
	thumbnail?: string;
	title?: string;
	body?: string;
	id?: string;
}

const EmbeddedVideo: React.FCWithChildren<EmbeddedVideoProps> = props => {
	const { provider, source, thumbnail, title } = props;
	const [clicked, setClicked] = useState(false);
	const thumbnailSrc = useContentAsset('website_core', thumbnail);

	if (provider !== 'youtube') return null;

	const fullSource = `https://www.youtube.com/embed/${source}?showinfo=0&autoplay=1`;

	return (
		<Wrapper>
			<Inner $thumbnail={thumbnailSrc} onClick={clicked ? void 0 : () => setClicked(true)} $loaded={clicked}>
				{clicked && (
					<Iframe
						width={560}
						height={315}
						src={fullSource}
						title={title}
						allow={
							'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						}
						// @ts-expect-error
						allowfullscreen
						frameborder={0}
					/>
				)}
			</Inner>
			{!clicked && (
				<IconWrap>
					<Icon icon={'ic_play_circle_fill'} $size={'96px'} $color={'blankFill'} />
				</IconWrap>
			)}
		</Wrapper>
	);
};

export const contentAdapter = (get: GetterType): EmbeddedVideoProps => {
	const provider = get<string>('provider', '');
	const source = get<string>('source', '');
	const autoplay = get<boolean>('autoplay', false);
	const thumbnail = get<string>('thumbnail');
	const title = get<string>('title', '');
	const body = get<string>('body', '');
	const id = get<string>('id');

	return {
		title,
		body,
		id,
		provider,
		thumbnail,
		source,
		autoplay,
	};
};

const IconWrap = styled.div`
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`;

export const Wrapper = styled.div`
	position: relative;
	text-align: center;
	margin: 0 auto;

	width: 100%;
	max-width: 1280px;
	height: 100%;
`;

const Inner = styled.div<{ $thumbnail: string; $loaded: boolean }>`
	position: relative;
	padding-top: 57%;

	${p =>
		p.$thumbnail &&
		`
		background: url(${p.$thumbnail});
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	`};

	display: flex;
	transition: transform 0.3s;

	filter: blur(0);

	${p =>
		!p.$loaded &&
		css`
			cursor: pointer;

			&:hover {
				filter: blur(1px);
			}
		`};
`;

const Iframe = styled.iframe`
	position: absolute;
	border: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export default EmbeddedVideo;
