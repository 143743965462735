import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
export const useResizeObserver = (ref, callback, debounceDelay = 400) => {
    const [width, setWidth] = useState(void 0);
    const [height, setHeight] = useState(void 0);
    const handleResize = useCallback((entries) => {
        if (!Array.isArray(entries))
            return;
        const entry = entries[0];
        setWidth(entry.contentRect.width);
        setHeight(entry.contentRect.height);
        if (callback)
            callback(entry.contentRect);
    }, [callback]);
    const debouncedFn = useMemo(() => debounce(handleResize, debounceDelay, { leading: true }), [handleResize, debounceDelay]);
    useEffect(() => {
        if (!ref.current)
            return void 0;
        const resizeObserver = new ResizeObserver(debouncedFn);
        resizeObserver.observe(ref.current);
        return () => {
            debouncedFn.cancel();
            resizeObserver.disconnect();
        };
    }, [debouncedFn, ref]);
    return [width, height];
};
export default useResizeObserver;
