import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import Typography from '~lib/frontend/design-system/components/Typography';
import useSegment from '~lib/frontend/hooks/use-segment';

const IncidentCase = styled(Typography)`
	padding: ${p => p.theme.spacing.extraSmall} ${p => p.theme.spacing.regular};
	background: ${p => p.theme.ui.background};
`;

const CasesWrap = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	margin: ${p => p.theme.spacing.large} ${p => p.theme.spacing.large} ${p => p.theme.spacing.large} 0;
`;

const IncidentDialogHint: React.FunctionComponent = () => {
	const contentString = useSegment('strings');
	const body = contentString?.content?.app_settings?.edit_account?.incidents?.incidents_explain_view;

	const [open, setOpen] = useState(false);
	const onClose = () => setOpen(false);

	if (!body) return null;

	return (
		<Fragment>
			<Button $type={'text'} type={'button'} onClick={() => setOpen(true)} $size={'medium'}>
				{'What counts as an incident'}
			</Button>

			<ComplexDialog.Dialog open={open} title={body.title} onClose={onClose}>
				<ComplexDialog.Content>
					{body.scenario.header}

					<CasesWrap>
						{(body.scenario.items as string[]).map(i => (
							<IncidentCase
								key={i}
								$type={'Label.Small'}
								$display={'inline-block'}
								$bold
								$color={'textOnSurfaceBackgroundMuted'}
							>
								{i}
							</IncidentCase>
						))}
					</CasesWrap>

					{body.scenario.footer}
				</ComplexDialog.Content>
				<ComplexDialog.Actions>
					<Button onClick={onClose}>{'Got it'}</Button>
				</ComplexDialog.Actions>
			</ComplexDialog.Dialog>
		</Fragment>
	);
};

export default IncidentDialogHint;
