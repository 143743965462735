import { all, fork } from 'redux-saga/effects';
import createVehicleProfile from './create-vehicle-profile';
import getVehicleProfile from './get-vehicle-profile';
import listVehicleProfiles from './list-vehicle-profiles';
import updateVehicleProfile from './update-vehicle-profile';
import userVehicleProfile from './user-vehicle-profile';
export default function* vehicleProfileSaga() {
    yield all([
        fork(getVehicleProfile),
        fork(listVehicleProfiles),
        fork(updateVehicleProfile),
        fork(createVehicleProfile),
        userVehicleProfile,
    ]);
}
