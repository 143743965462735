import URI from 'urijs';

import { delay } from './timeout';

// 'quick-quote' | 'web-to-app' | 'quick-quote-sms-test' | 'referral' | 'borrow_permission';

/*
 * @deprecated
 */
export async function generateDeepLink(feature: string, branchData: Record<string, string>) {
	const getLink = new Promise((resolve, reject) => {
		if (typeof window === 'undefined') return resolve(buildFallbackLink(feature, branchData));

		const uriParams: Record<string, string> = new URI(window.location.href).query(true);
		const objectParams = {
			cuv_campaign: uriParams['cuv_campaign'] || '',
			gclid: uriParams.gclid || '',
			keyword: uriParams.keyword || '',
			...branchData,
		};

		const fallbackLink = buildFallbackLink(feature, objectParams);

		// check if the script has been blocked by the adblock
		// @ts-ignore
		if (typeof window.branch === 'undefined') return resolve(fallbackLink);

		try {
			// @ts-ignore
			window.branch.link(
				{
					channel: 'web',
					feature,
					// eslint-disable-next-line id-blacklist
					data: {
						$desktop_deepview: 'cuvva_deepview_p2fb',
						previous_page: window.document.referrer,
						f: feature,
						...objectParams,
					},
				},
				(error: unknown, link: string) => {
					if (error) return reject(error);

					return resolve(link);
				}
			);
		} catch {
			return resolve(fallbackLink);
		}

		return void 0;
	});

	// If the branch SDK doesn't resolve after 1000 ms, assume it is being blocked by the
	// user's adblocker and continue as planned
	return await Promise.race([getLink, delay(1000)]);
}

/*
 * @deprecated
 */
function buildFallbackLink(feature: string, branchData: Record<string, string>) {
	const parts = {
		f: feature,
		...branchData,
	};

	const uri = new URI('https://cuvva.insure/QuickQuoteShortTerm');

	return uri.query(parts).toString();
}
