import React, { forwardRef } from 'react';
import Icon from '../Icon';
import ButtonWrapper from './atoms/ButtonWrapper';
import IconWrapper from './atoms/IconWrapper';
import Overlay from './atoms/Overlay';
import SpinnerWrapper from './atoms/SpinnerWrapper';
const IconButton = forwardRef((props, ref) => {
    const { $icon, $size = 'medium', $type = 'primary', $loading, $display, 'aria-label': ariaLabel, ...otherProps } = props;
    const defaultAriaLabel = $icon ? `Icon button with ${$icon}` : 'Icon button';
    return (React.createElement(ButtonWrapper, { ...otherProps, "$size": $size, "$type": $type, "$loading": $loading, "$display": $display, "$iconOnly": true, ref: ref, "aria-label": ariaLabel || defaultAriaLabel },
        $loading && (React.createElement(SpinnerWrapper, { buttonType: $type, size: $size },
            React.createElement(Icon, { icon: 'ic_loading_spinner', "$size": '18px', "$color": 'primaryFill' }))),
        React.createElement(IconWrapper, { "$type": $type }, $icon && React.createElement(Icon, { icon: $icon, "$size": '18px', "$color": 'primaryFill' })),
        React.createElement(Overlay, { size: $size, buttonType: $type })));
});
export default IconButton;
