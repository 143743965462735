import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IdentifierModalInput from '../molecules/IdentifierModalInput';
import IdentifierModalToken from '../molecules/IdentifierModalToken';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { userById } from '~lib/platform/auth/store/actions';
import { errorCodes } from '~lib/platform/code-mapping/store/actions';
import useUser from '~website/hooks/use-user';

interface EditPrimaryIdentifierProps {
	type: 'email' | 'mobile_phone';
}

const EditPrimaryIdentifier: React.FCWithChildren<EditPrimaryIdentifierProps> = ({ type }) => {
	const dispatch = useDispatch();
	const { userId } = useUser();
	const userByIdStore = useSelector(s => s.platform.auth.userById[`${userId}-false`]);
	const errorCodesStore = useSelector(s => s.platform.codeMapping.errorCodes);
	const errorCodesStatus = useAsyncStateStatus(errorCodesStore).status;

	const [step, setStep] = useState(0);
	const [open, setOpen] = useState(false);
	const [newIdentifier, setNewIdentifier] = useState<string>(void 0);

	const onCancel = useCallback(() => {
		setStep(0);
		setOpen(false);
		setNewIdentifier(void 0);
	}, []);

	useEffect(() => {
		if (!open) onCancel();
	}, [onCancel, open]);

	useEffect(() => {
		if (errorCodesStatus === 'pending') dispatch(errorCodes.request(void 0));
	}, [dispatch, errorCodesStatus]);

	const identifiers = userByIdStore?.response?.identifiers;
	const identifier = identifiers?.find(i => i.primary && i.type === type);

	const onSendToken = (identifier: string) => {
		setNewIdentifier(identifier);
		setStep(1);
	};

	const onComplete = () => {
		dispatch(userById.request({ userId, includeRemovedIdentifiers: false }));
		setOpen(false);
	};

	if (!identifier) return null;

	return (
		<React.Fragment>
			<Button $type={'text'} $size={'medium'} onClick={() => setOpen(true)}>
				{'Edit'}
			</Button>
			<ComplexDialog.Dialog
				open={open}
				onClose={() => setOpen(false)}
				title={`Edit ${type === 'email' ? 'email address' : 'phone number'}`}
			>
				{step === 0 && (
					<IdentifierModalInput
						userId={userId}
						type={type}
						initialValue={identifier.value}
						onCancel={onCancel}
						onProceed={onSendToken}
					/>
				)}

				{step === 1 && (
					<IdentifierModalToken
						userId={userId}
						type={type}
						identifier={newIdentifier}
						onCancel={onCancel}
						onComplete={onComplete}
					/>
				)}
			</ComplexDialog.Dialog>
		</React.Fragment>
	);
};

export default EditPrimaryIdentifier;
