import React, { Children, cloneElement, createContext, isValidElement, useContext, } from 'react';
import { LinedUpButtonArea, StackedButtonArea } from '../atoms/ActionsAlignment';
import { ComplexDialogActionsArea, ComplexDialogContainer, ComplexDialogContent, ComplexDialogTitleBar, } from '../atoms/ComplexDialogStyle';
import { IconContainer } from '../atoms/Icon';
import { defaultButtonTypes } from '../types';
import Modal from './Modal';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useMediaQuery from '~lib/frontend/hooks/use-media-query';
export { default as Illustration } from './Illustration';
const Alignment = createContext({ isCentered: false });
export const Dialog = props => {
    const { title, children, open, onClose, verticalAlign = 'center', size = 'small', dismissible = true, preventOutsideClick = false, allowFocusTrapOutsideClick = false, } = props;
    const isDesktop = useMediaQuery('(min-width: 768px)');
    return (React.createElement(Modal, { dismissible: dismissible, title: title, open: open, onClose: onClose, verticalAlign: verticalAlign, preventOutsideClick: preventOutsideClick, allowFocusTrapOutsideClick: allowFocusTrapOutsideClick },
        React.createElement(ComplexDialogContainer, { "$size": size },
            React.createElement(ComplexDialogTitleBar, null,
                !isDesktop && (React.createElement(Margin, { "$marginRight": 'large' },
                    React.createElement(IconContainer, { "$noDisplay": true },
                        React.createElement(Icon, { icon: 'ic_navigation_back', "$size": '24px', "$color": 'neutralFillMinor' })))),
                React.createElement(Typography, { "$type": isDesktop ? 'Heading.Small' : 'Label.Medium', "$color": 'textOnSurfaceBackground', "$align": isDesktop ? 'left' : 'center' }, title),
                React.createElement(Margin, { "$marginLeft": isDesktop ? 'extraLarge' : 'large' }, dismissible && (React.createElement(IconContainer, { tabIndex: -1, onClick: onClose },
                    React.createElement(Icon, { icon: 'ic_cross_outline', "$size": '24px', "$color": 'neutralFillMinor' }))))),
            React.createElement(Alignment.Provider, { value: { isCentered: !isDesktop } }, children))));
};
export const Content = ({ children, noPadding = false }) => (React.createElement(ComplexDialogContent, { "$noPadding": noPadding }, children));
export const Actions = ({ children }) => {
    const alignment = useContext(Alignment);
    const ActionsContainer = alignment.isCentered ? StackedButtonArea : LinedUpButtonArea;
    const newActions = Children.map(children, (action, index) => {
        if (!isValidElement(action))
            return null;
        const type = action.props.type || defaultButtonTypes[index];
        return cloneElement(action, {
            fullWidth: alignment.isCentered,
            size: 'large',
            type,
        });
    })?.reverse();
    return (React.createElement(ComplexDialogActionsArea, null,
        React.createElement(ActionsContainer, null, newActions)));
};
