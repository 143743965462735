import styled from 'styled-components';
const TableHead = styled.th `
	${p => p.$width &&
    `width:${p.$width} !important; 
		min-width: ${p.$width} !important; 
		max-width: ${p.$width} !important;`};

	text-transform: capitalize;
	white-space: nowrap;
	color: ${p => p.theme.ui.textOnSurfaceBackgroundMuted};
	font-weight: bold;
	${p => p.$align && `text-align: ${p.$align} !important`};
`;
export default TableHead;
