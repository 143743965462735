import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import SplitTest from '~lib/frontend/helpers/user-testing';
import { ApplicationState } from '~website/store';

interface Experiment {
	size: number;
	value: React.FCWithChildren;
}

interface MVExperimentsProps {
	testId?: string;
	experiments?: Experiment[];
}

const MVExperiments: React.FCWithChildren<MVExperimentsProps> = props => {
	const { testId, experiments } = props;
	const userId: string = useSelector((state: ApplicationState) => state.internal.auth.user.response);

	const rendered = useRef<React.FCWithChildren>();

	useEffect(() => {
		if (!userId) return;

		const valuePropStrategy = new SplitTest(testId, experiments);

		valuePropStrategy.evaluate(userId).then(value => {
			rendered.current = value;
		});
	}, [experiments, testId, userId]);

	if (!rendered?.current) return null;

	const RenderedComponent = rendered.current;

	return <RenderedComponent />;
};

export default MVExperiments;
