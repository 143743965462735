import React from 'react';

import Container from './atoms/Container';
import ContentSide from './atoms/ContentSide';
import NotScrollable from './atoms/NotScrollable';
import SplashSide from './atoms/SplashSide';
import VisionaryContext from '~website/contexts/VisionaryContext';
export { default as ContentContainer } from './atoms/ContentContainer';
export { default as TrustpilotContent } from './molecules/TrustpilotContent';

interface SplitUIProps {
	splashImage: string;
	splashContent?: React.ReactNode;
}

const SplitUI: React.FCWithChildren<SplitUIProps> = ({ children, splashContent, splashImage }) => (
	<Container>
		<ContentSide>{children}</ContentSide>
		<SplashSide>
			<NotScrollable $imageUrl={splashImage}>
				<VisionaryContext.Provider value={true}>{splashContent}</VisionaryContext.Provider>
			</NotScrollable>
		</SplashSide>
	</Container>
);

export default SplitUI;
