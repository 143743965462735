import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { IconType } from '~lib/frontend/design-system/assets/SvgIcon';
import Accordion from '~lib/frontend/design-system/components/accordion/WebAccordion';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import VehicleManufacturer from '~lib/frontend/design-system/components/VehicleManufacturer';
import useSelectorOrDefault from '~lib/frontend/hooks/use-selector-or-default';
import { taxStatus } from '~lib/platform/dvla-ves/store/actions';
import { vehicleStatus } from '~lib/platform/mot/store/actions';
import { PublicVehicle } from '~lib/platform/vehicle/types';
import { initialAsyncState } from '~lib/shared/redux/types/state';
import { ApplicationState } from '~website/store';

const safeDate = (value: string) => {
	if (!value) return 'N/A';

	const date = new Date(value);

	if (isNaN(date.getTime())) return value; // safer to return previous value

	const options: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		day: '2-digit',
		month: 'short',
		year: 'numeric',
	};

	return new Intl.DateTimeFormat('en-GB', options).format(date);
};

interface PanelItemProps {
	panel: {
		passed?: string;
		title: string;
		value: string | number | boolean;
	};
}

type Result = 'pass' | 'fail';

const PanelItem: React.FCWithChildren<PanelItemProps> = ({ panel }) => {
	const stateColor = (() => {
		if (panel.passed === 'pass') return 'textSuccess';
		if (panel.passed === 'fail') return 'textDanger';

		return 'textOnSurfaceBackground';
	})();

	return (
		<PanelRow passed={panel.passed as Result}>
			<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
				{panel.title}
			</Typography>
			<Typography $type={'Body.Medium'} $color={stateColor} $bold>
				{panel.value}
			</Typography>
		</PanelRow>
	);
};

interface CustomAccordionItemProps {
	icon?: IconType;
	title: string;
	value: string;
	CustomIcon?: React.FCWithChildren;
}

const CustomAccordionItem: React.FCWithChildren<CustomAccordionItemProps> = ({ icon, title, value, CustomIcon }) => (
	<VehicleProfileTableRow>
		<VehicleProfileTableRowRight>
			{!CustomIcon && <Icon icon={icon} $size={'24px'} $color={'primaryFill'} />}
			{CustomIcon && <CustomIcon />}
			<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $marginLeft={'large'}>
				{title}
			</Typography>
		</VehicleProfileTableRowRight>
		<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $bold>
			{value}
		</Typography>
	</VehicleProfileTableRow>
);

interface VehicleProfileTableProps {
	vehicle: PublicVehicle;
	vehicleId: string;
	title?: string;
}

const VehicleProfileTable: React.FCWithChildren<VehicleProfileTableProps> = ({ vehicleId, vehicle, title }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (!vehicleId) return;

		dispatch(vehicleStatus.request({ vehicleId }));
		dispatch(taxStatus.request({ vehicleId }));
	}, [dispatch, vehicleId]);

	const vehicleMOT = useSelectorOrDefault((s: ApplicationState) => s.platform.mot.vehicleStatus[vehicleId], {
		...initialAsyncState,
	});

	const vehicleTax = useSelectorOrDefault((s: ApplicationState) => s.platform.dvlaves.taxStatus[vehicleId], {
		...initialAsyncState,
	});

	const motItems = [
		{
			title: 'Time left',
			value: vehicleMOT?.response?.upToDate ? `${daysDiff(vehicleMOT?.response?.expiryDate)} days` : 'Overdue',
		},
		{
			title: 'Status',
			passed: vehicleMOT?.response?.upToDate ? 'pass' : 'fail',
			value: vehicleMOT?.response?.upToDate ? 'Passed' : 'Lapsed',
		},
	];

	const taxItems = [
		{
			title: 'Time left',
			value: vehicleTax?.response?.upToDate ? `${daysDiff(vehicleTax?.response?.dueDate)} days` : 'Overdue',
		},
		{
			title: 'Status',
			value: vehicleTax?.response?.upToDate ? 'Passed' : 'Lapsed',
			passed: vehicleTax?.response?.upToDate ? 'pass' : 'fail',
		},
	];

	const vehicleItems = [
		{ title: 'Registration number', value: vehicle?.vrm },
		{ title: 'Model variant', value: vehicle?.variant },
		{ title: 'Colour', value: vehicle?.color },
		{ title: 'CO2 rating', value: vehicle?.co2Rating ? `${vehicle.co2Rating} g/km` : '-' },
		{ title: 'Engine size', value: vehicle?.engineCapacity ? `${vehicle.engineCapacity}ltr` : '-' },
		{ title: 'Year of manufacture', value: vehicle?.manufactureYear },
		{ title: 'Seat count', value: `${vehicle?.seatCount} seats` },
		{ title: 'Door count', value: `${vehicle?.doorCount} doors` },
		{ title: 'Import status', value: vehicle?.dvlaImported ? 'Imported' : 'Not imported' },
	];

	return (
		<React.Fragment>
			<Typography $type={'Heading.XSmall'} $color={'textOnSurfaceBackgroundMuted'} $marginBottom={'extraSmall'}>
				{title}
			</Typography>
			<Accordion
				items={[
					{
						title: (
							<CustomAccordionItem
								icon={'ic_mot'}
								title={'MOT'}
								value={`${vehicleMOT?.response?.upToDate ? 'Due' : 'Expired'} ${safeDate(vehicleMOT?.response?.expiryDate)}`}
							/>
						),
						content: (
							<Panel>
								{motItems.map((panel, index) => (
									<PanelItem key={`${panel.title}-${index}`} panel={panel} />
								))}
							</Panel>
						),
					},
					{
						title: (
							<CustomAccordionItem
								icon={'ic_tax'}
								title={'Road Tax'}
								value={`${vehicleTax?.response?.upToDate ? 'Due' : 'Expired'} ${safeDate(vehicleTax?.response?.dueDate)}`}
							/>
						),
						content: (
							<Panel>
								{taxItems.map((panel, index) => (
									<PanelItem key={`${panel.title}-${index}`} panel={panel} />
								))}
							</Panel>
						),
					},
					{
						title: (
							<CustomAccordionItem
								CustomIcon={() => (
									<VehicleManufacturer make={vehicle?.make} size={'24px'} color={'primaryFill'} />
								)}
								title={'Vehicle info'}
								value={'Learn more'}
							/>
						),
						content: (
							<Panel>
								{vehicleItems.map((panel, index) => (
									<PanelItem panel={panel} key={`${panel.title}-${index}`} />
								))}
							</Panel>
						),
					},
				]}
			/>
		</React.Fragment>
	);
};

const daysDiff = (dateStr: string) => {
	if (!dateStr) return 'N/A';

	const date = new Date(dateStr);
	const now = new Date();

	if (isNaN(date.getTime())) return 'N/A';

	// Normalize both dates to the start of the day
	const dateStart = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	const nowStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());

	const timeDiff = dateStart.getTime() - nowStart.getTime();
	const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

	return daysDiff;
};

const VehicleProfileTableRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const VehicleProfileTableRowRight = styled.div`
	display: flex;
	align-items: center;
`;

const PanelRow = styled.div<{ passed?: Result }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};
	padding: ${p => p.theme.spacing.large} 0px;
`;

const Panel = styled.div`
	& > :last-child {
		border: none;
	}
`;

export default VehicleProfileTable;
