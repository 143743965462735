import React, { useContext } from 'react';
import styled from 'styled-components';

import TitleSegment, { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from './TitleSegment';
import { media } from '~lib/frontend/design-system/breakpoints';
import Typography from '~lib/frontend/design-system/components/Typography';
import { GetterType } from '~lib/frontend/types/content';
import VisionaryContext from '~website/contexts/VisionaryContext';

export interface StepItem {
	index?: number;
	title: string;
	body: string;
}

export interface TitleSegmentStepsProps extends TitleSegmentProps {
	hideLine?: boolean;
	steps: StepItem[];
}

const TitleSegmentSteps: React.FCWithChildren<TitleSegmentStepsProps> = props => {
	const isVisionary = useContext(VisionaryContext);
	const { centered, hideLine, steps, ...titleSegmentProps } = props;

	return (
		<TitleSegment {...titleSegmentProps} centered={centered}>
			<Wrapper $centered={centered}>
				{!hideLine && <DottedLine />}
				{steps.map((s, i) => (
					<Step key={`${i}-${s.title}`}>
						<Orb>
							<Typography $bold $type={'Label.Large'} $color={'textOnFill'}>
								{i + 1}
							</Typography>
						</Orb>

						<Typography
							$bold
							$type={'Heading.XSmall'}
							$color={isVisionary ? 'textOnFill' : 'textOnSurfaceBackground'}
						>
							{s.title}
						</Typography>
						<Typography
							$type={'Body.Medium'}
							$color={isVisionary ? 'textOnFillMuted' : 'textOnSurfaceBackgroundMuted'}
						>
							{s.body}
						</Typography>
					</Step>
				))}
			</Wrapper>
		</TitleSegment>
	);
};

export const contentAdapter = (get: GetterType): TitleSegmentStepsProps => {
	const steps = get<StepItem[]>('steps') || [];
	const hideLine = get<boolean>('hide_line');
	const titleSegment = titleSegmentContentAdapter(get);

	return {
		// @ts-ignore
		steps,
		hideLine,
		...titleSegment,
	};
};

const Wrapper = styled.div<{ $centered: boolean }>`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	max-width: 800px;
	gap: 30px;

	margin: 50px ${p => (p.$centered ? 'auto' : '0')};

	${media.lessThan('tablet')`
		flex-direction: column;
	`}
`;

const DottedLine = styled.div`
	position: absolute;
	z-index: 0;
	margin: 0 15px;
	top: calc(36px / 2);
	left: 15%;
	right: 15%;
	border-top: 1.125px dashed ${p => p.theme.ui.borderSeparator};

	${media.lessThan('tablet')`
		display: none;
	`}
`;

const Step = styled.div`
	flex: 0 1 33%;
	z-index: 1;

	${p => media.lessThan('tablet')`
		margin-bottom: ${p.theme.spacing.extraLarge};

		&:last-of-type {
			margin-bottom: 0;
		}
	`}
`;

const Orb = styled.div`
	background: ${p => p.theme.ui.surfaceDark};
	border-radius: 100%;
	margin: 0 auto;
	width: 35px;
	height: 36px;
	margin-bottom: ${p => p.theme.spacing.extraLarge};
	color: ${p => p.theme.ui.textOnFill};

	> ${Typography} {
		line-height: 36px;
	}
`;

export default TitleSegmentSteps;
