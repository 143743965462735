import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme } from 'styled-components';

import VisionaryContext from '~website/contexts/VisionaryContext';

const ThemeColor: React.FunctionComponent = () => {
	const visionary = useContext(VisionaryContext);
	const theme = useTheme();

	let color = theme.ui.blankBackground;

	if (visionary) color = theme.ui.secondaryBackground;

	return (
		<Helmet defer={false}>
			<meta name={'theme-color'} content={color} />
		</Helmet>
	);
};

export default ThemeColor;
