import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useSinglePolicy } from '../context/SinglePolicyContext';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { trackAccountsScreenView } from '~website/features/analytics/store/actions';

interface MakeAClaimModalProps {
	open: boolean;
	onClose: () => void;
}

const MakeAClaimModal: React.FCWithChildren<MakeAClaimModalProps> = props => {
	const dispatch = useDispatch();

	const { open, onClose } = props;
	const { reference, incidentPhone } = useSinglePolicy();

	useEffect(() => {
		if (open) {
			dispatch(
				trackAccountsScreenView({
					screenName: 'policy_claim_viewed',
				})
			);
		}
	}, [dispatch, open]);

	return (
		<ComplexDialog.Dialog open={open} title={'Make a claim'} onClose={onClose}>
			<ComplexDialog.Content>
				<MakeAClaimModalWrapper>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
						{
							'If you’ve had an accident, give us a call and we’ll put you in touch with the right claims team.'
						}
					</Typography>

					<Margin $marginTop={'large'} $marginBottom={'large'}>
						<Anchor href={`tel:${incidentPhone}`}>
							<Icon icon={'ic_phone'} $size={'16px'} $color={'textHighlight'} />
							<span>{`Call us on ${incidentPhone}`}</span>
						</Anchor>
					</Margin>

					<ListWrapper>
						<Typography
							$type={'Body.Medium'}
							$bold
							$color={'textOnSurfaceBackground'}
							$marginBottom={'large'}
						>
							{'What you’ll need'}
						</Typography>

						<List>
							<li>
								<Icon icon={'ic_check'} $size={'20px'} $color={'primaryFill'} />
								<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
									{`Your policy number: ${reference}`}
								</Typography>
							</li>
							<li>
								<Icon icon={'ic_check'} $size={'20px'} $color={'primaryFill'} />
								<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
									{'Name and contact details (of any other drivers involved, and any witnesses)'}
								</Typography>
							</li>
							<li>
								<Icon icon={'ic_check'} $size={'20px'} $color={'primaryFill'} />
								<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
									{'Where and when the accident took place, and what happened'}
								</Typography>
							</li>
							<li>
								<Icon icon={'ic_check'} $size={'20px'} $color={'primaryFill'} />
								<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
									{'Tell us about any injuries'}
								</Typography>
							</li>
						</List>
					</ListWrapper>
				</MakeAClaimModalWrapper>
			</ComplexDialog.Content>

			<ComplexDialog.Actions>
				<Button $type={'neutralMuted'} onClick={onClose}>
					{'Close'}
				</Button>
			</ComplexDialog.Actions>
		</ComplexDialog.Dialog>
	);
};

export default MakeAClaimModal;

const MakeAClaimModalWrapper = styled.div`
	a,
	a:hover,
	a:visited {
		color: ${p => p.theme.ui.textHighlight};
		display: flex;
		align-items: center;
		text-decoration: none;

		span {
			margin-left: ${p => p.theme.spacing.extraSmall};
		}
	}
`;

const ListWrapper = styled.div`
	padding: ${p => p.theme.spacing.large};
	background: ${p => p.theme.ui.surfaceFillMuted};
	border-radius: ${p => p.theme.borderRadius.small};
`;

const List = styled.ul`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};

	li {
		display: flex;
		align-items: flex-start;
		gap: ${p => p.theme.spacing.large};
	}
`;
