import styled from 'styled-components';
const Underline = styled.div `
	height: 2px;
	background: ${p => (p.$background === 'block' ? p.theme.ui.primaryFill : p.theme.ui.surfaceFill)};
	width: ${p => p.$width}px;
	left: ${p => p.$left}px;
	bottom: ${p => (p.$background === 'block' ? '-1px' : '0')};
	position: absolute;
	transition: all 250ms ease-in-out;
`;
export default Underline;
