import CuvvaClient from '@cuvva/cuvva-client';
import { Dispatch } from 'redux';
import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';
import seededshuffle from 'seededshuffle';

import { listCuvvaPeeps } from '../actions';
import { getStaffAndDoggos } from '../api';
import { AboutActionTypes, CuvvaPeeps } from '../types';
import { listOfficeDoggos } from '~lib/platform/doggos/store/actions';
import { listStaffPublic } from '~lib/platform/staff/store/actions';

const shuffleSeed = 69;

export default function* listCuvvaPeepsWatcher() {
	yield takeEvery(AboutActionTypes.LIST_CUVVA_PEEPS, listCuvvaPeepsSaga);
}

async function worker(client: CuvvaClient) {
	const { doggos, staff } = await getStaffAndDoggos(client);
	const filteredStaff = staff.filter(d => d.emoji);
	const filteredDoggos = doggos.reduce((acc, val) => {
		if (val.about?.images?.normal) return [...acc, val];

		return [...acc, { ...val, emoji: '🐕' }];
	}, []);

	const shuffled: CuvvaPeeps = seededshuffle.shuffle([...filteredDoggos, ...filteredStaff], shuffleSeed);

	return {
		doggos,
		shuffled,
		staff,
	};
}

function* listCuvvaPeepsSaga() {
	const client: CuvvaClient = yield getContext('api');

	try {
		const { doggos, shuffled, staff } = yield call(worker, client);

		yield all([
			put(listCuvvaPeeps.success(shuffled)),
			put(listStaffPublic.success(staff)),
			put(listOfficeDoggos.success(doggos)),
		]);
	} catch {
		// NOTE(afr): If we fail, just show the ones from BTR
	}
}

export async function listCuvvaPeepsFrontload(client: CuvvaClient, dispatch: Dispatch) {
	const { doggos, shuffled, staff } = await worker(client);

	await Promise.all([
		dispatch(listCuvvaPeeps.success(shuffled)),
		dispatch(listStaffPublic.success(staff)),
		dispatch(listOfficeDoggos.success(doggos)),
	]);
}
