/* eslint-disable require-yield */
import { stepActions } from '../actions';
import { setItem } from '~lib/shared/helpers/local-storage';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { quoteRecentNCB } from '~website/constants/storage';

export default createTakeEverySagaSet(stepActions.setNCBYears, function* setNCBSaga(action) {
	if ([void 0, null].includes(action.payload)) setItem(quoteRecentNCB, null, 'sessionStorage');
	else setItem(quoteRecentNCB, action.payload, 'sessionStorage');
});
