import React from 'react';
import styled from 'styled-components';
import { titleCase } from 'title-case';

import Button from '~lib/frontend/design-system/components/button/Button';
import Icon from '~lib/frontend/design-system/components/Icon';
import CircleIcon from '~lib/frontend/design-system/components/molecules/CircleIcon';
import Typography from '~lib/frontend/design-system/components/Typography';

interface IncidentCardProps {
	date: string;
	category: string;
	onEdit?: () => void;
}

const IncidentMiniCard: React.FCWithChildren<IncidentCardProps> = ({ date, category, onEdit }) => {
	const formatDate = getFormatDate(date);
	const formatCategory = category ? titleCase(category) : 'Not selected';

	return (
		<MiniCard $disabled={!onEdit}>
			<CircleIcon primaryColor={'primaryFill'} secondaryColor={'primaryFillMuted'} size={32} icon={'ic_signal'} />

			<div>
				<Typography $bold $type={'Body.Large'} $color={'textOnSurfaceBackground'}>
					{formatDate}
				</Typography>
				<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
					{formatCategory}
				</Typography>
			</div>

			{onEdit && (
				<Button $type={'primaryMuted'} $size={'medium'} onClick={onEdit}>
					{'Edit'}
				</Button>
			)}

			{!onEdit && (
				<div>
					<Icon icon={'ic_lock'} $size={'20px'} $color={'neutralFillMinor'} />
				</div>
			)}
		</MiniCard>
	);
};

const getFormatDate = (date: string) => {
	if (!date) return 'Invalid date';

	const parsedDate = new Date(date);

	if (isNaN(parsedDate.getTime())) return 'Invalid date';

	const options: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		year: 'numeric',
		month: 'long',
	};

	return new Intl.DateTimeFormat('en-GB', options).format(parsedDate);
};

const MiniCard = styled.div<{ $disabled: boolean }>`
	padding: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: ${p => p.theme.borderRadius.large};
	background: ${p => p.theme.ui.surface};
	border: 1px solid ${p => p.theme.ui.borderSeparator};

	& > :nth-child(1) {
		flex: 0 0 32px;
	}

	& > :nth-child(2) {
		margin: 0 ${p => p.theme.spacing.large};
		flex: 1;
	}

	& > :nth-child(3) {
		flex: 0;
	}
`;

export default IncidentMiniCard;
