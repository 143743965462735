import styled, { css } from 'styled-components';
import Typography from '~lib/frontend/design-system/components/Typography';
export const ComplexDialogContainer = styled.div `
	outline: 0;
	position: relative;
	background: ${p => p.theme.ui.secondarySurface};
	margin: 0;
	width: 100%;
	height: 100vh;
	max-height: 100vh;
	height: fill-available;

	height: calc(var(--window-inner-height) - 1px);

	display: flex;
	flex-direction: column;

	@media (max-width: 768px) {
		@supports (padding: max(0px)) {
			padding-top: max(16px, env(safe-area-inset-top));
			padding-bottom: max(16px, env(safe-area-inset-bottom));
		}
	}

	@media (min-width: 768px) {
		box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.12);
		border-radius: ${p => p.theme.borderRadius.large};
		max-width: 480px;
		width: 560px;

		margin: 12vmin 0;
		max-height: calc(100% - 24vmin);
		max-width: calc(100% - 32px);
		height: unset;

		${p => p.$size === 'extra-large' &&
    css `
				width: 80%;
			`}

		${p => p.$size === 'large' &&
    css `
				width: 850px;
			`}

		${p => p.$size === 'medium' &&
    css `
				width: 670px;
			`}

		${p => p.$size === 'small' &&
    css `
				width: 560px;
			`}
	}
`;
export const ComplexDialogTitleBar = styled.div `
	padding: 16px;
	border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;

	& > :first-child {
		flex: 0 0 24px;
	}

	${Typography} {
		flex: 1;
	}

	& > :last-child {
		flex: 0 0 24px;
	}

	@media (min-width: 768px) {
		padding: 24px;
	}
`;
export const ComplexDialogContent = styled.div `
	${p => p.theme.typography['Body.Medium']()};
	color: ${p => p.theme.ui.textOnSurfaceBackgroundMuted};
	overflow-y: auto;

	${p => !p.$noPadding &&
    css `
			padding: 16px;

			@media (min-width: 768px) {
				padding: 24px;
			}
		`}
`;
export const ComplexDialogActionsArea = styled.div `
	margin-top: auto;
	padding: ${p => p.theme.spacing.large};
	border-top: 1px solid ${p => p.theme.ui.borderSeparator};

	@media (min-width: 768px) {
		margin-top: none;
		padding: ${({ theme }) => `${theme.spacing.regular} 24px ${theme.spacing.large} 24px`};
	}
`;
