import { put } from 'redux-saga/effects';

import { analyticsTrackingDebug } from '../actions';
import { CustomEvent } from '~website/helpers/appsflyer-sdk';
import { pushEvent } from '~website/helpers/gtm';

function* handleGTM(payload: Omit<CustomEvent, 'eventType'>) {
	let extraVars = {};

	if (typeof payload.eventValue === 'object') {
		// here we expand the vars as GTM supports custom names, while AF doesn't
		extraVars = payload.eventValue;
	}

	const eventPayload = {
		event: payload.eventName,
		category: payload.eventCategory,
		label: payload.eventLabel,
		...extraVars,
	};

	pushEvent<unknown>(eventPayload);

	yield put(
		analyticsTrackingDebug({
			destination: 'gtm',
			payload: {
				event: payload.eventName,
				payload: eventPayload,
			},
		})
	);
}

export default handleGTM;
