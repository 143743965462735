import React from 'react';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useOnPageError from '../../hooks/use-on-page-error';
import useProfileInternalChangeRequest from '../../hooks/use-profile-internal-change-request';
import notEmptyBooleanValidator from '../../validators/not-empty-numeric-boolean';
import { AlignInlineChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import useContentKey from '~lib/frontend/hooks/use-content-key';

const options = [
	{
		value: true,
		display: 'Yes',
	},
	{
		value: false,
		display: 'No',
	},
];

const HomeOwner: React.FunctionComponent = () => {
	const g = useContentKey('strings');
	const { value, validation, onChange } = useProfileInternalChangeRequest('homeowner', notEmptyBooleanValidator);
	const error = useOnPageError(validation, 'home_owned_input');

	return (
		<QuoteInputDecorator
			label={g('motor.long_term.questions.about_you.homeowner.question', 'Do you own a property in the UK?')}
			error={error}
		>
			<AlignInlineChildren>
				{options.map(o => (
					<RadioInput
						key={o.display}
						name={'home-owner'}
						checked={value === o.value}
						value={o.display}
						onChange={() => onChange(o.value)}
						data-event-onchange-name={'home_owned_input_selected'}
					/>
				))}
			</AlignInlineChildren>
		</QuoteInputDecorator>
	);
};

export default HomeOwner;
