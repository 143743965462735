import React from 'react';
import styled, { css } from 'styled-components';

import UserSignedIn from './UserSignedIn';
import { Anchor } from '~lib/frontend/atoms';
import CuvvaLogo from '~lib/frontend/atoms/CuvvaLogo';
import Button from '~lib/frontend/design-system/components/button/Button';
import Icon from '~lib/frontend/design-system/components/Icon';
import useConfigFlag from '~lib/frontend/hooks/use-config-flag';
import useMediaQuery from '~lib/frontend/hooks/use-media-query';
import useUser from '~website/hooks/use-user';

interface HeaderBarProps {
	sticky?: boolean;
	transparent?: boolean;
}

const HeaderBar: React.FCWithChildren<HeaderBarProps> = ({ sticky, transparent }) => {
	const { namedUser } = useUser();
	const newFlag = useConfigFlag('login_account_website');

	const isMobile = useMediaQuery('(max-width: 1023px)');
	const forcedState = isMobile || transparent;
	const buttonColor = forcedState ? 'textOnFill' : 'neutralFill';

	return (
		<HeaderBarWrap $sticky={sticky} $transparent={transparent}>
			<Anchor href={'/'}>
				<CuvvaLogo />
			</Anchor>

			{newFlag && namedUser && <UserSignedIn color={buttonColor} />}
			{newFlag && !namedUser && (
				<Button
					as={Anchor}
					// @ts-ignore
					href={'/signin'}
					$type={'primary'}
					$size={'small'}
				>
					{'Sign in'}
				</Button>
			)}

			{!newFlag && (
				<Anchor href={'mailto:support@cuvva.com'} className={'intercom-launcher'}>
					<Icon icon={'ic_support'} $size={'24px'} $color={'blankFill'} />
				</Anchor>
			)}
		</HeaderBarWrap>
	);
};

interface HeaderBarWrapProps {
	$sticky?: boolean;
	$transparent?: boolean;
}

const HeaderBarWrap = styled.div<HeaderBarWrapProps>`
	background: ${p => p.theme.ui.secondaryBackground};

	@media (min-width: 1024px) {
		background: ${p => p.theme.ui.secondarySurface};
	}

	${p =>
		p.$sticky &&
		css`
			position: sticky;
			top: 0;
			z-index: 10;

			@media (min-width: 1024px) {
				position: relative;
			}
		`};

	${p =>
		p.$transparent &&
		`
		background: transparent;
	`};

	padding: ${p => p.theme.spacing.regular} ${p => p.theme.spacing.large};
	min-height: 56px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	@media (min-width: 1024px) {
		padding: 20px ${p => p.theme.spacing.extraLarge};
		min-height: 72px;
	}

	& > a:first-child,
	& > a[class~='intercom-launcher'] {
		display: flex;
		height: 20px;

		@media (min-width: 1024px) {
			height: 24px;
		}

		&,
		svg path {
			--fill: ${p => p.theme.ui.blankFill};
			fill: ${p => p.theme.ui.blankFill};

			@media (min-width: 1024px) {
				--fill: ${p => (p.$transparent ? p.theme.ui.blankFill : p.theme.ui.neutralFill)};
				fill: ${p => (p.$transparent ? p.theme.ui.blankFill : p.theme.ui.neutralFill)};
			}
		}
	}

	& > a[class~='intercom-launcher'] {
		width: 24px;
		height: 24px;
	}
`;

export default HeaderBar;
