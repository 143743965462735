import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { trackScreenView } from '../analytics/store/actions';
import InfoCards, { contentAdapter } from './molecules/InfoCards';
import NextSteps from './molecules/NextSteps';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import Container from '~website/components/atoms/Container';
import Meta from '~website/components/Meta';

const NoAppBorrowerRequest: React.FunctionComponent = () => {
	const g = useContentKey('website_core');
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			trackScreenView({
				eventName: 'vehicleSharing_explainer_viewed',
				eventPayload: {},
			})
		);
	}, [dispatch]);

	const get = (key: string, fallback?: string) => {
		if (key === 'head.canonical_url') return 'https://www.cuvva.com/borrower-request';

		return g(`pages[no-app-borrower-request].${key}`, fallback);
	};

	// content
	const sharingAsset = useDesignSystemAsset({ type: 'illustration', value: 'il_boy_girl_sharing_car.svg' });
	const mainTitle = 'Approve this trip in the Cuvva app';

	return (
		<Wrapper>
			<Meta get={(key, fallback) => get(`head.${key}`, fallback)} />
			<Section>
				<HeadingWrap>
					<Typography $type={'Heading.XLarge'} $color={'textOnSurfaceBackground'} as={'h1'}>
						{mainTitle}
					</Typography>
				</HeadingWrap>
				<NextSteps />
				{sharingAsset && <Illo src={sharingAsset} />}
			</Section>

			<Section>
				<Typography
					$type={'Heading.Large'}
					$color={'textOnSurfaceBackground'}
					dangerouslySetInnerHTML={{ __html: get('intro.title') }}
					$marginBottom={'large'}
				/>
				<Typography
					$type={'Body.XLarge'}
					$color={'textOnSurfaceBackgroundMuted'}
					dangerouslySetInnerHTML={{ __html: get('intro.explanation') }}
				/>
			</Section>

			<Section>
				<InfoCards {...contentAdapter((key, fallback) => get(`important.${key}`, fallback))} />
			</Section>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	margin: 0 auto;
	max-width: 800px;
	margin-bottom: 75px;
`;

const HeadingWrap = styled.div`
	text-align: center;
	position: relative;
	margin-top: 8rem;
	margin-bottom: ${p => p.theme.spacing['4xLarge']};

	${Typography} {
		> strong {
			color: ${p => p.theme.ui.textHighlight};
		}
	}

	> * + * {
		margin-top: 1rem;
	}
`;

const Illo = styled.img`
	margin: 0 auto;
	margin-bottom: ${p => p.theme.spacing.regular};
	margin-top: ${p => p.theme.spacing['5xLarge']};
	width: 100%;
	max-width: 20rem;
`;

const Section = styled(Container)`
	text-align: center;

	& + & {
		margin-top: 75px;
	}
`;

export default NoAppBorrowerRequest;
