import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import ksuid from '@cuvva/ksuid';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import Spinner from '~lib/frontend/design-system/components/spinner';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { GetterType } from '~lib/frontend/types/content';
import { authenticate } from '~lib/platform/auth/store/actions';
import Footer from '~website/components/layout/footer';
import VibrantScreen from '~website/components/VibrantScreen';
import VibrantScreenCard from '~website/components/VibrantScreen/VibrantScreenCard';
import { trackEvent } from '~website/features/analytics/store/actions';
import useEmailAttached from '~website/hooks/use-email-attached';
import useErrorMapper from '~website/hooks/use-error-mapper';
import { importAuthedUser } from '~website/store/auth/actions';

const Authenticate: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const isEmailAttached = useEmailAttached();

	const errorImage = useDesignSystemAsset({ type: 'illustration', value: 'il_car_barrier_01.svg' });
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.auth.${key}`, fallback);

	const query = new URLSearchParams(location.search);
	const { code, state } = readQuery(query);
	const [requestId] = useState(() => ksuid.generate('request').toString());
	const authenticateStore = useSelector(s => s.platform.auth.authenticate[requestId]);
	const authenticateStoreStatus = useAsyncStateStatus(authenticateStore).status;
	const authenticateError = authenticateStore?.error;
	const redirectUri = authenticateStore?.response?.redirectUri;

	const { getMappedError } = useErrorMapper();

	const status = (() => {
		if (authenticateError) return 'error';

		if (authenticateStoreStatus === 'response') return 'success';

		return 'fetching';
	})();

	useEffect(() => {
		if (status === 'success') dispatch(importAuthedUser.request());
	}, [status, dispatch]);

	useEffect(() => {
		if (!code || !state) return;

		dispatch(authenticate.request({ requestId, code, state }));
	}, [dispatch, code, state, requestId]);

	useEffect(() => {
		if (!isEmailAttached || authenticateStoreStatus !== 'response') return;

		const successEvent = 'web_signed_in';

		dispatch(
			trackEvent({
				eventName: successEvent,
				intercomEvent: successEvent,
			})
		);

		dispatch(push(redirectUri ?? '/'));
	}, [authenticateStoreStatus, redirectUri, dispatch, isEmailAttached]);

	const foundError = getMappedError(authenticateError);

	return (
		<React.Fragment>
			<Helmet defer>
				<title>{'Logging into Cuvva'}</title>
			</Helmet>
			<VibrantScreen>
				<VibrantScreenCard maxWidth={436}>
					<CenteredCardPricing>
						<SpinnerContainer $hide={status !== 'fetching'}>
							<Spinner $color={'primaryFill'} $size={'30px'} />
						</SpinnerContainer>

						<Error $hide={status !== 'error'}>
							<Illo src={errorImage} alt={'car stopped'} />

							<Typography
								$bold
								$marginTop={'24px'}
								$marginBottom={'large'}
								$type={'Heading.Medium'}
								$color={'textOnSurfaceBackground'}
							>
								{get('failure.title', 'Authentication failed')}
							</Typography>

							<Typography
								$marginTop={'24px'}
								$marginBottom={'extraLarge'}
								$type={'Body.Medium'}
								$color={'textOnSurfaceBackgroundMuted'}
							>
								{foundError ? foundError : null}
								{!foundError &&
									get(
										'failure.unknown_error',
										'There seems to be an issue authenticating you, please try signing in again.'
									)}
							</Typography>

							<Button
								as={Anchor}
								// @ts-ignore
								to={'/signin'}
								$type={'primary'}
								$size={'large'}
								$stretch
							>
								{get('failure.retry_cta', 'Return to sign in')}
							</Button>
						</Error>
					</CenteredCardPricing>
				</VibrantScreenCard>
			</VibrantScreen>
			<Footer landingPage />
		</React.Fragment>
	);
};

const Illo = styled.img`
	width: 128px;
	height: 128px;
`;

const CenteredCardPricing = styled.div`
	text-align: center;
	position: relative;
`;

const Error = styled.div<{ $hide: boolean }>`
	opacity: ${p => (p.$hide ? 0 : 1)};
	animation: opacity 0.6s ease-in-out;
`;

function readQuery(query: URLSearchParams) {
	const code = query.get('code');
	const state = query.get('state');

	return { code, state };
}

const SpinnerContainer = styled.div<{ $hide: boolean }>`
	margin: 0 auto;
	opacity: ${p => (p.$hide ? 0 : 1)};
	animation: opacity 0.6s ease-in-out;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
`;

export default Authenticate;
