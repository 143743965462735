import { PromoActionTypes, } from './types';
import { createAsyncMapAction } from '~lib/shared/redux/actions';
export const createDiscount = createAsyncMapAction(PromoActionTypes.CREATE_DISCOUNT, p => p.requestId);
export const listDiscounts = createAsyncMapAction(PromoActionTypes.LIST_DISCOUNTS, p => p.userId);
export const discount = createAsyncMapAction(PromoActionTypes.FETCH_DISCOUNT, p => p.discountId);
export const userReferrer = createAsyncMapAction(PromoActionTypes.USER_REFERRER, p => p.userId);
export const userReferees = createAsyncMapAction(PromoActionTypes.USER_REFEREES, p => p.userId);
export const listDiscountsNeu = createAsyncMapAction(PromoActionTypes.LIST_DISCOUNTS_NEU, p => p.requestId);
export const redeemCode = createAsyncMapAction(PromoActionTypes.REDEEM_CODE, p => [p.userId, p.code].join());
export const generateReferralCode = createAsyncMapAction(PromoActionTypes.GENERATE_REFERRAL_CODE, p => p.userId);
export const doNotOffer = createAsyncMapAction(PromoActionTypes.DO_NOT_OFFER, p => p.discountId);
export const listDiscountsPublic = createAsyncMapAction(PromoActionTypes.LIST_DISCOUNTS_PUBLIC, p => p.requestId);
export const listPromos = createAsyncMapAction(PromoActionTypes.LIST_PROMOS, p => p.requestId);
export const createPromos = createAsyncMapAction(PromoActionTypes.CREATE_PROMOS, p => p.requestId);
export const updatePromo = createAsyncMapAction(PromoActionTypes.UPDATE_PROMO, p => p.requestId);
