import React, { FCWithChildren, useState } from 'react';

import Confetti from '~website/components/atoms/Confetti';

const ConfettiOnOver: FCWithChildren = ({ children }) => {
	const [status, setStatus] = useState(false);

	return (
		<div onMouseEnter={() => setStatus(true)}>
			{children}
			{status && <Confetti />}
		</div>
	);
};

export default ConfettiOnOver;
