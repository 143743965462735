import React from 'react';

import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { GetterType } from '~lib/frontend/types/content';
import Meta from '~website/components/Meta';
import { Container, ContainerInner, IllustrationContainer, TitleContainer } from '~website/features/tools/Containers';
import GroupChecker from '~website/features/tools/group-checker';

const InsuranceGroupChecker: React.FunctionComponent = () => {
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.insurance-group-checker.${key}`, fallback);
	const vehicleQuestionIl = useDesignSystemAsset({ type: 'illustration', value: 'il_success_car.svg' });

	return (
		<React.Fragment>
			<Meta get={(key, fallback) => get(`head.${key}`, fallback)} />
			<Container>
				<ContainerInner>
					<IllustrationContainer>
						<img src={vehicleQuestionIl} alt={'Illustration of a car'} />
					</IllustrationContainer>
					<TitleContainer>
						<Typography
							$type={'Heading.XLarge'}
							$align={'center'}
							$color={'textOnSurfaceBackground'}
							as={'h1'}
						>
							{get('main_title')}
						</Typography>
						<Typography $type={'Body.Medium'} $align={'center'} $marginBottom={'extraLarge'}>
							{get('main_sub')}
						</Typography>
					</TitleContainer>

					<GroupChecker get={get} />
				</ContainerInner>
			</Container>
		</React.Fragment>
	);
};

export default InsuranceGroupChecker;
