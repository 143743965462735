import { call, getContext, put, select } from 'redux-saga/effects';

import { analyticsTrackingDebug } from '../actions';
import MixpanelClient from '~lib/frontend/api/mixpanel-client';
import { UserConfigPayload } from '~lib/platform/user-config/types';
import { getLogger } from '~lib/shared/logger';
import { AsyncState } from '~lib/shared/redux/types/state';
import { ApplicationState } from '~website/store';

function* sendToMixpanel(eventName: string, eventPayload: Record<string, unknown>) {
	const configFlags = [];

	const mixpanel: MixpanelClient = yield getContext('mixpanelClient');

	const userId = mixpanel?.getUserId();

	const userConfig: AsyncState<UserConfigPayload> = yield select(
		(state: ApplicationState) => state.platform.userConfig.getConfigById[userId]
	);

	if (userConfig && userConfig.response) configFlags.push(userConfig.response.flags);

	const flags = configFlags.reduce((accConfig, configFlag) => {
		const f = configFlag.reduce(
			(acc: Record<string, string>, curr: string) => ({
				...acc,
				[`config_flag__${curr}`]: curr,
			}),
			{}
		);

		return {
			...accConfig,
			...f,
		};
	}, {});

	try {
		yield call([mixpanel, mixpanel.track], eventName, {
			...eventPayload,
			...flags,
		});
	} catch (error) {
		getLogger().warning('mixpanel event failed', { error });
	}

	yield put(
		analyticsTrackingDebug({
			destination: 'mixpanel',
			payload: {
				event: 'screen_viewed',
				payload: {
					...eventPayload,
					...flags,
				},
			},
		})
	);
}

export default sendToMixpanel;
