import React, { useEffect, useMemo, useState } from 'react';

import useDLRInternalChangeRequest from '../../hooks/use-dln-internal-change-request';
import useOnPageError from '../../hooks/use-on-page-error';
import useProfileInternalChangeRequest from '../../hooks/use-profile-internal-change-request';
import validAddressValidator from '../../validators/valid-address';
import QuoteInputDecorator from '../atoms/QuoteInputDecorator';
import ResidentialAddress from './ResidentialAddress';
import { AlignInlineChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';

const radioOptions = [
	{
		label: 'Yes',
		value: true,
	},
	{
		label: 'No',
		value: false,
	},
];

const AddressRadio: React.FunctionComponent = () => {
	const [radioValue, setRadioValue] = useState<boolean | undefined>(void 0);

	const { value: postcode } = useDLRInternalChangeRequest('postcode');
	const {
		value: residentialAddress,
		validation,
		onChange,
	} = useProfileInternalChangeRequest('residentialAddress', validAddressValidator);
	const error = useOnPageError(validation, 'residence_status');

	const residentialAddressPostcode = residentialAddress?.postalCode.replace(/\s+/g, '')?.toUpperCase();
	const licencePostcode = postcode?.replace(/\s+/g, '')?.toUpperCase();
	const hasResidentialAddress = Boolean(residentialAddress);
	const [hasEverHadResidentialAddress, setHasEverHadResidentialAddress] = useState(hasResidentialAddress);

	useEffect(() => {
		if (hasResidentialAddress && !hasEverHadResidentialAddress) setHasEverHadResidentialAddress(true);
	}, [hasResidentialAddress, hasEverHadResidentialAddress]);

	const samePostcodeAsAddress = useMemo(
		() => residentialAddressPostcode === licencePostcode,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[licencePostcode, hasEverHadResidentialAddress]
	);

	const hasPostcodes = useMemo(
		() => Boolean(residentialAddressPostcode && licencePostcode),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[licencePostcode, hasEverHadResidentialAddress]
	);

	useEffect(() => {
		if (hasPostcodes) setRadioValue(samePostcodeAsAddress);
	}, [hasPostcodes, samePostcodeAsAddress, licencePostcode]);

	return (
		<React.Fragment>
			<QuoteInputDecorator label={'Do you live here?'} error={typeof radioValue === 'boolean' ? void 0 : error}>
				<AlignInlineChildren>
					{radioOptions.map(o => (
						<RadioInput
							key={o.label}
							name={'lives-same-address'}
							checked={radioValue === o.value}
							value={o.label}
							data-event-onchange-name={'residence_status_selected'}
							onChange={() => {
								setRadioValue(o.value);

								if (!o.value) onChange(void 0);
							}}
						/>
					))}
				</AlignInlineChildren>
			</QuoteInputDecorator>

			{(radioValue !== void 0 || hasResidentialAddress) && (
				<ResidentialAddress
					initialPostcode={radioValue ? postcode : void 0}
					// this is a little hack
					key={String(radioValue)}
				/>
			)}
		</React.Fragment>
	);
};

export default AddressRadio;
