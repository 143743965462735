import React, { Fragment, useContext } from 'react';

import { CategoriesGrid, CategoryColumn } from './atoms/categories';
import { FooterElement, FooterWrap } from './atoms/containers';
import { EndorsementLogos, EndorsementLogosInner } from './atoms/endorsments';
import { LegalLinks, LegalSection } from './atoms/legal';
import StyledLink from './atoms/link';
import LogoWrap from './atoms/logo-wrap';
import StoresWrap from './atoms/stores';
import Endorsement from './molecules/Endorsement';
import { Endorsement as EndorsementType, FooterCategory, LegalLink } from './types';
import Anchor from '~lib/frontend/atoms/Anchor';
import CuvvaLogo from '~lib/frontend/atoms/CuvvaLogo';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useMediaQuery from '~lib/frontend/hooks/use-media-query';
import AppStoreButtonsSegment from '~website/components/atoms/AppStoreButtonsSegment';
import BrandDesignContext from '~website/contexts/BrandDesignContext';
import { oneLink } from '~website/helpers/source-tracking';

export interface FooterProps {
	landingPage?: boolean;
	widgetJoinAppStore?: boolean;
	endorsements?: boolean;
}

const Footer: React.FCWithChildren<FooterProps> = ({ landingPage, endorsements }) => {
	const get = useContentKey('website_core');
	const isDesktop = useMediaQuery('(min-width: 768px)');
	const newDesign = useContext(BrandDesignContext);

	return (
		<Fragment>
			{endorsements && (
				<EndorsementLogos>
					<EndorsementLogosInner>
						{(get('global.footer.logos', []) as EndorsementType[])?.map(e => (
							<Endorsement key={e.image} endorsement={e} />
						))}
					</EndorsementLogosInner>
				</EndorsementLogos>
			)}
			<FooterElement $newDesign={newDesign}>
				<FooterWrap>
					<LogoWrap>
						<CuvvaLogo />
					</LogoWrap>
					{!landingPage && (
						<CategoriesGrid>
							{(get('global.footer.categories', []) as FooterCategory[]).map((c, i) => (
								<CategoryColumn key={`${i}:${c.items.length}`}>
									{Boolean(c.title) && (
										<Typography
											$type={'Heading.XSmall'}
											$marginBottom={'large'}
											$color={'textOnFill'}
										>
											{c.title}
										</Typography>
									)}
									{c.items.map(i => (
										<StyledLink
											// @ts-ignore
											as={Anchor}
											key={i.title}
											to={i.url ?? '#'}
											className={i.is_intercom ? 'intercom-launcher' : void 0}
											$type={'Label.Small'}
											$color={'textOnFillMuted'}
											$display={'block'}
										>
											{i.title}
										</StyledLink>
									))}
								</CategoryColumn>
							))}
							<StoresWrap>
								<AppStoreButtonsSegment url={oneLink} width={isDesktop ? '170px' : '150px'} />
							</StoresWrap>
						</CategoriesGrid>
					)}
					<LegalSection>
						<LegalLinks>
							{(get('global.footer.legal', []) as LegalLink[]).map(l => (
								<StyledLink
									// we force this to be a link because we want to open in a new tab
									// which will reroute using the lambda function
									as={'a'}
									key={l.title}
									href={l.url ?? '#'}
									target={'_blank'}
									$type={'Label.Small'}
									$color={'textOnFillMuted'}
									$display={'block'}
								>
									{l.title}
								</StyledLink>
							))}
						</LegalLinks>
						<Typography $type={'Body.XSmall'} $color={'textOnFillMuted'}>
							{get('global.footer.small_copy', '') as string}
						</Typography>
					</LegalSection>
				</FooterWrap>
			</FooterElement>
		</Fragment>
	);
};

export default Footer;
