import { all, put, select, take } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions/dist/types';

import { quoteActions } from '../actions';
import { userById } from '~lib/platform/auth/store/actions';
import { licenseByUserId } from '~lib/platform/driving-license-registration/store/actions';
import { listLatestIncidents } from '~lib/platform/incident/store/actions';
import { getProfile } from '~lib/platform/ltm/store/actions';
import { marketingConsentActions as marketingConsent } from '~lib/platform/marketing-consent/store/actions';
import { profileById } from '~lib/platform/profile/store/actions';
import { createTakeEverySagaSet, requestAndTake } from '~lib/shared/redux/sagas';
import { AsyncState } from '~lib/shared/redux/types/state';
import { ApplicationState } from '~website/store';
import { GetUserActionTypes } from '~website/store/auth/types';

export default createTakeEverySagaSet(quoteActions.fetchUser.request, function* prefetchSaga() {
	const userState: AsyncState<string> = yield select((state: ApplicationState) => state.internal.auth.user);
	let userId;

	if (!userState || userState.fetching) {
		const userSaga: PayloadAction<string, string> = yield take(GetUserActionTypes.IMPORT_REGISTER_USER_SUCCESS);

		userId = userSaga.payload;
	} else {
		userId = userState.response;
	}

	try {
		yield requestAndTake(profileById.request({ userId }));
		yield requestAndTake(userById.request({ userId, includeRemovedIdentifiers: false }));
		yield requestAndTake(marketingConsent.userStatus.request({ userId }));

		yield all([
			put(getProfile.request({ userId })),
			put(licenseByUserId.request({ userId })),
			put(listLatestIncidents.request({ userId })),
		]);
		// const isDeclaredEmpty = latestIncidents?.incidents !== null && latestIncidents.incidents.length === 0;
		// // `null` means never declared, `[]` means declared as empty
	} catch (error) {
		// loading user data is somewhat a best effort, if it doesn't work might be licence missing
		// or other data and could be expected
		yield put(quoteActions.fetchUser.failure(error));
	}

	yield put(quoteActions.fetchUser.success());
});
