export function handleErrorMessage(error) {
    switch (error.code) {
        case 'invalid_email':
            return ['Not valid', "That doesn't look like a valid email address", error];
        case 'invalid_phone_number':
            return ['Not valid', "That doesn't look like a valid phone number", error];
        case 'already_exists':
        case 'email_already_exists':
        case 'mobile_phone_already_exists':
            return ['Already exists', "The identifier you're trying to add is already associated", error];
        case 'identifier_not_found':
            return [
                'Unable to detach this identifier from this user',
                "The identifier you're trying to remove doesn' exists",
                error,
            ];
        case 'ambiguous_primary_identifier':
            return [
                'Unable to attach this identifier to this user',
                'Seems like no identifier is set as primary, set one as primary and try to add this identifier again.',
                error,
            ];
        default:
            return [error.code, "We don't really know what this error is, but you can see it below:", error];
    }
}
