import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import IlloWrapper from '../../atoms/IlloWrapper';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import IconWrapped from '~lib/frontend/design-system/components/IconWrapped';
import Typography from '~lib/frontend/design-system/components/Typography';
import { trackPayOnWebScreenView } from '~website/features/analytics/store/actions';
import { useVerificationContext } from '~website/features/quote/contexts/VerificationContext';

const VerificationStart = () => {
	const dispatch = useDispatch();
	const { verificationItems } = useVerificationContext();

	const { 0: first } = verificationItems;

	useEffect(() => {
		dispatch(trackPayOnWebScreenView({ screenName: 'verification_list' }));
	}, [dispatch]);

	return (
		<React.Fragment>
			<IlloWrapper filename={'il_id_gb_check.svg'} altDescription={'gb-id-check'} />

			<Margin $marginTop={'large'} $marginBottom={'extraLarge'}>
				<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'} $align={'center'}>
					{'We need to verify your details'}
				</Typography>

				<Typography
					$type={'Body.Medium'}
					$color={'textOnSurfaceBackground'}
					$align={'center'}
					$marginTop={'large'}
				>
					{'This won’t affect your price – we’ll save your quote for the next 2 hours.'}
				</Typography>
			</Margin>

			<Margin $marginTop={'extraLarge'} $marginBottom={'extraLarge'}>
				<Typography $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
					{'You’ll need to:'}
				</Typography>

				<VerificationListWrapper>
					{verificationItems.map(item => (
						<li key={item.type}>
							<IconWrapped $muted $size={'small'} $type={'square'} $style={'neutral'} icon={item.icon} />

							<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $marginLeft={'large'}>
								{item.label}
							</Typography>
						</li>
					))}
				</VerificationListWrapper>
			</Margin>

			<Button
				as={Anchor}
				// @ts-ignore
				to={`/quote/verifications/${first.path}`}
				$type={'primary'}
				$size={'large'}
				$stretch
			>
				{'Start verification'}
			</Button>
		</React.Fragment>
	);
};

export default VerificationStart;

const VerificationListWrapper = styled.ul`
	padding-top: ${p => p.theme.spacing.large};

	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};

	li {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
	}
`;
