import styled from 'styled-components';

interface PanelAreaProps {
	$center?: boolean;
}

export const PanelArea = styled.div<PanelAreaProps>`
	display: inline-flex;
	flex-direction: column;
	position: relative;
	${p => !p.$center && 'align-items: center;'};
	width: 100%;
	text-align: center;
	background: ${p => p.theme.ui.surfaceFill};
	border-radius: ${p => p.theme.borderRadius.large};
`;
