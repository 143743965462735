import React from 'react';

import { QuoteLayoutLowerSplash, QuoteLayoutLowerSplashInner, TrustpilotWrap } from '../atoms/QuoteRefresh';
import FancyLineBreak from './FancyLineBreak';
import FCABlock from './FCABlock';
import QuoteCardWithHeading from './QuoteCardWithHeading';
import { Anchor } from '~lib/frontend/atoms';
import { DesignSystemProvider } from '~lib/frontend/design-system';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import { Pricing } from '~lib/platform/ltm/types';
import TrustpilotThemeAware from '~website/assets/svgs/TrustpilotThemeAware';

interface LowerSplashProps {
	pricing: Pricing | undefined;
	nonSmartPricing: Pricing | undefined;
}

const LowerSplash: React.FCWithChildren<LowerSplashProps> = ({ pricing, nonSmartPricing }) => (
	<QuoteLayoutLowerSplash>
		<QuoteLayoutLowerSplashInner>
			<Typography $type={'Heading.Medium'} $color={'textOnFill'} $marginBottom={'extraLarge'} $align={'center'}>
				{'Trusted by over half a million drivers'}
			</Typography>

			<QuoteCardWithHeading pricing={pricing} nonSmartPricing={nonSmartPricing} />

			<FancyLineBreak $marginTop={'large'} $marginBottom={'large'}>
				{'or'}
			</FancyLineBreak>

			<Button
				as={Anchor}
				// @ts-ignore
				to={'/vehicles'}
				$type={'neutral'}
				$size={'large'}
				$stretch
			>
				{'Start a new quote'}
			</Button>

			<TrustpilotWrap>
				<DesignSystemProvider themeKey={'dark'} typographyStyle={'expressive'}>
					<TrustpilotThemeAware />
				</DesignSystemProvider>
			</TrustpilotWrap>

			<FCABlock />
		</QuoteLayoutLowerSplashInner>
	</QuoteLayoutLowerSplash>
);

export default LowerSplash;
