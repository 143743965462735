import React from 'react';

import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as SimpleDialog from '~lib/frontend/design-system/components/dialog/molecules/SimpleDialog';
import Typography from '~lib/frontend/design-system/components/Typography';

interface CancelASubscriptionModalProps {
	open: boolean;
	onClose: () => void;
}

const CancelImmediately: React.FCWithChildren<CancelASubscriptionModalProps> = props => {
	const { open, onClose } = props;

	return (
		<SimpleDialog.Dialog open={open} onClose={onClose} title={'Let’s put you through to the team right away'}>
			<SimpleDialog.Content>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
					{'Need to stop your cover today? We can help with that. '}
					{'We just need a few more details from you before we can make that happen.'}
				</Typography>
			</SimpleDialog.Content>
			<SimpleDialog.Actions>
				<Button $type={'neutralMuted'} onClick={onClose}>
					{'Keep subscription'}
				</Button>
				<Button
					as={Anchor}
					$type={'primary'}
					// @ts-ignore
					href={'mailto:support@cuvva.com'}
					className={'intercom-launcher'}
					onClick={onClose}
				>
					{'Start chat to cancel'}
				</Button>
			</SimpleDialog.Actions>
		</SimpleDialog.Dialog>
	);
};

export default CancelImmediately;
