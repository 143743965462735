import styled from 'styled-components';
const IconWrapper = styled.div `
	width: 24px;
	height: 18px;
	margin-right: ${p => p.theme.spacing.small};
	cursor: pointer;
	display: grid;
	place-items: center;

	& > * {
		transition: transform 0.3s;
		transform: ${p => (p.$open ? 'rotate(180deg)' : 'none')};
	}
`;
export default IconWrapper;
