import { call, put, select } from 'redux-saga/effects';

import * as actions from '../actions';
import { generateReferralCode } from '~lib/platform/promo/store/actions';
import { ReferralCode } from '~lib/platform/promo/types';
import { createTakeEverySagaSet, requestAndTake } from '~lib/shared/redux/sagas';
import { generateDeepLink } from '~website/helpers/branch';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(actions.generateReferralLink.request, function* generateReferralLinkSaga() {
	const userId: string = yield select((state: ApplicationState) => state.internal.auth.user.response);

	const response: ReferralCode = yield requestAndTake(generateReferralCode.request({ userId }));

	try {
		const branchData = { p: response.referralCode };
		const link = yield call(generateDeepLink, 'referral', branchData);

		yield put(actions.generateReferralLink.success({ referralLink: link }));
	} catch (error) {
		yield put(actions.generateReferralLink.failure(error));
	}
});
