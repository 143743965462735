import React from 'react';

import { AdapterProps } from '../types';
import TitleSegmentTwoCardsColumn, { contentAdapter } from '~website/components/atoms/TitleSegmentTwoCardsColumn';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<TitleSegmentTwoCardsColumn {...contentAdapter(getter)} />
);

export default {
	type: 'title_segment_two_cards_column',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			title: { type: 'string' },
			body: { type: 'text' },
			subtitle: { type: 'string' },
			show_button: { type: 'cta' },
			cards: {
				type: 'object[]',
				fields: {
					illustration_name: { type: 'string' },
					title: { type: 'string' },
					body: { type: 'text' },
					badge: { type: 'string' },
					card_url: { type: 'string' },
					show_checklist: { type: 'checklistItem[]' },
					show_button: { type: 'cta' },
				},
			},
		},
		default: {
			title: 'Anybody that needs a test',
			body: 'gets a test.',
			show_button: {
				text: 'Do get a test',
				url: '#',
			},
			cards: [
				{
					illustration_name: 'il_cuvva_phone.svg',
					title: 'test',
					body: 'The first feature',
					badge: 'New',
					card_url: '',
					show_checklist: [
						{ label: 'One', checked: true },
						{ label: 'Two', checked: true },
						{ label: 'Three', checked: true },
					],
					show_button: {
						text: 'Get a quote',
						url: '#',
					},
				},
				{
					illustration_name: 'il_cuvva_phone.svg',
					title: 'test',
					body: 'The second feature',
					card_url: '',
					show_checklist: [
						{ label: 'One', checked: true },
						{ label: 'Two', checked: true },
						{ label: 'Three', checked: true },
					],
					show_button: {
						text: 'Get a quote',
						url: '#',
					},
				},
			],
		},
	},
};
