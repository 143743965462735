import React from 'react';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';

interface DotListProps {
	items: string[];
}

const DotList: React.FCWithChildren<DotListProps> = ({ items }) =>
	items.map((item, i) => (
		<DotItem key={`${i}:${item}`}>
			<SingleDot>{i + 1}</SingleDot>
			<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
				{item}{' '}
			</Typography>
		</DotItem>
	));

const SingleDot = styled.li`
	min-width: 24px;
	height: 24px;
	text-align: center;
	line-height: 24px;
	border-radius: 50%;
	font-size: 12px;

	display: inline-block;
	position: relative;
	background: ${p => p.theme.ui.surfaceDark};
	color: ${p => p.theme.ui.textOnFill};
	margin-right: ${p => p.theme.spacing.small};
`;

const DotItem = styled.div`
	display: flex;
	align-items: flex-start;
	margin-bottom: ${p => p.theme.spacing.large};
`;

export default DotList;
