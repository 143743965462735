import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Cher from '@cuvva/cher';

import QuoteInputDecorator from '../../../components/atoms/QuoteInputDecorator';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import debounce from '~lib/shared/helpers/debounce';
import { postcodeRegex } from '~lib/shared/helpers/regex';
import useReversiblePostcodeLookup from '~website/features/quote/hooks/use-reversible-postcode-lookup';

interface Type {
	latitude: number;
	longitude: number;
}

interface PostcodeCoordsLookupProps {
	onChange: ({ latitude, longitude }: Type) => void;
	impliedPostcode: string;
	setFieldError: (error: Cher) => void;
	error: string;
	label: string;
}

const PostcodeCoordsLookup: React.FCWithChildren<PostcodeCoordsLookupProps> = ({
	onChange,
	impliedPostcode,
	setFieldError,
	error,
	label,
}) => {
	const { postcodeResult, postcodeResultStatus, onLookupPostcode } = useReversiblePostcodeLookup();
	const [searchPostcode, setSearchPostcode] = useState('');

	const onPostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value.replace(/[^a-z0-9 ]/gi, '');

		value = value.toLocaleUpperCase();
		setSearchPostcode(value);

		debouncedPostcodeLookup(value);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedPostcodeLookup = useCallback(
		debounce((value: string) => {
			const safePostcode = value?.replace(/\s/g, '');

			if (!postcodeRegex.exec(safePostcode)) {
				setFieldError(new Cher('invalid'));

				return;
			}

			setFieldError(new Cher('loading'));
			onLookupPostcode(value);
		}, 750),
		[]
	);

	useEffect(() => {
		if (!impliedPostcode) return;

		setSearchPostcode(impliedPostcode);
	}, [impliedPostcode]);

	useEffect(() => {
		if (postcodeResultStatus === 'error') {
			onChange(void 0);

			return;
		}

		if (postcodeResultStatus !== 'response') return;

		const { latitude, longitude } = postcodeResult.response;

		onChange({ latitude, longitude });
	}, [setFieldError, onChange, postcodeResultStatus, postcodeResult]);

	return (
		<Fragment>
			<QuoteInputDecorator label={label} error={error}>
				<TextInput value={searchPostcode} onChange={onPostcodeChange} />
			</QuoteInputDecorator>

			{/* SC: This should never happen but never say never */}
			{postcodeResultStatus === 'error' && (
				<Banner.Wrapper icon={'ic_alert'} type={'error'} muted>
					<div>{"We couldn't find that postcode."}</div>
					<div>{'Please try again'}</div>
				</Banner.Wrapper>
			)}
		</Fragment>
	);
};

export default PostcodeCoordsLookup;
