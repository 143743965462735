import styled from 'styled-components';
const ErrorMessage = styled.div `
	display: flex;
	flex-direction: row;
	gap: ${p => p.theme.spacing.extraSmall};
	align-items: flex-start;
	margin-top: ${p => p.theme.spacing.extraSmall};

	& > * {
		color: ${p => p.theme.ui.textDanger};
	}
`;
export default ErrorMessage;
