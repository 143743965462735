import React, { useContext } from 'react';

import { LtmQuoteContext } from '../context';
import { formatBool, formatIsoDate, formatNcb, formatValue } from '../formatters';
import InfoCard from '~website/features/policy/atoms/InfoCard';

const PolicyInfoCard: React.FunctionComponent = () => {
	const { asyncStatus, quote, vehicle } = useContext(LtmQuoteContext);

	if (asyncStatus !== 'response') return <InfoCard fetching hideSeparator />;

	return (
		<InfoCard
			hideSeparator
			items={[
				['Policy starts', formatIsoDate(quote.response.cycle.startDate, true)],
				[
					'Next payment',
					[
						formatValue(quote.response.cycle.pricing.totalPayable),
						formatIsoDate(quote.response.cycle.nextBillingDate),
					].join(', '),
				],
				['Policy type', 'Fully comprehensive'],
				['Reg plate', vehicle?.response?.prettyVrm],
				['Smart Pricing', formatBool(quote.response.summary.policy.smartPricing?.enabled)],
				['Total excess', formatValue(quote.response.cover.excess * 100)],
				['Business use', formatBool(quote.response.summary.policy.useClass === 'class1')],
				['No-claims bonus', formatNcb(quote.response.summary.policy.ncb?.durationMonths)],
			]}
		/>
	);
};

export default PolicyInfoCard;
