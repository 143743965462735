import React from 'react';

import Accordion from '~lib/frontend/design-system/components/accordion/NewWebAccordion';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';
import { GetterType } from '~lib/frontend/types/content';

const SmartPricingInsOut = () => {
	const g = useContentKey('strings');
	const get: GetterType = (key, fallback) => g(`insights.smart_pricing_education.${key}`, fallback);

	const content = get('faqs.topics', []);

	const accordionItems = content.map(({ title, body }) => ({
		title: (
			<Typography $bold $align={'left'} $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
				{title}
			</Typography>
		),
		content: (
			<Typography $align={'left'} $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
				{body}
			</Typography>
		),
	}));

	const { isMobile } = useMobileDetect();
	const marginSizing = isMobile ? 'extraLarge' : '48px';

	return (
		<Margin $marginTop={marginSizing}>
			<Typography $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'}>
				{get('faqs.title', 'The ins and outs')}
			</Typography>

			<Typography $type={'Body.Medium'} $marginTop={'regular'} $color={'textOnSurfaceBackgroundMuted'}>
				{get('faqs.intro', 'For all you read-the-label types. We got you. 👇')}
			</Typography>

			<Margin $marginTop={'extraLarge'}>
				<Accordion items={accordionItems} />
			</Margin>
		</Margin>
	);
};

export default SmartPricingInsOut;
