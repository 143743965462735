import { createSlice } from '@reduxjs/toolkit';

import { pageActions, quoteActions, stepActions } from '../actions';
import type { PreloadedVehicle } from '../types';
import type { Location, UseClass } from '~lib/platform/ltm/types';
import { getItem } from '~lib/shared/helpers/local-storage';
import { quoteLocation, quoteRecentNCB } from '~website/constants/storage';

export default createSlice({
	name: '@@internal/quote/state',
	initialState: {
		product: void 0 as string,
		step: void 0 as string,
		flow: void 0 as string,
		recentFlow: void 0 as string,
		vehicleId: void 0 as string,
		prefetch: {
			user: false,
			preferences: false,
		},
		recentVehicles: void 0 as PreloadedVehicle[],
		ncb: getItem(quoteRecentNCB, 'sessionStorage') as number,
		quoteLocation: null as Location | null,
		startDate: void 0 as string,
		useClass: void 0 as UseClass,
		voluntaryExcess: void 0 as number,
		smartPricing: void 0 as boolean,
	},
	reducers: {},
	extraReducers: builder =>
		builder
			.addCase(quoteActions.setProduct, (state, action) => void (state.product = action.payload))
			.addCase(quoteActions.setFlow, (state, action) => {
				state.flow = action.payload;
			})
			.addCase(quoteActions.fetchUser.success, state => void (state.prefetch.user = true))
			.addCase(quoteActions.fetchPreferences.success, state => void (state.prefetch.preferences = true))
			.addCase(quoteActions.setRecentFlow, (state, action) => void (state.recentFlow = action.payload))
			.addCase(quoteActions.setVehicle, (state, action) => {
				state.vehicleId = action.payload.vehicleId;
			})
			.addCase(quoteActions.setRecentVehicles, (state, action) => {
				state.recentVehicles = action.payload;
			})
			.addCase(pageActions.newChangeRequest, (state, action) => {
				// Preserves location when generating new pages e.g. on location.pathname change
				if (state.quoteLocation === null) {
					const storedLocation = getItem<Location>(quoteLocation, 'sessionStorage');

					if (storedLocation) {
						state.quoteLocation = storedLocation;
					}
				}
			})
			// NOTE(SC): fields below are an exception they can't be sent until quoting time and can't live in the
			// internal change request store branch, as that isn't persisted anywhere and would be discarded
			.addCase(stepActions.setNCBYears, (state, action) => void (state.ncb = action.payload))
			.addCase(stepActions.setQuoteLocation, (state, action) => void (state.quoteLocation = action.payload))
			.addCase(stepActions.setStartDate, (state, action) => void (state.startDate = action.payload))
			.addCase(stepActions.setUseClass, (state, action) => void (state.useClass = action.payload))
			.addCase(stepActions.setVoluntaryExcess, (state, action) => void (state.voluntaryExcess = action.payload))
			.addCase(stepActions.setSmartPricing, (state, action) => void (state.smartPricing = action.payload)),
});
