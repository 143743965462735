import React from 'react';

import { ActiveDiscountsProps } from '../../hooks/use-discounts';
import { DiscountListWrap } from '../atoms/alignment';
import Discount from '../atoms/Discount';
import Typography from '~lib/frontend/design-system/components/Typography';

const ActiveDiscounts = (props: ActiveDiscountsProps) => {
	const { loading, discounts } = props;

	if (loading) return <ActiveDiscountEmptyState />;

	if (!loading && discounts.length === 0) {
		return <ActiveDiscountEmptyState />;
	}

	return (
		<React.Fragment>
			<DiscountListWrap>
				{discounts.map(discount => (
					<Discount discount={discount} />
				))}
			</DiscountListWrap>

			<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'} $marginTop={'large'}>
				{'We’ll apply these in the order you see them when you go to pay. You can only use one per policy.'}
			</Typography>
		</React.Fragment>
	);
};

export default ActiveDiscounts;

const ActiveDiscountEmptyState = () => (
	<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
		{'No active discounts'}
	</Typography>
);
