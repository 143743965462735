import { put, select } from 'redux-saga/effects';

import { quoteActions } from '../actions';
import { latestDocuments, recordAgreement, userStatus } from '~lib/platform/terms/store/actions';
import { DocumentsResponse, UserStatus } from '~lib/platform/terms/types';
import { createTakeEverySagaSet, requestAndTake } from '~lib/shared/redux/sagas';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(quoteActions.acceptTerms, function* acceptTermsSaga() {
	const userId: string = yield select((state: ApplicationState) => state.internal.auth.user.response);

	const termsStatus: UserStatus = yield requestAndTake(userStatus.request({ userId }));

	if (termsStatus.status !== 'up_to_date') {
		const latestDocs: DocumentsResponse = yield requestAndTake(latestDocuments.request(void 0));

		yield put(
			recordAgreement.request({
				userId,
				document: 'terms',
				version: latestDocs.terms.version,
			})
		);

		yield put(
			recordAgreement.request({
				userId,
				document: 'privacy',
				version: latestDocs.privacy.version,
			})
		);
	}
});
