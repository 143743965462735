import React, { useContext } from 'react';

import { SectionHeaderText } from '../atoms/HiringOfferHeaderWrapper';
import HiringOfferContext from '../context';
import Typography from '~lib/frontend/design-system/components/Typography';

const CuvvaOptions: React.FunctionComponent = () => {
	const { shareOptionsCount, shareOptionsEstimatedValue } = useContext(HiringOfferContext);

	if (![shareOptionsCount, shareOptionsEstimatedValue].some(Boolean)) return null;
	const sharesValue = shareOptionsEstimatedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	return (
		<div>
			<SectionHeaderText>{'Options at Cuvva'}</SectionHeaderText>
			<Typography $type={'Body.XLarge'}>
				{`We closed our Series A at the end of 2019. We could imagine the value might be higher now, but no way to know until the next time we take on funding, so from 2019 the equivalent value of your ${shareOptionsCount} shares was £${sharesValue}.`}
			</Typography>

			<Typography $type={'Body.XLarge'} $marginTop={'large'}>
				{'More details about why we offer share options and how they work are in the options explainer.'}
			</Typography>
		</div>
	);
};

export default CuvvaOptions;
