import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { PageCtx } from '../contexts/PageCtx';
import useFormEventTracking from '../hooks/use-form-events-tracking';
import Form from './atoms/Form';
import TripStartLocation from './binded-fields/TripStartLocation';
import PageError from './molecules/PageError';
import ProceedButton from './molecules/ProceedButton';
import Typography from '~lib/frontend/design-system/components/Typography';
import VehicleManufacturer from '~lib/frontend/design-system/components/VehicleManufacturer';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { getPublicVehicle } from '~lib/platform/vehicle/store/actions';

const TripStart: React.FunctionComponent = () => {
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const [enabled, setEnabled] = useState(false);
	const formTracking = useFormEventTracking();

	const history = useHistory();
	const vehicleId = useSelector(s => s.internal.quote.state.vehicleId);
	const vehicle = useSelector(s => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);
	const vehicleStatus = useAsyncStateStatus(vehicle).status;

	const dispatch = useDispatch();

	useEffect(() => {
		if (!vehicleId) return;

		if (vehicleStatus === 'pending') dispatch(getPublicVehicle.request({ id: vehicleId, bypassChecks: false }));
	}, [dispatch, vehicleStatus, vehicleId]);

	const redirectToVehicleSearch = () => {
		history.push(createAwareUrl('/vehicles'));
	};

	return (
		<Fragment>
			{vehicle?.response && (
				<VehicleInfo onClick={redirectToVehicleSearch}>
					<VehicleManufacturer make={vehicle.response.make} size={'24px'} color={'neutralFillMuted'} />
					<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'} $bold>
						{vehicle.response.vrm}
					</Typography>
					<Typography $type={'Body.Small'}>
						{vehicle.response.make} {vehicle.response.model}
					</Typography>
				</VehicleInfo>
			)}

			<Form {...formTracking}>
				<div>
					<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
						{'Where will your trip start?'}
					</Typography>

					<PageError page={page} />
				</div>

				<TripStartLocation enableProceedButton={setEnabled} proceedButtonEnabled={enabled} />

				<div>
					<ProceedButton path={'/quote/your-details'} disabled={!enabled}>
						{'Continue'}
					</ProceedButton>
				</div>
			</Form>
		</Fragment>
	);
};

export default TripStart;

const VehicleInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};
	padding: ${p => p.theme.spacing.large} ${p => p.theme.spacing.large} ${p => p.theme.spacing.extraLarge};
	margin-bottom: ${p => p.theme.spacing.extraLarge};

	cursor: pointer;
`;
