import Cher from '@cuvva/cher';
import CuvvaClient from '@cuvva/cuvva-client';
import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
// @ts-ignore
import PostcodesIOClient from 'postcodesio-client';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';

import { WindowWithBTRState } from './augmentations';
import { initialAnalyticsState } from './features/analytics/store/types';
import { initialCookieConsentState } from './features/cookie-policy/store';
import { initialInternalQuoteState } from './features/quote/store';
import { initialReferralState } from './features/referral/store/types';
import { AppsFlyerSDK } from './helpers/appsflyer-sdk';
import { GetAddressIO } from './helpers/getaddressio-sdk';
import { ApplicationState, createRootReducer, rootSaga } from './store';
import { initialAboutState } from './store/about/types';
import { initialUserState } from './store/auth/types';
import { initialCareersState } from './store/careers/types';
import IterableSDK from '~lib/frontend/api/iterable-sdk';
import MixpanelClient, { MixpanelClientOptions } from '~lib/frontend/api/mixpanel-client';
import { getEnvironment } from '~lib/frontend/helpers/environment';
import { initialAuthState } from '~lib/platform/auth/store/types';
import { initialChangeRequestState } from '~lib/platform/change-request/store/types';
import { initialCodeMappingState } from '~lib/platform/code-mapping/store/types';
import { initialContentState } from '~lib/platform/content/store/types';
import { LiteSegment } from '~lib/platform/content/types';
import { initialDoggosState } from '~lib/platform/doggos/store/types';
import { initialDrivingLicenseRegistrationState } from '~lib/platform/driving-license-registration/store/types';
import { initialDVLAVESState } from '~lib/platform/dvla-ves/store/types';
import { initialHiringOfferState } from '~lib/platform/hiring-offer/store/types';
import { initialIncidentState } from '~lib/platform/incident/store/types';
import { initialKycState } from '~lib/platform/kyc/store/types';
import { initialLtmState } from '~lib/platform/ltm/store/types';
import { initialLtmCancellationState } from '~lib/platform/ltm-cancellation/store/types';
import { initialMarketingConsentState } from '~lib/platform/marketing-consent/store';
import { initialMOTState } from '~lib/platform/mot/store/types';
import { initialPaymentState } from '~lib/platform/payment/store/types';
import { initialPolicyDocumentsState } from '~lib/platform/policy-documents/store/types';
import { initialProfileState } from '~lib/platform/profile/store/types';
import { initialPromoState } from '~lib/platform/promo/store/types';
import { initialSocialDrivingState } from '~lib/platform/social-driving/store/types';
import { initialStaffState } from '~lib/platform/staff/store/types';
import { initialSurveyState } from '~lib/platform/survey/store/types';
import { initialUploadState } from '~lib/platform/upload/store/types';
import { initialUserConfigState } from '~lib/platform/user-config/store/types';
import { initialVehicleState } from '~lib/platform/vehicle/store/types';
import { initialVehicleProfileState } from '~lib/platform/vehicle-profile/store/types';
import { TypedObject } from '~lib/shared/helpers/typed';
import { AsyncMapState, initialAsyncState } from '~lib/shared/redux/types/state';

type Content = Record<string, LiteSegment>;
type AppStore = Store<ApplicationState>;

export default function configureStore(history: History, client?: CuvvaClient, content?: Content): AppStore {
	const composeEnhancers = composeWithDevTools({});
	const sagaMiddleware = createSagaMiddleware();
	const initialState: ApplicationState = getInitialState(content);
	const store = createStore(
		createRootReducer(history),
		initialState,
		composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history)))
	);

	const userId = store.getState()?.internal?.auth?.user?.response;

	const context = {
		api: client,
		postcodeApi: new PostcodesIOClient('https://api.postcodes.io/'),
		getAddress: new GetAddressIO('pNWgeX8Zv0KZPSmvZT5xiQ26312'),
		appsFlyer: new AppsFlyerSDK(),
		mixpanelClient: createMixpanelClient(userId),
		iterableClient: new IterableSDK(),
		flashMessages: {
			addSuccess: () => ({ type: '@@stub' }),
			addInfo: () => ({ type: '@@stub' }),
			addWarning: () => ({ type: '@@stub' }),
			addDanger: () => ({ type: '@@stub' }),
			addError: () => ({ type: '@@stub' }),
		},
	};

	sagaMiddleware.setContext(context);
	sagaMiddleware.run(rootSaga);

	return store;
}

const getAsyncContent = (content?: Content): AsyncMapState<LiteSegment> =>
	TypedObject.keys(content).reduce(
		(prev, curr) => ({
			...prev,
			[curr]: {
				...initialAsyncState,
				response: content[curr],
			},
		}),
		{}
	);

function getInitialState(content?: Content): ApplicationState {
	if (getEnvironment() === 'browser') {
		const btrState = (window as typeof window & WindowWithBTRState).__BTR_STATE__;

		if (btrState) {
			btrState.router = void 0;

			if (!content) return btrState;

			const contentAsync = getAsyncContent(content);

			return {
				...btrState,
				platform: {
					...btrState.platform,
					content: {
						...btrState.platform.content,
						segments: contentAsync,
					},
				},
			};
		}
	}

	return {
		internal: {
			about: initialAboutState,
			analytics: initialAnalyticsState,
			auth: initialUserState,
			careers: initialCareersState,
			cookieConsent: initialCookieConsentState,
			quote: initialInternalQuoteState,
			referral: initialReferralState,
		},
		platform: {
			auth: initialAuthState,
			changeRequest: initialChangeRequestState,
			codeMapping: initialCodeMappingState,
			content: { ...initialContentState, segments: getAsyncContent(content ?? {}) },
			doggos: initialDoggosState,
			drivingLicenseRegistration: initialDrivingLicenseRegistrationState,
			dvlaves: initialDVLAVESState,
			hiringOffer: initialHiringOfferState,
			incidents: initialIncidentState,
			kyc: initialKycState,
			ltm: initialLtmState,
			ltmCancellation: initialLtmCancellationState,
			marketingConsent: initialMarketingConsentState,
			mot: initialMOTState,
			payment: initialPaymentState,
			policyDocuments: initialPolicyDocumentsState,
			profile: initialProfileState,
			promo: initialPromoState,
			vehicle: initialVehicleState,
			vehicleProfile: initialVehicleProfileState,
			staff: {
				listStaffPublic: initialStaffState.listStaffPublic,
			},
			survey: initialSurveyState,
			socialDriving: initialSocialDrivingState,
			upload: initialUploadState,
			userConfig: {
				getConfigById: initialUserConfigState.getConfigById,
			},
		},
	};
}

function createMixpanelClient(userId: string) {
	if (typeof window !== 'undefined') {
		const token = window.cuvva.config?.mixpanelToken;
		const opts: MixpanelClientOptions = { token, userId };
		const client = new MixpanelClient(opts);

		client
			.setup()
			.then()
			.catch(error => new Cher('mixpanel_setup_failed', null, error));

		return client;
	}

	return null;
}
