import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../../Icon';
import { CheckBoxWrapper } from '../atoms/CheckBox';
import Typography from '~lib/frontend/design-system/components/Typography';
const CheckBox = props => {
    const { label, bold, disabled, checked, labelFirst, stretch, children, ...rest } = props;
    return (React.createElement(CheckBoxWrapper, { "$disabled": disabled, "$labelFirst": labelFirst, "$stretch": stretch },
        React.createElement("input", { type: 'checkbox', disabled: disabled, checked: checked, ...rest }),
        React.createElement(CheckBoxControl, { "$checked": checked }, checked && React.createElement(Icon, { icon: 'ic_check', "$color": 'textOnFill', "$size": '12px', "$display": 'inline-flex' })),
        label && (React.createElement(Typography, { "$bold": bold, "$type": 'Body.Medium', "$color": 'textOnSurfaceBackground' }, label))));
};
const CheckBoxControl = styled.div `
	border: 1px solid ${p => p.theme.ui.borderInteractiveDefault};
	width: 20px;
	height: 20px;
	border-radius: ${p => p.theme.borderRadius.extraSmall};
	text-align: center;
	display: grid;
	place-items: center;

	${p => p.$checked &&
    css `
			background: ${p => p.theme.ui.surfaceHighlight};
		`}
`;
export default CheckBox;
