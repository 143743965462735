import React from 'react';
import Icon from '../../Icon';
import { AfterFieldHintAlign } from '../atoms/Aligners';
import InputDecoratorSC from '../atoms/Decorator';
import ErrorMessage from '../atoms/ErrorMessage';
import Hint from '../atoms/Hint';
import LabelContainer from '../atoms/Label';
import InputDecoratorContext from '../context/decorator';
import Typography from '~lib/frontend/design-system/components/Typography';
import sanitizeHtml from '~lib/frontend/helpers/sanitize-html';
const sizeVariant = (size) => {
    switch (size) {
        case 'small':
            return 'Label.Small';
        case 'medium':
            return 'Label.Medium';
        case 'large':
            return 'Label.Large';
        default:
            return 'Label.Medium';
    }
};
const InputDecorator = props => {
    const { label, hint, hintPosition = 'end-of-line', error, size, help, children, ...innerProps } = props;
    return (React.createElement(InputDecoratorSC, { ...innerProps },
        label && (React.createElement(LabelContainer, { "$hintPosition": hintPosition },
            React.createElement(Typography, { as: 'label', "$type": sizeVariant(size), "$color": 'textOnSurfaceBackgroundMuted' }, label),
            hint && hintPosition !== 'after-field' && React.createElement(Hint, { "$hintPosition": hintPosition }, hint))),
        React.createElement(InputDecoratorContext.Provider, { value: { error } },
            (!hint || hintPosition !== 'after-field') && children,
            hint && hintPosition === 'after-field' && (React.createElement(AfterFieldHintAlign, null,
                children,
                React.createElement(Hint, null, hint)))),
        typeof error === 'string' && Boolean(error) && (React.createElement(ErrorMessage, null,
            React.createElement(Icon, { "$size": '18px', icon: 'ic_important_circle', "$color": 'textDanger' }),
            React.createElement(Typography, { "$type": 'Body.Small', "$color": 'textDanger', dangerouslySetInnerHTML: { __html: sanitizeHtml(error) } }))),
        help && !error && (React.createElement(Typography, { "$type": 'Body.Small', "$color": 'textMinor', "$marginTop": 'extraSmall', dangerouslySetInnerHTML: { __html: sanitizeHtml(help) } }))));
};
InputDecorator.defaultProps = {
    size: 'medium',
};
export default InputDecorator;
