import React from 'react';

import { AdapterProps } from '../types';
import TitleSegmentTrioFeatures, { contentAdapter } from '~website/components/atoms/TitleSegmentTrioFeatures';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<TitleSegmentTrioFeatures {...contentAdapter(getter)} />
);

export default {
	type: 'title_segment_trio_features',
	adapter,
	builder: {
		fields: {
			centered: { type: 'boolean' },
			id: { type: 'string' },
			title: { type: 'string' },
			body: { type: 'text' },
			show_button: { type: 'cta' },
			show_app_store_buttons: { type: 'boolean' },
			show_trustpilot: { type: 'boolean' },
			feature_color: { type: 'string' },
			feature_background: { type: 'string' },
			features: {
				type: 'object[]',
				fields: {
					icon: { type: 'string' },
					title: { type: 'string' },
					body: { type: 'string' },
				},
			},
		},
		default: {
			centered: true,
			title: 'Anybody that needs a test',
			body: 'gets a test',
			show_button: {
				text: 'Do things',
				url: '#',
			},
			show_app_store_buttons: false,
			show_trustpilot: false,
			feature_color: 'primaryFill',
			feature_background: 'primaryFillMuted',
			features: [
				{
					icon: 'test',
					title: 'Item juan',
					body: 'The first feature',
				},
				{
					icon: 'test',
					title: 'Item deux',
					body: 'The second feature',
				},
				{
					icon: 'test',
					title: 'Item three',
					body: 'The third feature',
				},
			],
		},
	},
};
