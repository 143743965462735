import React from 'react';
import styled from 'styled-components';

import InputDecorator, {
	InputDecoratorProps,
} from '~lib/frontend/design-system/components/input/molecules/InputDecorator';

const StyledInputDecorator = styled(InputDecorator)`
	scroll-margin-top: ${p => p.theme.spacing['4xLarge']};
`;

const QuoteInputDecorator: React.FCWithChildren<InputDecoratorProps> = props => (
	<StyledInputDecorator {...props} data-type={props.error ? 'input-decorator-error' : ''} />
);

export default QuoteInputDecorator;
