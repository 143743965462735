import React from 'react';

import { formatDate } from '../utils/formatDateTime';
import { BigCardWrapper } from './Card';
import InfoCard from './InfoCard';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';
import Typography from '~lib/frontend/design-system/components/Typography';
import type { Transaction } from '~lib/platform/ltm/types';
import { formatValue } from '~website/features/quote/components/quote-page/formatters';

interface BillingInfoCardProps {
	transactions: Transaction[];
	loading: boolean;
}

const BillingInfoCard: React.FCWithChildren<BillingInfoCardProps> = ({ loading, transactions }) => (
	<React.Fragment>
		{loading && (
			<BigCardWrapper>
				<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
					{'Receipt'}
				</Typography>

				<Margin $marginTop={'extraSmall'}>
					<SkeletonLoader width={'40%'} />
				</Margin>

				<Margin $marginTop={'small'}>
					<InfoCard fetching direction={'row'} />
				</Margin>
			</BigCardWrapper>
		)}

		{!loading &&
			transactions.map(transaction => (
				<BigCardWrapper key={transaction.transactionId}>
					<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
						{'Receipt'}
					</Typography>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
						{`${formatDate(transaction.confirmedAt).date} at ${formatDate(transaction.confirmedAt).time}`}
					</Typography>

					<Margin $marginTop={'small'}>
						<InfoCard
							direction={'row'}
							items={[
								['Insurance premium', formatValue(transaction?.pricing.underwriterPremium)],
								['Insurance premium tax', formatValue(transaction?.pricing.ipt)],
								['Deductions', formatValue(transaction?.pricing.deductions)],
								['Admin fee', formatValue(transaction?.pricing.extraFees)],
								['Your total price', formatValue(transaction?.pricing.totalPayable)],
							]}
						/>
					</Margin>
				</BigCardWrapper>
			))}
	</React.Fragment>
);

export default BillingInfoCard;
