import React, { useCallback, useEffect, useState } from 'react';

import useOnPageError from '../../hooks/use-on-page-error';
import useQuoteLocation from '../../hooks/use-quote-location';
import useReversiblePostcodeLookup from '../../hooks/use-reversible-postcode-lookup';
import LocateMe, { LocationUpdate } from '../molecules/address-lookup/LocateMe';
import PostcodeCoordsLookup from '../molecules/address-lookup/PostcodeCoordsLookup';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';

interface TripStartLocationProps {
	enableProceedButton: (value: boolean) => void;
	proceedButtonEnabled: boolean;
}

const TripStartLocation: React.FCWithChildren<TripStartLocationProps> = props => {
	const { enableProceedButton, proceedButtonEnabled } = props;
	const { onLookupCoords, reverseGeocodingResult, reverseGeocodingResultStatus } = useReversiblePostcodeLookup();

	const { onChange, validation, value, setFieldError } = useQuoteLocation();
	const pageError = useOnPageError(validation, 'trip_start_location');
	const [impliedPostcode, setImpliedPostcode] = useState('');
	const { error: blurError, ...blurEvents } = useOnBlurError(validation);

	useEffect(() => {
		const enabled = !validation;

		if (proceedButtonEnabled !== enabled) enableProceedButton(enabled);
	}, [validation, enableProceedButton, proceedButtonEnabled, reverseGeocodingResultStatus, impliedPostcode]);

	const onLocateMeChange = useCallback(
		(update: LocationUpdate) => {
			if (!update) {
				onChange(void 0);
				setImpliedPostcode('');

				return;
			}

			const { postcode, ...coords } = update;

			onChange(coords);
			setImpliedPostcode(postcode);
		},
		[onChange]
	);

	useEffect(() => {
		if (value && !impliedPostcode) onLookupCoords(value);
	}, [value, impliedPostcode, onLookupCoords]);

	useEffect(() => {
		if (reverseGeocodingResultStatus !== 'response') return;

		setImpliedPostcode(reverseGeocodingResult.response.postcode);
	}, [reverseGeocodingResult, reverseGeocodingResultStatus]);

	return (
		<div {...blurEvents}>
			<PostcodeCoordsLookup
				onChange={onChange}
				impliedPostcode={impliedPostcode}
				setFieldError={setFieldError}
				error={pageError}
				label={'Postcode'}
			/>

			<LocateMe onLocationChange={onLocateMeChange} key={validation} />
		</div>
	);
};

export default TripStartLocation;
