import React from 'react';
import styled from 'styled-components';
import EnhancedUncontrolledTooltip from './EnhancedUncontrolledTooltip';
const Abbr = props => {
    const { className, hideUnderline, children, ...otherProps } = props;
    return (React.createElement(AbbWrapper, { hideUnderline: hideUnderline, className: className },
        React.createElement(EnhancedUncontrolledTooltip, { ...otherProps }, children)));
};
Abbr.defaultProps = {
    autohide: false,
    delay: {
        show: 250,
        hide: 250,
    },
    hideUnderline: false,
    placement: 'top',
};
const AbbWrapper = styled.div `
	text-decoration: underline dotted;
	cursor: help;
	border-bottom: 0;
	display: inline-block;
	${p => (p.hideUnderline ? 'text-decoration: none' : '')};
`;
export default Abbr;
