import { VehicleActionTypes, } from './types';
import { createAsyncAction, createAsyncMapAction } from '~lib/shared/redux/actions';
export const banVehicle = createAsyncMapAction(VehicleActionTypes.BAN_VEHICLE, p => p.requestId);
export const findVehicleId = createAsyncMapAction(VehicleActionTypes.FIND_VEHICLE_ID, p => [p.field, p.value].join(':'));
export const flushCache = createAsyncMapAction(VehicleActionTypes.FLUSH_CACHE, p => p.requestId);
export const getPublicVehicle = createAsyncMapAction(VehicleActionTypes.GET_PUBLIC_VEHICLE, p => [p.id, p.bypassChecks].join(':'));
export const getVehicle = createAsyncMapAction(VehicleActionTypes.GET_VEHICLE, p => [p.id, String(p.revision), p.bypassChecks].join(':'));
export const getVehicleConfig = createAsyncMapAction(VehicleActionTypes.GET_VEHICLE_CONFIG, p => p.id);
export const listVehicleRevisions = createAsyncMapAction(VehicleActionTypes.LIST_VEHICLE_REVISIONS, p => p.vehicleId);
export const listExampleVehicles = createAsyncAction(VehicleActionTypes.LIST_EXAMPLE_VEHICLES);
export const setVehicleProvider = createAsyncMapAction(VehicleActionTypes.SET_VEHICLE_PROVIDER, p => p.requestId);
export const overrideVrm = createAsyncMapAction(VehicleActionTypes.OVERRIDE_VRM, p => p.requestId);
