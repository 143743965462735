import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pastEndDate } from '../utils/formatDateTime';
import { sortIso8601 } from '~lib/frontend/helpers/sort';
import { listPoliciesBySubscription, listSubscriptions } from '~lib/platform/ltm/store/actions';
import { PolicyBySubscription, Subscription } from '~lib/platform/ltm/types';
import useUser from '~website/hooks/use-user';

export type FlattenPolicySubs = PolicyBySubscription & { subInfo: Subscription };

const useSubscriptions = () => {
	const dispatch = useDispatch();
	const { userId } = useUser();

	const subscriptionStore = useSelector(s => s.platform.ltm.listSubscriptions?.[userId]);
	const subscriptions = subscriptionStore?.response;

	const policyStore = useSelector(s => s.platform.ltm.listPoliciesBySubscription);

	const loading = subscriptions?.map(s => s.subscriptionId).some(subId => policyStore[subId]?.fetching) ?? true;
	const ready =
		subscriptions?.map(s => s.subscriptionId).every(subId => Boolean(policyStore[subId]?.response)) ?? false;

	const getPoliciesFromSubs = useCallback(
		(subscriptionId: string) => {
			dispatch(
				listPoliciesBySubscription.request({
					userId,
					subscriptionId,
				})
			);
		},
		[dispatch, userId]
	);

	const checkForFailedPayment = useMemo(() => {
		if (!subscriptions) return false;

		return subscriptions
			.filter(sub => sub.endDate && !pastEndDate(sub.endDate))
			.some(sub => 'paymentFailed' in sub.actionsRequired);
	}, [subscriptions]);

	useEffect(() => {
		if (userId) dispatch(listSubscriptions.request({ userId }));
	}, [dispatch, userId]);

	useEffect(() => {
		if (subscriptions && subscriptions.length > 0) {
			subscriptions.map(subs => subs.subscriptionId).forEach(subId => getPoliciesFromSubs(subId));
		}
	}, [getPoliciesFromSubs, subscriptions]);

	const flattenSubscriptions: FlattenPolicySubs[] = useMemo(() => {
		if (policyStore && Object.keys(policyStore).length > 0) {
			return subscriptions
				?.filter(policy => Boolean(policy.endDate))
				.map(subs => ({
					...subs,
					policies: (policyStore[subs.subscriptionId]?.response ?? []).map(policy => ({
						...policy,
						subInfo: subs,
					})),
				}))
				.flatMap(subs => subs.policies)
				.sort(sortIso8601(policy => policy.startDate, 'desc'));
		}

		return [];
	}, [policyStore, subscriptions]);

	return {
		subscriptions: flattenSubscriptions,
		loading: loading || !ready,
		checkForFailedPayment,
	};
};

export default useSubscriptions;
