import { createContext, useCallback, useState } from 'react';

const defaultValue = {
	removeNav: false,
	setRemoveNav: (_display: boolean) => {
		/* noop */
	},
	parentNavOpen: false,
	setParentNavOpen: (_display: boolean) => {
		/* noop */
	},
};

export const NavContext = createContext(defaultValue);

interface StickyNavProps {
	removeNav: boolean;
	parentNavOpen: boolean;
	setRemoveNav: (display: boolean) => void;
	setParentNavOpen: (display: boolean) => void;
}

export const useStickyNav = (): StickyNavProps => {
	const [removeNav, setRemove] = useState(false);
	const [parentNavOpen, setParentNavOpen] = useState(false);
	const setRemoveNav = useCallback((display: boolean) => {
		setRemove(display);
	}, []);

	return {
		removeNav,
		setRemoveNav,
		parentNavOpen,
		setParentNavOpen,
	};
};
