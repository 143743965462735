import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';
import styled, { css, keyframes } from 'styled-components';

import HorizontalRule from '../../atoms/HorizontalRule';
import { QuoteCard } from '../atoms/QuoteCard';
import { LtmQuoteContext } from '../context';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as Alert from '~lib/frontend/design-system/components/dialog/molecules/Alert';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { openBlankTab, writeHtmlToWindow } from '~lib/frontend/helpers/window';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { getDocumentBase, getPages } from '~lib/platform/content/store/actions';

const PolicyDocsCard: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const { quote, asyncStatus } = useContext(LtmQuoteContext);
	const pages = useSelector(s => s.platform.content.pages);
	const docBase = useSelector(s => s.platform.content.documentBase);
	const [error, setError] = useState<[string, Cher]>();
	const onError = (type: string, e: Cher) => setError([type, e]);
	const onDismiss = () => setError(void 0);
	const ltmQuote = quote?.response;
	const pagesResp = pages.response;
	const pagesStatus = useAsyncStateStatus(pages).status;
	const docBaseStatus = useAsyncStateStatus(docBase).status;

	const ipidKey = ltmQuote?.underwriter.documents.ipidKey;
	const fullKey = `ipid.${ipidKey}.full`;
	const ipid = pagesResp?.pages?.[fullKey];
	const fetching = ![asyncStatus, pagesStatus, docBaseStatus].every(s => s === 'response');

	useEffect(() => {
		dispatch(getPages.request());
		dispatch(getDocumentBase.request());
	}, [dispatch]);

	return (
		<React.Fragment>
			<SpecialCard>
				<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'} $marginBottom={'extraLarge'}>
					{'Policy documents'}
				</Typography>

				<DocList>
					<PolicyDoc
						title={'Policy wording and terms'}
						url={ltmQuote?.documents.termsUrl}
						styles={docBase?.response?.cssUrl}
						onError={e => onError('policy terms', e)}
						fetching={fetching}
					/>
					<HorizontalRule />
					<PolicyDoc
						title={'Insurance Product Information Document (IPID)'}
						url={ipid?.htmlUrl}
						styles={ipid?.cssUrl}
						onError={e => onError('IPID', e)}
						fetching={fetching}
					/>
				</DocList>
			</SpecialCard>

			<Alert.Dialog open={Boolean(error)} title={"Couldn't open the document"}>
				<Alert.Content>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
						{'Unable to fetch the document.'}
					</Typography>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
						{'Please try again or get in touch with customer support.'}
					</Typography>
				</Alert.Content>
				<Alert.Actions>
					<Button $type={'primary'} onClick={onDismiss}>
						{'Dismiss'}
					</Button>
				</Alert.Actions>
			</Alert.Dialog>
		</React.Fragment>
	);
};

interface PolicyDocProps {
	title: string;
	url: string;
	styles: string;
	onError: (e: Cher) => void;
	fetching: boolean;
}

export const PolicyDoc: React.FCWithChildren<PolicyDocProps> = ({ title, url, styles, onError, fetching }) => {
	const [localFetching, setLocalFetching] = useState(false);
	const loading = fetching || localFetching;

	const onClick: React.MouseEventHandler<HTMLAnchorElement> = e => {
		e.preventDefault();

		if (loading) return;

		setLocalFetching(true);

		const win = openBlankTab();

		Promise.all([fetch(url).then(r => r.text()), fetch(styles).then(r => r.text())])
			.then(([html, stylesheet]) => {
				writeHtmlToWindow(win, title, html, void 0, stylesheet);
				setLocalFetching(false);
			})
			.catch(e => {
				onError(Cher.coerce(e));
				setLocalFetching(false);

				win.close();
			});
	};

	return (
		<PolicyDocItem onClick={onClick} href={'#'} $fetching={loading}>
			<Icon icon={'ic_policy'} $color={'neutralFill'} $size={'24px'} />
			<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $bold>
				{title}
			</Typography>
			<Icon icon={loading ? 'ic_loading_spinner' : 'ic_external_link'} $color={'primaryFill'} $size={'24px'} />
		</PolicyDocItem>
	);
};

const SpecialCard = styled(QuoteCard)`
	position: relative;
	overflow: hidden;
`;

const DocList = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
`;

const Rotate = keyframes`
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
`;

const PolicyDocItem = styled.a<{ $fetching: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;

	& > :nth-child(2) {
		flex: 1;
		margin: 0 ${p => p.theme.spacing.large};
	}

	${p =>
		p.$fetching &&
		css`
			& > :nth-child(3) {
				svg {
					transform-origin: center;
					animation: ${Rotate} 0.6s linear infinite;
				}
			}
		`};

	&,
	&:visited,
	&:hover {
		text-decoration: none;
		color: ${p => p.theme.ui.textOnSurfaceBackground};
	}
`;

export default PolicyDocsCard;
