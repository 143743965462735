import React from 'react';

import { ListItem as ListItemSC, ListItemLabel } from '../atoms/list';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

interface ListItemProps {
	label: string;
	body: React.ReactNode;
	locked: boolean;
	action?: React.ReactNode;
}

const ListItem: React.FCWithChildren<ListItemProps> = ({ label, body, locked, action }) => (
	<ListItemSC>
		<ListItemLabel>
			<Typography $type={'Label.Medium'} $color={'textOnSurfaceBackground'}>
				{label}
			</Typography>

			<Typography $type={'Body.Medium'}>{body}</Typography>
		</ListItemLabel>
		<div>
			{locked && <Icon icon={'ic_lock'} $color={'neutralFillMinor'} $size={'24px'} />}
			{!locked && (action ?? null)}
		</div>
	</ListItemSC>
);

export default ListItem;
