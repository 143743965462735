import styled, { css } from 'styled-components';

interface TabItemProps {
	active: boolean;
}

const TabBar = styled.div<{ $items: number }>`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin: 0 auto;
	max-width: ${p => (p.$items < 3 ? '400px' : 'unset')};
`;

const TabItem = styled.div<TabItemProps>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: ${p => p.theme.ui.surface};
	color: ${p => p.theme.ui.primaryFill};
	box-shadow: 6px 8px 30px rgba(102, 102, 255, 0.08);
	margin-bottom: 10px;

	cursor: pointer;
	padding: 7px 9px;
	border-radius: ${p => p.theme.borderRadius.small};
	min-width: 160px;

	${p =>
		p.active
			? css`
					color: ${p.theme.ui.textOnFill};
					background: ${p.theme.ui.primaryFill};
				`
			: ''}
`;

export { TabBar, TabItem };
