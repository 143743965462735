import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';
import { CardContainer as Card } from '~website/features/me-hub/components/atoms/alignment';

export const CardContainer = styled(Card)`
	grid-row: 1 / span 3;
`;

export const FormWrapper = styled.form`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.small};
`;

export const DiscountListWrap = styled.ul`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};

	li {
		display: flex;
		flex-direction: row;

		& > div:nth-child(2n) {
			margin-left: ${p => p.theme.spacing.large};
		}
	}

	li:not(:last-of-type) {
		padding-bottom: ${p => p.theme.spacing.large};
		border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};
	}
`;

export const ReferAFriendWrap = styled.div`
	@media (min-width: 1024px) {
		grid-row: 1;
		grid-column: 2;
	}
`;

export const ReferAFriendTitleWrap = styled.div`
	text-align: center;
`;

export const ReferAFriendSteps = styled.ol`
	list-style: none;

	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
	padding-inline-start: ${p => p.theme.spacing['4xLarge']};
	margin: ${p => p.theme.spacing.extraLarge} 0;

	counter-reset: my-counter;

	li {
		counter-increment: my-counter;
		position: relative;

		&::before {
			content: counter(my-counter);

			position: absolute;
			top: 0;
			color: ${p => p.theme.ui.textHighlight};
			background: ${p => p.theme.ui.surfaceFill};
			border-radius: 50%;
			border: 2px solid ${p => p.theme.ui.textHighlight};
			text-align: center;

			${p => p.theme.typography['Label.Small']()};

			--size: ${p => p.theme.spacing.extraLarge};
			left: calc(-1 * var(--size) - 16px);
			line-height: 28px;
			width: var(--size);
			height: var(--size);
		}
	}
`;

export const ReferAFriendTypography = styled(Typography)`
	line-height: ${p => p.theme.spacing.extraLarge};
`;
