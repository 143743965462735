import React, { useState } from 'react';
import styled from 'styled-components';

import { SmallCardWrapper } from '../atoms/Card';
import MakeAClaimModal from './MakeAClaimModal';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

const MakeAClaim = () => {
	const [open, setOpen] = useState(false);
	const onOpen = () => setOpen(true);
	const onClose = () => setOpen(false);

	const handsWithPhone = useDesignSystemAsset({ type: 'illustration', value: 'il_hands_with_phone.svg' });

	return (
		<React.Fragment>
			<MakeAClaimWrapper onClick={onOpen}>
				<Flex>
					<div>
						<CardTitleWrapper>
							<Typography $display={'inline'} $type={'Heading.Small'} $bold $color={'textHighlight'}>
								{'Make a claim'}
							</Typography>
							<Icon $size={'24px'} icon={'ic_chevron_right_small'} $color={'primaryFill'} />
						</CardTitleWrapper>
						<Typography
							$marginTop={'small'}
							$marginRight={'large'}
							$type={'Body.Medium'}
							$color={'textOnSurfaceBackground'}
						>
							{'Get in touch as soon as you can if you’ve had an accident.'}
						</Typography>
					</div>
					<IlloWrapper>
						<picture>
							<img src={handsWithPhone} alt={'hands with phone'} />
						</picture>
					</IlloWrapper>
				</Flex>
			</MakeAClaimWrapper>

			<MakeAClaimModal open={open} onClose={onClose} />
		</React.Fragment>
	);
};

export default MakeAClaim;

const MakeAClaimWrapper = styled(SmallCardWrapper)`
	cursor: pointer;
`;

export const CardTitleWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const Flex = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const IlloWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1 0 auto;
	margin: auto;
	max-width: 100%;
	max-height: 100%;

	img {
		width: 80px;
		height: 80px;
		object-fit: cover;
	}
`;
