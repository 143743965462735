import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import { media } from '~lib/frontend/design-system/breakpoints';
import Button from '~lib/frontend/design-system/components/button/Button';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import RegPlateInput from '~lib/frontend/design-system/components/input/molecules/RegPlateInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import useSelectorOrDefault from '~lib/frontend/hooks/use-selector-or-default';
import { findVehicleId, getPublicVehicle } from '~lib/platform/vehicle/store/actions';
import { ApplicationState } from '~website/store';

const VehicleSearch: React.FunctionComponent = () => {
	const dispatch = useDispatch();

	const [flight, setFlight] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [errorValue, setErrorValue] = useState(false);

	const vehicleSearch = useSelectorOrDefault(
		(s: ApplicationState) => s.platform.vehicle.findVehicleId[`vrm:${inputValue}`],
		{ fetching: false }
	);

	const onSearch = () => {
		if (!inputValue) {
			dispatch(push(createAwareUrl('/get-an-estimate?product=stm_quote')));

			return;
		}
		setFlight(true);
		dispatch(findVehicleId.request({ field: 'vrm', value: inputValue }));
	};

	useEffect(() => {
		if (flight) {
			if (vehicleSearch?.error) setErrorValue(true);

			if (vehicleSearch?.response) {
				dispatch(getPublicVehicle.request({ id: inputValue, bypassChecks: false }));
				dispatch(push(createAwareUrl(`/vehicles/${vehicleSearch.response.id}`)));
			}
		}
	}, [dispatch, flight, inputValue, vehicleSearch]);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onSearch();
	};

	return (
		<Fragment>
			<VehicleSearchWrapper onSubmit={onSubmit}>
				<RegPlateInputWrapper
					error={(errorValue || vehicleSearch?.error) && 'Not a valid reg number, double check and try again'}
				>
					<RegPlateInput
						value={inputValue}
						onChange={val => {
							if (flight) setFlight(false);
							if (errorValue) setErrorValue(void 0);
							setInputValue(val.toUpperCase());
						}}
						placeholder={'Search number plate'}
						ariaLabel={'Number plate'}
						background={'white'}
					/>
				</RegPlateInputWrapper>
				<Button $type={'primary'} type={'submit'} $size={'large'} $stretch>
					{'Get a quote'}
				</Button>
			</VehicleSearchWrapper>
			<TermsAndConditions>
				<Typography $type={'Body.Small'} $color={'textMinor'} $display={'inline'}>
					{'By continuing, you accept that you have read our '}

					<Anchor href={'https://www.cuvva.com/support/cuvvas-terms-conditions'} target={'_blank'}>
						<Typography $type={'Body.Small'} $color={'textHighlight'} $display={'inline'}>
							{'terms'}
						</Typography>
					</Anchor>

					{' and '}

					<Anchor href={'https://www.cuvva.com/support/cuvva-privacy-policy'} target={'_blank'}>
						<Typography $type={'Body.Small'} $color={'textHighlight'} $display={'inline'}>
							{'privacy notice'}
						</Typography>
					</Anchor>

					{'.'}
				</Typography>
			</TermsAndConditions>
		</Fragment>
	);
};

const VehicleSearchWrapper = styled.form`
	position: relative;
	display: flex;
	align-items: flex-start;
	gap: ${p => p.theme.spacing.regular};
	flex-direction: column;
	justify-content: center;

	${media.greaterThan('tablet')({
		flexDirection: 'row',
	})};

	button {
		flex-basis: 45%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		margin: 0;
		max-height: 50px;
	}
`;

const RegPlateInputWrapper = styled(InputDecorator)`
	${media.lessThan('tablet')({
		width: '100%',
	})};
`;

const TermsAndConditions = styled.div`
	margin-top: ${p => p.theme.spacing.regular};
	text-align: center;

	a {
		text-decoration: none;
	}
`;

export default VehicleSearch;
