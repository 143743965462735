import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { EmailForm, StyledComplexDialogContent } from '../../atoms/ReferralDialog';
import { OwnersData } from '../../types';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import CheckBoxInput from '~lib/frontend/design-system/components/input/molecules/CheckBoxInput';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import { trackOwnersEvent, trackOwnersScreenView } from '~website/features/analytics/store/actions';
import useCreateAccount from '~website/hooks/use-create-account';
import useQuery from '~website/hooks/use-query';

interface ConfirmEmailProps {
	open: boolean;
	setOpen: (val: boolean) => void;
	ownersData: OwnersData;
	variantScreenName: string;
	variantConsentEvent: string;
	updatePage?: (val: boolean) => void;
	get?: (key: string) => string;
	discount?: boolean;
	dismissible?: boolean;
}

const ConfirmEmailVariant: React.FCWithChildren<ConfirmEmailProps> = props => {
	const {
		open,
		setOpen,
		ownersData,
		variantScreenName,
		variantConsentEvent,
		updatePage,
		get,
		discount,
		dismissible,
	} = props;
	const query = useQuery();
	const dispatch = useDispatch();

	const cuvvaSource = query.get('cuvva_source');
	const [email, setEmail] = useState<string>('');
	const [consent, setConsent] = useState(false);

	const onSuccess = () => {
		setOpen(false);
		updatePage(true);
	};

	const { loading, onClick, error } = useCreateAccount({
		email,
		profile: ownersData?.profile,
		sharedDetails: ownersData?.share,
		onSuccess,
		enableDiscount: discount,
		marketingConsent: consent,
	});

	useEffect(() => {
		if (open) {
			dispatch(
				trackOwnersScreenView({
					screenName: variantScreenName,
					cuvvaSource,
				})
			);
		}
	}, [cuvvaSource, dispatch, open, variantScreenName]);

	const trackCTA = () => {
		dispatch(
			trackOwnersEvent({
				eventName: 'stm_referred_lender_owner_variant_button_action',
				cuvvaSource,
			})
		);
	};

	const createAccount = () => {
		trackCTA();
		onClick();
	};

	const onChangeConsent = () => {
		setConsent(!consent);
		dispatch(
			trackOwnersEvent({
				eventName: variantConsentEvent,
				cuvvaSource,
			})
		);
	};

	const registerEmailEvent = () => {
		dispatch(
			trackOwnersEvent({
				eventName: 'stm_referred_lender_owner_variant_email_entered_action',
				cuvvaSource,
			})
		);
	};

	return (
		<React.Fragment>
			<ComplexDialog.Dialog open={open} dismissible={dismissible} title={''} onClose={() => setOpen(false)}>
				<StyledComplexDialogContent>
					<EmailForm>
						<Typography
							$type={'Heading.Medium'}
							$align={'center'}
							$color={'textOnSurfaceBackground'}
							$marginBottom={'regular'}
						>
							{get('form.title')}
						</Typography>
						<Typography
							$type={'Body.Large'}
							$align={'center'}
							$color={'textOnSurfaceBackgroundMuted'}
							$marginTop={'regular'}
						>
							{get('form.sub_title')}
						</Typography>
						<Margin $marginTop={'large'}>
							<InputDecorator error={Boolean(error.error)}>
								<TextInput
									value={email}
									onChange={e => setEmail(e.target.value)}
									onBlur={registerEmailEvent}
									type={'email'}
									placeholder={'example@cuvva.com'}
									onKeyDown={e => {
										if (e.key === 'Enter') createAccount();
									}}
								/>
								{error.errorMessage}
							</InputDecorator>
						</Margin>
						<Margin>
							<InputDecorator>
								<CheckBoxInput
									name={'marketingConsent'}
									label={get('form.consent.title')}
									checked={consent}
									onChange={onChangeConsent}
								/>
								<Typography $type={'Body.Small'} $color={'textMinor'} $marginLeft={'extraLarge'}>
									{get('form.consent.subtitle')}
								</Typography>
							</InputDecorator>
						</Margin>
					</EmailForm>
				</StyledComplexDialogContent>
				<ComplexDialog.Actions>
					<Button onClick={createAccount} $type={'primary'} $size={'large'} $loading={loading}>
						{get('form.cta')}
					</Button>
				</ComplexDialog.Actions>
			</ComplexDialog.Dialog>
		</React.Fragment>
	);
};

export default ConfirmEmailVariant;
