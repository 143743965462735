import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { setDetails } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherSetDetails() {
    yield takeEvery(LtmActionTypes.SET_DETAILS, workerSetDetails);
}
function* workerSetDetails({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    const { requestId, ...requestPayload } = payload;
    try {
        const response = yield call(api.setDetails, client, requestPayload);
        yield put(setDetails.success(ident, response));
    }
    catch (error) {
        yield put(setDetails.failure(ident, error));
    }
}
