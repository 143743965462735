import { put, select } from 'redux-saga/effects';

import { trackEvent } from '../actions';
import * as actions from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { flows } from '~website/features/quote/flows';
import { InternalQuoteState as QuoteState } from '~website/features/quote/store/types';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(actions.trackPaymentErrorEvent, function* accountsEventSaga({ payload }) {
	const eventName = 'error';
	const qState: QuoteState = yield select((s: ApplicationState) => s.internal.quote);
	const flow = qState.state.flow || qState.state.recentFlow;
	const product = flows?.[flow]?.product;
	const userId: string = yield select((s: ApplicationState) => s.internal.auth.user.response);

	const eventPayload = {
		action: payload.action,
		code: payload.code,
		decline_code: payload.declineCode,
		error: payload.message,
		product: product ? product : 'none',
		platform: 'website',
		user_id: userId,
	};

	yield put(
		trackEvent({
			eventName,
			eventPayload,
		})
	);
});
