import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ksuid from '@cuvva/ksuid';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { sendIdentifierToken } from '~lib/platform/auth/store/actions';
import { trackAccountsScreenView } from '~website/features/analytics/store/actions';

interface EditPrimaryIdentifierProps {
	userId: string;
	type: 'email' | 'mobile_phone';
	initialValue: string;
	onProceed: (value: string) => void;
	onCancel: () => void;
}

const generateRequestId = () => ksuid.generate('request').toString();

const IdentifierModalInput: React.FCWithChildren<EditPrimaryIdentifierProps> = props => {
	const { userId, type, initialValue, onProceed, onCancel } = props;
	const dispatch = useDispatch();
	const [value, setValue] = useState('');
	const [requestId, setRequestId] = useState(generateRequestId);

	const sendIdentifierTokenStore = useSelector(s => s.platform.auth.sendIdentifierToken[requestId]);
	const sendIdentifierTokenStatus = useAsyncStateStatus(sendIdentifierTokenStore).status;

	const errorCodes = useSelector(s => s.platform.codeMapping.errorCodes);
	const error = errorCodes?.response?.find(e => e.code === sendIdentifierTokenStore?.error?.code);

	useEffect(() => {
		dispatch(
			trackAccountsScreenView({
				screenName: `profile_edit${type === 'email' ? 'EmailAddress' : 'PhoneNumber'}_viewed`,
			})
		);
	}, [dispatch, type]);

	// Sync initial value
	useEffect(() => setValue(initialValue), [initialValue]);

	// Redirect to the next page on success
	useEffect(() => {
		if (sendIdentifierTokenStatus === 'response') onProceed(value);
	}, [value, onProceed, sendIdentifierTokenStatus]);

	const onSendToken = () => {
		const requestId = generateRequestId();

		setRequestId(requestId);
		dispatch(
			sendIdentifierToken.request({
				requestId,
				clientId: void 0,
				type,
				value,
				userId,
			})
		);
	};

	return (
		<React.Fragment>
			<ComplexDialog.Content>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{type === 'email' &&
						'We’ll send your policy documents to this email address, and you can use it to sign in.'}
					{type === 'mobile_phone' &&
						'We’ll use this number in an emergency, or if we need to contact you for some other reason.'}
				</Typography>

				<Margin $marginTop={'extraLarge'}>
					<InputDecorator
						label={type === 'email' ? 'Email address' : 'Mobile phone number'}
						error={
							error?.message ??
							(sendIdentifierTokenStore?.error ? 'Please use a valid identifier' : void 0)
						}
					>
						<TextInput
							type={type === 'email' ? 'email' : 'tel'}
							autoComplete={type === 'email' ? 'email' : 'tel'}
							onKeyDown={e => {
								if (Boolean(value) && e.key === 'Enter') onSendToken();
							}}
							value={value ?? ''}
							onChange={e => setValue(e.target.value)}
						/>
					</InputDecorator>
				</Margin>
			</ComplexDialog.Content>

			<ComplexDialog.Actions>
				<Button $type={'neutralMuted'} onClick={onCancel} disabled={sendIdentifierTokenStatus === 'fetching'}>
					{'Cancel'}
				</Button>
				<Button $type={'primary'} onClick={onSendToken} disabled={sendIdentifierTokenStatus === 'fetching'}>
					{'Save'}
				</Button>
			</ComplexDialog.Actions>
		</React.Fragment>
	);
};

export default IdentifierModalInput;
