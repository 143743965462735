import Cher from '@cuvva/cher';
import { fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { createAsyncActionTypes } from '../actions/types';
export function* requestAndTake(api) {
    yield put(api);
    const { failure, success } = createAsyncActionTypes(api.type);
    const response = yield take([failure, success]);
    if (response.type === failure)
        throw Cher.coerce(response.payload);
    return response.payload;
}
export function* requestAndTakeWithMeta(ident, api) {
    yield put(api);
    const { failure, success } = createAsyncActionTypes(api.type);
    while (true) {
        const resp = yield take([failure, success]);
        if (resp.meta.ident !== ident)
            continue;
        if (resp.type === failure)
            throw Cher.coerce(resp.payload);
        return resp.payload;
    }
}
export function createTakeEverySagaSet(actionCreator, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
worker) {
    return fork(function* watcher() {
        yield takeEvery(actionCreator.type, worker);
    });
}
export function createTakeLatestSagaSet(actionCreator, 
// eslint-disable-next-line @typescript-eslint/no-explicit-any
worker) {
    return fork(function* watcher() {
        yield takeLatest(actionCreator.type, worker);
    });
}
