import React from 'react';

import CardOnVibrantBackground from './atoms/CardOnVibrantBackground';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';

const VibrantScreenCard: React.FCWithChildren<{ maxWidth?: number }> = ({ children, maxWidth }) => (
	<Margin $marginTop={'extraLarge'} $marginBottom={'large'}>
		<CardOnVibrantBackground $maxWidth={maxWidth}>{children}</CardOnVibrantBackground>
	</Margin>
);

export default VibrantScreenCard;
