import { put, select } from 'redux-saga/effects';

import { trackEvent } from '../actions';
import * as actions from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { flows } from '~website/features/quote/flows';
import { InternalQuoteState as QuoteState } from '~website/features/quote/store/types';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(actions.trackQuoteEvent, function* quoteEvent({ payload }) {
	const qState: QuoteState = yield select((s: ApplicationState) => s.internal.quote);
	const flow = qState.state.flow || qState.state.recentFlow;
	const product = flows?.[flow]?.product;

	const { action, value } = payload;

	const properties = payload.properties || {};

	const eventValue = {
		...properties,
		value,
		product,
		action,
	};

	const eventPayload = { ...properties, product, platform: 'website' };

	yield put(
		trackEvent({
			eventCategory: `Web quote ${product}`,
			eventName: action,
			eventValue,
			eventAction: action,
			eventPayload,
		})
	);
});
