import React from 'react';
import { createRoot } from 'react-dom/client';
import CuvvaClient, { LocalStore } from '@cuvva/cuvva-client';
import { createBrowserHistory } from 'history';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { WindowWithBTRState } from './augmentations';
import configureStore from './configure-store';
import { APP_PREFIX } from './constants/storage';
import environmentSetup from './environment';
import { sourceTracking } from './features/analytics/store/actions';
import Main from './Main';
import { contentSegments } from './prerender/content';
import fetchContent from './prerender/fetchContent';
import { importRegisterUser } from './store/auth/actions';
import { COMMON_PREFIX } from '~lib/frontend/constants/storage';
import { LocalStorage } from '~lib/shared/helpers/local-storage';
import { setupLogger } from '~lib/shared/logger';

setupLogger('website');

const { baseUrl, clientId, redirectUri, hostEnvironment } = environmentSetup();
const history = createBrowserHistory();
const container = document.getElementById('cuvva-app-injection-site');

const client = new CuvvaClient({
	baseUrl,
	clientId,
	authStorage: new LocalStore(`${COMMON_PREFIX}.auth`),
	localStorage: new LocalStorage(`${APP_PREFIX}.auth`),
	redirectUri,
});

// Check if this is a build-time-rendered page
const isBTRPage = Boolean((window as unknown as WindowWithBTRState).__BTR_STATE__);

// If we're running locally or on a BTR page, fetch content then render
if (hostEnvironment === 'local' || isBTRPage) {
	(async () => {
		try {
			const content = await fetchContent(client, contentSegments);
			const store = configureStore(history, client, content);

			store.dispatch(importRegisterUser.request());
			store.dispatch(sourceTracking());

			const root = createRoot(container!);

			root.render(<Main history={history} store={store} />);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn('Content segments failed to load:', error);
			// Don't create root - keep pre-rendered HTML
		}
	})();
} else {
	// For regular pages, hydrate immediately
	const store = configureStore(history, client);

	store.dispatch(importRegisterUser.request());
	store.dispatch(sourceTracking());

	const root = createRoot(container!);

	root.render(<Main history={history} store={store} />);
}
