import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import type { FlattenPolicySubs } from './use-subscriptions';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import {
	getSubscriptionByPolicy,
	listPoliciesBySubscription,
	listSubscriptions,
} from '~lib/platform/ltm/store/actions';
import useUser from '~website/hooks/use-user';

const useSingleSubscription = (policyId: string) => {
	const dispatch = useDispatch();
	const { userId } = useUser();

	const subscriptionStore = useSelector(s => s.platform.ltm.listSubscriptions?.[userId]);
	const subscriptions = subscriptionStore?.response;

	const subsByPolicyStore = useSelector(s => s.platform.ltm.getSubscriptionByPolicy?.[policyId]);
	const subDetail = subsByPolicyStore?.response;

	const subscription = useMemo(
		() => subscriptions?.find(s => s?.subscriptionId === subDetail?.subscriptionId),
		[subscriptions, subDetail]
	);

	const policiesStore = useSelector(s => s.platform.ltm.listPoliciesBySubscription?.[subscription?.subscriptionId]);
	const policies = policiesStore?.response;

	const policy = useMemo(() => policies?.find(s => s?.policyId === policyId), [policies, policyId]);

	const subsStatus = useAsyncStateStatus(subscriptionStore).status;
	const subDetailStatus = useAsyncStateStatus(subsByPolicyStore).status;
	const policiesStatus = useAsyncStateStatus(policiesStore).status;

	const loading = ![subsStatus, subDetailStatus, policiesStatus].every(s => s === 'response');

	useEffect(() => {
		if (userId) dispatch(listSubscriptions.request({ userId }));
	}, [dispatch, userId]);

	useEffect(() => {
		if (userId && policyId) dispatch(getSubscriptionByPolicy.request({ userId, policyId }));
	}, [dispatch, userId, policyId]);

	useEffect(() => {
		if (userId && subscription) {
			dispatch(
				listPoliciesBySubscription.request({
					userId,
					subscriptionId: subscription.subscriptionId,
				})
			);
		}
	}, [subscription, dispatch, userId]);

	const policyDetails: FlattenPolicySubs = useMemo(
		() => ({ ...policy, subInfo: subscription }),
		[policy, subscription]
	);

	const refetch = useCallback(() => {
		dispatch(listSubscriptions.request({ userId }));
		dispatch(getSubscriptionByPolicy.request({ userId, policyId }));
		dispatch(
			listPoliciesBySubscription.request({
				userId,
				subscriptionId: subscription?.subscriptionId,
			})
		);
	}, [dispatch, policyId, subscription?.subscriptionId, userId]);

	return {
		policy,
		subscription,
		policyDetails,
		loading,
		refetch,
	};
};

export default useSingleSubscription;
