import Cher from '@cuvva/cher';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { listPromos, updatePromo } from '../actions';
import * as api from '../api';
import { PromoActionTypes } from '../types';
export default function* watcherUpdatePromo() {
    yield takeEvery(PromoActionTypes.UPDATE_PROMO, workerUpdatePromo);
}
function* workerUpdatePromo({ payload, meta }) {
    const { ident } = meta;
    const { listRequestId, requestId, pageNumber, ...requestBody } = payload;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    try {
        yield call(api.updatePromo, client, requestBody);
        yield put(updatePromo.success(ident));
        yield put(flashMessages.addSuccess('The promotion has successfully been updated 💸'));
        yield put(listPromos.request({ requestId: listRequestId, pageNumber, pageSize: 20 }));
    }
    catch (error) {
        yield put(flashMessages.addError('Unable to update promo', 'There was an unknown issue when updating the promotion. The error is below:', Cher.coerce(error)));
        yield put(updatePromo.failure(ident, error));
    }
}
