import React, { FunctionComponent } from 'react';

const Calendar: FunctionComponent = () => (
	<svg xmlns={'http://www.w3.org/2000/svg'} width={'36'} height={'34'} fill={'none'} viewBox={'0 0 36 34'}>
		<path
			fill={'#5B5B95'}
			d={
				'M21.423 15.166h-1.018c-.615 0-.825-.176-.825-.79V13.34c0-.614.21-.807.825-.807h1.018c.632 0 .825.193.825.807v1.036c0 .614-.193.79-.825.79zM27.268 15.166h-1.036c-.614 0-.825-.176-.825-.79V13.34c0-.614.21-.807.825-.807h1.036c.614 0 .825.193.825.807v1.036c0 .614-.21.79-.825.79zM9.768 20.905H8.732c-.614 0-.825-.175-.825-.79V19.08c0-.614.21-.79.825-.79h1.036c.614 0 .825.176.825.79v1.036c0 .614-.21.79-.825.79zM15.595 20.905H14.56c-.615 0-.808-.175-.808-.79V19.08c0-.614.193-.79.808-.79h1.035c.615 0 .825.176.825.79v1.036c0 .614-.21.79-.825.79zM21.423 20.905h-1.018c-.615 0-.825-.175-.825-.79V19.08c0-.614.21-.79.825-.79h1.018c.632 0 .825.176.825.79v1.036c0 .614-.193.79-.825.79zM27.268 20.905h-1.036c-.614 0-.825-.175-.825-.79V19.08c0-.614.21-.79.825-.79h1.036c.614 0 .825.176.825.79v1.036c0 .614-.21.79-.825.79zM9.768 26.663H8.732c-.614 0-.825-.193-.825-.808V24.82c0-.615.21-.79.825-.79h1.036c.614 0 .825.175.825.79v1.035c0 .615-.21.808-.825.808zM15.595 26.663H14.56c-.615 0-.808-.193-.808-.808V24.82c0-.615.193-.79.808-.79h1.035c.615 0 .825.175.825.79v1.035c0 .615-.21.808-.825.808zM21.423 26.663h-1.018c-.615 0-.825-.193-.825-.808V24.82c0-.615.21-.79.825-.79h1.018c.632 0 .825.175.825.79v1.035c0 .615-.193.808-.825.808zM13.625 11.75a.875.875 0 00-.875.875v2.625c0 .483.392.875.875.875h2.625a.875.875 0 00.875-.875v-2.625a.875.875 0 00-.875-.875h-2.625z'
			}
		></path>
		<path
			fill={'#5B5B95'}
			fillRule={'evenodd'}
			d={
				'M29.988 33.157H6.012C2.36 33.157.5 31.35.5 27.716V6.284C.5 2.651 2.36.843 6.012.843h23.976c3.669 0 5.512 1.825 5.512 5.441v21.432c0 3.616-1.843 5.441-5.512 5.441zM3.326 27.874c0 1.632.878 2.457 2.44 2.457h24.45c1.563 0 2.458-.825 2.458-2.457V11.322c0-1.615-.895-2.458-2.457-2.458H5.766c-1.562 0-2.44.843-2.44 2.458v16.552z'
			}
			clipRule={'evenodd'}
		></path>
	</svg>
);

export default Calendar;
