import Cher from '@cuvva/cher';
import { camelCase } from 'change-case';
import cloneDeep from 'lodash/cloneDeep';
import { all, put, select } from 'redux-saga/effects';

import { runLtmEstimate, runStmQuote } from '..';
import { pageActions, quoteActions, stepActions } from '../actions';
import { InternalQuoteState } from '../types';
import { attachIdentifier } from '~lib/platform/auth/store/actions';
import { User } from '~lib/platform/auth/types';
import { confirmChangeRequest, createChangeRequest } from '~lib/platform/change-request/store/actions';
import {
	ConfirmChangeRequestRequestPayload,
	CreateChangeRequestRequestPayload,
} from '~lib/platform/change-request/store/types';
import { CreateChangeRequestResponse } from '~lib/platform/change-request/types';
import { declareLicense, updateLicense } from '~lib/platform/driving-license-registration/store/actions';
import { clearIncidents, createIncident, listLatestIncidents } from '~lib/platform/incident/store/actions';
import { marketingConsentActions as marketingConsent } from '~lib/platform/marketing-consent/store/actions';
import { Profile } from '~lib/platform/profile/types';
import { userVehicleProfile as userVehicleProfileActions } from '~lib/platform/vehicle-profile/store/actions';
import { UserDeclared, VehicleProfile } from '~lib/platform/vehicle-profile/types';
import { TypedObject } from '~lib/shared/helpers/typed';
import { createTakeEverySagaSet, requestAndTake } from '~lib/shared/redux/sagas';
import { AsyncMapState } from '~lib/shared/redux/types/state';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(pageActions.submitPage.request, function* submitPageSaga(action) {
	const { pageId, submitEstimate } = action.payload;

	const state: InternalQuoteState = yield select((s: ApplicationState) => s.internal.quote);

	try {
		const page = state.pages[pageId];
		const { internalChangeRequestId, fields } = page;

		const fieldsList = TypedObject.keys(fields || {});

		// load fields list and check which one aren't completed
		for (const field of fieldsList) {
			if (page.fields[field]?.error) throw new Cher('missing_fields');
		}

		const returns: unknown[] = yield all([
			handleAuth(pageId, internalChangeRequestId),
			handleChangeRequest(pageId, internalChangeRequestId),
			handleMarketingConsent(pageId, internalChangeRequestId),
			handleIncidents(pageId, internalChangeRequestId),
		]);

		const error = returns.find(Boolean);

		if (error) throw error;

		const dln: Cher | void = yield handleDLR(pageId, internalChangeRequestId);

		if (dln) throw dln;

		if (state.state.product === 'ltm') yield requestAndTake(runLtmEstimate.actions.request());
		else if (state.state.product === 'stm' && submitEstimate) yield put(runStmQuote.actions.request());

		yield put(pageActions.submitPage.success(pageId, null));
	} catch (error) {
		// unknown: every api call must handle its own errors
		yield put(pageActions.submitPage.failure(pageId, Cher.coerce(error)));
	}

	// re-fetch user and all related data
	yield put(quoteActions.fetchUser.request());
});

function* handleAuth(pageId: string, internalChangeRequestId: string) {
	try {
		const state: InternalQuoteState = yield select((s: ApplicationState) => s.internal.quote);
		const userId: string = yield select((state: ApplicationState) => state.internal.auth.user.response);
		const userById: User = yield select(
			(state: ApplicationState) => state.platform.auth.userById[`${userId}-false`]?.response
		);

		if (!userById) return new Cher('unknown'); // shall never happen

		const newValue = state.internalChangeRequest?.[internalChangeRequestId]?.auth?.[userId];
		const alreadyExists = userById.identifiers.find(i => i.type === 'email' && i.value === newValue);

		if (alreadyExists || !newValue) return void 0;

		yield requestAndTake(attachIdentifier.request({ userId, type: 'email', value: newValue, token: null }));
	} catch (error) {
		const cher = Cher.coerce(error);

		if (cher.code === 'email_already_exists')
			//  | invalid_email
			yield put(
				stepActions.setFieldError({ pageId, field: 'emailAddress', error: new Cher('email_already_exists') })
			);
		else yield put(stepActions.setFieldError({ pageId, field: 'emailAddress', error: new Cher('invalid') }));

		return error;
	}

	return void 0;
}

function* handleChangeRequest(pageId: string, internalChangeRequestId: string) {
	const state: InternalQuoteState = yield select((s: ApplicationState) => s.internal.quote);
	const userId: string = yield select((s: ApplicationState) => s.internal.auth.user.response);
	const userVehicleProfile: AsyncMapState<VehicleProfile> = yield select(
		(s: ApplicationState) => s.platform.vehicleProfile.userVehicleProfile
	);

	try {
		const profileChanges = state.internalChangeRequest?.[internalChangeRequestId]?.profile?.[userId];
		const vehicleProfilesChanges = state.internalChangeRequest?.[internalChangeRequestId]?.vehicleProfile;

		if ((!profileChanges || TypedObject.keys(profileChanges).length < 1) && !vehicleProfilesChanges) return void 0;

		let transformVehicleProfiles: Record<string, UserDeclared> | null = null;

		if (vehicleProfilesChanges) {
			transformVehicleProfiles = Object.keys(vehicleProfilesChanges).reduce(
				(acc, curr) => ({
					...acc,
					[curr]: {
						owner: {
							type: vehicleProfilesChanges[curr]?.owner?.type,
							durationMonths: vehicleProfilesChanges[curr]?.owner?.durationMonths,
						},
						storage: {
							type: vehicleProfilesChanges[curr]?.storage?.type,
							location: vehicleProfilesChanges[curr]?.storage?.location,
						},
						annualMileage: vehicleProfilesChanges[curr]?.annualMileage,
						registeredKeeperCode: vehicleProfilesChanges[curr]?.registeredKeeperCode,
						estimatedValue: vehicleProfilesChanges[curr]?.estimatedValue,
					} as UserDeclared,
				}),
				{}
			);

			// Clean up data or enhance it as to not fail validation
			// `Storage` and `Owner` are treated like an object, therefore the validation will fail if
			// they miss one of the two fields.
			// The only way to work around this is:
			// -> if we have even one value change within the object we must resend it all.
			// -> If we have undefined (no change) we can omit the whole object.

			// Special case is Owner as `durationMonths` shouldn't be required if type !== 'policyholder'
			// but backend create_change_request isn't very clever about it so we need to send 0
			for (const vehicleId of TypedObject.keys(transformVehicleProfiles)) {
				const userDeclared = userVehicleProfile[`${userId}@${vehicleId}`]?.response?.userDeclared;
				const owner = transformVehicleProfiles[vehicleId].owner;
				const storage = transformVehicleProfiles[vehicleId].storage;

				if (Object.values(owner).every(val => val === void 0)) {
					transformVehicleProfiles[vehicleId].owner = void 0;
				} else {
					// Restore previous values as the object must be sent as whole
					const durationMonths = owner.durationMonths;
					const type = owner.type;

					owner.durationMonths = durationMonths ?? userDeclared?.owner?.durationMonths;
					owner.type = type ?? userDeclared?.owner?.type;

					// eslint-disable-next-line max-depth
					if (type !== 'policyholder' && owner.durationMonths === void 0) owner.durationMonths = 0;
				}

				if (Object.values(storage).every(val => val === void 0)) {
					transformVehicleProfiles[vehicleId].storage = void 0;
				} else {
					// Restore previous values as the object must be sent as whole
					const type = storage.type;
					const location = storage.location;

					storage.type = type ?? userDeclared?.storage?.type;
					storage.location = location ?? userDeclared?.storage?.location;
				}
			}
		}

		const createRequestPayload: CreateChangeRequestRequestPayload = {
			createdBy: userId,
			userId,
			requestId: userId, // TODO(sc): change to use a real request id (passed in to this func?)
			profile: formatProfile(profileChanges) ?? null,
			products: null,
			vehicles: null,
			vehicleProfiles: transformVehicleProfiles ?? null,
		};

		const { changeRequestId, products }: CreateChangeRequestResponse = yield requestAndTake(
			createChangeRequest.request(createRequestPayload)
		);

		const productTypes = TypedObject.keys(products);

		for (const prod of productTypes) {
			if (products[prod].status !== 'no_action_required') throw new Cher('unknown');
		}

		const confirmRequestPayload: ConfirmChangeRequestRequestPayload = {
			requestId: userId,
			changeRequestId,
			confirmedBy: userId,

			products: {
				longterm: {
					payments: null,
					documents: null,
				},
			},
		};

		yield requestAndTake(confirmChangeRequest.request(confirmRequestPayload));

		if (vehicleProfilesChanges) {
			const vehicleIds = Object.keys(vehicleProfilesChanges);

			for (const vehicleId of vehicleIds) {
				yield put(
					userVehicleProfileActions.request({
						revisionId: null,
						vehicleId,
						userId,
					})
				);
			}
		}
	} catch (error) {
		yield handleChangeRequestError(pageId, Cher.coerce(error));

		return error;
	}

	return void 0;
}

function* handleMarketingConsent(pageId: string, internalChangeRequestId: string) {
	const state: InternalQuoteState = yield select((s: ApplicationState) => s.internal.quote);
	const userId: string = yield select((s: ApplicationState) => s.internal.auth.user.response);

	try {
		const consent =
			state.internalChangeRequest?.[internalChangeRequestId]?.marketingConsent?.[userId] ?? 'relevant';

		yield put(
			marketingConsent.setUserStatus.request({
				requestId: userId, // we don't really care about grabbing the status from here
				userId,
				emailSetting: consent,
			})
		);
	} catch (error) {
		stepActions.setFieldError({ pageId, field: 'marketingConsent', error: Cher.coerce(error) });

		return error;
	}

	return void 0;
}

function* handleDLR(pageId: string, internalChangeRequestId: string) {
	// we need to make sure to have our store correctly up to date
	const state: InternalQuoteState = yield select((s: ApplicationState) => s.internal.quote);
	const userId: string = yield select((state: ApplicationState) => state.internal.auth.user.response);

	const cr = state.internalChangeRequest[internalChangeRequestId];
	const dlrCr = cr?.drivingLicenseRegistration?.[userId];

	const dln = dlrCr?.dln;
	const postcode = dlrCr?.postcode;

	if (!dln && !postcode) return void 0;

	// We have to declare first the DLN then update with the postcode

	if (dln) {
		try {
			yield requestAndTake(declareLicense.request({ userId, dln, requestId: userId }));
		} catch (e) {
			const error = Cher.coerce(e);

			yield put(stepActions.setFieldError({ pageId, field: 'dln', error }));

			return error;
		}
	}

	if (postcode) {
		try {
			yield requestAndTake(updateLicense.request({ userId, postcode, requestId: userId }));
		} catch (e) {
			const error = new Cher('invalid');

			yield put(stepActions.setFieldError({ pageId, field: 'postcode', error }));

			return error;
		}
	}

	return void 0;
}

function* handleIncidents(pageId: string, internalChangeRequestId: string) {
	const state: InternalQuoteState = yield select((s: ApplicationState) => s.internal.quote);
	const userId: string = yield select((state: ApplicationState) => state.internal.auth.user.response);
	const incidentsCr = state.internalChangeRequest[internalChangeRequestId]?.incidents;

	const beenInvolved = incidentsCr?.beenInvolved;
	const incidentsList = incidentsCr?.incidents;

	if (typeof beenInvolved !== 'boolean') return void 0;

	try {
		if (beenInvolved === false) yield requestAndTake(clearIncidents.request({ userId, requestId: userId }));

		if (beenInvolved === true) {
			const processedIncidents: typeof incidentsList = incidentsList.map(i => ({
				...i,
				date: formatDate(i.date),
				// @see https://cuvva.slack.com/archives/CV8K1KMMW/p1660749007775409?thread_ts=1660569775.276739&cid=CV8K1KMMW
				// "the backend will still need an entry in the payload for it. Makes sense to send in £0."
				cost: 0,

				atFault: i.category === 'accident' ? i.atFault : void 0,
				bodilyInjury: i.category === 'accident' ? i.bodilyInjury : void 0,
				theftOf: i.category === 'theft' ? i.theftOf : void 0,
			}));

			yield requestAndTake(
				createIncident.request({
					requestId: userId,
					userId,
					incidents: processedIncidents,
				})
			);
		}

		yield put(listLatestIncidents.request({ userId }));
	} catch (error) {
		const cher = Cher.coerce(error);

		yield put(stepActions.setFieldError({ pageId, field: 'incidents', error: cher }));

		return cher;
	}

	return void 0;
}

function* handleChangeRequestError(pageId: string, error: Cher) {
	// we only handle bad_request for now
	if (error?.code !== 'bad_request') return;

	if (error?.reasons?.length < 1) return;

	const state: InternalQuoteState = yield select((s: ApplicationState) => s.internal.quote);
	const page = state.pages[pageId];

	for (const r of error.reasons) {
		if (r.code !== 'schema_failure') continue;

		const toCamelCaseField = camelCase(r.meta!.field as string);

		if (page.fields[toCamelCaseField]) {
			yield put(stepActions.setFieldError({ pageId, field: toCamelCaseField, error: new Cher('invalid') }));
		} else {
			// .. what do we do here?
			// this is meant for special cases where we can't automatically map the error to the field
			// ..
			// switch (r.meta.field) {
			// 	case 'personal_name':
			// 		yield put(stepActions.setFieldError({ pageId, field: 'personalName', error: new Cher('invalid') }));
			// 		break;
			// 	default:
			// 		continue;
			// }
		}
	}
}

function formatDate(date: string) {
	const dateParts = date.split('-');

	return dateParts.map(d => d.padStart(2, '0')).join('-');
}

function formatProfile(profile: Partial<Profile>) {
	if (!profile) return profile;

	const clonedProfile = cloneDeep(profile);

	if (profile.birthDate) clonedProfile.birthDate = formatDate(clonedProfile.birthDate);

	if (clonedProfile.residentialAddress) {
		clonedProfile.residentialAddress.verified = void 0;
		clonedProfile.residentialAddress.region = void 0;

		const lines = clonedProfile.residentialAddress.lines;

		clonedProfile.residentialAddress.lines = lines.filter(Boolean);
	}

	return clonedProfile;
}
