import type { IconType } from '~lib/frontend/design-system/assets/SvgIcon';

const verificationList = [
	'auth.email',
	'auth.mobile_phone',
	'profile.kyc_photo',
	'profile.license_photo',
	'profile.residential_address_proof',
	'vehicle.photo',
	'ncb.photo',
];

export type VerificationItem = {
	type: (typeof verificationList)[number];
	icon: IconType;
	label: string;
	path: string;
};

const matchVerificationList: VerificationItem[] = [
	{
		type: 'auth.email',
		icon: 'ic_email',
		label: 'Verify your email',
		path: 'email',
	},
	{
		type: 'auth.mobile_phone',
		icon: 'ic_phone',
		label: 'Verify your mobile number',
		path: 'mobile_phone',
	},
	{
		type: 'profile.kyc_photo',
		icon: 'ic_account',
		label: 'Take a selfie',
		path: 'kyc_photo',
	},
	{
		type: 'profile.license_photo',
		icon: 'ic_card_licence',
		label: 'Scan your UK driving licence',
		path: 'license_photo',
	},
	{
		type: 'profile.residential_address_proof',
		icon: 'ic_home',
		label: 'Show proof of address',
		path: 'residential_photo',
	},
	{
		type: 'vehicle.photo',
		icon: 'ic_car',
		label: 'Take a photo of your car',
		path: 'vehicle_photo',
	},
	{
		type: 'ncb.photo',
		icon: 'ic_document',
		label: 'Show proof of no-claims bonus',
		path: 'ncb_photo',
	},
];

export const getPolicyVerificationList = (policyItem: string[]) =>
	matchVerificationList.filter(item => policyItem?.includes(item.type));
