import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { SmallCardWrapper } from '../atoms/Card';
import useSingleSubscription from '../hooks/use-single-subscription';
import CancelActiveSubscription from './CancelActiveSubscription';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import CancelUpcomingSubscription from './CancelUpcomingSubscription';
import { CardTitleWrapper, Flex, IlloWrapper } from './MakeAClaim';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useConfigFlag from '~lib/frontend/hooks/use-config-flag';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { Subscription } from '~lib/platform/ltm/types';
import { getConfig } from '~lib/platform/ltm-cancellation/store/actions';
import { trackAccountsScreenView } from '~website/features/analytics/store/actions';
import useUser from '~website/hooks/use-user';

const CancelSubscription = () => {
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const onOpen = () => setOpen(true);
	const onClose = () => setOpen(false);

	const pigeonDrivingWaving = useDesignSystemAsset({ type: 'illustration', value: 'il_pigeon_driving_waving.svg' });
	const cancelSubscription = useConfigFlag('web_subscription_cancellation');

	const { policyId } = useParams<{ policyId: string }>();
	const { subscription } = useSingleSubscription(policyId);
	const { userId } = useUser();

	useEffect(() => {
		const build = typeof window === 'undefined' ? 'unknown' : window.cuvva.deployment.gitCommit;

		dispatch(
			getConfig.request({
				userId,
				client: {
					platform: 'web',
					version: '1.0.0',
					build,
				},
			})
		);
	}, [dispatch, userId]);

	useEffect(() => {
		if (open) {
			dispatch(
				trackAccountsScreenView({
					screenName: 'policy_cancelSubscription_viewed',
				})
			);
		}
	}, [dispatch, open]);

	return (
		<Fragment>
			<CancelASubWrapper onClick={onOpen}>
				<Flex>
					<div>
						<CardTitleWrapper>
							<Typography $display={'inline'} $type={'Heading.Small'} $bold $color={'textHighlight'}>
								{'Cancel subscription'}
							</Typography>
							<Icon $size={'24px'} icon={'ic_chevron_right_small'} $color={'primaryFill'} />
						</CardTitleWrapper>
						<Typography
							$marginTop={'small'}
							$marginRight={'large'}
							$type={'Body.Medium'}
							$color={'textOnSurfaceBackground'}
						>
							{'No hard feelings — and never any cancellation fees.'}
						</Typography>
					</div>
					<IlloWrapper>
						<picture>
							<img src={pigeonDrivingWaving} alt={'pigeon driving waving'} />
						</picture>
					</IlloWrapper>
				</Flex>
			</CancelASubWrapper>

			{!cancelSubscription && <CancelSubscriptionModal open={open} onClose={onClose} />}
			{cancelSubscription && isUpcomingSubscription(subscription) && (
				<CancelUpcomingSubscription open={open} onClose={onClose} />
			)}
			{cancelSubscription && !isUpcomingSubscription(subscription) && (
				<CancelActiveSubscription open={open} onClose={onClose} />
			)}
		</Fragment>
	);
};

export default CancelSubscription;

const CancelASubWrapper = styled(SmallCardWrapper)`
	cursor: pointer;
`;

const isUpcomingSubscription = (subscription: Subscription) => {
	const now = new Date();
	const subscriptionStartDate = new Date(subscription.startDate);

	return subscriptionStartDate > now;
};
