import React from 'react';
import Confetti from 'react-confetti';
import styled from 'styled-components';

const Wrapper = styled.div`
	canvas {
		position: fixed !important;
		width: 100vw;
		height: 100vh;
		z-index: -1 !important;
	}
`;

const ConfettiMain: React.FunctionComponent = () => (
	<Wrapper>
		{
			// @ts-ignore
			<Confetti colors={['#6666FF', '#8888FF', '#33CC99', '#B7EDDB', '#FFD23B']} />
		}
	</Wrapper>
);

export default ConfettiMain;
