import styled from 'styled-components';

import Anchor from '~lib/frontend/atoms/Anchor';

interface DesktopMenuProps {
	$open: boolean;
	$visionary: boolean;
}

export const DesktopMenu = styled.div<DesktopMenuProps>`
	white-space: normal;
	position: absolute;
	top: 40px;
	left: -105px; /* this needs to be fixed but there's no time now :( */
	right: 0;
	z-index: 2;
	width: 360px;
	border-radius: ${p => p.theme.borderRadius.large};
	background: ${p => (p.$visionary ? p.theme.ui.surfaceDark : p.theme.ui.surfaceFill)};

	${p => (p.$open ? '' : 'pointer-events: none')};
	opacity: ${p => (p.$open ? '1' : '0')};
	will-change: transform, opacity;
	box-shadow: ${p => p.theme.boxShadow.medium};
	transition: all 0.1s ease-in-out;

	transform: ${p => (p.$open ? 'rotateX(0deg)' : 'rotateX(-30deg)')};
	transform-origin: 0px 0px;
	display: none;

	flex-direction: column;
	padding: ${p => p.theme.spacing.extraSmall};

	@media (min-width: 768px) {
		display: flex;
	}
`;

export const DesktopMenuItem = styled(Anchor)<{ $visionary: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	cursor: pointer;

	padding: ${p => p.theme.spacing.small};
	transition: all 0.1s ease-in-out;
	border: 1px solid transparent;

	/* SVG */
	& > :first-child {
		flex: 0 0 24px;
		width: 24px;
		margin-right: ${p => p.theme.spacing.small};
	}

	& > :nth-child(2) {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	&,
	&:visited,
	&:hover {
		text-decoration: none;
	}

	&:hover {
		border-radius: ${p => p.theme.borderRadius.regular};
		background: ${p => (p.$visionary ? p.theme.ui.blankFillMuted : p.theme.ui.primaryFillMuted)};
	}
`;
