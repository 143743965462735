import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Guides } from '../Containers';
import Content from '../content';
import DownloadInfo from '../molecules/DownloadInfo';
import { Anchor } from '~lib/frontend/atoms';
import { media } from '~lib/frontend/design-system/breakpoints';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import RegPlateInput from '~lib/frontend/design-system/components/input/molecules/RegPlateInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import VehicleManufacturer from '~lib/frontend/design-system/components/VehicleManufacturer';
import { GetterType } from '~lib/frontend/types/content';
import { findVehicleId, getPublicVehicle } from '~lib/platform/vehicle/store/actions';
import PageList from '~website/components/atoms/PageList';
import { trackEvent, trackScreenView } from '~website/features/analytics/store/actions';
import ToolLink from '~website/features/tools/molecules/ToolLink';
import useQuery from '~website/hooks/use-query';
import { useGuideContent, useWebsiteCoreContent } from '~website/hooks/use-segment';
import { ApplicationState } from '~website/store';

interface GroupCheckerProps {
	get: GetterType;
}

const customErrorMessage = 'Not a valid reg plate, double check and try again';
const insuranceGroupGuides = ['insurance-groups-explained', 'older-vs-newer-cars', 'why-car-insurance-is-so-expensive'];

const GroupChecker: React.FCWithChildren<GroupCheckerProps> = ({ get }) => {
	const segment = useWebsiteCoreContent();
	const content = segment.content.pages?.['insurance-group-checker'];
	const guideSegment = useGuideContent();
	const { global } = guideSegment?.content || {};
	const topics = global ? Object.keys(global.guides) : [];
	const allGuides = topics.reduce((acc, curr) => [...acc, ...global.guides[curr]], []);
	const displayedGuides = allGuides.filter(({ url }) => insuranceGroupGuides.includes(url));
	const resultGuide = displayedGuides.find(({ url }) => url === 'insurance-groups-explained');

	const query = useQuery();
	const cuvvaSource = query.get('cuvva_source');

	const [input, setInput] = useState('');
	const [vehicleId, setVehicleId] = useState('');
	const vehicle = useSelector((s: ApplicationState) => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);
	const vehicleSearch = useSelector((s: ApplicationState) => s.platform.vehicle.findVehicleId[`vrm:${input}`]);

	const dispatch = useDispatch();

	const onSearch = () => {
		dispatch(findVehicleId.request({ field: 'vrm', value: input }));

		dispatch(
			trackEvent({
				eventName: 'insurance_group_checker_cta_action',
				eventPayload: {
					product: 'none',
					platform: 'website',
					cuvva_source: cuvvaSource,
				},
			})
		);
	};

	const onKeyUp = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.keyCode === 13) onSearch();
	};

	useEffect(() => {
		if (vehicleSearch?.response) {
			setVehicleId(vehicleSearch?.response.id);
			dispatch(getPublicVehicle.request({ id: vehicleSearch?.response.id, bypassChecks: false }));
		}
	}, [dispatch, vehicleSearch]);

	useEffect(() => {
		dispatch(
			trackScreenView({
				eventName: 'insurance_group_checker_landing_page',
				eventPayload: {
					product: 'none',
					platform: 'website',
					cuvva_source: cuvvaSource,
				},
			})
		);
	}, [cuvvaSource, dispatch]);

	const searchVehicleRegEvent = () => {
		dispatch(
			trackEvent({
				eventName: 'insurance_group_checker_input_started',
				eventPayload: {
					product: 'none',
					platform: 'website',
					cuvva_source: cuvvaSource,
				},
			})
		);
	};

	return (
		<React.Fragment>
			<GroupCheckerContainer>
				<InputDecorator error={(vehicle?.error || vehicleSearch?.error) && customErrorMessage}>
					<RegPlateInput
						value={input}
						onChange={input => setInput(input)}
						placeholder={'Search number plate'}
						ariaLabel={'Number plate'}
						onKeyUp={onKeyUp}
						displaySearchButton
						onSearch={onSearch}
						onBlur={searchVehicleRegEvent}
						loading={vehicle?.fetching || vehicleSearch?.fetching}
					/>
				</InputDecorator>
				{vehicle?.response && vehicleSearch?.response && (
					<React.Fragment>
						<GroupCheckerResult>
							<ResultHeader>
								<VehicleManufacturer
									make={vehicle.response.make}
									size={'18px'}
									color={'primaryAction'}
								/>
								<Typography
									$type={'Body.Medium'}
									$bold
									$marginLeft={'small'}
									$color={'textOnSurfaceBackground'}
								>
									{`${vehicle.response.make} ${vehicle.response.model}`}
								</Typography>
							</ResultHeader>
							<ResultBody>
								<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
									{`Insurance group ${vehicle.response.thatchamInsuranceGroup50}`}
								</Typography>
							</ResultBody>
							<Anchor to={`how-insurance-works/${resultGuide.url}`}>{resultGuide.title}</Anchor>
						</GroupCheckerResult>
						<DownloadInfo get={get} />
						<Guides>
							<Typography
								$type={'Heading.Medium'}
								$align={'center'}
								$marginBottom={'large'}
								$color={'textOnSurfaceBackground'}
							>
								{'Guides'}
							</Typography>
							<PageList
								content={displayedGuides.map((a: any) => ({
									text: a.title,
									url: `how-insurance-works/${a.url}`,
								}))}
							/>
						</Guides>
						<Typography
							$type={'Heading.Medium'}
							$align={'center'}
							$marginBottom={'large'}
							$color={'textOnSurfaceBackground'}
						>
							{get('other_tools_header')}
						</Typography>
						{content?.other_tools?.map((tool, index) => (
							<ToolLink
								key={`${index}-${tool.title}`}
								title={tool.title}
								url={tool.url}
								subtitle={tool.subtitle}
								illustrationName={`${tool.illustration_name}.svg`}
							/>
						))}
					</React.Fragment>
				)}
			</GroupCheckerContainer>
			{!vehicle?.response && <Content get={get} />}
		</React.Fragment>
	);
};

const GroupCheckerContainer = styled.div`
	width: 340px;
	${media.greaterThan('tablet')`
		width: 370px;
	`}
`;

const GroupCheckerResult = styled.div`
	border-radius: ${p => p.theme.borderRadius.large};
	background: ${p => p.theme.ui.surfaceFillMuted};
	margin-top: ${p => p.theme.spacing.small};
	margin-bottom: 62px;
	display: flex;
	flex-direction: column;
	padding: 24px 16px;

	${media.greaterThan('tablet')`
		margin-bottom: 80px;
	`}

	a, a:hover, a:visited {
		color: ${p => p.theme.ui.textHighlight};
		line-height: 24px;
	}
`;

const ResultHeader = styled.div`
	display: flex;
	gap: ${p => p.theme.spacing.regular};

	& > * {
		flex: 0 0 auto;
	}
`;

const ResultBody = styled.div`
	display: flex;
	align-items: baseline;
	margin: ${p => p.theme.spacing.large} 0;
`;

export default GroupChecker;
