import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { BuilderPageStructure } from '../../types';
import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as Alert from '~lib/frontend/design-system/components/dialog/molecules/Alert';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import { createAwareUrl } from '~lib/frontend/helpers/uri';

type Mode = 'selector' | 'open_existing';

const example: BuilderPageStructure = {
	head: {
		title: 'One day car insurance from Cuvva | Get a quote in the app now',
		description:
			'Get fast, flexible car insurance for just a day with Cuvva. Sign up in the app and get insured in minutes.',
		social_title: 'Daily car insurance from Cuvva',
		social_description: 'Fast, flexible car insurance for just a day with Cuvva.',
		noindex: true,
	},
	url: '/your-url',
	page: {
		landing: true,
		quote_product: '',
		show_header_hero: true,
		new_design: false,
	},

	hero: {
		theme: 'dark',
		alignment: 'left',

		main_image: {
			type: 'background',
			value: '',
		},
		mobile_image: {
			type: 'illustration',
			value: '',
		},

		embedded_video: {
			provider: 'youtube',
			source: '',
			autoplay: false,
			thumbnail: '',
		},

		title_segment: {
			title: 'Title',
			subtitle: 'Subtitle',
			body: 'Body',
			checklist: [],
			segment_checklist: [],
			show_trustpilot: true,
			show_app_store_buttons: true,
			show_app_store_buttons_with_review: false,
			show_button: {
				text: 'Get a quote',
				url: '/get-an-estimate',
				type: void 0,
				leading_icon: void 0,
				trailing_icon: void 0,
				variant: 'default',
				track_click: false,
				event_name: '',
			},
			allow_more_buttons: void 0,
		},
	},

	sections: [],
};

function createBuilderTemplate() {
	return btoa(JSON.stringify(example));
}

const ModeSelector: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const [mode, setMode] = useState<Mode>('selector');
	const [pageError, setPageError] = useState<string | undefined>();
	const [pageJson, setPageJson] = useState<string>('');

	function resetOpenExistingModal() {
		setPageJson('');
		setPageError(void 0);
	}

	function updatePageJson(text: string) {
		setPageJson(text);

		try {
			JSON.parse(text);

			setPageError(void 0);
		} catch {
			setPageError('Invalid JSON payload');
		}
	}

	function simplifyPageJson() {
		const simplified = JSON.stringify(JSON.parse(pageJson));

		return btoa(encodeURIComponent(simplified));
	}

	switch (mode) {
		case 'selector':
			return (
				<Alert.Dialog open title={'Cuvva website page builder'} centered>
					<Alert.Content>{'Before we get started, what do you want to do?'}</Alert.Content>
					<Alert.Actions>
						<Button
							$type={'primary'}
							onClick={() =>
								dispatch(push(createAwareUrl(`/content/page-builder#${createBuilderTemplate()}`)))
							}
						>
							{'Create new page'}
						</Button>
						<Button $type={'primaryMuted'} onClick={() => setMode('open_existing')}>
							{'Open existing page'}
						</Button>

						<Button $type={'neutral'} onClick={() => dispatch(push(createAwareUrl('/')))}>
							{'Go home'}
						</Button>
					</Alert.Actions>
				</Alert.Dialog>
			);
		case 'open_existing':
			return (
				<Alert.Dialog open title={'Open an existing page'}>
					<Alert.Content>
						<p>
							{'Please paste the JSON content of the page from the content repo on '}
							<Anchor
								href={
									'https://github.com/cuvva/content/tree/master/segments/website_core/content/builder'
								}
							>
								{'GitHub'}
							</Anchor>{' '}
							{'below.'}
						</p>

						<InputDecorator label={'Page JSON data'} error={pageError}>
							<TextInput onChange={e => updatePageJson(e.currentTarget.value)} value={pageJson} />
						</InputDecorator>
					</Alert.Content>
					<Alert.Actions>
						<Button
							$type={'primary'}
							disabled={!pageJson || Boolean(pageError)}
							onClick={() =>
								dispatch(push(createAwareUrl(`/content/page-builder#${simplifyPageJson()}`)))
							}
						>
							{'Open'}
						</Button>

						<Button
							$type={'neutral'}
							onClick={() => {
								setMode('selector');
								resetOpenExistingModal();
							}}
						>
							{'Back'}
						</Button>
					</Alert.Actions>
				</Alert.Dialog>
			);

		default:
			return null;
	}
};

export default ModeSelector;
