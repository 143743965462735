import styled from 'styled-components';
const Callout = styled.div `
	${p => p.theme.typography['Label.XSmall']()};
	color: ${p => p.theme.ui.textHighlight};
	background: ${p => p.theme.ui.primaryActionMuted};
	border-radius: ${p => p.theme.borderRadius.small};
	padding: ${p => p.theme.spacing.large};
	display: flex;
	align-items: flex-start;

	a,
	a:hover,
	a:visited {
		color: ${p => p.theme.ui.textHighlight};
	}

	& > :first-child {
		flex: 0 0 auto;
	}

	& > * {
		flex: 1;
	}
`;
export default Callout;
