import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { fileById } from '../actions';
import { UploadActionTypes } from '../types';
export default function* watcherFileById() {
    yield takeEvery(UploadActionTypes.FILE_BY_ID, workerFileById);
}
function* workerFileById({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    const { fileId, urlTtl, preview, browserCache, includeUnconfirmed } = payload;
    const usePreview = preview || preview === void 0;
    try {
        const response = yield call([client, client.rpc], 'service-upload', '2/2020-08-25/get_file', {
            fileId,
            urlTtl,
            includeUnconfirmed, // Useful to see these unconfirmed files like back of the licence
            includeHidden: false,
            preview: usePreview,
        });
        if (browserCache)
            new Image().src = response.url;
        yield put(fileById.success(ident, response));
    }
    catch (error) {
        yield put(fileById.failure(ident, error));
    }
}
