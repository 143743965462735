import React from 'react';
import styled from 'styled-components';

import vehicleProfileEvents from '../events';
import Gauge from './Guage';
import SignalInterestCTAs from './SignalInterestCTAs';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

interface TripHistoryProps {
	open: boolean;
	closeModal: () => void;
}

const TripHistory: React.FCWithChildren<TripHistoryProps> = ({ open, closeModal }) => {
	const mapImg = useDesignSystemAsset({
		type: 'image',
		value: 'owners_trip_map.png',
	});

	return (
		<ComplexDialog.Dialog open={open} title={'Individual trip history'} onClose={closeModal}>
			<ComplexDialog.Content>
				<Typography
					$type={'Body.Medium'}
					$color={'textOnSurfaceBackgroundMuted'}
					$marginTop={'regular'}
					$marginBottom={'extraLarge'}
					$align={'center'}
				>
					{
						'Get a trip score for every car journey you make. Along with trip stats and the ability to visually see your trip on an interactive map.'
					}
				</Typography>
				<TripMap $backgroundImage={mapImg}>
					<TripInformation>
						<DateContainer>
							<Typography $type={'Label.Small'} $color={'textOnSurfaceBackgroundMuted'}>
								{'Today,  21:34'}
							</Typography>
						</DateContainer>
						<Gauge value={'88'} percentage={28} />
						<Typography
							$type={'Body.Medium'}
							$bold
							$align={'center'}
							$color={'textOnSurfaceBackground'}
							$marginBottom={'small'}
						>
							{'Excellent'}
						</Typography>
						<Button $type={'text'} $trailingIcon={'ic_chevron_right_sm'}>
							{'View trip'}
						</Button>
					</TripInformation>
				</TripMap>
			</ComplexDialog.Content>
			<ComplexDialog.Actions>
				<SignalInterestCTAs
					optinEvent={`${vehicleProfileEvents.ownersHub.tripHistoryCta}_yes`}
					optoutEvent={`${vehicleProfileEvents.ownersHub.tripHistoryCta}_no`}
					closeModal={closeModal}
				/>
			</ComplexDialog.Actions>
		</ComplexDialog.Dialog>
	);
};

const TripMap = styled.div<{ $backgroundImage: string }>`
	width: 100%;
	background: url(${p => p.$backgroundImage});
	background-repeat: no-repeat;
	background-size: cover;
	padding: ${p => p.theme.spacing.large};
	border-radius: ${p => p.theme.borderRadius.large};
	display: flex;
	justify-content: flex-end;
`;

const TripInformation = styled.div`
	background: ${p => p.theme.ui.surface};
	border-radius: ${p => p.theme.borderRadius.large};
	box-shadow: ${p => p.theme.boxShadow.extraSmall};
	display: flex;
	flex-direction: column;
	align-items: center;
	width: fit-content;
	padding: ${p => p.theme.spacing.large};
`;

const DateContainer = styled.div`
	background: ${p => p.theme.ui.surfaceFillMuted};
	border-radius: ${p => p.theme.borderRadius.extraSmall};
	margin-bottom: 20px;
	padding: 6px 10px;
`;

export default TripHistory;
