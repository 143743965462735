import CuvvaClient, { Auth } from '@cuvva/cuvva-client';
import { all, call, getContext, put, takeEvery } from 'redux-saga/effects';

import { importRegisterUser } from '../actions';
import { registerUser } from '../api';
import { GetUserActionTypes } from '../types';
import { userById } from '~lib/platform/auth/store/actions';
import { getConfigById } from '~lib/platform/user-config/store/actions';
import { AppsFlyerSDK } from '~website/helpers/appsflyer-sdk';
import { addEventVariable } from '~website/helpers/gtm';

export default function* watcherGetOrRegisterUser() {
	yield takeEvery(GetUserActionTypes.IMPORT_REGISTER_USER, workerGetOrRegisterUser);
}

function* workerGetOrRegisterUser() {
	const client: CuvvaClient = yield getContext('api');
	const appsFlyer: AppsFlyerSDK = yield getContext('appsFlyer');

	try {
		if (client.auth) return;

		const registerNewUser: Auth = yield call(registerUser, client);

		yield all([
			put(userById.request({ userId: registerNewUser.userId, includeRemovedIdentifiers: false })),
			put(importRegisterUser.success(registerNewUser.userId)),
			put(
				getConfigById.request({
					userId: registerNewUser.userId,
				})
			),
		]);

		addEventVariable('user_id', registerNewUser.userId);
		appsFlyer.identify(registerNewUser.userId);
	} catch (e) {
		yield put(importRegisterUser.failure(e));
	}
}
