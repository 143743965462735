import React from 'react';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useLockedDetails from '../../hooks/use-locked-details';
import useOnPageError from '../../hooks/use-on-page-error';
import useProfileInternalChangeRequest from '../../hooks/use-profile-internal-change-request';
import notEmptyValidator from '../../validators/not-empty';
import { AlignInlineChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { Profile } from '~lib/platform/profile/types';

const options = [
	{
		value: 'M' as Profile['sex'],
		display: 'Male',
	},
	{
		value: 'F' as Profile['sex'],
		display: 'Female',
	},
];

const SexOnDVLA: React.FunctionComponent = () => {
	const get = useContentKey('strings');
	const { value, validation, onChange } = useProfileInternalChangeRequest('sex', notEmptyValidator);
	const error = useOnPageError(validation, 'gender_input');
	const locked = useLockedDetails();

	return (
		<QuoteInputDecorator
			label={'Gender'}
			hintPosition={'end-of-line'}
			error={error}
			help={'This must match your DVLA record. We respect you may identify differently.'}
		>
			<AlignInlineChildren>
				{options.map(o => (
					<RadioInput
						disabled={locked}
						key={o.value}
						name={'sex-dvla'}
						checked={value === o.value}
						value={o.display}
						onChange={() => onChange(o.value)}
						data-event-onchange-name={'gender_input_selected'}
					/>
				))}
			</AlignInlineChildren>
		</QuoteInputDecorator>
	);
};

export default SexOnDVLA;
