import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import HorizontalRule from '../atoms/HorizontalRule';
import HeaderBar from '../molecules/headers/HeaderBar';
import { QuoteCard } from './atoms/QuoteCard';
import {
	DetailsCard,
	PolicyDocsWrap,
	PriceWrap,
	QuoteLayout,
	QuoteLayoutWrap,
	SplashContent,
	TrustpilotAligner,
} from './atoms/QuoteRefresh';
import { LtmQuoteContext } from './context';
import useQuoteValiditySupervisor from './hooks/use-quote-validity-supervisor';
import LowerSplash from './molecules/LowerSplash';
import PolicyPurchaseOverview from './molecules/PolicyPurchaseOverview';
import QuoteCardWithHeading from './molecules/QuoteCardWithHeading';
import SpinnerCover from './molecules/SpinnerCover';
import WhatsCovered from './molecules/WhatsCovered';
import AboutYouInfoCard from './organisms/AboutYouInfoCard';
import CarInfoCard from './organisms/CarInfoCard';
import PolicyDocsCard from './organisms/PolicyDocsCard';
import PolicyInfoCard from './organisms/PolicyInfoCard';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import TrustpilotThemeAware from '~website/assets/svgs/TrustpilotThemeAware';
import Footer from '~website/components/layout/footer';

const firstChildHeight = (el: React.MutableRefObject<HTMLDivElement>) =>
	Math.round((el.current?.firstChild as HTMLElement)?.getBoundingClientRect().height ?? 0);

const LtmQuote: React.FunctionComponent = () => {
	const { vehicle, quote: asyncQuote, asyncStatus, requestQuote } = useContext(LtmQuoteContext);
	const [requested, setRequested] = useState(false);
	const history = useHistory();
	const priceRef = useRef<HTMLDivElement>();
	const docsRef = useRef<HTMLDivElement>();

	useQuoteValiditySupervisor();

	useEffect(() => {
		if (requested) return;

		setRequested(true);
		requestQuote();
	}, [requested, requestQuote]);

	useEffect(() => {
		if (asyncStatus === 'error') history.push(createAwareUrl('/quote/cannot-quote'));
	}, [history, asyncStatus]);

	const prettyVrm = vehicle?.response?.prettyVrm;
	const background = useContentAsset('website_core', 'quote/quote_page.svg');

	if (asyncStatus === 'pending') return <SpinnerCover />;

	const quote = asyncQuote.response;
	const discounts = quote?.eligibleDiscounts ?? [];
	const smartPricingEnabled = quote?.summary.policy.smartPricing.enabled;
	const nonSmartPricing = smartPricingEnabled ? quote?.smartPricing.nonSmartPricing : void 0;

	return (
		<Fragment>
			<HeaderBar transparent />
			<SplashContent $background={background}>
				<Typography $type={'Heading.Small'} $color={'textOnFill'}>
					{prettyVrm}
				</Typography>

				<Typography $type={'Heading.Large'} $color={'textOnFill'}>
					{'Your quote for your'}
				</Typography>
				<Typography $type={'Heading.Large'} $color={'textOnFill'}>
					{[vehicle?.response?.make, vehicle?.response?.model].join(' ')}
				</Typography>
			</SplashContent>

			<QuoteLayoutWrap>
				<QuoteLayout>
					<PriceWrap ref={priceRef} $docsHeigh={(() => firstChildHeight(docsRef))()}>
						<div>
							<QuoteCardWithHeading
								discounts={discounts}
								pricing={quote?.pricing}
								nonSmartPricing={nonSmartPricing}
							/>
						</div>
					</PriceWrap>
					<PolicyDocsWrap
						ref={docsRef}
						$stickyOffset={(() => firstChildHeight(priceRef))()}
						$docsHeigh={(() => firstChildHeight(docsRef))()}
					>
						<div>
							<PolicyDocsCard />

							<TrustpilotAligner $mobile={false}>
								<TrustpilotThemeAware />
							</TrustpilotAligner>
						</div>
					</PolicyDocsWrap>
					<DetailsCard>
						<QuoteCard>
							<PolicyPurchaseOverview vrm={prettyVrm} startDate={quote?.cycle.startDate} />
						</QuoteCard>
						<TrustpilotAligner $mobile>
							<TrustpilotThemeAware />
						</TrustpilotAligner>
						<QuoteCard>
							<Typography
								$type={'Heading.Small'}
								$color={'textOnSurfaceBackground'}
								$marginBottom={'extraLarge'}
							>
								{'What’s covered'}
							</Typography>

							<WhatsCovered />
						</QuoteCard>
						<QuoteCard>
							<InfoWrap>
								<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
									{'Check your details'}
								</Typography>

								<Margin $marginBottom={'regular'}>
									<Typography
										$type={'Heading.XSmall'}
										$color={'textOnSurfaceBackground'}
										$marginBottom={'semiLarge'}
									>
										{'Policy'}
									</Typography>

									<HorizontalRule />

									<Margin $marginTop={'regular'}>
										<PolicyInfoCard />
									</Margin>
								</Margin>

								<Margin $marginBottom={'regular'}>
									<Typography
										$type={'Heading.XSmall'}
										$color={'textOnSurfaceBackground'}
										$marginBottom={'semiLarge'}
									>
										{'About you'}
									</Typography>

									<HorizontalRule />

									<Margin $marginTop={'regular'}>
										<AboutYouInfoCard />
									</Margin>
								</Margin>

								<Margin $marginBottom={'regular'}>
									<Typography
										$type={'Heading.XSmall'}
										$color={'textOnSurfaceBackground'}
										$marginBottom={'semiLarge'}
									>
										{'About your car'}
									</Typography>

									<HorizontalRule />

									<Margin $marginTop={'regular'}>
										<CarInfoCard />
									</Margin>
								</Margin>
							</InfoWrap>

							<Margin $marginTop={'2xLarge'}>
								<Button
									as={Anchor}
									// @ts-ignore
									to={'/quote/your-details'}
									$display={'inline-block'}
									$type={'primaryMuted'}
								>
									{'Edit details'}
								</Button>
							</Margin>
						</QuoteCard>
					</DetailsCard>
				</QuoteLayout>
			</QuoteLayoutWrap>

			<LowerSplash pricing={quote?.pricing} nonSmartPricing={nonSmartPricing} />

			<Footer landingPage />
		</Fragment>
	);
};

const InfoWrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.extraLarge};
`;

export default LtmQuote;
