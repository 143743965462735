/* eslint-disable require-yield */
import { stepActions } from '../actions';
import { setItem } from '~lib/shared/helpers/local-storage';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { quoteUseClass } from '~website/constants/storage';

export default createTakeEverySagaSet(stepActions.setUseClass, function* setUseClassSaga(action) {
	if ([void 0, null].includes(action.payload)) setItem(quoteUseClass, null, 'sessionStorage');
	else setItem(quoteUseClass, action.payload, 'sessionStorage');
});
