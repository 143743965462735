import { combineReducers } from 'redux';

import internalChangeRequest from './reducers/internal-change-request';
import pages from './reducers/pages';
import state from './reducers/state';
import type { LookupByPostcodeResponse, LtmQuote, ReverseGeocoding, ReverseGeocodingResponse } from './types';
import type { CreateClientCodeResponse } from '~lib/platform/auth/store/types';
import type { CreateEstimateResponse } from '~lib/platform/ltm/types';
import type { MotorCoverageQuoteResponse } from '~lib/platform/motor-coverage/types';
import createAsyncMapSlice from '~lib/shared/redux/slices/async-map-slice';
import createAsyncSlice from '~lib/shared/redux/slices/async-slice';
import { RequestIdPayload } from '~lib/shared/redux/types/payloads';

const action = (...args: TemplateStringsArray[]) => ['@@internal/quote'].concat(...args).join('/');

export const runStmQuote = createAsyncSlice<void, MotorCoverageQuoteResponse>(action`runStmQuote`);
export const runLtmEstimate = createAsyncSlice<void, CreateEstimateResponse>(action`runLtmEstimate`);
export const runLtmQuote = createAsyncMapSlice<{ requestId: string }, LtmQuote>(action`runLtmQuote`, p => p.requestId);
export const clientCode = createAsyncSlice<void, CreateClientCodeResponse>(action`clientCode`);
export const lookupByPostcode = createAsyncMapSlice<{ postcode: string }, LookupByPostcodeResponse>(
	action`lookupByPostcode`,
	p => p.postcode
);
export const reverseGeocoding = createAsyncMapSlice<RequestIdPayload<ReverseGeocoding>, ReverseGeocodingResponse>(
	action`reverseGeocoding`,
	p => p.requestId
);

const reducers = combineReducers({
	estimate: combineReducers({
		ltm: runLtmEstimate.reducer,
	}),
	handoff: clientCode.reducer,
	internalChangeRequest: internalChangeRequest.reducer,
	lookupByPostcode: lookupByPostcode.reducer,
	pages: pages.reducer,
	quote: combineReducers({
		stm: runStmQuote.reducer,
		ltm: runLtmQuote.reducer,
	}),
	reverseGeocoding: reverseGeocoding.reducer,
	state: state.reducer,
});

export const initialInternalQuoteState: ReturnType<typeof reducers> = {
	estimate: {
		ltm: runLtmEstimate.getInitialState(),
	},
	handoff: clientCode.getInitialState(),
	internalChangeRequest: internalChangeRequest.getInitialState(),
	lookupByPostcode: lookupByPostcode.getInitialState(),
	pages: pages.getInitialState(),
	quote: {
		stm: runStmQuote.getInitialState(),
		ltm: runLtmQuote.getInitialState(),
	},
	reverseGeocoding: reverseGeocoding.getInitialState(),
	state: state.getInitialState(),
};

export default reducers;
