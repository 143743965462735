import React, { useMemo } from 'react';
import styled from 'styled-components';

import useQuoteStepIndicator from '../../hooks/use-quote-step-indicator';
import Progress from '~lib/frontend/design-system/components/progress';
import Typography from '~lib/frontend/design-system/components/Typography';

const SteppedProgress = () => {
	const {
		product,
		quoteSteps,
		activeQuoteStepIndex,
		verificationSteps,
		activeVerificationStepIndex,
		paymentSteps,
		activePaymentStepIndex,
	} = useQuoteStepIndicator();

	if (product === 'stm') {
		return (
			<SteppedProgressIndicator
				label={'Get a quote'}
				align={'left'}
				steps={quoteSteps + 1}
				activeStep={activeQuoteStepIndex + 1}
			/>
		);
	}

	return (
		<SteppedProgressWrapper>
			<SteppedProgressIndicator
				steps={quoteSteps + 1}
				activeStep={activeQuoteStepIndex + 1}
				label={
					<span>
						{'Quote'}
						{/* <span>{` · ${Math.min(activeQuoteStepIndex + 1, quoteSteps)} of ${quoteSteps}`}</span> */}
					</span>
				}
			/>

			<SteppedProgressIndicator
				label={'Verify'}
				steps={verificationSteps + 1}
				activeStep={activeVerificationStepIndex + 1}
			/>

			<SteppedProgressIndicator
				steps={paymentSteps}
				activeStep={activePaymentStepIndex}
				label={
					<span>
						{'Pay '}
						<span role={'emoji'}>{'🏁'}</span>
					</span>
				}
			/>
		</SteppedProgressWrapper>
	);
};

export default SteppedProgress;

const SteppedProgressWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: ${p => p.theme.spacing.small};
`;

interface SteppedProgressIndicatorProps {
	label: React.ReactNode;
	steps: number;
	activeStep: number;
	align?: 'left' | 'center' | 'right';
}

const SteppedProgressIndicator = (props: SteppedProgressIndicatorProps) => {
	const { label, activeStep, steps, align = 'center' } = props;

	const state = useMemo(() => {
		const idle = activeStep === 0;
		const active = activeStep > 0 && activeStep < steps;
		const completed = activeStep === steps;

		if (idle) return 'textMinorMuted';
		if (active) return 'textHighlight';
		if (completed) return 'textOnSurfaceBackground';

		return 'textOnSurfaceBackground';
	}, [activeStep, steps]);

	return (
		<div>
			<ProgressIndicatorTitle
				$color={state}
				$align={align}
				$type={'Label.Small'}
				$marginBottom={'small'}
				disabled={activeStep === 0}
			>
				{label}
			</ProgressIndicatorTitle>

			<Progress max={steps} size={'large'} color={'gradient'} value={activeStep} corner={'rounded'} />
		</div>
	);
};

const ProgressIndicatorTitle = styled(Typography)<{ disabled?: boolean }>`
	${p =>
		p.disabled &&
		`
		span[role="emoji"] {
			opacity: 0.4;
			-webkit-filter: grayscale(100%);
   			filter: grayscale(100%);
		}
	`}
`;
