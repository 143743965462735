import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';

import Renderer from '../index';
import { BuilderPageStructure, SubHeroType } from '../types';
import Builder from './builder';
import {
	BuilderContainer,
	BuilderPosition,
	PleaseAddNewSection,
	RendererContainer,
	WholePageContainer,
} from './styled';
import { EditableTypographyContext } from '~lib/frontend/design-system/context/editable-typography';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { GetterType } from '~lib/frontend/types/content';
import { getItem, setItem } from '~lib/shared/helpers/local-storage';
import { pageBuilderPosition } from '~website/constants/storage';

export const subHeroInitialState: SubHeroType = {
	asset: {
		type: 'illustration',
		value: '',
	},

	title_segment: {
		title: 'Title',
		subtitle: 'Subtitle',
		body: 'Body',
		checklist: [],
		show_trustpilot: true,
		show_app_store_buttons: true,
		show_app_store_buttons_with_review: false,
		show_button: {
			text: 'Get a quote',
			url: '/get-an-estimate',
		},
	},
};

const Generator: React.FunctionComponent = () => {
	const { hash } = useLocation();
	const dispatch = useDispatch();
	const [state, setState] = useState<BuilderPageStructure | null>(null);
	const [builderPosition, setBuilderPosition] = useState<BuilderPosition>(getInitialBuilderPosition());
	const getter: GetterType = (key: string, fallback?: any) => lodashGet(state, key, fallback);

	useEffect(() => {
		const preparedHash = prepareHash(hash);

		if (preparedHash) setState(preparedHash);
		else dispatch(push(createAwareUrl('/content/page-builder')));

		// We only want this to happen on mount!
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	function updateState(updatedState: BuilderPageStructure) {
		setState(updatedState);

		const hash = btoa(encodeURIComponent(JSON.stringify(updatedState)));

		window.location.replace(`${window.location.pathname}${window.location.search}#${hash}`);
	}

	function toggleBuilderPosition() {
		const newPosition = builderPosition === 'bottom' ? 'side' : 'bottom';

		setItem(pageBuilderPosition, newPosition);
		setBuilderPosition(newPosition);
	}

	if (state === null) return null;

	return (
		<EditableTypographyContext.Provider
			value={{
				updateValue: (key, value) => {
					updateState(lodashSet(state, key, value));
				},
			}}
		>
			<WholePageContainer $position={builderPosition}>
				<BuilderContainer $position={builderPosition}>
					<Builder state={state} togglePosition={toggleBuilderPosition} setState={updateState} />
				</BuilderContainer>

				<RendererContainer $position={builderPosition}>
					{/* ->
						Why specifying `key`:
						Unfortunately react isn't smart enough
						to understand a dynamic set of components
					*/}
					<Renderer get={getter} key={state.sections.length} />

					{state.sections.length === 0 && (
						<PleaseAddNewSection data-testid={'no-sections'}>
							{'Hey, seems like this page has no sections!'}
							<br />
							{'Use the builder to add a new section'}
						</PleaseAddNewSection>
					)}
				</RendererContainer>
			</WholePageContainer>
		</EditableTypographyContext.Provider>
	);
};

function prepareHash(hash: string) {
	if (hash.length === 0) return null;

	const sanitizedHash = hash.substring(1);

	try {
		return JSON.parse(decodeURIComponent(atob(sanitizedHash)));
	} catch {
		return null;
	}
}

function getInitialBuilderPosition(): BuilderPosition {
	const pos = getItem<string>(pageBuilderPosition);
	const options = ['side', 'bottom'];

	if (options.includes(pos)) return pos as BuilderPosition;

	return window.innerWidth < 1200 ? 'bottom' : 'side';
}

export default Generator;
