import { deserialize } from 'bson';
import pako from 'pako';
import { getFullSegment, getLiteSegment } from './api';
export async function getAndParseLiteSegment(client, req) {
    const response = await getLiteSegment(client, req);
    const segmentBuffer = await fetch(response.url).then(convertToBuffer);
    const segmentBson = pako.ungzip(segmentBuffer);
    return deserialize(segmentBson);
}
export async function getAndParseFullSegment(client, req) {
    const response = await getFullSegment(client, req);
    const segmentBuffer = await fetch(response.url).then(convertToBuffer);
    const segmentBson = pako.ungzip(segmentBuffer);
    return deserialize(segmentBson);
}
async function convertToBuffer(response) {
    if (response.arrayBuffer) {
        // In a browser environment, use the arrayBuffer method
        return await response.arrayBuffer();
    }
    else if (response.buffer) {
        // In a Node.js environment, use the buffer method
        return await response.buffer();
    }
    throw new Error('Unsupported environment');
}
