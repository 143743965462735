import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';

import SearchVehicle from '../components/organisms/SearchVehicle';
import VehicleProfile from '../components/organisms/VehicleProfile';
import Spinner from '~lib/frontend/design-system/components/spinner';
import lazyWithPreload from '~lib/frontend/helpers/lazy-with-preload';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import useConfigFlag from '~lib/frontend/hooks/use-config-flag';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import { getPublicVehicle } from '~lib/platform/vehicle/store/actions';
import Meta from '~website/components/Meta';
import SplitUI, { TrustpilotContent } from '~website/components/SplitUI';
import HeaderBar from '~website/features/quote/components/molecules/headers/HeaderBar';
import { quoteActions } from '~website/features/quote/store/actions';
import { useHideIntercomLauncher } from '~website/hooks/intercom';

const [, preloadQuote] = lazyWithPreload(() => import('../../quote/containers/Quote'));

interface UrlParams {
	vehicleId: string;
}

const SpinnerContainer = styled.div`
	width: 50px;
	height: 50px;
	margin: 50px auto;
`;

const VehicleView: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const { vehicleId } = useParams<UrlParams>();
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.vehicle-profile.${key}`, fallback);
	const splashImage = useContentAsset('website_core', 'quote/quote_column.svg');

	const vehicle = useSelector(s => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);
	const vehicleStatus = useAsyncStateStatus(vehicle);

	const flagEnabled = useConfigFlag('web_stm_quote');

	useEffect(() => {
		preloadQuote();
	}, []);

	useEffect(() => {
		if (vehicleId && vehicleStatus.is('pending', 'fetching'))
			dispatch(getPublicVehicle.request({ id: vehicleId, bypassChecks: false }));
	}, [vehicleId, vehicleStatus, dispatch]);

	useEffect(() => {
		if (vehicleStatus.is('response')) dispatch(quoteActions.setVehicle({ vehicleId }));
	}, [dispatch, vehicleId, vehicleStatus]);

	useHideIntercomLauncher();

	let component;

	if (flagEnabled === false) {
		return <Redirect to={'/download-quote'} />;
	}

	if (!vehicle || vehicle?.error) component = <SearchVehicle vehicleId={vehicleId} />;

	const vehicleLoading = vehicleId && (!vehicle || vehicleStatus.is('pending', 'fetching'));

	if (!flagEnabled || vehicleLoading) {
		component = (
			<SpinnerContainer>
				<Spinner $size={'50px'} />
			</SpinnerContainer>
		);
	}

	if (vehicleId && vehicle?.response) component = <VehicleProfile vehicleId={vehicleId} />;

	const splashContent = (
		<TrustpilotContent name={get('trustpilot.name', 'Paul')} children={get('trustpilot.content', 'Excellent!')} />
	);

	return (
		<Fragment>
			<Meta get={(key, fallback) => get(`head.${key}`, fallback)} />

			<SplitUI splashImage={splashImage} splashContent={splashContent}>
				<HeaderBar sticky />
				{component}
			</SplitUI>
		</Fragment>
	);
};

export default VehicleView;
