import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { Report, ReportingToWrapper } from '../atoms/ReportingToWrapper';
import Typography from '~lib/frontend/design-system/components/Typography';
import HiringOfferContext from '~website/features/hiring-offer/context';

const ReportingTo: React.FunctionComponent = () => {
	const { reportsTo, probationPeriod, noticePeriods } = useContext(HiringOfferContext);
	const staffStore = useSelector(s => s.platform.staff.listStaffPublic);

	const noticeAfterProbation = noticePeriods?.afterProbation ?? null;
	const noticeDuringProbation = noticePeriods?.duringProbation ?? null;

	if (![reportsTo, probationPeriod, noticeAfterProbation, noticeDuringProbation].some(Boolean)) return null;
	const staffToReportTo = staffStore.response?.find(staff => staff.id === reportsTo)?.about.name;

	const convertToWeeks = (string: string): string => {
		const weeks = moment.duration(string).asWeeks();

		return weeks > 1 ? `${weeks} weeks` : `${weeks} week`;
	};

	return (
		<ReportingToWrapper>
			{staffToReportTo && (
				<Report>
					<Typography $type={'Heading.XSmall'}>{'Reporting to'}</Typography>
					<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
						{staffToReportTo}
					</Typography>
				</Report>
			)}
			{probationPeriod && (
				<Report>
					<Typography $type={'Heading.XSmall'}>{'Probation period'}</Typography>
					<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
						{convertToWeeks(probationPeriod)}
					</Typography>
				</Report>
			)}
			<Report>
				<Typography $type={'Heading.XSmall'}>{'Notice period'}</Typography>
				<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
					{convertToWeeks(noticeAfterProbation)}
				</Typography>
				<Typography
					$type={'Body.Medium'}
				>{`${convertToWeeks(noticeDuringProbation)} during probation`}</Typography>
			</Report>
		</ReportingToWrapper>
	);
};

export default ReportingTo;
