import Cher from '@cuvva/cher';

import dateValidator from './valid-date';

const MAX_AGE = 74;
const MIN_AGE = 16;

const profileDOBValidator = (value: string) => {
	if (!value || value === '--') return new Cher('empty');

	const { valid, day, month, year } = dateValidator(value);

	if (!valid) return new Cher('invalid');

	const date = new Date(year, month - 1, day); // month is 0-based

	if (isNaN(date.getTime())) return new Cher('invalid');

	if (isFutureDate(date)) return new Cher('future_date');

	if (!minAge(date)) return new Cher('too_young');

	if (!maxAge(date)) return new Cher('too_old');

	return void 0;
};

function isFutureDate(date: Date) {
	const now = new Date();

	return date > now;
}

function minAge(date: Date) {
	const now = new Date();
	const minAgeDate = new Date(now.getFullYear() - MIN_AGE, now.getMonth(), now.getDate());

	return date <= minAgeDate;
}

function maxAge(date: Date) {
	const now = new Date();
	const maxAgeDate = new Date(now.getFullYear() - MAX_AGE, now.getMonth(), now.getDate());

	return date >= maxAgeDate;
}

export default profileDOBValidator;
