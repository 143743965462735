import styled from 'styled-components';
export const SingleOTP = styled.input `
	${p => p.theme.typography['Body.Medium']()};
	padding: ${p => p.theme.spacing.regular};
	border-radius: ${p => p.theme.borderRadius.regular};

	min-width: 0;
	transition: all 100ms;

	background: ${p => p.theme.ui.surface};
	color: ${p => p.theme.ui.textOnSurfaceBackground};

	border: 1px solid ${p => p.theme.ui.borderInteractiveDefault};

	&:hover {
		border: 1px solid ${p => p.theme.ui.borderInteractiveHover};
	}

	&:focus {
		outline: none; /* Outline isn't implemented or respected very well across browsers */
		border: 1px solid ${p => p.theme.ui.borderInteractiveFocus};
		box-shadow: inset 0px 0px 0px 1px ${p => p.theme.ui.borderInteractiveFocus};
	}

	&:focus:not(:focus-visible) {
		outline: none;
	}

	&:focus-within {
		border: 1px solid ${p => p.theme.ui.borderInteractiveFocus};
		box-shadow: inset 0px 0px 0px 1px ${p => p.theme.ui.borderInteractiveFocus};
	}

	&:disabled {
		cursor: not-allowed;
		background: ${p => p.theme.ui.surfaceFillMuted};

		border: 1px solid ${p => p.theme.ui.borderInteractiveDisabled};

		color: ${p => p.theme.ui.textMinor};
		-webkit-text-fill-color: ${p => p.theme.ui.textMinor};
		opacity: 1;
	}

	${p => p.$error
    ? `
		background: ${p.theme.ui.surface};
		border: 2px solid ${p.theme.ui.borderInteractiveDanger};
		box-shadow: inset 0px 0px 0px 1px ${p.theme.ui.borderInteractiveDanger};

		&:hover {
			border: 1px solid ${p.theme.ui.borderInteractiveDanger};
		}

		&:focus-within {
			border: 2px solid ${p.theme.ui.borderInteractiveFocus};
			box-shadow: inset 0px 0px 0px 1px ${p.theme.ui.borderInteractiveFocus};
		}
	`
    : ''};

	&[type='text'],
	&[type='email'],
	&[type='number'] {
		appearance: none;
	}

	width: -webkit-fill-available;
	text-align: center;
`;
export const OTP = styled.div `
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: ${p => p.theme.spacing.regular};
	white-space: nowrap;

	&:nth-child(3) {
		margin-right: ${p => p.theme.spacing.large};
	}
	&:nth-child(4) {
		margin-left: ${p => p.theme.spacing.large};
	}

	${SingleOTP} {
		width: ${p => p.theme.spacing['3xLarge']};
	}
`;
