import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';
import styled from 'styled-components';

import { useSinglePolicy } from '../context/SinglePolicyContext';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as Alert from '~lib/frontend/design-system/components/dialog/molecules/Alert';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { documentsByPolicyId } from '~lib/platform/policy-documents/store/actions';
import HorizontalRule from '~website/features/quote/components/atoms/HorizontalRule';
import { PolicyDoc } from '~website/features/quote/components/quote-page/organisms/PolicyDocsCard';

const PolicyDocuments = () => {
	const dispatch = useDispatch();
	const { policyId } = useSinglePolicy();

	const documentStore = useSelector(s => s.platform.policyDocuments.documentsByPolicyId[policyId]);
	const fetching = useAsyncStateStatus(documentStore).status !== 'response';
	const docs = documentStore?.response;

	const documents = useMemo(() => {
		if (docs) return docs[docs.length - 1];

		return null;
	}, [docs]);

	const [error, setError] = useState<[string, Cher]>();
	const onError = (type: string, e: Cher) => setError([type, e]);
	const onDismiss = () => setError(void 0);

	useEffect(() => {
		dispatch(documentsByPolicyId.request({ policyId }));
	}, [dispatch, policyId]);

	return (
		<React.Fragment>
			<PolicyDocCardWrapper>
				<Margin $marginBottom={'semiLarge'}>
					<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
						{'Your policy documents'}
					</Typography>
				</Margin>

				<DocList>
					<PolicyDoc
						title={'Policy details'}
						url={documents?.certificate.contentUrl}
						styles={documents?.certificate.stylesUrl}
						onError={e => onError('policy details', e)}
						fetching={fetching}
					/>
					<HorizontalRule />
					<PolicyDoc
						title={'Full policy'}
						url={documents?.terms.contentUrl}
						styles={documents?.terms.stylesUrl}
						onError={e => onError('full policy', e)}
						fetching={fetching}
					/>
					<HorizontalRule />
					<PolicyDoc
						title={'Terms'}
						url={documents?.cuvvaTerms.contentUrl}
						styles={documents?.cuvvaTerms.stylesUrl}
						onError={e => onError('terms', e)}
						fetching={fetching}
					/>
				</DocList>
			</PolicyDocCardWrapper>

			<Alert.Dialog open={Boolean(error)} title={"Couldn't open the document"}>
				<Alert.Content>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
						{'Unable to fetch the document.'}
					</Typography>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
						{'Please try again or get in touch with customer support.'}
					</Typography>
				</Alert.Content>
				<Alert.Actions>
					<Button $type={'primary'} onClick={onDismiss}>
						{'Dismiss'}
					</Button>
				</Alert.Actions>
			</Alert.Dialog>
		</React.Fragment>
	);
};

export default PolicyDocuments;

const PolicyDocCardWrapper = styled.div`
	background-color: ${p => p.theme.ui.surface};
	border-radius: ${p => p.theme.borderRadius.large};

	padding: ${p => p.theme.spacing.semiLarge} ${p => p.theme.spacing.large};

	@media (min-width: 628px) {
		padding: ${p => p.theme.spacing?.['2xLarge']} ${p => p.theme.spacing.extraLarge};
	}

	@media (min-width: 940px) {
		padding: ${p => p.theme.spacing.extraLarge} ${p => p.theme.spacing.semiLarge};
	}
`;

const DocList = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
`;
