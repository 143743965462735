import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import TimeLeft from './TimeLeft';
import { getPercentage, getPolicyDuration, getTimerStates, getTitle } from './utils';
import Typography from '~lib/frontend/design-system/components/Typography';
const PolicyTimer = props => {
    const { voided, startDate, endDate, type } = props;
    const policyEndDate = moment(endDate);
    const policyStartDate = moment(startDate);
    const isPolicyEnded = moment().isAfter(policyEndDate);
    const isStartDateUpcoming = policyStartDate.isAfter(moment());
    const timeToPolicyEnd = isStartDateUpcoming
        ? policyStartDate.format('ddd, D MMM YYYY, h:mma')
        : policyEndDate.format('ddd, D MMM YYYY, h:mma');
    const percentageRemaining = getPercentage({
        policyStartDate,
        policyEndDate,
        isStartDateUpcoming,
        currentTime: moment(),
    });
    const diff = policyEndDate.diff(policyStartDate);
    const duration = moment.duration(Math.abs(diff)).asSeconds();
    return (React.createElement(ProgressContainer, { "$state": getTimerStates(voided, percentageRemaining, isStartDateUpcoming) },
        !type && (React.createElement(PolicyDuration, null,
            React.createElement(Typography, { "$type": 'Body.Medium', "$color": 'textOnSurfaceBackgroundMuted' }, getTitle(isPolicyEnded, isStartDateUpcoming)),
            React.createElement(Typography, { "$type": 'Body.Small', "$color": 'textOnSurfaceBackground', "$bold": true }, voided ? '🚫' : timeToPolicyEnd))),
        type && (React.createElement(PolicyDuration, null,
            voided ? (React.createElement(Typography, { "$type": 'Body.Small', "$color": 'textOnSurfaceBackground' }, 'Not taken up')) : (React.createElement(TimeLeft, { isPolicyEnded: isPolicyEnded, isStartDateUpcoming: isStartDateUpcoming, endDate: endDate, startDate: startDate })),
            React.createElement(Typography, { "$type": 'Body.Small', "$color": 'textOnSurfaceBackground' },
                React.createElement("strong", null, getPolicyDuration(duration, type)),
                ' duration'))),
        !isStartDateUpcoming && !voided && (React.createElement("progress", { value: isPolicyEnded ? '100' : percentageRemaining?.toFixed(2)?.toString(), max: '100' })),
        voided && React.createElement("progress", { value: 100, max: '100' })));
};
const getStateColors = (state, ui) => {
    const colorMapping = {
        'upcoming': ui.surfaceFillMuted,
        'just-started': 'linear-gradient(269.89deg, #C202D9 0%, #6151FF 99.81%)',
        'midway': 'linear-gradient(269.89deg, #C202D9 0%, #6151FF 99.81%)',
        'ending': 'linear-gradient(270deg, rgba(0, 0, 0, 0.1) -410.66%, rgba(0, 0, 0, 0) 100%), #E53D61',
        'voided': 'linear-gradient(270deg, rgba(0, 0, 0, 0.1) -410.66%, rgba(0, 0, 0, 0) 100%), #E53D61',
    };
    return colorMapping[state];
};
const ProgressContainer = styled.div `
	progress {
		appearance: none;
		width: 100%;
		height: 6px;
		border-radius: 2px;
	}

	progress[value]::-webkit-progress-bar {
		background-color: ${p => p.theme.ui.surfaceFillMuted};
		border-radius: 2px;
	}

	progress[value]::-webkit-progress-value {
		background: ${p => getStateColors(p.$state, p.theme.ui)};
		border-radius: 2px;
	}
`;
const PolicyDuration = styled.div `
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export default PolicyTimer;
