import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { trackQuoteEvent } from '~website/features/analytics/store/actions';

interface UseCheckedTypingInterface {
	action: string;
	value?: string;
}

const useCheckedTyping = ({ action, value }: UseCheckedTypingInterface) => {
	const dispatch = useDispatch();

	const [checkStartedTyping, setCheckStartedTyping] = useState(false);

	useEffect(() => {
		if (checkStartedTyping && action) {
			dispatch(
				trackQuoteEvent({
					action,
					...(value ? { value } : {}),
				})
			);
		}
	}, [dispatch, checkStartedTyping, action, value]);

	return {
		checkStartedTyping,
		setCheckStartedTyping,
	};
};

export default useCheckedTyping;
