import { css } from 'styled-components';
export const safeAreaPadded = (padding, desktopPadding) => css `
	padding-left: ${padding};
	padding-right: ${padding};

	@supports (padding: max(0px)) {
		padding-left: max(${padding}, env(safe-area-inset-left));
		padding-right: max(${padding}, env(safe-area-inset-right));
	}

	${desktopPadding &&
    css `
		@media (min-width: 768px) {
			@supports (padding: max(0px)) {
				padding-left: max(${desktopPadding}, env(safe-area-inset-left));
				padding-right: max(${desktopPadding}, env(safe-area-inset-right));
			}
		}
	`};
`;
