import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import ConfirmEmailVariant from '../../proposition-testing/ConfirmEmailVariant';
import ComingSoon from '../coming-soon';
import vehicleProfileEvents from '../events';
import VehicleDetails from '../VehicleDetails';
import OwnersHub from './OwnersHub';
import UnlockOwnersHub from './UnlockOwnersHub';
import { media } from '~lib/frontend/design-system/breakpoints';
import Button from '~lib/frontend/design-system/components/button/Button';
import PolicyTimer from '~lib/frontend/design-system/components/policy-timer';
import Typography from '~lib/frontend/design-system/components/Typography';
import VehicleManufacturer from '~lib/frontend/design-system/components/VehicleManufacturer';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import HeaderBar from '~website/components/atoms/HeaderBar';
import { trackOwnersEvent } from '~website/features/analytics/store/actions';
import PageSpinner from '~website/features/referral/atoms/PageSpinner';
import useSharedQuote from '~website/features/referral/hooks/use-shared-quote';
import useEmailAttached from '~website/hooks/use-email-attached';

interface LockedProps {
	showHub?: boolean;
}

const Locked: React.FCWithChildren<LockedProps> = ({ showHub }) => {
	const { ownersData, loading, vehicle } = useSharedQuote();
	const [open, setOpen] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [complete, setComplete] = useState(false);
	const { entryPointLocked } = vehicleProfileEvents;

	const g = useContentKey('website_core');
	const getVariantContent = (variantKey: string) => (key: string, fallback?: string) =>
		g(`pages.referral.${variantKey}.${key}`, fallback);
	const isEmailAttached = useEmailAttached();
	const dispatch = useDispatch();

	const updatePageAndDispatchSuccessEvent = (val: boolean) => {
		setComplete(val);
	};

	const viewDetails = () => {
		dispatch(
			trackOwnersEvent({
				eventName: vehicleProfileEvents.ownersHub.vehicleDetailsCta,
			})
		);
		setOpen(true);
	};

	if (loading) {
		return <PageSpinner />;
	}

	const component =
		isEmailAttached || complete || showHub ? (
			<OwnersHub ownersData={ownersData} />
		) : (
			<UnlockOwnersHub
				ownersData={ownersData}
				onCreateAccount={() => {
					dispatch(
						trackOwnersEvent({
							eventName: vehicleProfileEvents.entryPointLocked.createAccountCtaEvent,
						})
					);

					setOpenModal(true);
				}}
			/>
		);

	return (
		<Container>
			<HeaderContainer>
				<HeaderBar />
				<VehicleInfo>
					<VehicleIconWrapper>
						<VehicleManufacturer make={vehicle?.response?.make} size={'32px'} color={'surface'} />
					</VehicleIconWrapper>
					<Typography $type={'Body.Large'} $align={'center'} $color={'textOnFill'} $bold>
						{vehicle?.response?.vrm}
					</Typography>
					<Typography $type={'Body.Medium'} $align={'center'} $marginBottom={'regular'} $color={'textOnFill'}>
						{vehicle?.response?.model}
					</Typography>
					<Button $type={'neutralMutedOnFill'} $size={'small'} onClick={viewDetails}>
						{'View details'}
					</Button>
				</VehicleInfo>
				<Borrower>
					<Typography $type={'Label.Medium'} $color={'textOnFillMuted'}>
						{`${ownersData?.profile?.personalName} is driving 🚘 `}
					</Typography>
				</Borrower>
			</HeaderContainer>

			<Content>
				<PolicyCard>
					<PolicyTimer startDate={ownersData?.policy?.startDate} endDate={ownersData?.policy?.endDate} />
					{component}
				</PolicyCard>

				<ComingSoon />
				<VehicleDetails vehicle={vehicle} ownersData={ownersData} open={open} closeModal={setOpen} />
				<ConfirmEmailVariant
					open={openModal}
					setOpen={setOpenModal}
					ownersData={ownersData}
					variantConsentEvent={entryPointLocked.variantConsentEvent}
					variantScreenName={entryPointLocked.variantScreenName}
					updatePage={updatePageAndDispatchSuccessEvent}
					get={getVariantContent('variants.policy_docs')}
				/>
			</Content>
		</Container>
	);
};

const Container = styled.div`
	height: 100vh;
`;

const HeaderContainer = styled.div`
	background: radial-gradient(
			50% 100% at 50% 100%,
			#a644e2 5.93%,
			rgba(101, 68, 226, 0.3) 59.9%,
			rgba(101, 68, 226, 0) 100%
		),
		${p => p.theme.ui.secondaryBackground};
	padding: 0 16px 48px;
	display: flex;
	flex-direction: column;
	div:nth-child(1) {
		border: none;

		svg {
			path {
				fill: ${p => p.theme.ui.blankFill};
			}
		}
	}
`;

const Borrower = styled.div`
	align-self: center;
	width: 343px;
`;

const Content = styled.div`
	background: ${p => p.theme.ui.background};
	display: flex;
	flex-direction: column;
	align-items: center;

	${media.greaterThan('tablet')`
		height: 75vh;
	`}
`;

const PolicyCard = styled.div`
	background: ${p => p.theme.ui.surface};
	width: 343px;
	border-radius: ${p => p.theme.borderRadius.large};
	padding: 24px 16px;
	margin-top: -32px;

	div:last-child {
		text-align: center;

		& > a {
			text-decoration: none;
		}
	}
`;

const VehicleInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: ${p => p.theme.spacing.extraLarge};
	margin-top: 46px;
`;

const VehicleIconWrapper = styled.div`
	border-radius: 50%;
	border: 2px solid ${p => p.theme.ui.surfaceFill};
`;

export default Locked;
