import React, { useEffect, useState } from 'react';
import { useFrontload } from 'react-frontload';
import { useDispatch } from 'react-redux';
import { sentenceCase } from 'change-case';
import styled from 'styled-components';

import CareerCard from '../molecules/CareerCard';
import Typography from '~lib/frontend/design-system/components/Typography';
import { getEnvironment } from '~lib/frontend/helpers/environment';
import useSelectorOrDefault from '~lib/frontend/hooks/use-selector-or-default';
import { GetterType } from '~lib/frontend/types/content';
import { TypedObject } from '~lib/shared/helpers/typed';
import { TabBar, TabItem } from '~website/components/molecules/Tabs';
import { getFrontloadClient } from '~website/prerender/frontload-client';
import { ApplicationState } from '~website/store';
import { getCareers } from '~website/store/careers/actions';
import { listCareersFrontload } from '~website/store/careers/sagas/get-careers';
import { Career, Careers } from '~website/store/careers/types';

interface OpenRolesProps {
	title: string;
	body?: string;
	filter?: string;
}

const OpenRoles: React.FCWithChildren<OpenRolesProps> = ({ title, body, filter }) => {
	const dispatch = useDispatch();
	const [tab, setTab] = useState(() => (filter ? filter : 'All teams'));
	const careerStore = useSelectorOrDefault((s: ApplicationState) => s.internal.careers.careers, { fetching: true });

	useFrontload('service-career', async () => {
		await listCareersFrontload(getFrontloadClient(), dispatch);
	});

	useEffect(() => {
		if (getEnvironment() === 'browser') dispatch(getCareers.request());
	}, [dispatch]);

	const tabs = getTabContent(careerStore.response || [], filter);

	useEffect(() => {
		if (Object.keys(tabs).length === 1 && careerStore.response?.length && filter) setTab('All teams');
	}, [careerStore.response, filter, tabs]);

	if (careerStore.error || !careerStore.response) return null;

	const listing = careerStore.response.filter(c => filterCareers(c, tab, filter));

	return (
		<Wrapper id={'open-roles'}>
			<TitleContainer>
				<Typography $type={'Heading.Large'} $color={'textOnSurfaceBackground'}>
					{title}
				</Typography>
				{body && (
					<Typography $type={'Body.Large'} $color={'textOnSurfaceBackgroundMuted'} $marginTop={'24px'}>
						{body}
					</Typography>
				)}
			</TitleContainer>

			<TabBar $items={TypedObject.keys(tabs).length}>
				{TypedObject.keys(tabs).map(k => (
					<TabItem active={tab === k} key={k} onClick={() => setTab(k)}>
						<Typography $type={'Label.Large'} $color={'inherit'}>
							{k}
						</Typography>
						<Typography $type={'Label.Large'} $color={'inherit'}>
							{tabs[k]}
						</Typography>
					</TabItem>
				))}
			</TabBar>

			<CareersWrapper>
				{listing.map(career => (
					<CareerCard key={`${career.id}:${career.title}`} career={career} />
				))}
			</CareersWrapper>
		</Wrapper>
	);
};

function getTabContent(careers: Careers, filter: string) {
	const mapping: Record<string, number> = { 'All teams': careers.length };

	for (const career of careers) {
		const dept = career.department;

		if (!dept) continue;

		if (filter && !career.title.toLowerCase().includes(filter.toLowerCase())) continue;

		const friendlyDept = filter ? filter : sentenceCase(dept);

		if (mapping[friendlyDept]) mapping[friendlyDept] += 1;
		else mapping[friendlyDept] = 1;
	}

	return mapping;
}

function filterCareers(career: Career, tab: string, filter?: string) {
	if (tab === 'All teams') return true;

	if (filter && career.title.toLowerCase().includes(filter.toLowerCase())) return true;

	return career.department === tab;
}

const Wrapper = styled.div``;

const TitleContainer = styled.div`
	margin: 0 auto;
	margin-bottom: 64px;
	max-width: 512px;
	text-align: center;
`;

const CareersWrapper = styled.div`
	margin: 30px 0;
`;

export const contentAdapter = (get: GetterType): OpenRolesProps => {
	const title = get('title');
	const body = get('body');
	const filter = get('filter');

	return {
		title,
		body,
		filter,
	};
};

export default OpenRoles;
