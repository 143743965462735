import styled, { css } from 'styled-components';
const getSize = (size) => {
    if (size === 'small')
        return 4;
    if (size === 'large')
        return 8;
    return 6;
};
const getColor = (color, ui) => {
    switch (color) {
        case 'success':
            return ui.successFill;
        case 'danger':
            return ui.dangerFill;
        case 'primary':
            return ui.primaryFill;
        case 'onfill':
            return ui.blankFill;
        case 'gradient':
        default:
            // SC: todo, find a better way to handle gradients in out codebase.
            return 'linear-gradient(270deg, #C202D9 0%, #6151FF 100%);';
    }
};
const ProgressBar = styled.progress `
	appearance: none;
	border: none;
	display: block;
	width: 100%;
	height: ${p => getSize(p.$size)}px;
	border-radius: ${p => (p.$corner === 'rounded' ? '3px' : 'none')};
	overflow: hidden;

	/* Firefox only, background */
	background-color: ${p => (p.$color === 'onfill' ? p.theme.ui.borderSeparatorOnFill : p.theme.ui.borderSeparator)};

	/* Chrome, background */
	::-webkit-progress-bar {
		background-color: ${p => p.$color === 'onfill' ? p.theme.ui.borderSeparatorOnFill : p.theme.ui.borderSeparator};

		${p => p.$corner === 'rounded' &&
    css `
				border-radius: 2px;
			`};
	}

	/* Chrome, progress bar */
	::-webkit-progress-value {
		background: ${p => getColor(p.$color, p.theme.ui)};
		-webkit-transition: all 0.2s;
		transition: all 0.2s;

		${p => p.$corner === 'rounded' &&
    css `
				border-top-right-radius: 2px;
				border-bottom-right-radius: 2px;
			`};
	}

	/* Firefox, progress bar */
	::-moz-progress-bar {
		background: ${p => getColor(p.$color, p.theme.ui)};
		transition: all 0.2s;

		${p => p.$corner === 'rounded' &&
    css `
				border-top-right-radius: 2px;
				border-bottom-right-radius: 2px;
			`};
	}
`;
export default ProgressBar;
