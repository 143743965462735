import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../api';
import { createVehicleProfile } from '../actions';
import { VehicleProfileActionTypes } from '../types';
export default function* watcherCreateVehicleProfile() {
    yield takeEvery(VehicleProfileActionTypes.CREATE_VEHICLE_PROFILE, workerCreateVehicleProfile);
}
function* workerCreateVehicleProfile({ payload, meta }) {
    const client = yield getContext('api');
    const { ident } = meta;
    try {
        const response = yield call(api.createVehicleProfile, client, payload);
        yield put(createVehicleProfile.success(ident, response));
    }
    catch (error) {
        yield put(createVehicleProfile.failure(ident, error));
    }
}
