import React from 'react';
import numeral from 'numeral';

export function monzoMoney(inputPennies: number, ignorePennies = false) {
	const parsed = parsePennies(inputPennies);

	if (!parsed) return 'NaP';

	const { isNegative, pounds, pennies } = parsed;

	return (
		<span>
			<small>{isNegative ? '-£' : '£'}</small>
			{numeral(pounds).format('0,0')}
			{!ignorePennies && <small>{`.${`0${pennies}`.substr(-2)}`}</small>}
		</span>
	);
}

export function plainMoney(inputPennies: number, ignorePennies = false) {
	const parsed = parsePennies(inputPennies);

	if (!parsed) return 'NaP';

	const { isNegative, pounds, pennies } = parsed;

	const parts = [isNegative ? '-£' : '£', numeral(pounds).format('0,0')];

	if (!ignorePennies) parts.push(`.${`0${pennies}`.substr(-2)}`);

	return parts.join('');
}

function parsePennies(inputPennies: number) {
	if (typeof inputPennies !== 'number' || !Number.isInteger(inputPennies)) return null;

	let pennies = inputPennies % 100;
	let pounds = (inputPennies - pennies) / 100;
	const isNegative = pounds < 0 || pennies < 0;

	if (isNegative) {
		pounds = Math.abs(pounds);
		pennies = Math.abs(pennies);
	}

	return {
		isNegative,
		pounds,
		pennies,
	};
}
