import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';

import vehicleProfileEvents from './events';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import { createAwareUrl, getSafeRedirectUri } from '~lib/frontend/helpers/uri';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import HeaderBar from '~website/components/atoms/HeaderBar';
import SplitUI, { ContentContainer } from '~website/components/SplitUI';
import { trackOwnersEvent, trackOwnersScreenView } from '~website/features/analytics/store/actions';
import useCreateAccount from '~website/hooks/use-create-account';
import useQuery from '~website/hooks/use-query';

const CreateAccount: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const history = useHistory();
	const location = useLocation();
	const query = useQuery();
	const splashImage = useDesignSystemAsset({ type: 'background', value: 'car_rocket.png' });

	const onSuccess = () => {
		const redirectUri = (location.state as Record<string, string>)?.redirectUri;

		if (!redirectUri) return;

		history.push(createAwareUrl(getSafeRedirectUri(redirectUri)), { complete: true });
	};

	const { loading, onClick, error } = useCreateAccount({ email, onSuccess });

	const trackCTA = () =>
		dispatch(
			trackOwnersEvent({
				eventName: vehicleProfileEvents.entryPointLandingPage.createAccountPageCtaEvent,
				cuvvaSource: query.get('cuvva_source'),
			})
		);

	const createAccount = () => {
		trackCTA();
		onClick();
	};

	useEffect(() => {
		dispatch(
			trackOwnersScreenView({
				screenName: vehicleProfileEvents.entryPointLandingPage.createAccountPage,
				cuvvaSource: query.get('cuvva_source'),
			})
		);
	}, [dispatch, query]);

	return (
		<SplitUI splashImage={splashImage}>
			<HeaderBar />
			<ContentContainer>
				<BodyContent>
					<Typography
						$type={'Heading.Medium'}
						$color={'textOnSurfaceBackground'}
						$marginBottom={'large'}
						$align={'center'}
					>
						{'Create an account'}
					</Typography>
					<Typography
						$type={'Body.Medium'}
						$color={'textOnSurfaceBackground'}
						$marginBottom={'extraLarge'}
						$align={'center'}
					>
						{'What’s your email?'}
					</Typography>
					<InputDecorator error={Boolean(error.error)}>
						<TextInput
							type={'email'}
							value={email}
							onChange={e => setEmail(e.target.value)}
							onKeyDown={e => {
								if (e.key === 'Enter') createAccount();
							}}
						/>
						{Boolean(error.error) && error.errorMessage}
					</InputDecorator>

					<Margin $marginTop={error ? 'large' : void 0}>
						<Button
							disabled={!email}
							onClick={createAccount}
							$type={'primary'}
							$size={'large'}
							$stretch
							$loading={loading}
						>
							{'Get started'}
						</Button>
					</Margin>
				</BodyContent>
			</ContentContainer>
		</SplitUI>
	);
};

const BodyContent = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 800px;
	width: 100%;

	> * {
		width: 100%;
	}
`;

export default CreateAccount;
