import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';
import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';
import { getEnvironment } from '~lib/frontend/helpers/environment';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import useSegment from '~lib/frontend/hooks/use-segment';
import useSelectorOrDefault from '~lib/frontend/hooks/use-selector-or-default';
import Container from '~website/components/atoms/Container';
import NewsItem from '~website/components/atoms/NewsItem';
import { ApplicationState } from '~website/store';
import { listCuvvaPeeps } from '~website/store/about/actions';

const MainHeader = styled.div`
	position: relative;
	padding: 100px 0;
	margin: 0 auto;
	text-align: center;

	@media (max-width: 767px) {
		padding: 0;
	}
`;

const WrapperList = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-column-gap: ${p => p.theme.spacing.extraLarge};
	grid-row-gap: ${p => p.theme.spacing.extraLarge};

	margin: 0 auto;
	max-width: 1280px;
	box-sizing: content-box;
	${p => safeAreaPadded(p.theme.spacing.large, '24px')}

	&>* {
		grid-column-start: span 2;
	}

	& > :nth-child(1) {
		grid-column-start: 1;
		grid-column-end: 4;
	}
	& > :nth-child(2) {
		grid-column-start: 4;
		grid-column-end: 7;
	}

	@media (max-width: 767px) {
		& > *,
		& > *:nth-child(2) {
			grid-column: span 3;
		}

		& > *:nth-child(1) {
			grid-column: span 6;
		}
	}

	@media (max-width: 550px) {
		& > *,
		& > *:nth-child(1),
		& > *:nth-child(2) {
			grid-column-start: span 6;
			grid-column-end: inherit;
		}
	}
`;

const SeeMoreWrap = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: calc(${p => p.theme.spacing.extraLarge} * 2) auto ${p => p.theme.spacing.extraLarge} auto;
	cursor: pointer;
`;

const Illustration = styled.img`
	margin: 0 auto;
	width: 175px;
`;

const News: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const newsSegment = useSegment('website_news');
	const [showMore, setShowMore] = useState<number>(14);
	const isBTR = getEnvironment() === 'server';

	const changeValue = () => setShowMore(showMore + 15);

	useEffect(() => {
		dispatch(listCuvvaPeeps.request());
	}, [dispatch]);

	const notebookAsset = useDesignSystemAsset({ type: 'illustration', value: 'il_notebook.svg' });

	const staff = useSelectorOrDefault((s: ApplicationState) => s.platform.staff.listStaffPublic.response, []);

	const newsIdToNews: Record<string, Record<string, string>> = useMemo(() => {
		if (!newsSegment?.content) return {};

		return Object.keys(newsSegment.content).reduce((acc, val) => {
			const content = newsSegment.content[val];

			return {
				...acc,
				[content.id]: content,
			};
		}, {});
	}, [newsSegment]);

	const listOfNews = Object.keys(newsIdToNews)
		.sort((a, b) => (a > b ? -1 : 1))
		.slice(0, showMore)
		.map((k, i) => (
			<NewsItem
				key={`${k}:${i}`}
				altMain={newsIdToNews[k].description}
				author={staff.find(s => s.id === newsIdToNews[k].user_id)}
				imageLoad={'eager'}
				imgMain={newsSegment?.assets?.[newsIdToNews?.[k]?.image_asset]?.content}
				postDate={newsIdToNews[k].post_date}
				tagMain={newsIdToNews[k].category}
				textMain={newsIdToNews[k].title}
				url={newsIdToNews[k].url}
			/>
		));

	return (
		<React.Fragment>
			<Helmet defer>
				<title>{'The blog: news, insurance insights and behind the scenes | Cuvva'}</title>
				<meta
					name={'description'}
					content={
						"Welcome to the Cuvva blog. Here you'll find product updates, behind-the-scenes stories and insurance guides."
					}
				/>
				<meta property={'og:locale'} content={'en_GB'} />
				<meta property={'og:type'} content={'website'} />
				<meta property={'og:title'} content={'The blog: news, insurance insights and behind the scenes'} />
				<meta
					property={'og:description'}
					content={
						"Welcome to the Cuvva blog. Here you'll find product updates, behind-the-scenes stories and insurance guides."
					}
				/>
				<meta property={'og:url'} content={'https://www.cuvva.com/news'} />
				<meta property={'og:site_name'} content={'Cuvva'} />
				<meta property={'og:image'} content={'/static/images/share/shared-thumbnail.png'} />
				<meta property={'og:image:secure_url'} content={'/static/images/share/shared-thumbnail.png'} />
				<meta property={'og:image:width'} content={'1200'} />
				<meta property={'og:image:height'} content={'630'} />
				<meta property={'og:image:alt'} content={'shared-thumbnail'} />
				<meta name={'twitter:card'} content={'summary_large_image'} />
				<meta
					name={'twitter:description'}
					content={
						"Welcome to the Cuvva blog. Here you'll find product updates, behind-the-scenes stories and insurance guides."
					}
				/>
				<meta name={'twitter:title'} content={'The blog: news, insurance insights and behind the scenes'} />
				<meta name={'twitter:site'} content={'@Cuvva'} />
				<meta name={'twitter:image'} content={'/static/images/share/reviews.svg'} />
				<meta name={'twitter:creator'} content={'@cuvva'} />
				<link rel={'canonical'} href={'https://www.cuvva.com/news'} />
			</Helmet>
			<Container>
				<MainHeader>
					<Illustration src={notebookAsset} />
					<Typography $color={'textOnSurfaceBackground'} $type={'Heading.Large'} as={'h1'}>
						{'The Cuvva blog'}
					</Typography>
				</MainHeader>
				<WrapperList>{listOfNews}</WrapperList>

				{isBTR && (
					<noscript>
						<WrapperList>{listOfNews}</WrapperList>
					</noscript>
				)}
				{!isBTR && Object.keys(newsIdToNews).length >= showMore && (
					<SeeMoreWrap>
						<Typography
							$color={'textOnSurfaceBackground'}
							$type={'Body.Medium'}
							$bold
							onClick={() => changeValue()}
						>
							{'See more posts'}
						</Typography>
					</SeeMoreWrap>
				)}
			</Container>
		</React.Fragment>
	);
};

export default News;
