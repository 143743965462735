import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '~lib/frontend/design-system/components/button/Button';
import { trackOwnersEvent } from '~website/features/analytics/store/actions';

interface SignalInterestCTAsProps {
	optinEvent: string;
	optoutEvent: string;
	closeModal: () => void;
}

const SignalInterestCTAs: React.FCWithChildren<SignalInterestCTAsProps> = props => {
	const { optinEvent, optoutEvent, closeModal } = props;
	const dispatch = useDispatch();

	const onClick = (eventName: string) => {
		dispatch(
			trackOwnersEvent({
				eventName,
			})
		);

		closeModal();
	};

	return (
		<React.Fragment>
			<Button $type={'primary'} $size={'large'} $stretch onClick={() => onClick(optinEvent)}>
				{"I can't wait"}
			</Button>
			<Button $type={'primaryMuted'} $size={'large'} $stretch onClick={() => onClick(optoutEvent)}>
				{"It's not for me"}
			</Button>
		</React.Fragment>
	);
};

export default SignalInterestCTAs;
