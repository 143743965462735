import { all } from 'redux-saga/effects';

import generateReferralLink from './generate-referral-link';
import setRenewalDate from './set-renewal-date';
import sharedQuotePreferences from './shared-quote-preferences';
import submitRenewalReminder from './submit-reminder';

export default function* referral() {
	yield all([setRenewalDate, sharedQuotePreferences, submitRenewalReminder, generateReferralLink]);
}
