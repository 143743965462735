import styled from 'styled-components';
const Switch = styled.input `
	&[type='checkbox'] {
		width: 0;
		height: 0;
		visibility: hidden;

		& + label {
			display: flex;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
			width: 42px;
			height: 23px;
			position: relative;
			border-radius: 210px;
			transition: background-color 0.2s;
			background: ${p => (p.$withIcons ? p.theme.ui.dangerFill : p.theme.ui.neutralFillMinorMuted)};

			span {
				content: '';
				position: absolute;
				top: 2px;
				width: 20px;
				height: 19px;
				border-radius: 50%;
				transition: all 0.2s;
				background: white;
				box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
				left: 2px;
				transform: translateX(0);
			}
		}

		&:checked + label {
			background: ${p => p.theme.ui[p.$accent]};

			span {
				left: calc(100% - 2px);
				transform: translateX(-100%);
				box-shadow:
					0px 3px 1px rgba(0, 0, 0, 0.0510643),
					0px 2px 2px rgba(0, 0, 0, 0.1),
					0px 3px 3px rgba(0, 0, 0, 0.05);
			}
		}
	}
`;
export default Switch;
