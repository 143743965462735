import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cher from '@cuvva/cher';

import IllustrationWrapper from '../../../atoms/IlloWrapper';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import { sanitiseEmailAddress } from '~lib/frontend/helpers/email';
import { sanitisePhoneNumber } from '~lib/frontend/helpers/phone-number';
import { trackPayOnWebScreenView } from '~website/features/analytics/store/actions';
import useErrorMapper from '~website/hooks/use-error-mapper';

export interface VerifyPrimaryIdentifierProps {
	type: 'email' | 'mobile_phone';
	error: Cher;
	initialValue: string;
	loading: boolean;
	onProceed: (identifier: string) => void;
}

const VerifyPrimaryIdentifier = (props: VerifyPrimaryIdentifierProps) => {
	const { type, initialValue, error, loading, onProceed } = props;
	const { getMappedError } = useErrorMapper();

	const mappedError = getMappedError(error);

	const [value, setValue] = useState(initialValue);
	const dispatch = useDispatch();

	const proxiedSetValue = (inputValue: string) => {
		if (type === 'mobile_phone') setValue(sanitisePhoneNumber(inputValue));
		else setValue(sanitiseEmailAddress(inputValue));
	};

	const illustration = type === 'email' ? 'il_envelope01.svg' : 'il_phone_question_mark.svg';
	const altDescription = type === 'email' ? 'email-envelope-illustration' : 'phone-envelope-illustration';
	const pageTitle = type === 'email' ? 'Let’s start with your email' : 'Verify your mobile number';
	const pageDescription =
		type === 'email'
			? 'We’ll send you a code to verify your email.'
			: 'We’ll only get in touch if it’s something important about your policy. We’ll never sell your data.';

	const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();

		onProceed(value);
	};

	useEffect(() => {
		const pageType = type === 'email' ? 'email' : 'mobileNumber';

		dispatch(trackPayOnWebScreenView({ screenName: `verification_${pageType}` }));
	}, [dispatch, type]);

	const inputRef = useRef<HTMLInputElement>();

	useEffect(() => {
		if (inputRef?.current) inputRef.current.focus();
	}, []);

	useEffect(() => {
		if (mappedError && inputRef?.current) inputRef.current.focus();
	}, [mappedError]);

	return (
		<React.Fragment>
			<IllustrationWrapper filename={illustration} altDescription={altDescription} />

			<Margin $marginTop={'large'} $marginBottom={'extraLarge'}>
				<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'} $align={'center'}>
					{pageTitle}
				</Typography>

				<Typography
					$type={'Body.Medium'}
					$color={'textOnSurfaceBackground'}
					$align={'center'}
					$marginTop={'large'}
				>
					{pageDescription}
				</Typography>
			</Margin>

			<form onSubmit={handleFormSubmit}>
				<InputDecorator label={type === 'email' ? 'Email address' : 'Mobile phone number'} error={mappedError}>
					<TextInput
						ref={inputRef}
						type={type === 'email' ? 'email' : 'tel'}
						autoComplete={type === 'email' ? 'email' : 'tel'}
						value={value ?? ''}
						onChange={e => proxiedSetValue(e.target.value)}
						onKeyDown={e => {
							if (Boolean(value) && e.key === 'Enter') onProceed(value);
						}}
					/>
				</InputDecorator>

				<Margin $marginTop={'extraLarge'}>
					<Button
						$stretch
						$size={'large'}
						type={'button'}
						$loading={loading}
						onClick={() => onProceed(value)}
					>
						{type === 'email' ? 'Send me a code' : 'Text me a code'}
					</Button>
				</Margin>
			</form>
		</React.Fragment>
	);
};

export default VerifyPrimaryIdentifier;
