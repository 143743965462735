import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import { oneLink, reapplyParamsIfExternalUrl } from '~website/helpers/source-tracking';

interface ContinueInAppLinkProps {
	url?: string;
	text?: string;
	style?: 'button' | 'link';
	applyParams?: boolean;
}

const ContinueInAppLink: React.FCWithChildren<ContinueInAppLinkProps> = ({
	url = oneLink.general,
	text = 'Continue in app',
	style = 'button',
	applyParams = true,
}) => {
	const sourceTracking = useSelector(s => s.internal.analytics.sourceTracking);
	const location = useLocation();
	const urlWithParams = reapplyParamsIfExternalUrl(url, sourceTracking, location.pathname);

	return style === 'button' ? (
		<Button
			$stretch
			$size={'large'}
			as={Anchor}
			// @ts-ignore
			to={applyParams ? urlWithParams : url}
			rel={'nofollow'}
		>
			{text}
		</Button>
	) : (
		<Typography $type={'Body.Medium'} $bold>
			<Anchor to={applyParams ? urlWithParams : url} rel={'nofollow'}>
				{text}
			</Anchor>
		</Typography>
	);
};

export default ContinueInAppLink;
