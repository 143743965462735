import { call, getContext, put } from 'redux-saga/effects';

import { analyticsTrackingDebug } from '../actions';
import IterableSDK, { CustomEvent } from '~lib/frontend/api/iterable-sdk';
import { getLogger } from '~lib/shared/logger';

function* sendToIterable(event: CustomEvent) {
	const client: IterableSDK = yield getContext('iterableClient');

	try {
		yield call([client, client.trackEvent], event);
	} catch (error) {
		getLogger().warning('iterable event failed', { error });
	}

	yield put(
		analyticsTrackingDebug({
			destination: 'iterable',
			payload: {
				event: 'screen_viewed',
				payload: event,
			},
		})
	);
}

export default sendToIterable;
