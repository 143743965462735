import styled from 'styled-components';
export const LinedUpButtonArea = styled.footer `
	text-align: right;

	display: flex;
	flex-direction: row-reverse;

	& > * {
		flex: 0;
	}

	& > :nth-child(2) {
		margin-right: ${p => p.theme.spacing.regular};
	}

	& > :nth-child(3) {
		margin-right: auto;
	}
`;
export const StackedButtonArea = styled.footer `
	text-align: right;

	display: flex;
	flex-direction: column;

	& > * {
		flex: 1;
	}

	& > :nth-child(2) {
		margin-top: ${p => p.theme.spacing.regular};
	}

	& > :nth-child(3) {
		margin-top: 24px;
	}
`;
export const ActionsSpacer = styled.div `
	margin-top: 24px;
`;
