import Cher from '@cuvva/cher';
import { call, getContext, put } from 'redux-saga/effects';

import { reverseGeocoding } from '..';
import { createTakeLatestSagaSet } from '~lib/shared/redux/sagas';

interface WithPostcode {
	postcode: string;
}

export default createTakeLatestSagaSet(reverseGeocoding.actions.request, function* reverseGeocodingSaga(action) {
	const postcodeApi = yield getContext('postcodeApi');
	const { latitude, longitude } = action.payload;
	const { ident } = action.meta;

	try {
		const res: WithPostcode = yield call([postcodeApi, postcodeApi.reverseGeocode], latitude, longitude);

		yield put(
			reverseGeocoding.actions.success(ident, {
				latitude,
				longitude,
				postcode: res.postcode,
			})
		);
	} catch (error) {
		yield put(reverseGeocoding.actions.failure(ident, Cher.coerce(error)));
	}
});
