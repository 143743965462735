import React from 'react';

import { AdapterProps } from '../types';
import WorkplaceVibes, { contentAdapter } from '~website/features/careers/components/organisms/WorkplaceVibes';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => <WorkplaceVibes {...contentAdapter(getter)} />;

export default {
	type: 'careers_workplace_vibes',
	adapter,
	builder: {
		fields: {
			title: { type: 'string' },
			body: { type: 'string' },
		},
		default: {
			title: 'Workplace vibes',
			body: 'Whether from home or in the office. Everyone gets a home workspace budget.',
		},
	},
};
