import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ksuid from '@cuvva/ksuid';

import { reverseGeocoding } from '../store';
import usePostcodeLocationSearch from './use-postcode-location-search';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { Location } from '~lib/platform/ltm/types';
import { AsyncState } from '~lib/shared/redux/types/state';

interface CachedResult {
	postcode: string;
	latitude: number;
	longitude: number;
}

function buildResult<T extends CachedResult = CachedResult>(result: T) {
	return {
		postcode: result.postcode,
		latitude: result.latitude,
		longitude: result.longitude,
	};
}

const generateRequestId = () => ksuid.generate('request').toString();

const localCache: CachedResult[] = [];

const useReversiblePostcodeLookup = () => {
	const dispatch = useDispatch();
	const { result: postcodeOwnResult, onChange: onPostcodeSearch } = usePostcodeLocationSearch();
	const postcodeOwnStatus = useAsyncStateStatus(postcodeOwnResult).status;
	const [postcodeResult, setPostcodeResult] = useState<AsyncState<CachedResult>>();
	const postcodeResultStatus = useAsyncStateStatus(postcodeResult).status;

	const [reverseGeoReqId, setReverseGeoReqId] = useState(generateRequestId);
	const reverseGeocodingState = useSelector(s => s.internal.quote.reverseGeocoding[reverseGeoReqId]);
	const reverseGeocodingStatus = useAsyncStateStatus(reverseGeocodingState).status;
	const [reverseGeocodingResult, setReverseGeocodingResult] = useState<AsyncState<CachedResult>>();
	const reverseGeocodingResultStatus = useAsyncStateStatus(reverseGeocodingResult).status;

	const onLookupPostcode = useCallback(
		(postcode: string) => {
			const found = localCache.find(c => c.postcode === postcode);

			if (found) {
				setPostcodeResult({ fetching: false, response: found });

				return;
			}

			setPostcodeResult({ fetching: true });
			onPostcodeSearch(postcode);
		},
		[onPostcodeSearch]
	);

	const onLookupCoords = useCallback(
		(coords: Location) => {
			const found = localCache.find(c => c.latitude === coords.latitude && c.longitude === coords.longitude);

			if (found) {
				setReverseGeocodingResult({ fetching: false, response: found });

				return;
			}

			const requestId = generateRequestId();

			setReverseGeoReqId(requestId);
			setReverseGeocodingResult({ fetching: true });
			dispatch(
				reverseGeocoding.actions.request({
					requestId,
					...coords,
				})
			);
		},
		[dispatch]
	);

	// postcode lookup
	useEffect(() => {
		if (postcodeOwnStatus !== 'response') {
			setPostcodeResult(postcodeOwnResult);

			return;
		}

		const response: CachedResult = buildResult(postcodeOwnResult.response!);

		localCache.unshift(response);

		setPostcodeResult({ fetching: false, response });
	}, [postcodeOwnStatus, postcodeOwnResult]);

	// reverse geolocation lookup
	useEffect(() => {
		if (reverseGeocodingStatus !== 'response') {
			setReverseGeocodingResult(reverseGeocodingState);

			return;
		}
		const response: CachedResult = buildResult(reverseGeocodingState.response!);

		localCache.unshift(response);
		setReverseGeocodingResult({ fetching: false, response });
	}, [reverseGeocodingStatus, reverseGeocodingState]);

	return {
		onLookupPostcode,
		onLookupCoords,

		postcodeResult,
		postcodeResultStatus,

		reverseGeocodingResult,
		reverseGeocodingResultStatus,
	};
};

export default useReversiblePostcodeLookup;
