import React from 'react';
import { useSelector } from 'react-redux';
import Cher from '@cuvva/cher';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useOnPageError from '../../hooks/use-on-page-error';
import useVehicleProfileInternalChangeRequest from '../../hooks/use-vehicle-profile-internal-change-request';
import TrackedSelectInput from '../atoms/TrackedSelectInput';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import { getAbiCodesOrDefault } from '~website/helpers/quick-quote';

const notEmptyKeeperValidator = (value: string) => {
	if (!value) return new Cher('invalid');

	return void 0;
};

const RegisteredKeeper: React.FunctionComponent = () => {
	const g = useContentKey('strings');

	const get: GetterType = (key, fallback) =>
		g(`motor.long_term.questions.vehicle.registered_keeper.${key}`, fallback);

	const { value, validation, onChange } = useVehicleProfileInternalChangeRequest(
		'registeredKeeperCode',
		void 0,
		notEmptyKeeperValidator
	);

	const abiCodes = useSelector(s => s.platform.codeMapping.abiCodes);
	const error = useOnPageError(validation, 'registered_keeper_input');

	const ownerCodes = getAbiCodesOrDefault(abiCodes).ownerCodes?.map(({ code, sentenceForm }) => ({
		label: sentenceForm,
		value: code,
	}));

	return (
		<React.Fragment>
			<QuoteInputDecorator
				label={get('question', 'Who will be using the car?')}
				help={get(
					'detail',
					"This means the name on the V5C Vehicle Registration Certificate—please check if you're not sure"
				)}
				error={error}
			>
				<TrackedSelectInput
					value={value}
					options={ownerCodes}
					placeholder={'Choose an option'}
					onChange={change => onChange(change.value)}
					data-event-onfocus-name={'dropdown_selected'}
					data-event-onfocus-value={'registered_keeper'}
					data-event-onchange-name={'registered_keeper_selected'}
				/>
			</QuoteInputDecorator>
		</React.Fragment>
	);
};

export default RegisteredKeeper;
