import DOMPurify from 'dompurify';
const sanitizerOptions = {
    ALLOWED_TAGS: ['b', 'strong', 'u', 'i', 'em'],
    ALLOWED_ATTR: [],
};
export default function sanitizeHtml(input) {
    if (!input)
        return input;
    return DOMPurify.sanitize(input, sanitizerOptions);
}
