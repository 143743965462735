import React from 'react';
import styled from 'styled-components';

import { MeCardBody, MeCardWrapper } from '../atoms/Card';
import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { SubSteps } from '~website/components/atoms/SubSteps';

const NoSubscription = () => {
	const steeringWheel = useDesignSystemAsset({ type: 'illustration', value: 'il_steering_wheel.svg' });

	return (
		<MeCardWrapper>
			<MeCardBody>
				<IlloWrapper>
					<picture>
						<img src={steeringWheel} alt={'steering wheel'} />
					</picture>
				</IlloWrapper>

				<Typography
					$marginTop={'large'}
					$type={'Heading.Small'}
					$align={'center'}
					$color={'textOnSurfaceBackground'}
				>
					{'You don’t have any policies yet'}
				</Typography>

				<SubSteps>
					<li>
						<Typography $type={'Body.Large'} $bold $color={'textOnSurfaceBackground'}>
							{'Find your car'}
						</Typography>
						<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
							{'Enter your registration plate'}
						</Typography>
					</li>
					<li>
						<Typography $type={'Body.Large'} $bold $color={'textOnSurfaceBackground'}>
							{'Pick a policy'}
						</Typography>
						<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
							{'Get insured from 1 hour up to 28 days'}
						</Typography>
					</li>
					<li>
						<Typography $type={'Body.Large'} $bold $color={'textOnSurfaceBackground'}>
							{'Check your cover and pay'}
						</Typography>
						<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
							{'And that’s it, you’re covered!'}
						</Typography>
					</li>
				</SubSteps>

				<Button
					$stretch
					type={'button'}
					$type={'primary'}
					as={Anchor}
					// @ts-ignore
					href={'/vehicles'}
				>
					{'Get a quote'}
				</Button>
			</MeCardBody>
		</MeCardWrapper>
	);
};

export default NoSubscription;

export const IlloWrapper = styled.div`
	max-width: 100%;
	max-height: 100%;
	text-align: center;

	img {
		width: 120px;
		height: 120px;
		object-fit: contain;
	}
`;
