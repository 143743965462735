import styled from 'styled-components';

export const FadeVector = styled.div`
	position: relative;
	pointer-events: none;
	opacity: 0.25;
	filter: blur(45px);
	width: 1300px;
	margin: 0 auto;
	overflow: visible;

	&:after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		width: min(110vw, 1300px);

		clip-path: polygon(47% 31%, 100% 43%, 100% 100%, 0 100%, 0 0);
		height: 450px;
		background: ${p => p.theme.ui.primaryFill};
		opacity: 0.35;
		margin: 0 auto;
	}
`;

export const FadeWrap = styled.div`
	& > :not(${FadeVector}) {
		position: relative;
		z-index: 1;
	}
`;
