import React from 'react';
import { useSelector } from 'react-redux';

import YourLtmEstimate from './organisms/YourLtmEstimate';
import YourStmQuote from './organisms/YourStmQuote';

const LegacyQuotePage: React.FunctionComponent = () => {
	const { product } = useSelector(s => s.internal.quote.state);

	if (product === 'ltm') return <YourLtmEstimate />;

	return <YourStmQuote />;
};

export default LegacyQuotePage;
