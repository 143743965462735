import React, { useCallback, useEffect, useRef, useState } from 'react';

import { maskDate } from './DateUtils';
import TextInput, { TextInputProps } from '~lib/frontend/design-system/components/input/molecules/TextInput';

interface DateProps extends Omit<TextInputProps, 'onChange'> {
	value: string; // as YYYYY-MM-DD
	disabled: boolean;
	onChange: (value: string) => void; // will return value as YYYYY-MM-DD
}

const Date: React.FCWithChildren<DateProps> = props => {
	const { value, onChange, ...otherProps } = props;
	const [text, setText] = useState('');
	const ref = useRef<HTMLInputElement>();

	useEffect(() => {
		const text = value?.split('-').reverse().join('/');

		setText(text ?? '');
	}, [value]);

	const bubbleUp = useCallback(
		(date?: string | undefined) => {
			const change = (date ?? text)
				.split('/')
				.map(d => d.replace(/\D/g, ''))
				.reverse()
				.join('-');

			onChange(change as string);
		},
		[onChange, text]
	);

	const onBlur = () => bubbleUp();

	const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
		const backspace = e.key === 'Backspace';

		let maskedDate = maskDate(text);
		const slash = maskedDate.endsWith('/');

		if (backspace && slash) maskedDate = maskDate(text.slice(0, -1));

		setText(maskedDate);
	};

	const onDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const date = e.target.value;

		if (date.length > 10) return;

		// SC: Ideally we should't reach here to do cleaning of the string (as we can't stop the browser from
		// getting the new value, then cleaning it here will reset the selectionStart / selectionEnd refs)
		// but pasted strings and unforeseeable things might happen.
		const value = date.replace(/[^0-9/]/g, '').replace(/\/\//g, '/');

		setText(value);

		// Workaround for date parsing: this apparently works only on Safari
		// This is a trick to understand if the change event is coming from the autofill or not.
		if (!e.isTrusted && !e.nativeEvent.composed) bubbleUp(value);
	};

	return (
		<TextInput
			{...otherProps}
			value={text}
			maxLength={10}
			ref={ref}
			onChange={onDateChange}
			onKeyUp={onKeyUp}
			onBlur={onBlur}
			inputMode={'numeric'}
		/>
	);
};

export default Date;
