import React, { ReactNode, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Selectable } from '~lib/frontend/atoms';
import { ContentKey } from '~lib/frontend/contexts/content';
import Typography from '~lib/frontend/design-system/components/Typography';

const eventTime = (createdAt: string) => {
	const date = new Date(createdAt);
	const options: Intl.DateTimeFormatOptions = {
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
	};

	return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const AnalyticsDebugPopover: React.FunctionComponent = () => {
	const { showContentKey } = useContext(ContentKey);
	const trackingDebug = useSelector(s => s.internal.analytics.analyticsTrackingDebug);
	const ref = useRef<HTMLDivElement>(void 0);

	useEffect(() => {
		if (!ref?.current) return;

		ref.current.scrollTop = ref.current.scrollHeight;
	}, [trackingDebug, showContentKey]);

	if (!showContentKey) return null;

	return (
		<Wrapper ref={ref}>
			{trackingDebug.map((item, i) => (
				<div key={i}>
					<div>
						<Typography $type={'Body.Small'} as={'span'} $bold>
							{item.destination}
						</Typography>
						{Boolean(item.payload.event) && (
							<BodySmallEventName $type={'Body.Small'} as={'span'} $color={'textOnSurfaceBackground'}>
								{'('}
								<Selectable>{item.payload.event as ReactNode}</Selectable>
								{')'}
							</BodySmallEventName>
						)}
						<Typography $type={'Body.Small'} as={'span'} $color={'textOnSurfaceBackground'}>
							{eventTime(item.createdAt)}
						</Typography>
					</div>
					<Selectable>
						<Typography $type={'Body.Small'} as={'pre'} $color={'textOnSurfaceBackground'}>
							{JSON.stringify(item.payload)}
						</Typography>
					</Selectable>
				</div>
			))}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: absolute;
	overflow-y: auto;
	z-index: 50;
	top: 0;
	right: 0;
	width: 400px;
	padding: 10px;
	height: 300px;
	background: ${p => p.theme.ui.surfaceFill}EE;

	& > * {
		margin-bottom: 10px;
	}
`;

const BodySmallEventName = styled(Typography)`
	text-decoration: underline;
	opacity: 0.95;
`;

export default AnalyticsDebugPopover;
