import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as Alert from '~lib/frontend/design-system/components/dialog/molecules/Alert';

interface DlnAlreadyExistsProps {
	open: boolean;
	emailFound: boolean;
	error: string;
	onClose: () => void;
}

const DlnAlreadyExists: React.FCWithChildren<DlnAlreadyExistsProps> = props => {
	const { open, emailFound, error, onClose } = props;

	return (
		<Alert.Dialog
			open={open}
			title={'Looks like your licence is linked to another account'}
			onClose={onClose}
			centered
		>
			<Alert.Content>
				<SpacedContent>
					<div>{error}</div>

					{emailFound && (
						<div>{'If you don’t recognise this email or no longer have access you can get help.'}</div>
					)}
				</SpacedContent>
			</Alert.Content>
			<Alert.Actions>
				<Button $type={'primary'} onClick={onClose}>
					{'OK'}
				</Button>
				<Button
					as={Anchor}
					$type={'neutralMuted'}
					// @ts-ignore
					href={'mailto:support@cuvva.com'}
					className={'intercom-launcher'}
					onClick={onClose}
				>
					{'Contact us'}
				</Button>
			</Alert.Actions>
		</Alert.Dialog>
	);
};

const SpacedContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
`;

export default DlnAlreadyExists;
