import { put, select, take } from 'redux-saga/effects';

import * as actions from '../actions';
import { getSharedQuote } from '~lib/platform/social-driving/store/actions';
import { createTakeEverySagaSet, requestAndTake } from '~lib/shared/redux/sagas';
import { AsyncState } from '~lib/shared/redux/types/state';
import { ApplicationState } from '~website/store';
import { GetUserActionTypes } from '~website/store/auth/types';

export default createTakeEverySagaSet(actions.sharedQuotePreferences.request, function* sharedQuotePreferences(action) {
	const { ident } = action.meta;

	let userState: AsyncState<string> = yield select((state: ApplicationState) => state.internal.auth.user);

	if (userState.fetching || !userState.response) {
		yield take(GetUserActionTypes.IMPORT_REGISTER_USER_SUCCESS);

		userState = yield select((state: ApplicationState) => state.internal.auth.user);
	}

	try {
		yield requestAndTake(getSharedQuote.request({ key: action.payload.key }));
	} catch (error) {
		yield put(actions.sharedQuotePreferences.failure(ident, error));
	}
});
