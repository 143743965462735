import React, { Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { titleCase } from 'title-case';

import { ShowChecklist } from '../molecules/CardChecklist';
import { BuilderValue, ClickToAction } from '../types';
import {
	AddNewArrayItem,
	ChecklistItemWrapper,
	FieldWrap,
	MoreButtonsAdditionWrapper,
	PricingItemsWrapper,
	RemoveListItem,
	RemoveListItemInline,
} from './styled';
import { icons } from '~lib/frontend/design-system/assets/SvgIcon';
import Margin from '~lib/frontend/design-system/components/atoms/utils/Margin';
import Button from '~lib/frontend/design-system/components/button/Button';
import { ButtonTypes, ButtonVariants } from '~lib/frontend/design-system/components/button/types';
import CheckBox from '~lib/frontend/design-system/components/input/molecules/CheckBox';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import SelectInput from '~lib/frontend/design-system/components/input/molecules/SelectInput';
import TextAreaInput from '~lib/frontend/design-system/components/input/molecules/TextAreaInput';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import { assetTypes } from '~lib/frontend/types/content';
import { TypedObject } from '~lib/shared/helpers/typed';

interface FieldDefinition {
	type: string;
	[s: string]: any;
}

export const getField = (
	field: FieldDefinition,
	key: string,
	value: BuilderValue,
	onChange: (val: BuilderValue) => void
) => {
	if (field.type === 'string') {
		return (
			<InputDecorator label={`${titleCase(key)}:`} key={key}>
				<TextInput
					$size={'small'}
					key={key}
					type={'text'}
					value={value}
					onChange={e => onChange(e.target.value)}
				/>
			</InputDecorator>
		);
	}

	if (field.type === 'text') {
		return (
			<InputDecorator key={key} label={`${titleCase(key)}:`}>
				<TextAreaInput
					key={key}
					value={value}
					$size={'small'}
					rows={5}
					onChange={e => onChange(e.target.value)}
				/>
			</InputDecorator>
		);
	}

	if (field.type === 'boolean') {
		return (
			<CheckBox
				key={key}
				checked={value}
				onChange={e => onChange(e.target.checked)}
				label={`${titleCase(key)}:`}
				labelFirst
				stretch
			/>
		);
	}

	if (field.type === 'cta') {
		return (
			<React.Fragment key={key}>
				<Typography
					$type={'Heading.XSmall'}
					$marginTop={'extraLarge'}
					$marginBottom={'regular'}
					$color={'textOnSurfaceBackground'}
				>
					{'CTA'}
				</Typography>

				<InputDecorator label={'Text:'} key={key}>
					<TextInput
						$size={'small'}
						type={'text'}
						value={value?.text}
						onChange={e => onChange({ ...value, text: e.target.value })}
					/>
				</InputDecorator>

				<InputDecorator label={'URL:'} key={key}>
					<TextInput
						$size={'small'}
						type={'text'}
						value={value?.url}
						onChange={e => onChange({ ...value, url: e.target.value })}
					/>
				</InputDecorator>

				<InputDecorator label={'Button type:'} key={key}>
					<SelectInput
						$size={'small'}
						value={value?.type}
						onChange={e => onChange({ ...value, type: e.value })}
						options={ButtonTypes.map(type => ({ value: type, label: type }))}
						placeholder={'Select button type'}
					/>
				</InputDecorator>

				<InputDecorator label={'Variant'} key={key}>
					<SelectInput
						$size={'small'}
						value={value?.variant}
						onChange={e => onChange({ ...value, variant: e.value })}
						options={ButtonVariants.map(variant => ({ value: variant, label: variant }))}
						placeholder={'Select button variant'}
					/>
				</InputDecorator>

				<InputDecorator label={'Leading icon'} key={key}>
					<SelectInput
						$size={'small'}
						value={value?.leading_icon}
						onChange={e => onChange({ ...value, leading_icon: e.value })}
						options={icons.map(icon => ({ value: icon, label: icon }))}
						placeholder={'Select leading icon'}
					/>
				</InputDecorator>

				<InputDecorator label={'Trailing icon'} key={key}>
					<SelectInput
						$size={'small'}
						value={value?.trailing_icon}
						onChange={e => onChange({ ...value, trailing_icon: e.value })}
						options={icons.map(icon => ({ value: icon, label: icon }))}
						placeholder={'Select trailing icon'}
					/>
				</InputDecorator>

				<CheckBox
					key={key}
					checked={value?.track_click}
					onChange={e => onChange({ ...value, track_click: e.target.checked })}
					label={'Track button click:'}
					labelFirst
					stretch
				/>

				{value?.track_click && (
					<Fragment>
						<InputDecorator label={'Event name:'} key={key}>
							<TextInput
								$size={'small'}
								type={'text'}
								value={value.event_name}
								onChange={e => onChange({ ...value, event_name: e.target.value })}
							/>
						</InputDecorator>
					</Fragment>
				)}
			</React.Fragment>
		);
	}

	if (field.type === 'cta[]') {
		const values = value as ClickToAction[];

		const removeArrayItem = (arr: ClickToAction[], index: number) => {
			arr.splice(index, 1);
			onChange(arr);
		};
		const addArrayItem = (arr: ClickToAction[]) => {
			const moreButtons = arr ?? [];

			moreButtons.push(
				moreButtons[0] ? cloneDeep(moreButtons[0]) : { text: 'Get a quote', url: '/get_an_estimate' }
			);
			onChange(moreButtons);
		};

		return (
			<React.Fragment key={key}>
				<Typography
					$type={'Heading.XSmall'}
					$marginTop={'extraLarge'}
					$marginBottom={'regular'}
					$color={'textOnSurfaceBackground'}
				>
					{'More CTAs'}
				</Typography>

				{values?.map((cta, index: number) => (
					<Margin key={index} $marginBottom={'regular'}>
						<Typography
							$bold
							$type={'Body.Small'}
							$marginBottom={'small'}
							$color={'textOnSurfaceBackground'}
						>
							{`CTA ${index + 1}`}
						</Typography>
						<MoreButtonsAdditionWrapper key={index}>
							<div>
								<InputDecorator label={'Text:'} key={key}>
									<TextInput
										$size={'small'}
										type={'text'}
										value={cta?.text}
										onChange={e =>
											onChange(
												values?.map((cta, i) => {
													if (i === index) return { ...cta, text: e.target.value };

													return cta;
												})
											)
										}
									/>
								</InputDecorator>

								<InputDecorator label={'URL:'} key={key}>
									<TextInput
										$size={'small'}
										type={'text'}
										value={cta?.url}
										onChange={e =>
											onChange(
												values?.map((cta, i) => {
													if (i === index) return { ...cta, url: e.target.value };

													return cta;
												})
											)
										}
									/>
								</InputDecorator>

								<InputDecorator label={'Button type:'} key={key}>
									<SelectInput
										$size={'small'}
										value={cta?.type}
										onChange={e =>
											onChange(
												values?.map((cta, i) => {
													if (i === index) return { ...cta, type: e.value };

													return cta;
												})
											)
										}
										options={ButtonTypes.map(type => ({ value: type, label: type }))}
										placeholder={'Select button type'}
									/>
								</InputDecorator>

								<InputDecorator label={'Button Variant:'} key={key}>
									<SelectInput
										$size={'small'}
										value={cta?.variant}
										onChange={e =>
											onChange(
												values?.map((cta, i) => {
													if (i === index) return { ...cta, variant: e.value };

													return cta;
												})
											)
										}
										options={ButtonVariants.map(variant => ({ value: variant, label: variant }))}
										placeholder={'Select button variant'}
									/>
								</InputDecorator>

								<InputDecorator label={'Leading icon'} key={key}>
									<SelectInput
										$size={'small'}
										value={cta?.leading_icon}
										onChange={e =>
											onChange(
												values?.map((cta, i) => {
													if (i === index) return { ...cta, leading_icon: e.value };

													return cta;
												})
											)
										}
										options={icons.map(icon => ({ value: icon, label: icon }))}
										placeholder={'Select leading icon'}
									/>
								</InputDecorator>

								<InputDecorator label={'Trailing icon'} key={key}>
									<SelectInput
										$size={'small'}
										value={cta?.trailing_icon}
										onChange={e =>
											onChange(
												values?.map((cta, i) => {
													if (i === index) return { ...cta, trailing_icon: e.value };

													return cta;
												})
											)
										}
										options={icons.map(icon => ({ value: icon, label: icon }))}
										placeholder={'Select trailing icon'}
									/>
								</InputDecorator>
								<CheckBox
									key={key}
									checked={cta?.track_click}
									onChange={e =>
										onChange(
											values?.map((cta, i) => {
												if (i === index) return { ...cta, track_click: e.target.checked };

												return cta;
											})
										)
									}
									label={'Track button click:'}
									labelFirst
									stretch
								/>

								{cta?.track_click && (
									<Fragment>
										<InputDecorator label={'Event name:'} key={key}>
											<TextInput
												$size={'small'}
												type={'text'}
												value={cta?.event_name}
												onChange={e =>
													onChange(
														values?.map((cta, i) => {
															if (i === index)
																return { ...cta, event_name: e.target.value };

															return cta;
														})
													)
												}
											/>
										</InputDecorator>
									</Fragment>
								)}
							</div>

							<RemoveListItem onClick={() => removeArrayItem(value, index)}>{'x'}</RemoveListItem>
						</MoreButtonsAdditionWrapper>
					</Margin>
				))}

				<AddNewArrayItem>
					<Button
						$stretch
						$size={'small'}
						$type={'neutral'}
						$leadingIcon={'ic_plus'}
						onClick={() => addArrayItem(value)}
					>
						{'Add new Button'}
					</Button>
				</AddNewArrayItem>
			</React.Fragment>
		);
	}

	if (field.type === 'design_system_asset') {
		return (
			<React.Fragment key={key}>
				<Typography $type={'Heading.XSmall'} $marginBottom={'regular'} $color={'textOnSurfaceBackground'}>
					{`${titleCase(key)}`}
				</Typography>

				<InputDecorator label={'Type'}>
					<SelectInput
						$size={'small'}
						value={value.type}
						onChange={e => onChange({ ...value, type: e.value })}
						options={assetTypes.map(asset => ({ value: asset, label: asset }))}
						placeholder={'Select asset type'}
					/>
				</InputDecorator>

				<InputDecorator label={'Value'}>
					<TextInput
						$size={'small'}
						type={'text'}
						value={value.value}
						onChange={e => onChange({ ...value, value: e.target.value })}
					/>
				</InputDecorator>

				<InputDecorator label={'Alt description'}>
					<TextInput
						$size={'small'}
						type={'text'}
						value={value.description}
						onChange={e => onChange({ ...value, description: e.target.value })}
					/>
				</InputDecorator>
			</React.Fragment>
		);
	}

	if (field.type === 'embedded_video') {
		return (
			<React.Fragment key={key}>
				<Typography $type={'Heading.XSmall'} $marginBottom={'regular'} $color={'textOnSurfaceBackground'}>
					{`${titleCase(key)}`}
				</Typography>

				<InputDecorator label={'Provider'}>
					<TextInput
						$size={'small'}
						type={'text'}
						value={value?.provider}
						// we only support youtube for now
						readOnly
						disabled
					/>
				</InputDecorator>

				<InputDecorator label={'Source'}>
					<TextInput
						$size={'small'}
						type={'text'}
						value={value?.source}
						onChange={e => onChange({ ...value, source: e.target.value })}
					/>
				</InputDecorator>

				<InputDecorator label={'Thumbnail'}>
					<TextInput
						$size={'small'}
						type={'text'}
						value={value?.thumbnail}
						onChange={e => onChange({ ...value, thumbnail: e.target.value })}
					/>
				</InputDecorator>

				<InputDecorator label={'Autoplay'}>
					<CheckBox
						checked={value?.autoplay}
						onChange={e => onChange({ ...value, autoplay: e.target.checked })}
					/>
				</InputDecorator>
			</React.Fragment>
		);
	}

	if (field.type === 'option') {
		return (
			<InputDecorator label={`Option ${titleCase(key)}:`} key={key}>
				<SelectInput
					$size={'small'}
					key={key}
					value={value}
					onChange={({ value }) => onChange(value)}
					options={field?.options?.map((f: string) => ({ label: f, value: f }))}
				/>
			</InputDecorator>
		);
	}

	if (field.type === 'checklistItem') {
		// old values workaround
		if (typeof value === 'string') {
			onChange({ label: value, checked: true });

			return null;
		}

		const label = value?.label ?? '';
		const checked = value?.checked ?? true;

		return (
			<InputDecorator label={`${titleCase(key)}:`} key={key}>
				<ChecklistItemWrapper>
					<TextInput
						$size={'small'}
						key={key}
						type={'text'}
						value={label}
						onChange={e => onChange({ ...value, label: e.target.value })}
					/>
					<Margin $marginTop={'small'}>
						<CheckBox
							key={key}
							checked={checked}
							onChange={e => onChange({ ...value, checked: e.target.checked })}
							stretch={false}
						/>
					</Margin>
				</ChecklistItemWrapper>
			</InputDecorator>
		);
	}

	if (field.type === 'checklistItem[]') {
		const removeArrayItem = (arr: ShowChecklist[], index: number) => {
			arr.splice(index, 1);
			onChange(arr);
		};
		const addArrayItem = (arr: ShowChecklist[]) => {
			const checkListItems = arr ?? [];

			checkListItems.push(checkListItems[0] ? cloneDeep(checkListItems[0]) : { label: '', checked: true });
			onChange(checkListItems);
		};

		return (
			<React.Fragment key={key}>
				<div key={'group-checklist-items'}>
					<Typography
						$type={'Heading.XSmall'}
						$marginTop={'extraLarge'}
						$marginBottom={'regular'}
						$color={'textOnSurfaceBackground'}
					>
						{'Checklist items'}
					</Typography>
					{value?.map((val: string[], i: number) => (
						<FieldWrap key={`group-checklist-item-${i}`}>
							{getField({ type: 'checklistItem' }, `Checklist item ${i + 1}`, val, newVal => {
								const newValue = [...value];

								newValue[i] = newVal;

								onChange(newValue);
							})}
							<RemoveListItem onClick={() => removeArrayItem(value, i)}>{'x'}</RemoveListItem>
						</FieldWrap>
					))}
				</div>
				<AddNewArrayItem>
					<Button
						$stretch
						$size={'small'}
						$type={'neutral'}
						$leadingIcon={'ic_plus'}
						onClick={() => addArrayItem(value)}
					>
						{'Add checklist item'}
					</Button>
				</AddNewArrayItem>
			</React.Fragment>
		);
	}

	if (field.type === 'object[]' || field.type === 'string[]' || field.type === 'text[]') {
		const fields = field.fields;

		const removeArrayItem = (arr: Record<string, string>[], index: number) => {
			arr.splice(index, 1);
			onChange(arr);
		};
		const addArrayItem = (arr: Record<string, string>[]) => {
			arr.push(cloneDeep(arr[0]));
			onChange(arr);
		};

		return (
			<React.Fragment key={key}>
				{field.type === 'object[]' &&
					value?.map((val: Record<string, string>, i: number) => (
						<PricingItemsWrapper key={`group-object-${i}`}>
							{TypedObject.keys(fields).map(f =>
								getField(fields[f], `Item ${i + 1} ${f as string}`, val[f as string], newVal => {
									const newValue = cloneDeep(value);

									newValue[i][f as string] = newVal;

									onChange(newValue);
								})
							)}

							<RemoveListItemInline>
								<Button
									$size={'small'}
									$type={'destructiveMuted'}
									$leadingIcon={'ic_close_rounded'}
									onClick={() => removeArrayItem(value, i)}
								>
									{'Remove'}
								</Button>
							</RemoveListItemInline>
						</PricingItemsWrapper>
					))}

				{field.type === 'string[]' && (
					<div key={'group-strings'}>
						{value?.map((val: string[], i: number) => (
							<FieldWrap key={`group-string-${i}`}>
								{getField({ type: 'string' }, `Item ${i + 1}`, val, newVal => {
									const newValue = [...value];

									newValue[i] = newVal;

									onChange(newValue);
								})}
								<RemoveListItem onClick={() => removeArrayItem(value, i)}>{'x'}</RemoveListItem>
							</FieldWrap>
						))}
					</div>
				)}

				{field.type === 'text[]' && (
					<div key={'group-strings'}>
						{value?.map((val: string[], i: number) => (
							<FieldWrap key={`group-string-${i}`}>
								{getField({ type: 'text' }, `Item ${i + 1}`, val, newVal => {
									const newValue = [...value];

									newValue[i] = newVal;

									onChange(newValue);
								})}
								<RemoveListItem onClick={() => removeArrayItem(value, i)}>{'x'}</RemoveListItem>
							</FieldWrap>
						))}
					</div>
				)}

				<AddNewArrayItem>
					<Button
						$stretch
						$size={'small'}
						$type={'neutral'}
						$leadingIcon={'ic_plus'}
						onClick={() => addArrayItem(value)}
					>
						{'Add new item'}
					</Button>
				</AddNewArrayItem>
			</React.Fragment>
		);
	}

	return null;
};
