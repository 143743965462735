import React, { useState } from 'react';

import { useCancellationFlow } from '../../context/CancellationFlowContext';
import CancellationModalLayout from './CancellationModalLayout';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';

const CustomCancellationReason = () => {
	const { cancellationReason, setCancellationReason } = useCancellationFlow();
	const [inputtedReason, setInputtedReason] = useState<string>(cancellationReason.description);

	const updateReasonDescription = (val: string) => {
		setInputtedReason(val);
		setCancellationReason({ ...cancellationReason, description: val });
	};

	const onSkip = () => setCancellationReason({ ...cancellationReason, description: '' });

	return (
		<CancellationModalLayout
			canProceed
			onSkip={onSkip}
			modalHeader={'So you said “something else” — mind telling us a bit more?'}
		>
			<TextInput value={inputtedReason} onChange={e => updateReasonDescription(e.target.value)} />
		</CancellationModalLayout>
	);
};

export default CustomCancellationReason;
