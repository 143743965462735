import React, { useContext, useEffect } from 'react';

import { LtmQuoteContext } from '../context';
import { formatCoords, formatValue, useAbiCodeFormatter, useFormatMileage } from '../formatters';
import { formatYearsMonths } from '~lib/frontend/helpers/format-years-months';
import InfoCard from '~website/features/policy/atoms/InfoCard';
import useReversiblePostcodeLookup from '~website/features/quote/hooks/use-reversible-postcode-lookup';

const CarInfoCard: React.FunctionComponent = () => {
	const { asyncStatus, quote } = useContext(LtmQuoteContext);
	const formatter = useAbiCodeFormatter();
	const mileage = useFormatMileage();
	const overnightStorageLocation = quote.response?.summary.vehicle.storage.location;
	const formattedCoords = formatCoords(overnightStorageLocation);
	const { onLookupCoords, reverseGeocodingResult, reverseGeocodingResultStatus } = useReversiblePostcodeLookup();

	useEffect(() => {
		if (!overnightStorageLocation || reverseGeocodingResultStatus !== 'pending') return;

		onLookupCoords(overnightStorageLocation);
	}, [onLookupCoords, overnightStorageLocation, reverseGeocodingResultStatus]);

	if (asyncStatus !== 'response') return <InfoCard fetching hideSeparator />;

	const items: [string, string][] = [
		['Overnight storage', formatter('locationCodes', quote.response.summary.vehicle.storage.type)],
		[
			'Storage location',
			reverseGeocodingResultStatus === 'response' ? reverseGeocodingResult.response.postcode : formattedCoords,
		],
		['Annual mileage', mileage(quote.response.summary.vehicle.annualMileage)],
		['Car owner', formatter('ownerCodes', quote.response.summary.vehicle.owner.type)],
		['Length of ownership', formatYearsMonths(quote.response.summary.vehicle.owner.durationMonths)],
		['Registered keeper', formatter('ownerCodes', quote.response.summary.vehicle.registeredKeeperCode)],
	];

	if (typeof quote.response.summary.vehicle.estimatedValue === 'number')
		items.push(['Estimated value', formatValue(quote.response.summary.vehicle.estimatedValue)]);

	return <InfoCard items={items} hideSeparator />;
};

export default CarInfoCard;
