import React from 'react';

import { AdapterProps } from '../types';
import InlineBanner, { contentAdapter } from '~website/components/atoms/InlineBanner';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => <InlineBanner {...contentAdapter(getter)} />;

export default {
	type: 'inline_banner',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			asset: { type: 'design_system_asset' },
			title: { type: 'string' },
			body: { type: 'string' },
			show_button: { type: 'cta' },
		},
		default: {
			asset: {
				type: 'illustration',
				value: 'il_pension.svg',
				description: '',
			},
			show_button: {
				text: 'Do get a test',
				url: '#',
			},
		},
	},
};
