import { useSelector } from 'react-redux';

import { DrivingLicense } from '~lib/platform/driving-license-registration/types';
import { Profile } from '~lib/platform/profile/types';

const useLockedDetails = () => {
	const userId = useSelector(s => s.internal.auth.user.response)!;
	const profileStore = useSelector(s => s.platform.profile.profileById[userId]);
	const dlrStore = useSelector(s => s.platform.drivingLicenseRegistration.licenseByUserId[userId]);

	if (!profileStore?.response || !dlrStore?.response) return false;

	return profileComplete(profileStore.response) && dlrComplete(dlrStore.response);
};

function profileComplete(profile: Profile) {
	return profile.personalName && profile.familyName && profile.sex && profile.birthDate;
}

function dlrComplete(license: DrivingLicense) {
	return license.dln?.verified && license.postcode?.verified;
}

export default useLockedDetails;
