import React, { useEffect, useState } from 'react';

import useFriendlyError from '../../hooks/use-friendly-error';
import { PageItem } from '../../store/types';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import { TypedObject } from '~lib/shared/helpers/typed';

interface PageErrorProps {
	page: PageItem;
}

const PageError: React.FCWithChildren<PageErrorProps> = ({ page }) => {
	const fieldError = page?.error;
	const fields = TypedObject.values(page?.fields ?? {});
	const friendlyError = useFriendlyError(fieldError) ?? page?.error?.code;
	const [errorFields, setErrorFields] = useState(0);
	const [errorsTouched, setErrorsTouched] = useState(false);
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		setErrorsTouched(false);
		setScrolled(false);
	}, [fieldError]);

	useEffect(() => {
		if (!scrolled && fieldError) {
			if (errorFields === 1) {
				document.querySelector('[data-type="input-decorator-error"]')?.scrollIntoView({ behavior: 'smooth' });
			} else {
				window.scrollTo({
					top: 0,
					behavior: 'smooth',
				});
			}
			setScrolled(true);
		}
	}, [fieldError, errorFields, scrolled]);

	useEffect(() => {
		if (fields) {
			const errorFields = fields.filter(field => Boolean(field?.error)).length;

			if (errorFields) setErrorsTouched(true);

			setErrorFields(errorFields);
		}
	}, [fields]);

	if (!fieldError) return null;

	// error banner is not visible when page have only one error and it's related to fields validation
	const notFieldRelatedError = errorFields === 0 && fieldError && !errorsTouched;

	if (errorFields < 2 && !notFieldRelatedError) return null;

	return (
		<Margin $marginTop={'regular'}>
			<Banner.Wrapper type={'error'} icon={'ic_exclamation_circle'} muted>
				{friendlyError}
			</Banner.Wrapper>
		</Margin>
	);
};

export default PageError;
