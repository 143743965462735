import React, { useState } from 'react';
import { createFrontloadState, FrontloadProvider } from 'react-frontload';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { Store } from 'redux';

import Routes from './routes';
import { ApplicationState } from './store';
import { ContentKey } from '~lib/frontend/contexts/content';
import { useHotkeys } from '~lib/frontend/hooks/use-hotkeys';

const helmetContext = {};
const frontloadState = createFrontloadState.client({
	context: {},
	serverRenderedData: {},
});

function Main({ store, history }: { store: Store<ApplicationState>; history: History }) {
	const [showKey, setShowKey] = useState(false);

	useHotkeys(
		'cmd+k',
		{},
		() => {
			setShowKey(!showKey);
		},
		[showKey]
	);

	return (
		<ContentKey.Provider value={{ showContentKey: showKey }}>
			<FrontloadProvider initialState={frontloadState}>
				<HelmetProvider context={helmetContext}>
					<React.StrictMode>
						<Provider store={store}>
							<ConnectedRouter history={history}>
								<Routes />
							</ConnectedRouter>
						</Provider>
					</React.StrictMode>
				</HelmetProvider>
			</FrontloadProvider>
		</ContentKey.Provider>
	);
}

export default Main;
