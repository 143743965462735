import Cher from '@cuvva/cher';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { createDiscount, listDiscountsNeu } from '../actions';
import * as api from '../api';
import { PromoActionTypes } from '../types';
export default function* watcherCreateDiscount() {
    yield takeEvery(PromoActionTypes.CREATE_DISCOUNT, workerCreateDiscount);
}
function* workerCreateDiscount({ payload, meta, }) {
    const { ident } = meta;
    const { requestId, listRequestId, ...requestBody } = payload;
    const { userId } = requestBody;
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    try {
        const response = yield call(api.createDiscount, client, requestBody);
        yield put(createDiscount.success(ident, response));
        yield put(flashMessages.addSuccess('The discount has successfully been added 💸'));
        yield put(listDiscountsNeu.request({ requestId: listRequestId, userId }));
    }
    catch (error) {
        yield put(flashMessages.addError('Unable to create discount', 'There was an unknown issue when creating the discount. The error is below:', Cher.coerce(error)));
        yield put(createDiscount.failure(ident, error));
    }
}
