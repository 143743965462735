import Cher from '@cuvva/cher';
import { put, select, take } from 'redux-saga/effects';

import { runStmQuote } from '..';
import { quoteActions } from '../actions';
import { InternalQuoteState } from '../types';
import { createQuoteRPC } from '~lib/platform/motor-coverage/store/actions';
import { MotorCoverageQuoteResponse } from '~lib/platform/motor-coverage/types';
import { createTakeEverySagaSet, requestAndTake } from '~lib/shared/redux/sagas';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(runStmQuote.actions.request, function* runStmQuoteSaga() {
	// wait for other stuff to actually load..
	if ((yield select((s: ApplicationState) => s.internal.quote.state.prefetch.user)) !== true)
		yield take(quoteActions.fetchUser.success);

	if ((yield select((s: ApplicationState) => s.internal.quote.state.prefetch.preferences)) !== true)
		yield take(quoteActions.fetchPreferences.success);

	const qState: InternalQuoteState = yield select((state: ApplicationState) => state.internal.quote);

	try {
		const userId: string = yield select((state: ApplicationState) => state.internal.auth.user.response);
		const vehicleId: string = yield select((s: ApplicationState) => s.internal.quote.state.vehicleId);

		const response: MotorCoverageQuoteResponse = yield requestAndTake(
			createQuoteRPC.request({
				userId,
				vehicleId,
				location: qState.state.quoteLocation,
				reason: 'web_get_a_quote',
				originalPolicyId: null,
				vehicleValue: null,
			})
		);

		yield put(runStmQuote.actions.success(response));
	} catch (error) {
		yield put(runStmQuote.actions.failure(Cher.coerce(error)));
	}
});
