import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import Generator from '../generator';
import ModeSelector from './organisms/ModeSelector';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as Alert from '~lib/frontend/design-system/components/dialog/molecules/Alert';
import Spinner from '~lib/frontend/design-system/components/spinner';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { SmallerContainer } from '~website/components/atoms/Container';
import Header from '~website/components/layout/header';
import { listCuvvaPeeps } from '~website/store/about/actions';

const SpinnerWrapper = styled.div`
	width: 100%;
	height: 100%;
	margin-top: 50px;
	margin: 0 auto;
`;

const BannerContainer = styled(SmallerContainer)`
	margin-top: 50px;
`;

const PageBuilder: React.FunctionComponent = () => {
	const { hash } = useLocation();
	const dispatch = useDispatch();
	const user = useSelector(s => s.internal.auth.user.response);
	const staffStore = useSelector(s => s.internal.about.listCuvvaPeeps);
	const staff = useAsyncStateStatus(staffStore);
	const isStaff = staff.is('response') && staffStore.response!.find(s => s.id === user);

	useEffect(() => {
		dispatch(listCuvvaPeeps.request());
	}, [dispatch]);

	if (staff.is('fetching', 'pending')) {
		return (
			<React.Fragment>
				<Header />
				<SpinnerWrapper>
					<Spinner $size={'100px'} />
				</SpinnerWrapper>
			</React.Fragment>
		);
	}

	if (staff.is('error')) {
		return (
			<React.Fragment>
				<Header />
				<BannerContainer>
					<Banner.Wrapper icon={'ic_alert'} type={'error'} muted>
						<strong>{'But are you?'}</strong>
						<br />
						{'There was a problem checking if your account is a staff members'}
					</Banner.Wrapper>
				</BannerContainer>
			</React.Fragment>
		);
	}

	if (!isStaff) {
		return (
			<React.Fragment>
				<Header />
				<Alert.Dialog open title={'No page builder 4 u'} centered>
					<Alert.Content>
						{"Only members of the Cuvvarati may enter. Are you sure you're signed into the correct "}
						{'account?'}
					</Alert.Content>
					<Alert.Actions>
						<Button $type={'primary'} onClick={() => dispatch(push(createAwareUrl('/signin')))}>
							{'Ah, maybe not'}
						</Button>
						<Button $type={'destructive'} onClick={() => dispatch(push(createAwareUrl('/')))}>
							{"Yeah i'm sure..."}
						</Button>
					</Alert.Actions>
				</Alert.Dialog>
			</React.Fragment>
		);
	}

	if (hash) return <Generator />;

	return (
		<React.Fragment>
			<Header />
			<ModeSelector />
		</React.Fragment>
	);
};

export default PageBuilder;
