import React from 'react';
import styled from 'styled-components';

import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';

export const QRCode: React.FC<{ path?: string; alt?: string }> = ({
	path = 'quote/branch-qr-code.png',
	alt = 'QR code download the Cuvva app',
}) => {
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages[download-quote].${key}`, fallback);

	const qrCodeAssetName = get('qr_code', path);

	const src = useContentAsset('website_core', qrCodeAssetName);

	return <QRCodeImage src={src} alt={alt} />;
};

const QRCodeImage = styled.img`
	margin: 0 auto;
	display: block;
	padding: ${p => p.theme.spacing.large};
	width: calc(128px + 2 * ${p => p.theme.spacing.large});
	height: calc(128px + 2 * ${p => p.theme.spacing.large});
	background: white;
	border-radius: ${p => p.theme.borderRadius.regular};
	outline: 1px solid ${p => p.theme.ui.borderSeparator};
`;
