import { select } from 'redux-saga/effects';

import * as actions from '../actions';
import handleAppsflyer from './handle-appsflyer';
import sendToIterable from './handle-iterable';
import sendToMixpanel from './handle-mixpanel';
import { UserConfigPayload } from '~lib/platform/user-config/types';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { AsyncState } from '~lib/shared/redux/types/state';
import handleGTM from '~website/features/analytics/store/sagas/handle-gtm';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(actions.trackEvent, function* trackEvent({ payload }) {
	const user: AsyncState<string> = yield select((state: ApplicationState) => state.internal.auth.user);

	const configFlags = [];

	if (user && user.response) {
		const userConfig: AsyncState<UserConfigPayload> = yield select(
			(state: ApplicationState) => state.platform.userConfig.getConfigById[user.response]
		);

		if (userConfig && userConfig.response) configFlags.push(userConfig.response.flags);
	}

	const { eventName, eventCategory, eventValue } = payload;

	const eventLabel = configFlags.join(',');

	const appsFlyerEventPayload = {
		eventName,
		eventCategory,
		eventLabel,
		eventValue,
	};
	const mixPanelAction = payload?.eventAction || eventName;
	const iterableEventPayload = {
		eventName,
		userId: user?.response,
	};

	if (window?.Intercom && payload?.intercomEvent)
		window.Intercom('trackEvent', payload.intercomEvent, payload?.eventPayload);

	yield handleGTM(payload);
	yield sendToMixpanel(mixPanelAction, payload.eventPayload);
	yield handleAppsflyer(appsFlyerEventPayload);
	yield sendToIterable(iterableEventPayload);
});
