import Cher from '@cuvva/cher';
import CuvvaClient from '@cuvva/cuvva-client';

let internalClient: CuvvaClient | null = null;

export function setFrontloadClient(client: CuvvaClient) {
	internalClient = client;
}

export function getFrontloadClient() {
	if (internalClient === null) throw new Cher('internal_client_not_set');

	return internalClient;
}
