import { AsyncMapState, AsyncState, initialAsyncState } from '~lib/shared/redux/types/state';

export interface SetRenewalDateRequest {
	renewalDate: string;
	userId: string;
	vehicleId: string;
	referralCode?: string;
}

export type SetRenewalDateResponse = object;

export interface SubmitRenewalReminderRequest {
	userId: string;
	email: string;
	reminder: SetRenewalDateRequest;
}

export interface GenerateReferralResponse {
	referralLink: string;
}

export interface ReferralState {
	setRenewalDate: AsyncMapState<SetRenewalDateResponse>;
	generateReferralLink: AsyncState<GenerateReferralResponse>;
}

export const initialReferralState: ReferralState = {
	setRenewalDate: {},
	generateReferralLink: initialAsyncState,
};

export const ReferralActionTypes = {
	SET_RENEWAL_DATE: '@platform/referral/SET_RENEWAL_DATE',
	SUBMIT_RENEWAL_REMINDER: '@platform/referral/SUBMIT_RENEWAL_REMINDER',
	SHARED_QUOTE_PREFERENCES: '@platform/referral/SHARED_QUOTE_PREFERENCES',
	GENERATE_REFERRAL_LINK: '@platform/referral/GENERATE_REFERRAL_LINK',
};
