import CuvvaClient from '@cuvva/cuvva-client';

import { LiteSegment } from '~lib/platform/content/types';
import { getAndParseLiteSegment } from '~lib/platform/content/utils';

export default async function fetchContent(client: CuvvaClient, segments: string[]) {
	const content: Record<string, LiteSegment> = {};

	await Promise.all(
		segments.map(async segment => {
			const payload = await getAndParseLiteSegment(client, {
				schemaVersion: '1.0.0',
				segment,
			});

			content[segment] = payload;
		})
	);

	return content;
}
