import React from 'react';
import styled from 'styled-components';

import Icon, { IconType } from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

export interface LiteSteppedProgressStep {
	title: string;
	icon?: IconType;
	emoji?: string;
}

export const LiteSteppedProgress = ({ steps }: { steps: LiteSteppedProgressStep[] }) => (
	<Wrapper $centered={true}>
		<BgLine />
		<ProgressLine />
		{steps.map((s, i) => (
			<Step key={`${i}-${s.title}`}>
				{s.icon && (
					<IconWrapper>
						<IconBackground />
						<Icon icon={s.icon} $size={'20px'} $color={'primaryFill'} />
					</IconWrapper>
				)}
				{s.emoji && <EmojiCircle>{s.emoji}</EmojiCircle>}

				<Typography $type={'Body.XSmall'} $color={'textOnSurfaceBackgroundMuted'}>
					{s.title}
				</Typography>
			</Step>
		))}
	</Wrapper>
);

const IconWrapper = styled.div`
	position: relative;
`;

const IconBackground = styled.div`
	background-color: ${p => p.theme.ui.blankBackground};
	width: 12px;
	height: 12px;
	border-radius: 50%;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const EmojiCircle = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid ${p => p.theme.ui.primaryFill};
	box-shadow: 0 0 0 2px ${p => p.theme.brand.indigo200};
	background-color: ${p => p.theme.ui.blankBackground};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	line-height: 1;
`;

const Wrapper = styled.div<{ $centered: boolean }>`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	max-width: 800px;
`;

const BgLine = styled.div`
	position: absolute;
	z-index: 0;
	margin: 0 15px;
	top: 8px;
	left: 12%;
	right: 12%;
	border-top: 4px solid ${p => p.theme.ui.background};
`;

const ProgressLine = styled(BgLine)`
	border-top: 4px solid ${p => p.theme.ui.primaryFill};
	right: calc(1 / 3 * (100% - 12%));
`;

const Step = styled.div`
	flex: 0 1 33%;
	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${p => p.theme.spacing.extraSmall};
`;
