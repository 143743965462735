import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import {
	CardContainer,
	DownloadAppWrap,
	GridLayout,
	MakeChangesDesktopWrap,
	MakeChangesMobileWrap,
} from '../components/atoms/alignment';
import { Container } from '../components/atoms/background';
import { Card, CardHeading, CardHeadingSide } from '../components/atoms/card';
import { List } from '../components/atoms/list';
import EditableIdentifierListItem from '../components/molecules/EditableIdentifierListItem';
import IncidentList from '../components/molecules/IncidentList';
import ListItem from '../components/molecules/ListItem';
import PaymentMethodsList from '../components/molecules/PaymentMethodsList';
import MarketingPreferences from '../components/organisms/MarketingPreferences';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { GetterType } from '~lib/frontend/types/content';
import AppStoreButtonsSegment from '~website/components/atoms/AppStoreButtonsSegment';
import CheckList from '~website/components/CheckList';
import { trackAccountsScreenView } from '~website/features/analytics/store/actions';
import { formatAddress, useAbiCodeFormatter } from '~website/features/quote/components/quote-page/formatters';
import { oneLink } from '~website/helpers/source-tracking';
import useUser from '~website/hooks/use-user';

const Profile: React.FunctionComponent = () => {
	const { userId, profile } = useUser();
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.profile.${key}`, fallback);
	const licenseByUserIdState = useSelector(s => s.platform.drivingLicenseRegistration.licenseByUserId[userId]);
	const formatter = useAbiCodeFormatter();
	const illoPhone = useDesignSystemAsset({ type: 'illustration', value: 'il_hands_with_phone.svg' });

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			trackAccountsScreenView({
				screenName: 'profile_accountDetails_viewed',
			})
		);
	}, [dispatch]);

	return (
		<Container>
			<GridLayout>
				<CardContainer>
					<Card>
						<CardHeading>{'Personal details'}</CardHeading>
						<List>
							<ListItem
								label={'Name'}
								body={[profile?.personalName, profile?.familyName].filter(Boolean).join(' ')}
								locked
							/>
							<ListItem label={'Date of birth'} body={formatDate(profile?.birthDate)} locked />
							<ListItem label={'Sex'} body={formatSex(profile?.sex)} locked />

							<EditableIdentifierListItem name={'Mobile phone number'} type={'mobile_phone'} />
							<EditableIdentifierListItem name={'Email'} type={'email'} />

							<ListItem
								label={'GB driving licence number'}
								body={licenseByUserIdState?.response?.dln?.value}
								locked
							/>
							<ListItem
								label={'Residential address'}
								body={profile?.residentialAddress && formatAddress(profile.residentialAddress)}
								locked
							/>
							<ListItem
								label={'Employment status'}
								body={profile?.employmentCode && formatter('employmentCodes', profile.employmentCode)}
								locked
							/>
							<ListItem
								label={'Occupation'}
								body={profile?.occupationCode && formatter('occupationCodes', profile.occupationCode)}
								locked
							/>
						</List>
					</Card>

					<Card>
						<CardHeading>{'Previous incidents'}</CardHeading>
						<IncidentList userId={userId} />
					</Card>

					<Card>
						<CardHeading>{'Payment methods'}</CardHeading>

						<PaymentMethodsList userId={userId} />
					</Card>

					<Card>
						<CardHeading>
							{'Contact preferences'}

							<Typography $type={'Body.Medium'}>{'What can we email you about?'}</Typography>
						</CardHeading>

						<MarketingPreferences />
					</Card>

					<DeleteAccountSection>
						<Typography $type={'Body.Small'} $color={'textMinor'} $display={'inline'}>
							{'Looking to delete your account? '}

							<Anchor href={'mailto:support@cuvva.com'} className={'intercom-launcher'}>
								<Typography $type={'Body.Small'} $color={'textHighlight'} $display={'inline'}>
									{'Chat to us'}
								</Typography>
							</Anchor>

							{'.'}
						</Typography>
					</DeleteAccountSection>
				</CardContainer>

				<MakeChangesMobileWrap>
					<Banner.Wrapper icon={'ic_lock'} type={'primary'} muted>
						{'Some of these details can affect your pricing. If anything’s changed, '}

						<Anchor href={'mailto:support@cuvva.com'} className={'intercom-launcher'}>
							{'chat to us.'}
						</Anchor>
					</Banner.Wrapper>
				</MakeChangesMobileWrap>

				<MakeChangesDesktopWrap>
					<SideCard>
						<CenterContent>
							<Icon icon={'ic_lock'} $color={'primaryFill'} $size={'48px'} $marginBottom={'small'} />

							<Margin $marginTop={'small'}>
								<CardHeadingSide>{'Which details can be edited?'}</CardHeadingSide>
							</Margin>
							<Typography $type={'Body.Medium'}>
								{'Some of these details can affect your pricing. If anything’s changed, '}
								<Anchor href={'mailto:support@cuvva.com'} className={'intercom-launcher'}>
									{'chat to us.'}
								</Anchor>
							</Typography>
						</CenterContent>
					</SideCard>
				</MakeChangesDesktopWrap>

				<DownloadAppWrap>
					<SideCard>
						<Illo src={illoPhone} />

						<Margin $marginBottom={'semiLarge'}>
							<CardHeadingSide>{get('download_app.title', 'Unlock more with the app')}</CardHeadingSide>
						</Margin>

						<CheckList
							items={get('download_app.items', [
								'Set MOT and tax reminders',
								'Your policy in your pocket',
								'Customer support 9am to 9pm, get a response in less than 2 minutes',
							])}
						/>

						<Margin $marginTop={'extraLarge'}>
							<AppStoreButtonsSegment url={oneLink} width={'170px'} />
						</Margin>
					</SideCard>
				</DownloadAppWrap>
			</GridLayout>
		</Container>
	);
};

export function formatSex(sex: string | null) {
	if (!sex) return '';

	if (sex.toUpperCase() === 'F') return 'Female';
	else if (sex.toUpperCase() === 'M') return 'Male';

	return '';
}

export function formatDate(date: string) {
	if (!date) return null;

	const parsedDate = new Date(date);

	if (isNaN(parsedDate.getTime())) return null;

	const options: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	};

	return new Intl.DateTimeFormat('en-GB', options).format(parsedDate);
}

const DeleteAccountSection = styled.div`
	a {
		text-decoration: none;
	}
`;

const CenterContent = styled.div`
	text-align: center;

	a,
	a:hover,
	a:focus,
	a:visited {
		color: ${p => p.theme.ui.primaryFill};
	}
`;

const SideCard = styled(Card)`
	padding: 24px;
`;
const Illo = styled.img`
	width: 128px;
	height: 128px;
	margin: 0 auto;
	margin-bottom: ${p => p.theme.spacing.semiLarge};
	display: inline-block;
`;

export default Profile;
