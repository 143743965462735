import { call, getContext, put } from 'redux-saga/effects';
import { discardChangeRequest } from '../actions';
import * as api from '../api';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
export default createTakeEverySagaSet(discardChangeRequest.request, function* workerDiscardChangeRequest({ payload, meta }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        yield call(api.discardChangeRequest, client, {
            changeRequestId: payload.changeRequestId,
            discardedBy: payload.discardedBy,
        });
        yield put(discardChangeRequest.success(ident));
    }
    catch (error) {
        yield put(discardChangeRequest.failure(ident, error));
    }
});
