import { AbiCodesResponse } from '~lib/platform/code-mapping/types';
import { AsyncState } from '~lib/shared/redux/types/state';

export const getAbiCodesOrDefault = (abiCodes: AsyncState<AbiCodesResponse>) => {
	if (abiCodes && abiCodes.response) return abiCodes.response;

	return {
		employmentCodes: [],
		occupationCodes: [],
		locationCodes: [],
		relationCodes: [],
		maritalCodes: [],
		ownerCodes: [],
		additionalPolicyholderRelationCodes: [],
	};
};
