import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Cher from '@cuvva/cher';
import styled from 'styled-components';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as SimpleDialog from '~lib/frontend/design-system/components/dialog/molecules/SimpleDialog';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import OTPInput from '~lib/frontend/design-system/components/input/molecules/OTPInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import { trackPayOnWebScreenView } from '~website/features/analytics/store/actions';
import useErrorMapper from '~website/hooks/use-error-mapper';

export interface VerifyPrimaryIdentifierTokenProps {
	type: 'email' | 'mobile_phone';
	error: Cher;
	identifier: string;
	loading: boolean;
	onResendCode: () => void;
	onChangeIdentifier: () => void;
	onConfirmToken: (token: string) => void;
}

const timerLengthSeconds = 60;
const sanitiseCode = (code: string) => code.replace(/[^0-9]/gim, '');

const VerifyPrimaryIdentifierToken = (props: VerifyPrimaryIdentifierTokenProps) => {
	const { type, error, loading, onResendCode, onConfirmToken, onChangeIdentifier, identifier } = props;
	const dispatch = useDispatch();

	const { getMappedError } = useErrorMapper();
	const mappedError = getMappedError(error);

	const [tick, setTick] = useState(timerLengthSeconds);
	const tickEnded = tick < 1;

	useEffect(() => {
		if (tickEnded) return;

		const interval = setInterval(() => {
			setTick(t => Math.max(t - 1, 0));
		}, 1_000);

		// eslint-disable-next-line consistent-return
		return () => {
			// it wouldn't even be necessary.
			clearInterval(interval);
		};
	}, [tickEnded]);

	useEffect(() => {
		window.scroll({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	const pageTitle =
		type === 'email' ? 'Enter the code we sent to your email' : 'Enter the code we sent to your mobile';
	const editTitle = type === 'email' ? 'Edit email address' : 'Edit mobile number';

	const [openDialogResend, setOpenDialogResend] = useState(false);
	const [code, setCode] = useState('');

	const resendCode = () => {
		onResendCode();
		setOpenDialogResend(false);
		setTick(timerLengthSeconds);
	};

	const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		e.stopPropagation();

		if (loading) return;

		onConfirmToken(sanitiseCode(code));
	};

	const onChangeIdentifierProxied = () => {
		if (loading) return;

		onChangeIdentifier();
	};

	const onChangeCode = (code: string) => {
		if (loading) return;

		setCode(code);

		const value = sanitiseCode(code);

		if (value.length === 6) onConfirmToken(value);
	};

	const onOpenResendDialog = () => {
		if (loading) return;

		setOpenDialogResend(true);
	};

	const onResendCodeProxied = () => {
		if (loading) return;

		resendCode();
	};

	useEffect(() => {
		const pageType = type === 'email' ? 'emailCode' : 'mobileCode';

		dispatch(trackPayOnWebScreenView({ screenName: `verification_${pageType}` }));
	}, [dispatch, type]);

	const inputRef = useRef<HTMLInputElement>();

	useEffect(() => {
		if (inputRef?.current) inputRef.current.focus();
	}, []);

	useEffect(() => {
		if (mappedError && inputRef?.current) inputRef.current.focus();
	}, [mappedError]);

	return (
		<React.Fragment>
			<TitleWrapper>
				<Typography $align={'center'} $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
					{pageTitle}
				</Typography>

				<Typography $marginTop={'extraLarge'} $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{'We sent a 6 digit code to'}
				</Typography>

				<Typography $bold $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{identifier}
				</Typography>

				<EditWrapper>
					<Button
						$type={'text'}
						$size={'large'}
						type={'button'}
						$leadingIcon={'ic_edit'}
						disabled={loading}
						onClick={onChangeIdentifierProxied}
					>
						{editTitle}
					</Button>
				</EditWrapper>
			</TitleWrapper>

			<form onSubmit={handleFormSubmit}>
				<InputDecorator label={''} error={mappedError}>
					<OTPInput $autoFocus $length={6} onChangeOTP={onChangeCode} />
				</InputDecorator>

				<Margin $marginTop={'extraLarge'}>
					<Button
						$stretch
						$size={'large'}
						type={'button'}
						onClick={onOpenResendDialog}
						disabled={!tickEnded || loading}
						$type={'primaryMuted'}
					>
						{tickEnded && 'I didn’t get a code'}
						{!tickEnded && `Resend in ${tick}...`}
					</Button>
				</Margin>
			</form>

			<SimpleDialog.Dialog
				open={openDialogResend}
				onClose={() => setOpenDialogResend(false)}
				title={`Didn't get ${type === 'email' ? 'the email' : 'a text'}`}
			>
				<SimpleDialog.Content>
					<Typography $display={'inline'} $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
						{'We sent it to '}
					</Typography>
					<Typography $bold $display={'inline'} $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
						{identifier}
					</Typography>

					{type === 'email' && (
						<Typography $marginTop={'large'} $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
							{'Check your junk folder – it might have landed there by mistake'}
						</Typography>
					)}

					<Typography $marginTop={'large'} $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
						{'Let us know if you want us to send you a new one.'}
					</Typography>
				</SimpleDialog.Content>

				<SimpleDialog.Actions>
					<Button $type={'primary'} onClick={onResendCodeProxied} disabled={loading}>
						{`Resend the ${type === 'email' ? 'email' : 'text'}`}
					</Button>
				</SimpleDialog.Actions>
			</SimpleDialog.Dialog>
		</React.Fragment>
	);
};

export default VerifyPrimaryIdentifierToken;

const TitleWrapper = styled.div`
	margin-top: ${p => p.theme.spacing.large};
	margin-bottom: ${p => p.theme.spacing.extraLarge};
	text-align: center;
`;

const EditWrapper = styled.div`
	display: grid;
	place-items: center;
`;
