import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';

import { PageCtx } from '../contexts/PageCtx';
import { pageActions, stepActions } from '../store/actions';
import useFriendlyError from './use-friendly-error';
import type { UseClass } from '~lib/platform/ltm/types';

const field = 'useClass';

function useVehicleUseClass() {
	const dispatch = useDispatch();
	const value = useSelector(s => s.internal.quote.state[field]);

	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const validation = page?.fields?.useClass?.error;

	const onChange = (useClass: UseClass) => {
		dispatch(stepActions.setUseClass(useClass));
	};

	useEffect(() => {
		dispatch(
			pageActions.registerField({
				field,
				pageId,
			})
		);

		return () => {
			dispatch(
				pageActions.deregisterField({
					field,
					pageId,
				})
			);
		};
	}, [dispatch, pageId]);

	useEffect(() => {
		const isValid = typeof value !== 'undefined';
		const error = isValid ? void 0 : new Cher('invalid');

		dispatch(
			stepActions.setFieldError({
				field,
				pageId,
				error,
			})
		);

		return () => {
			dispatch(
				stepActions.setFieldError({
					field,
					pageId,
					error: void 0,
				})
			);
		};
	}, [dispatch, value, pageId]);

	return {
		onChange,
		value,
		validation: useFriendlyError(validation, 'quote', field),
	};
}

export default useVehicleUseClass;
