import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { Career } from '~website/store/careers/types';

interface CareerCardProps {
	career: Career;
}

const CareerCard: React.FCWithChildren<CareerCardProps> = ({ career }) => {
	const icon = getIcon(career);
	const image = useDesignSystemAsset({ type: 'illustration', value: icon });

	return (
		<Wrapper href={career.url}>
			<Icon src={image} />
			<Info>
				<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'}>
					{career.title}
				</Typography>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
					{career.department}
				</Typography>
			</Info>
			<Arrow />
		</Wrapper>
	);
};

function getIcon(career: Career) {
	switch (career.department.toLowerCase()) {
		case 'marketing':
			return 'il_pension.svg';
		case 'operations':
			return 'il_volunteer_day.svg';
		case 'product':
			return 'il_phone.svg';

		default:
			return 'il_macbook.svg';
	}
}

const Icon = styled.img`
	margin-right: 30px;
	width: 100px;
	height: 100px;

	@media (max-width: 767px) {
		width: 70px;
		height: 70px;
		margin-right: 20px;
	}
`;

const Info = styled.div`
	flex-grow: 2;
`;

const Arrow = styled.div`
	width: 30px;
	height: 30px;
	margin-left: 15px;
	transform: translateX(-50%);
	background-repeat: no-repeat;
	background-size: 100%;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMzAiIHZpZXdCb3g9IjAgMCAzMSAzMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE4LjEzNzYgMy42MDM2NUMxNy4zNjc2IDQuNDA4NTEgMTcuMzY3NiA1LjcxMzQ1IDE4LjEzNzYgNi41MTgzMUwyMy42MDI3IDEyLjIzMDhMMi40MzU1NyAxMi4yMzA4QzEuMzExMzQgMTIuMjMwOCAwLjM5OTk3NSAxMy4xOTUxIDAuMzk5OTc1IDE0LjM4NDZDMC4zOTk5NzUgMTUuNTc0MiAxLjMxMTM0IDE2LjUzODUgMi40MzU1NyAxNi41Mzg1TDI0Ljc1NiAxNi41Mzg1TDE4LjExMzUgMjMuNDgxN0MxNy4zNDM1IDI0LjI4NjUgMTcuMzQzNSAyNS41OTE1IDE4LjExMzUgMjYuMzk2NEMxOC44ODM1IDI3LjIwMTIgMjAuMTMxOSAyNy4yMDEyIDIwLjkwMTkgMjYuMzk2NEwzMC40MjI1IDE2LjQ0NDdDMzEuMTkyNSAxNS42Mzk4IDMxLjE5MjUgMTQuMzM0OSAzMC40MjI1IDEzLjUzTDIwLjkyNjEgMy42MDM2NUMyMC4xNTYxIDIuNzk4NzggMTguOTA3NiAyLjc5ODc4IDE4LjEzNzYgMy42MDM2NVoiIGZpbGw9IiM2NjY2RkYiLz48L3N2Zz4=);
	transition: 0.8s;
`;

const Wrapper = styled(Anchor)`
	display: flex;
	align-items: center;
	flex-direction: row;
	padding: ${p => p.theme.spacing.large} 60px;
	margin-bottom: 40px;
	border-radius: ${p => p.theme.borderRadius.small};
	box-shadow: 6px 8px 30px rgba(102, 102, 255, 0.08);
	cursor: pointer;

	text-decoration: none;

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		${Arrow} {
			transform: translateX(0);
		}
	}

	@media (max-width: 767px) {
		padding: ${p => p.theme.spacing.large} ${p => p.theme.spacing.regular};
	}
`;

export default CareerCard;
