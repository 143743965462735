import React from 'react';

const Number3Confetti: React.FunctionComponent = () => (
	<svg xmlns={'http://www.w3.org/2000/svg'} fill={'none'} viewBox={'0 0 126 94'}>
		<path fill={'#3C9'} d={'M27.18 79l6.85 4.13-6.18 10.29L21 89.28z'} opacity={'0.2'}></path>
		<path fill={'#66F'} d={'M98 77.31l7.9-4.31 6.7 12.3-7.9 4.31z'} opacity={'0.2'}></path>
		<path fill={'#3C9'} d={'M60.18 43l6.85 4.13-6.18 10.29L54 53.28z'} opacity={'0.2'}></path>
		<path fill={'#FFDC3B'} d={'M90.17 28l7.69 2.2-3.59 12.5-7.69-2.2z'} opacity={'0.2'}></path>
		<path fill={'#3C9'} d={'M17 19.91L23.37 17l4.97 10.92-6.37 2.91z'} opacity={'0.9'}></path>
		<path fill={'#FFDC3B'} d={'M70.58 70l7.69 2.21-3.58 12.5L67 82.5z'} opacity={'0.6'}></path>
		<path fill={'#FFDC3B'} d={'M3.58 53l7.7 2.21-3.6 12.5L0 65.5z'} opacity={'0.9'}></path>
		<path fill={'#66F'} d={'M58.77 6.07L61.25 0l11.61 6.74-3.24 5.6-10.85-6.27z'} opacity={'0.9'}></path>
	</svg>
);

export default Number3Confetti;
