import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import useAsyncPayable from '../../hooks/use-async-payable';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';
import Typography from '~lib/frontend/design-system/components/Typography';
import TrustSegment from '~website/components/atoms/TrustSegment';
import { TrustpilotContent } from '~website/components/SplitUI';
import { monzoMoney } from '~website/helpers/monies';

const EstimateTip = styled.div`
	background: ${p => p.theme.ui.surface};
	box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	padding: ${p => p.theme.spacing.extraLarge};
	z-index: 10;
`;

export const SplashContent = styled.div`
	max-width: 440px;
	z-index: 10;
	display: flex;
	flex-direction: column;

	svg {
		path:nth-of-type(1) {
			fill: white;
		}
	}
`;

export const TrustSegmentWrap = styled.div`
	z-index: 10;
	display: flex;
	justify-content: center;
	margin-top: ${p => p.theme.spacing.extraLarge};
`;

const reviewContent = (
	<TrustpilotContent name={'Paul'}>
		{`“Excellent!!! Just what I was looking for. Insurance within minutes.
		I took the 12 hour package which was perfect for my situation.
		I will be using the app regular and would highly recommend.
		A strong 5 stars from me.”`}
	</TrustpilotContent>
);

export const SplashLTM: React.FunctionComponent = () => {
	const ltmEstimate = useSelector(s => s.internal.quote.estimate.ltm);
	const { error, fetching, totalPayable, nonSmartPricing } = useAsyncPayable(ltmEstimate);

	if (error || (!fetching && totalPayable === void 0)) return reviewContent;

	return (
		<Margin $marginLeft={'extraLarge'} $marginRight={'extraLarge'}>
			<SplashContent>
				<EstimateTip>
					<PriceWrap>
						{fetching && <SkeletonLoader width={'96px'} height={'24px'} />}

						{!fetching && typeof nonSmartPricing === 'number' && (
							<React.Fragment>
								<Typography $type={'Heading.Medium'} $color={'textHighlight'} $display={'inline-block'}>
									{monzoMoney(totalPayable)}
								</Typography>
								<Typography
									$type={'Heading.XSmall'}
									$color={'textOnSurfaceBackgroundMuted'}
									$display={'inline-block'}
									$textDecoration={'line-through'}
								>
									{monzoMoney(nonSmartPricing)}
								</Typography>
							</React.Fragment>
						)}

						{!fetching && typeof nonSmartPricing !== 'number' && (
							<Typography $type={'Heading.Medium'} $color={'textHighlight'} $display={'inline-block'}>
								{monzoMoney(totalPayable)}
							</Typography>
						)}
					</PriceWrap>
					<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'} $bold>
						{'Monthly estimate'}
					</Typography>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
						{"We'll refine your estimate as you answer more questions"}
					</Typography>
				</EstimateTip>
				<TrustSegmentWrap>
					<TrustSegment trustProvider={'trustpilot'} />
				</TrustSegmentWrap>
			</SplashContent>
		</Margin>
	);
};

const PriceWrap = styled.div`
	margin-bottom: ${p => p.theme.spacing.large};
	display: flex;
	flex-direction: row;
	min-height: 38px;
	gap: ${p => p.theme.spacing.regular};
	align-items: center;

	& > * {
		flex: 0 0 auto;
	}
`;
