import React, { Fragment, useState } from 'react';
import { sentenceCase } from 'change-case';
import flatten from 'lodash/flatten';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import { DesignSystemProvider } from '~lib/frontend/design-system';
import Button from '~lib/frontend/design-system/components/button/Button';
import IconButton from '~lib/frontend/design-system/components/button/IconButton';
import Callout from '~lib/frontend/design-system/components/callout/Callout';
import Card, {
	CardBody,
	CardHeader,
	CardHeaderGroup,
	CardHeaderGroupAction,
	CardHeaderText,
} from '~lib/frontend/design-system/components/card/Card';
import Icon from '~lib/frontend/design-system/components/Icon';
import IconWrapped from '~lib/frontend/design-system/components/IconWrapped';
import {
	AlignDoubleColChildren,
	AlignInlineChildren,
	AlignSingleColChildren,
} from '~lib/frontend/design-system/components/input/atoms/Aligners';
import { ClickableHint } from '~lib/frontend/design-system/components/input/atoms/Hint';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';
import DateInput from '~lib/frontend/design-system/components/input/molecules/DateInput';
import IconTextInput from '~lib/frontend/design-system/components/input/molecules/IconTextInput';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import PrefilledInput from '~lib/frontend/design-system/components/input/molecules/PrefilledInput';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import RegPlateInput from '~lib/frontend/design-system/components/input/molecules/RegPlateInput';
import SelectInput from '~lib/frontend/design-system/components/input/molecules/SelectInput';
import SwitchInput from '~lib/frontend/design-system/components/input/molecules/SwitchInput';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Table from '~lib/frontend/design-system/components/table/Table';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '~lib/frontend/design-system/components/tabs';
import Typography from '~lib/frontend/design-system/components/Typography';
import { TypographyStyle } from '~lib/frontend/design-system/types';

const ComponentsPage: React.FunctionComponent = () => {
	const [t8yStyle, setT8yStyle] = useState<TypographyStyle>('expressive');

	const renderT8ySelect = () => (
		<React.Fragment>
			<span>{'Typography style:'}</span>
			<select value={t8yStyle} onChange={e => setT8yStyle(e.target.value as TypographyStyle)}>
				<option value={'expressive'}>{'expressive'}</option>
				<option value={'productive'}>{'productive'}</option>
			</select>
			<br />
		</React.Fragment>
	);

	return (
		<RandWrapper>
			{renderT8ySelect()}

			<DesignSystemProvider themeKey={'light'} typographyStyle={t8yStyle}>
				<Typography $type={'Heading.Large'} $color={'textOnSurfaceBackground'}>
					{'Licence and address'}
				</Typography>

				<Callout icon={'ic_question_circle'}>
					{'Don’t have your licence to hand? '}
					<Anchor>{'Get an estimate'}</Anchor>
				</Callout>
				<VehicleRegInput />
				<PlainInput />
				<DateOfBirth />
				<PrefilledInputExample />
				<InputWithIconExample />
				{renderT8ySelect()}
				<PostcodeSearch />
				<AlignDoubleColChildren>
					<SmallFieldExample name={'Town'} />
					<SmallFieldExample name={'Postcode'} />
				</AlignDoubleColChildren>
				<ExampleSelect />
				<RadioButton />
				{renderT8ySelect()}
				<MultiInput />
				<TableComponent />
				<CardComponent />
				<SwitchTestInput />
				<Button>{'Create'}</Button>
				<IconButton $type={'destructiveMuted'} $icon={'ic_close_rounded'} />
				<Button $stretch $type={'primary'} $leadingIcon={'ic_help'}>
					{'Full width with icon'}
				</Button>
				<Icons />
				<TabsWrap />
				<SmallCopy $type={'Label.Small'} $color={'textMinor'}>
					{
						'By continuing you agree with Cuvva carrying out searches using your data, and sharing it with relevant parties including the DVLA. '
					}
					<Anchor>{'Get more info.'}</Anchor>
				</SmallCopy>
			</DesignSystemProvider>
		</RandWrapper>
	);
};

const SwitchTestInput: React.FunctionComponent = () => {
	const [switchOn, setSwitch] = useState(false);

	return <SwitchInput checked={switchOn} onChange={() => setSwitch(state => !state)} />;
};

const PlainInput: React.FunctionComponent = () => {
	const [input, setInput] = useState('Banana');
	const { error, onFocus, onBlur } = useOnBlurError(input.length < 1 && 'Enter first name');

	return (
		<InputDecorator label={'First name'} error={error} size={'small'}>
			<TextInput value={input} onChange={e => setInput(e.target.value)} onFocus={onFocus} onBlur={onBlur} />
		</InputDecorator>
	);
};

const VehicleRegInput: React.FunctionComponent = () => {
	const [input, setInput] = useState<string>('');
	const { error, onFocus, onBlur } = useOnBlurError(input.length < 4 && 'Enter a valid regplate');

	return (
		<InputDecorator error={error} onFocus={onFocus} onBlur={onBlur}>
			<RegPlateInput value={input} onChange={input => setInput(input)} />
		</InputDecorator>
	);
};

const DateOfBirth: React.FunctionComponent = () => {
	const [input, setInput] = useState<[string, string, string]>(['', '', '']);
	const { error, onFocus, onBlur } = useOnBlurError(input[2].length < 4 && 'Enter a valid date');

	return (
		<InputDecorator
			label={'Date of birth'}
			error={error}
			hint={'DD/MM/YYYY'}
			hintPosition={'inline'}
			onFocus={onFocus}
			onBlur={onBlur}
		>
			<DateInput values={input} onChange={(day, month, year) => setInput([day, month, year])} />
		</InputDecorator>
	);
};

const selectOptions = [
	{
		value: 'one',
		label: 'First option',
	},
	{
		value: 'two',
		label: 'Second option',
	},
	{
		value: 'three',
		label: 'Third option?',
	},
];

const ExampleSelect: React.FunctionComponent = () => {
	const [value, setValue] = useState<string>(void 0);

	return (
		<Fragment>
			<InputDecorator label={'Where is the car kept at night?'} hint={'info'} hintPosition={'end-of-line'}>
				<SelectInput
					value={void 0}
					disabled
					options={selectOptions}
					placeholder={'Choose an option'}
					onChange={({ value }) => setValue(value)}
				/>
			</InputDecorator>
			<InputDecorator label={'Where is the car kept at night? /2'} hint={'Hint'} hintPosition={'after-field'}>
				<SelectInput
					value={value}
					options={selectOptions}
					placeholder={'Choose an option'}
					onChange={({ value }) => setValue(value)}
				/>
			</InputDecorator>
		</Fragment>
	);
};

const radioOptions = ['Male', 'Female', 'Non binary', 'Other'];
const RadioButton: React.FunctionComponent = () => {
	const [input, setInput] = useState<string>(void 0);
	const [otherInput, setOtherInput] = useState<string>(void 0);
	const error = otherInput === 'Other' ? 'X' : void 0;

	return (
		<Fragment>
			<InputDecorator
				label={'Sex recorded on your DVLA file'}
				hintPosition={'after-field'}
				hint={
					<ClickableHint>
						<Icon icon={'ic_info_circle'} $size={'20px'} $color={'textHighlight'} />
					</ClickableHint>
				}
			>
				<AlignInlineChildren>
					{radioOptions.slice(0, 2).map(o => (
						<RadioInput
							key={o}
							name={'sex1'}
							checked={input === o}
							value={o}
							onChange={() => setInput(o)}
						/>
					))}
				</AlignInlineChildren>
			</InputDecorator>

			<InputDecorator
				label={'Sex recorded on your DVLA file'}
				hintPosition={'below-label'}
				error={error}
				hint={"Here's why we need it"}
			>
				<AlignDoubleColChildren>
					{radioOptions.map(o => (
						<RadioInput
							key={o}
							name={'sex2'}
							checked={otherInput === o}
							value={o}
							onChange={() => setOtherInput(o)}
						/>
					))}
				</AlignDoubleColChildren>
			</InputDecorator>
		</Fragment>
	);
};

const PrefilledInputExample: React.FunctionComponent = () => {
	const [value, setValue] = useState('');
	const { error, onFocus, onBlur } = useOnBlurError(value.length < 4 && 'Enter a valid licence number');

	return (
		<InputDecorator
			label={'Last 4 digits on your driving licence'}
			onFocus={onFocus}
			hint={
				<ClickableHint>
					<Icon icon={'ic_info_circle'} $size={'20px'} $color={'textHighlight'} />
				</ClickableHint>
			}
			onBlur={onBlur}
			error={error}
		>
			<PrefilledInput
				prefilled={'WILLI855013C'}
				value={value}
				onChange={e => setValue(e.target.value.substring(0, 4).toUpperCase())}
			/>
		</InputDecorator>
	);
};

const PostcodeSearch: React.FunctionComponent = () => {
	const [value, setValue] = useState('');
	const { error, onFocus, onBlur } = useOnBlurError(value.length < 1 && 'Enter a valid postcode');

	return (
		<InputDecorator label={'Postcode on licence'} onFocus={onFocus} onBlur={onBlur} error={error}>
			<AlignDoubleColChildren>
				<TextInput value={value} onChange={e => setValue(e.target.value)} />
			</AlignDoubleColChildren>
		</InputDecorator>
	);
};

const SmallFieldExample: React.FCWithChildren<{ name: string }> = ({ name }) => {
	const [value, setValue] = useState('');
	const { error, onFocus, onBlur } = useOnBlurError(value.length < 1 && `Enter a valid ${name}`);

	return (
		<InputDecorator label={name} onFocus={onFocus} onBlur={onBlur} error={error}>
			<TextInput value={value} onChange={e => setValue(e.target.value)} />
		</InputDecorator>
	);
};

const MultiInput: React.FunctionComponent = () => {
	const [value, setValue] = useState(['', '']);
	const { error, onFocus, onBlur } = useOnBlurError(value[0].length < 1 && 'Enter a building or street');

	return (
		<InputDecorator label={'Building and street'} onFocus={onFocus} onBlur={onBlur} error={error}>
			<AlignSingleColChildren>
				<TextInput value={value[0]} onChange={e => setValue([e.target.value, value[1]])} />
				<TextInput value={value[1]} onChange={e => setValue([value[0], e.target.value])} />
			</AlignSingleColChildren>
		</InputDecorator>
	);
};

const InputWithIconExample: React.FunctionComponent = () => {
	const [value, setValue] = useState('');

	return <IconTextInput value={value} onChange={setValue} icon={'ic_search'} />;
};

const RandWrapper = styled.div`
	max-width: 600px;
	margin: 150px auto;

	& > * {
		margin-top: 20px;
	}
`;

const SmallCopy = styled(Typography)`
	a,
	a:hover,
	a:visited {
		color: ${p => p.theme.ui.textHighlight};
	}
`;

const TableComponent: React.FunctionComponent = () => {
	const entries = [
		{
			reason: 'Policy sale',
			underwriter: '£4.08',
			premium: '£4.08',
			IPT: '£4.08',
			iptRate: '12%',
			fees: '£1.81',
			total: '£6.38',
		},
		{
			reason: 'Policy sale',
			underwriter: '£4.08',
			premium: '£4.08',
			IPT: '£4.08',
			iptRate: '-',
			fees: '£1.81',
			total: '£6.38',
		},
	];

	const columns = [
		{ value: 'reason' as 'reason' },
		{ value: 'underwriter' as 'underwriter' },
		{ value: 'premium' as 'premium' },
		{ value: 'IPT' as 'IPT' },
		{ value: 'iptRate' as 'iptRate' },
		{ value: 'fees' as 'fees' },
		{ value: 'total' as 'total' },
	];

	return <Table cols={columns} entries={entries} highlightOnHover />;
};

const CardComponent: React.FunctionComponent = () => (
	<Fragment>
		<Card>
			<CardHeaderGroup>
				<CardHeaderText>{'Overnight storage location'}</CardHeaderText>
				<CardHeaderGroupAction>
					<Button $type={'primary'} $size={'small'}>
						{'Check'}
					</Button>
				</CardHeaderGroupAction>
			</CardHeaderGroup>
			<CardBody>{'Body'}</CardBody>
		</Card>
		<Card collapsible>
			<CardHeader>
				<CardHeaderText>{'Header'}</CardHeaderText>
			</CardHeader>
			<CardBody border>{'First Body'}</CardBody>
			<CardBody border>{'Second Body'}</CardBody>
			<CardBody border>{'Third Body'}</CardBody>
		</Card>
	</Fragment>
);

const iconStyles = ['neutral', 'primary', 'success', 'warning', 'danger', 'onFill'] as const;
const iconType = ['square', 'circle'] as const;
const iconSize = ['medium', 'small'] as const;

/* eslint-disable max-nested-callbacks */
const Icons: React.FunctionComponent = () => (
	<IconWrapper>
		{flatten(
			iconSize.map(size =>
				[true, false].map(muted =>
					iconType.map(type =>
						iconStyles.map(style => (
							<IconWrapped
								key={`${style}:${String(muted)}:${size}:${type}`}
								$style={style}
								$muted={muted}
								$size={size}
								icon={'ic_car'}
								$type={type}
							/>
						))
					)
				)
			)
		)}
	</IconWrapper>
);

const toggleTabs = [
	{ resource: 'all', notification: 0, content: <>{'hi'}</> },
	{ resource: 'my', notification: 0, content: <>{'yoo'}</> },
	{ resource: 'shared', notification: 0, content: <>{'clearrr'}</> },
];

const TabsWrap = () => {
	const [index, setIndex] = useState(1);

	return (
		<Tabs defaultIndex={index} onChange={index => setIndex(index)}>
			<TabList>
				{toggleTabs.map(tab => (
					<Tab key={tab.resource}>{sentenceCase(tab.resource)}</Tab>
				))}
				<Tab disabled>{sentenceCase('nope')}</Tab>
			</TabList>
			<TabPanels>
				{toggleTabs.map(tab => (
					<TabPanel key={tab.resource}>{tab.content}</TabPanel>
				))}
				<TabPanel>{sentenceCase('laaaall')}</TabPanel>
			</TabPanels>
		</Tabs>
	);
};

/* eslint-enable */

const IconWrapper = styled.div`
	padding: ${p => p.theme.spacing.large};
	border-radius: ${p => p.theme.borderRadius.large};
	background: lightgrey; /* can't be a ds token as it would inevitably clash with them lol */
	text-align: center;

	& > * {
		margin: ${p => p.theme.spacing.extraSmall};
	}
`;

export default ComponentsPage;
