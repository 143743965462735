import React from 'react';

import { AdapterProps } from '../types';
import TitleSegmentSteps, { contentAdapter } from '~website/components/atoms/TitleSegmentSteps';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<TitleSegmentSteps {...contentAdapter(getter)} centered={true} />
);

export default {
	type: 'title_segment_steps',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			title: { type: 'string' },
			body: { type: 'text' },
			hide_line: { type: 'boolean' },
			steps: {
				type: 'object[]',
				fields: {
					title: { type: 'string' },
					body: { type: 'text' },
				},
			},
		},
		default: {
			title: 'Title',
			body: 'Body',
			hide_line: false,
			steps: [
				{
					title: 'One',
					body: 'Body1',
				},
				{
					title: 'Two',
					body: 'Body2',
				},
				{
					title: 'Three',
					body: 'Body3',
				},
			],
		},
	},
};
