import React from 'react';

import { StyledContentContainer } from '../../containers/Quote';
import DesktopScreenTab from './DesktopScreenTab';
import PricingHeaderBar from './headers/PricingHeaderBar';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import SplitUI from '~website/components/SplitUI';

interface VerificationsSplitUIProps {
	hideProgress?: boolean;
	splashContent?: React.ReactNode;
}

const VerificationsSplitUI: React.FCWithChildren<VerificationsSplitUIProps> = props => {
	const { children, splashContent, hideProgress } = props;
	const splashImage = useContentAsset('website_core', 'quote/quote_column.svg');

	return (
		<SplitUI splashImage={splashImage} splashContent={splashContent ?? null}>
			<PricingHeaderBar hideProgress={hideProgress} hideEstimatedPrice />
			<DesktopScreenTab hideProgress={hideProgress} />

			<StyledContentContainer>
				<React.Fragment>{children}</React.Fragment>
			</StyledContentContainer>
		</SplitUI>
	);
};

export default VerificationsSplitUI;
