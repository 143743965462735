import { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { LtmQuoteContext } from '../context';
import { createAwareUrl } from '~lib/frontend/helpers/uri';

const useQuoteValiditySupervisor = () => {
	const { quote } = useContext(LtmQuoteContext);
	const expiryDate = quote?.response?.expiryDate;
	const history = useHistory();
	const [timeoutReference, setTimeoutReference] = useState(0);

	const runCheck = useCallback(() => {
		if (!expiryDate) return;

		setTimeoutReference(s => s + 1);

		const parsedExpiryDate = new Intl.DateTimeFormat('en-GB', { timeZone: 'Europe/London' }).format(
			new Date(expiryDate)
		);
		const currentDate = new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' });
		const expired = new Date(parsedExpiryDate) < new Date(currentDate);

		if (expired) history.push(createAwareUrl('/quote/expired'));
	}, [expiryDate, history]);

	useEffect(() => {
		if (!('visibilityState' in document)) return void 0;

		const callback = () => {
			if (document.visibilityState === 'visible') runCheck();
		};

		document.addEventListener('visibilitychange', callback);

		return () => document.removeEventListener('visibilitychange', callback);
	}, [runCheck]);

	// Uses a timer instead of interval, as the browser might delay allowances for them
	// while in this way it will setup the new one only if the previous one ran.
	// It's not the best solution, but given it's a very small use case and working with
	// timezones is hard, this is the fastest solution for the time being.
	useEffect(() => {
		const timeout = setTimeout(runCheck, 10_000);

		return () => clearTimeout(timeout);
		// eslint-disable-next-line
	}, [timeoutReference]);

	// this runs it the first time, so you can "browser back button" to see it again.
	useEffect(runCheck, [runCheck]);
};

export default useQuoteValiditySupervisor;
