import React, { useState } from 'react';
import Button from './Button';
import IconButton from './IconButton';
import { ButtonTypes } from './types';
import { DesignSystemProvider } from '~lib/frontend/design-system';
import { icons as iconMap } from '~lib/frontend/design-system/assets/SvgIcon';
export const ButtonPage = () => {
    const [leadingIcon, setLeadingIcon] = useState(void 0);
    const [trailingIcon, setTrailingIcon] = useState(void 0);
    const [stretch, setStretch] = useState(false);
    const [size, setSize] = useState('medium');
    const [type, setType] = useState('primaryMuted');
    const [disabled, setDisabled] = useState(false);
    const [content, setContent] = useState('hello');
    const [loading, setLoading] = useState(false);
    const [t8yStyle, setT8yStyle] = useState('expressive');
    const [iconButtonSize, setIconButtonSize] = useState('medium');
    const [iconButtonType, setIconButtonType] = useState('primary');
    const [iconButtonDisabled, setIconButtonDisabled] = useState(false);
    const [icon, setIcon] = useState('ic_close_rounded');
    const [as, setAs] = useState('button');
    const [buttonType, setButtonType] = useState(void 0);
    return (React.createElement("div", { style: { padding: 30 } },
        React.createElement(DesignSystemProvider, { themeKey: 'light', typographyStyle: t8yStyle },
            React.createElement("h1", { style: { marginBottom: '8px' } }, 'Buttons'),
            React.createElement("p", null, 'Create button'),
            React.createElement("div", { style: { margin: 10 } },
                React.createElement("label", null, 'Typography style'),
                React.createElement("select", { value: t8yStyle, onChange: e => setT8yStyle(e.target.value) },
                    React.createElement("option", { value: 'expressive' }, 'expressive'),
                    React.createElement("option", { value: 'productive' }, 'productive'))),
            React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap' } },
                React.createElement("div", { style: { margin: 10 } },
                    React.createElement("label", null, 'As'),
                    React.createElement("select", { onChange: e => setAs(e.target.value), value: as }, ['button', 'div'].map(key => (React.createElement("option", { key: key, value: key }, key))))),
                React.createElement("div", { style: { margin: 10 } },
                    React.createElement("label", null, 'Button type'),
                    React.createElement("select", { onChange: e => setButtonType((e.target.value === 'none' ? void 0 : e.target.value)), value: buttonType }, ['none', 'submit'].map(key => (React.createElement("option", { key: key, value: key }, key))))),
                React.createElement("div", { style: { margin: 10 } },
                    React.createElement("label", null, 'type'),
                    React.createElement("select", { onChange: e => {
                            setType(e.target.value);
                        } }, ButtonTypes.map(key => (React.createElement("option", { key: key, value: key }, key))))),
                React.createElement("div", { style: { margin: 10 } },
                    React.createElement("label", null, 'size'),
                    React.createElement("select", { value: size, onChange: e => setSize(e.target.value) },
                        React.createElement("option", { value: 'medium' }, 'medium'),
                        React.createElement("option", { value: 'small' }, 'small'),
                        React.createElement("option", { value: 'large' }, 'large'))),
                React.createElement("div", { style: { margin: 10 } },
                    React.createElement("label", null, 'disabled'),
                    React.createElement("select", { value: disabled ? 'yes' : 'no', onChange: e => setDisabled(e.target.value === 'yes') },
                        React.createElement("option", { value: 'no' }, 'no'),
                        React.createElement("option", { value: 'yes' }, 'yes'))),
                React.createElement("div", { style: { margin: 10 } },
                    React.createElement("label", null, 'stretch'),
                    React.createElement("select", { value: stretch ? 'yes' : 'no', onChange: e => setStretch(e.target.value === 'yes') },
                        React.createElement("option", { value: 'no' }, 'no'),
                        React.createElement("option", { value: 'yes' }, 'yes'))),
                React.createElement("div", { style: { margin: 10 } },
                    React.createElement("label", null, 'leading icon'),
                    React.createElement("select", { value: leadingIcon, onChange: e => {
                            const value = e.target.value;
                            setLeadingIcon(value === 'none' ? void 0 : value);
                        } },
                        React.createElement("option", { value: 'none' }, 'none'),
                        iconMap.map(key => (React.createElement("option", { key: key, value: key }, key))))),
                React.createElement("div", { style: { margin: 10 } },
                    React.createElement("label", null, 'trailing icon'),
                    React.createElement("select", { value: trailingIcon, onChange: e => {
                            const value = e.target.value;
                            setTrailingIcon(value === 'none' ? void 0 : value);
                        } },
                        React.createElement("option", { value: 'none' }, 'none'),
                        iconMap.map(key => (React.createElement("option", { key: key, value: key }, key))))),
                React.createElement("div", { style: { margin: 10 } },
                    React.createElement("label", null, 'loading'),
                    React.createElement("select", { onChange: e => setLoading(e.target.value === 'yes'), value: loading ? 'yes' : 'no' },
                        React.createElement("option", { value: 'no' }, 'no'),
                        React.createElement("option", { value: 'yes' }, 'yes'))),
                React.createElement("div", { style: { margin: 10 } },
                    React.createElement("label", null, 'button text'),
                    React.createElement("input", { type: 'text', value: content, onChange: e => setContent(e.target.value) }))),
            React.createElement("div", { style: { margin: 10, width: 250 } },
                React.createElement(Button, { "$leadingIcon": leadingIcon, "$trailingIcon": trailingIcon, "$stretch": stretch, "$size": size, "$type": type, "$loading": loading, as: as, type: buttonType, disabled: disabled, 
                    // eslint-disable-next-line no-alert
                    onClick: () => alert('Uh la la you clicked it!') }, content)),
            React.createElement("p", null, 'Create icon button'),
            React.createElement("div", { style: { display: 'flex' } },
                React.createElement("div", { style: { marginRight: 10 } },
                    React.createElement("label", null, 'icon'),
                    React.createElement("select", { onChange: e => {
                            setIcon(e.target.value);
                        } },
                        React.createElement("option", { value: 'none' }, 'none'),
                        iconMap.map(key => (React.createElement("option", { key: key, value: key }, key))))),
                React.createElement("div", { style: { marginRight: 10 } },
                    React.createElement("label", null, 'type'),
                    React.createElement("select", { onChange: e => {
                            setIconButtonType(e.target.value);
                        } }, ButtonTypes.map(key => (React.createElement("option", { key: key, value: key }, key))))),
                React.createElement("div", { style: { marginRight: 10 } },
                    React.createElement("label", null, 'size'),
                    React.createElement("select", { onChange: e => {
                            setIconButtonSize(e.target.value);
                        } },
                        React.createElement("option", { value: 'medium' }, 'medium'),
                        React.createElement("option", { value: 'small' }, 'small'),
                        React.createElement("option", { value: 'large' }, 'large'))),
                React.createElement("div", { style: { marginRight: 10 } },
                    React.createElement("label", null, 'disabled'),
                    React.createElement("select", { onChange: e => {
                            const val = e.target.value === 'yes';
                            setIconButtonDisabled(val);
                        } },
                        React.createElement("option", { value: 'no' }, 'no'),
                        React.createElement("option", { value: 'yes' }, 'yes')))),
            React.createElement("div", { style: { marginTop: 10, width: 250 } },
                React.createElement(IconButton, { "$size": iconButtonSize, "$type": iconButtonType, disabled: iconButtonDisabled, "$icon": icon })))));
};
export default ButtonPage;
