import React from 'react';

import { AdapterProps } from '../types';
import TitleSegmentStaffHighlight, { contentAdapter } from '~website/components/atoms/TitleSegmentStaffHighlight';

const adapter: React.FCWithChildren<AdapterProps> = ({ getter }) => (
	<TitleSegmentStaffHighlight {...contentAdapter(getter)} />
);

export default {
	type: 'title_segment_staff_highlight',
	adapter,
	builder: {
		fields: {
			id: { type: 'string' },
			title: { type: 'string' },
			body: { type: 'text' },
			staff: {
				type: 'object[]',
				fields: {
					id: { type: 'string' },
					name: { type: 'string' },
					role: { type: 'string' },
					links: {
						type: 'object[]',
						fields: {
							icon: { type: 'string' },
							link: { type: 'string' },
						},
					},
					sections: {
						type: 'object[]',
						fields: {
							heading: { type: 'string' },
							body: { type: 'text' },
						},
					},
				},
			},
		},
		default: {
			title: 'Hey hey',
			body: '',
			staff: [
				{
					id: '',
					name: 'Name',
					role: 'Role',
					links: [
						{
							icon: 'ic_github',
							link: '/',
						},
					],
					sections: [
						{
							heading: 'What?',
							body: 'Where?',
						},
						{
							heading: 'How did it happen?',
							body: 'Who knows',
						},
					],
				},
			],
		},
	},
};
