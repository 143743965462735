import React from 'react';
import styled, { css, FlattenInterpolation, ThemeProps } from 'styled-components';

import Anchor from '~lib/frontend/atoms/Anchor';
import { DesignSystem } from '~lib/frontend/design-system/types';

type ButtonStyle = 'default' | 'primaryActionMuted';

const customStyles: Record<ButtonStyle, FlattenInterpolation<ThemeProps<DesignSystem>>> = {
	default: css`
		color: ${props => props.theme.ui.textOnAction};
		background: ${props => props.theme.ui.primaryAction};
	`,
	primaryActionMuted: css`
		color: ${props => props.theme.ui.textHighlight};
		background: ${props => props.theme.ui.primaryActionMuted};
	`,
};

const buttonBaseStyle = css`
	display: inline-block;
	padding: ${p => p.theme.spacing.small} ${p => p.theme.spacing.extraLarge};
	border-radius: 6px;
	font-family: ${p => p.theme.fonts.default};

	font-size: 1.125rem;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	letter-spacing: 0.6px;
	transition: ease 0.2s;

	&:hover {
		transform: translateY(-2px);
	}
`;

const StyledLink = styled(Anchor)<{ $style?: FlattenInterpolation<ThemeProps<DesignSystem>> }>`
	${buttonBaseStyle}
	${p => p.$style};
`;

interface LinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	$style?: ButtonStyle;
}

export const LinkButton: React.FCWithChildren<LinkButtonProps> = ({ children, $style, ...otherProps }) => (
	<StyledLink
		$style={customStyles[$style || 'default']}
		{...(otherProps as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
	>
		{children}
	</StyledLink>
);
