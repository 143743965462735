import moment from 'moment';

import type { FlattenPolicySubs } from '../hooks/use-subscriptions';
import { formatDate, policyTimeString } from './formatDateTime';
import { BadgeType } from '~lib/frontend/design-system/components/badge/types';

export const policyBadgeType = (subscription: FlattenPolicySubs) => {
	if (!subscription || !subscription?.subInfo) return void 0;

	const { startDate, endDate } = subscription;
	const { state, actionsRequired, upcomingPolicy } = subscription.subInfo;

	if (endDate && moment(endDate).isBefore(moment())) {
		return {
			type: 'neutral' as BadgeType,
			text: 'Ended',
		};
	}

	if (startDate && moment(startDate).isAfter(moment())) {
		if (state.status === 'pending') {
			return {
				type: 'neutral' as BadgeType,
				text: `Starts in ${policyTimeString(startDate).timeString}`,
			};
		}

		return {
			type: 'neutral' as BadgeType,
			text: `Starts in ${policyTimeString(startDate).timeString}`,
		};
	}

	if (state.status === 'active') {
		if (!state.autocycle || 'paymentFailed' in actionsRequired || !upcomingPolicy) {
			return {
				type: 'warning' as BadgeType,
				text: `Ends in ${policyTimeString(endDate).timeString}`,
			};
		}

		return {
			type: 'success' as BadgeType,
			text: 'Active',
		};
	}

	return {
		type: 'neutral' as BadgeType,
		text: 'Ended',
	};
};

export const policyTimeline = (subscription: FlattenPolicySubs, billingDate?: string) => {
	if (!subscription || !subscription?.subInfo) return void 0;

	const { startDate, endDate } = subscription;
	const { state, upcomingPolicy, actionsRequired } = subscription.subInfo;

	const start = formatDate(startDate).date;
	const end = formatDate(endDate).date;
	const startTime = formatDate(startDate).time;
	const endTime = formatDate(endDate).time;

	if (endDate && moment(endDate).isBefore(moment())) return `${start} to ${end}`;

	if (startDate && moment(startDate).isAfter(moment())) {
		if (state.status === 'pending') return `Starting ${startTime}, ${start}`;

		return `Starting ${startTime}, ${start}`;
	}

	if (state.status === 'active') {
		if (!state.autocycle || 'paymentFailed' in actionsRequired || !upcomingPolicy)
			return `Ending ${endTime}, ${end}`;

		if (upcomingPolicy) {
			const paymentDate = billingDate ? billingDate : formatDate(upcomingPolicy.startDate).date;

			return `Next payment ${paymentDate}`;
		}
	}

	return `${start} to ${end}`;
};
