import React, { FCWithChildren, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useLatestLtmQuote } from '../../store/quote';
import { LtmQuote } from '../../store/types';
import { Status } from '~lib/frontend/hooks/use-async-state-status';
import { CreateQuoteResponse } from '~lib/platform/ltm/types';
import { PublicVehicle } from '~lib/platform/vehicle/types';
import { AsyncState } from '~lib/shared/redux/types/state';

interface ContextState {
	state: AsyncState<LtmQuote>;
	vehicle: AsyncState<PublicVehicle>;
	quote: AsyncState<CreateQuoteResponse>;
	asyncStatus: Status;
	requestQuote: () => void;
	verificationIds: VerificationId;
	setVerificationIds: React.Dispatch<React.SetStateAction<ContextState['verificationIds']>>;
}

type VerificationId = Map<string, string>;

export const LtmQuoteContext = React.createContext<ContextState>(void 0);

const LtmQuoteProvider: FCWithChildren = ({ children }) => {
	const { state, quote, asyncStatus, requestQuote } = useLatestLtmQuote();
	const stateVehicleId = useSelector(s => s.internal.quote.state.vehicleId);

	// SC: This is questionable. We want to render something ASAP without having to wait
	// for the wrap action to trigger the saga and do `.success`, other the other hand
	// this looks dirty. battled.
	const vehicleId = state.response?.vehicleId ?? stateVehicleId;
	const vehicle = useSelector(s => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);

	const [verificationIds, setVerificationIds] = useState<VerificationId>(void 0);

	const value = useMemo(
		() => ({
			state,
			quote,
			asyncStatus,
			requestQuote,
			vehicle,
			verificationIds,
			setVerificationIds,
		}),
		[state, quote, asyncStatus, requestQuote, vehicle, verificationIds, setVerificationIds]
	);

	return React.createElement(LtmQuoteContext.Provider, { value }, children);
};

export default LtmQuoteProvider;
