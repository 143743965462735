import React from 'react';

import { CheckListContainer, CheckListItem, CheckListMain } from '../atoms/CheckList';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

interface ChecklistProps {
	title?: string;
	titleAlign?: 'center' | 'left';
	orientation?: 'inline' | 'block';
}

const CheckList: React.FCWithChildren<ChecklistProps> = props => {
	const { title, children, titleAlign, orientation = 'block' } = props;

	return (
		<CheckListMain>
			<Typography
				$type={'Heading.Small'}
				$color={'textOnSurfaceBackground'}
				$align={titleAlign}
				$marginBottom={'24px'}
			>
				{title}
			</Typography>
			<CheckListContainer $orientation={orientation}>
				{React.Children.toArray(children).map((t, i) => (
					<CheckListItem $orientation={orientation} key={`${t}:${i}`}>
						<Icon
							icon={'ic_check'}
							$size={'24px'}
							$color={'primaryFill'}
							$marginRight={orientation === 'inline' ? 'extraSmall' : 'large'}
						/>
						<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
							{t}
						</Typography>
					</CheckListItem>
				))}
			</CheckListContainer>
		</CheckListMain>
	);
};

export default CheckList;
