import { css } from 'styled-components';
const breakpoints = {
    mobile: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopMedium: '1280px',
    laptopLarge: '1440px',
    desktop: '2560px',
};
const lessThan = (breakpoint) => (f, ...args) => css `
		@media (max-width: ${props => props.theme.breakpoints[breakpoint]}) {
			${css(f, ...args)}
		}
	`;
const greaterThan = (breakpoint) => (f, ...args) => css `
		@media (min-width: ${props => props.theme.breakpoints[breakpoint]}) {
			${css(f, ...args)}
		}
	`;
const between = (fb, sb) => (f, ...args) => css `
		@media (min-width: ${props => props.theme.breakpoints[fb]}) and (max-width: ${props => props.theme.breakpoints[sb]}) {
			${css(f, ...args)}
		}
	`;
const media = {
    lessThan,
    greaterThan,
    between,
};
export default breakpoints;
export { media };
