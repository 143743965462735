import React from 'react';
import { sentenceCase } from 'change-case';
import styled from 'styled-components';
import SkeletonLoader from '../skeleton/SkeletonLoader';
import Typography from '../Typography';
import TableData from './atoms/TableData';
import TableHead from './atoms/TableHeader';
import TableRow from './atoms/TableRow';
import TableWrapper from './atoms/TableWrapper';
const searchInEntries = (entries, searchableFields, searchText) => {
    if (!searchText)
        return entries;
    const filtered = entries.filter(entry => searchableFields?.some(field => {
        const value = entry[field];
        return typeof value === 'string' && value.toLowerCase().includes(searchText.toLowerCase());
    }));
    return filtered ?? [];
};
export default function Table({ entries, cols, rowState, loading, customEmptyMessage = 'No entries found', searchableFields, searchText, ...wrapperProps }) {
    const filteredEntries = searchInEntries(entries, searchableFields, searchText);
    return (React.createElement(TableDiv, null,
        React.createElement(TableWrapper, { ...wrapperProps },
            React.createElement("thead", null,
                React.createElement("tr", null, cols.map((col, i) => (React.createElement(TableHead, { "$width": col.size, "$align": col.thAlign, key: `${String(col.value)}-${i}` }, col.customHeader ? col.customHeader : sentenceCase(String(col.value))))))),
            React.createElement("tbody", null,
                loading && (React.createElement("tr", null, cols.map((col, i) => {
                    const key = `${String(col.value)}-${i}`;
                    return (React.createElement("td", { key: key },
                        React.createElement(SkeletonLoader, { width: '48px' })));
                }))),
                !loading && filteredEntries?.length === 0 && (React.createElement("tr", null,
                    React.createElement("td", { colSpan: cols.length },
                        React.createElement(Typography, { "$type": 'Body.Small', "$color": 'textOnSurfaceBackground' }, customEmptyMessage)))),
                !loading &&
                    filteredEntries?.map((entry, index) => {
                        const state = rowState?.state(entry) ?? null;
                        return (React.createElement(TableRow
                        // @ts-ignore
                        , { 
                            // @ts-ignore
                            customRowStyle: rowState?.customRowStyle[state], key: index }, cols.map((col, i) => {
                            const customComponent = col.customComponent;
                            const key = `${String(col.value)}-${i}`;
                            if (customComponent)
                                return (React.createElement(TableData, { "$width": col.size, "$align": col.tdAlign, key: key }, customComponent(entry)));
                            return (React.createElement(TableData, { "$width": col.size, "$align": col.tdAlign, key: key }, entry[col.value]));
                        })));
                    })))));
}
const TableDiv = styled.div `
	overflow: auto;
`;
