const version = '1/2021-03-22';
export const listEvents = (api, userId, afterEventIdentifier) => api.rpc('service-ltm', `${version}/list_events`, { userId, afterEventIdentifier });
export const config = (api, userId, client) => api.rpc('service-ltm', `${version}/config`, { userId, client });
export const setDetails = (api, details) => api.rpc('service-ltm', `${version}/set_details`, details);
export const getSubscriptionUser = (api, payload) => api.rpc('service-ltm', `${version}/get_subscription_user`, payload);
export const startPricing = (api, payload) => api.rpc('service-ltm', `${version}/start_pricing`, payload);
export const updatePricing = (api, payload) => api.rpc('service-ltm', `${version}/update_pricing`, payload);
export const createEstimate = (api, payload) => api.rpc('service-ltm', `${version}/create_estimate`, payload);
export const createQuote = (api, payload) => api.rpc('service-ltm', '1/2021-10-27/create_quote', payload);
export const getProfile = (api, userId) => api.rpc('service-ltm', `${version}/get_profile`, { userId });
export const quoteCancellation = (api, payload) => api.rpc('service-ltm', `${version}/quote_cancellation`, payload);
export const confirmCancellation = (api, payload) => api.rpc('service-ltm', `${version}/confirm_cancellation`, payload);
export const getSubscriptionDetail = (api, payload) => api.rpc('service-ltm', `${version}/get_subscription`, payload);
export const quoteAutocycle = (api, payload) => api.rpc('service-ltm', `${version}/quote_autocycle`, payload);
export const getNextBillingDate = (api, payload) => api.rpc('service-ltm', `${version}/get_next_billing_date`, payload);
export const regenerateQuote = (api, payload) => api.rpc('service-ltm', `${version}/regenerate_quote`, payload);
export const processCycle = (api, payload) => api.rpc('service-ltm', `${version}/process_cycle`, payload);
export const pushFailedPayment = (api, payload) => api.rpc('service-ltm', `${version}/push_failed_payment`, payload);
export const listPoliciesBySubscription = (api, userId, subscriptionId) => api.rpc('service-ltm', '1/2021-10-27/list_policies_by_subscription', {
    userId,
    subscriptionId,
});
export const listSubscriptions = (api, userId) => api.rpc('service-ltm', '1/2021-10-27/list_subscriptions', { userId });
export const listTransactionsByPolicy = (api, userId, policyId) => api.rpc('service-ltm', '1/2021-10-27/list_transactions_by_policy', { userId, policyId });
export const getSubscriptionByPolicy = (api, userId, policyId) => api.rpc('service-ltm', '1/2021-10-27/get_subscription_by_policy', { userId, policyId });
export const attemptCycleGeneration = (api, payload) => api.rpc('service-ltm', '1/2021-10-27/attempt_cycle_generation', payload);
export const createSubscription = (api, payload) => api.rpc('service-ltm', '1/2021-10-27/create_subscription', payload);
export const getAdditionalDriver = (api, payload) => api.rpc('service-ltm', '1/2021-10-27/get_additional_driver', payload);
