import styled, { css } from 'styled-components';
import { parseMargin } from '../../atoms/utils/Margin';
export const SkeletonWrapper = styled.div `
	position: relative;
	display: inline-block;
	height: ${p => p.height ?? '20px'};
	width: ${p => p.width ?? '100%'};

	${p => p.circle &&
    css `
			border-radius: 50%;
			width: ${p.width ?? p.height ?? '100px'};
			height: ${p.height ?? p.width ?? '100px'};
		`}

	${parseMargin};

	&:after {
		position: absolute;
		transform: translateY(-50%);
		top: 50%;
		left: 0;
		content: '';
		display: block;
		height: 100%;
		width: 100%;
		background-image: linear-gradient(
			100deg,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.5) 60%,
			rgba(255, 255, 255, 0.3) 80%,
			rgba(255, 255, 255, 0.05) 100%
		);
		background-size: 100% 100%;
		background-repeat: no-repeat;
		animation: loading 1s ease-in infinite;
	}

	@keyframes loading {
		0% {
			opacity: 0.2;
		}

		50% {
			opacity: 1;
		}

		100% {
			opacity: 0.2;
		}
	}
`;
export const Skeleton = styled.div `
	background-color: ${p => p.theme.ui.neutralActionMuted};
	height: 100%;
	width: 100%;
	border-radius: 6px;

	${p => p.circle &&
    css `
			border-radius: 50%;
		`}
`;
