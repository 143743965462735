import styled from 'styled-components';
const Container = styled.aside `
	background: ${p => p.theme.ui.surface};
	box-shadow: ${p => p.theme.boxShadow.medium};
	border: 1px solid ${p => p.theme.ui.borderSeparator};
	margin: 0 auto;
	padding: ${p => p.theme.spacing.small};
	display: ${p => (p.$shown ? 'flex' : 'none')};
	border-radius: ${p => p.theme.borderRadius.large};
	flex-direction: column;
	z-index: 2;
	width: calc(100vw - ${p => p.theme.spacing.large});
	max-width: calc(100vw - ${p => p.theme.spacing.large});
	max-height: calc(55vh - ${p => p.theme.spacing.large});
	overflow-y: auto;

	@media (min-width: 425px) {
		width: 320px;
	}
`;
export default Container;
