import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';

const ReferralBanner: React.FunctionComponent = () => {
	const history = useHistory();
	const referredIllustration = useDesignSystemAsset({ type: 'illustration', value: 'il_refer_landing.svg' });

	const onClickBannerButton = () => {
		history.push(createAwareUrl('/refer-a-friend'));
	};

	return (
		<Banner.Wrapper type={'primary'} icon={'ic_info_circle'}>
			<Banner.Title>{'Want up to £10 off?'}</Banner.Title>
			<ReferralBannerContainer>
				<Margin $marginBottom={'small'} $marginTop={'small'}>
					{'Tell a friend about us, and you will both get a discount!'}
				</Margin>

				<img src={referredIllustration} />
			</ReferralBannerContainer>
			<Banner.Button onClick={onClickBannerButton}>{'Refer a friend'}</Banner.Button>
		</Banner.Wrapper>
	);
};

const ReferralBannerContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	img {
		height: 50px;
		width: 50px;
	}

	& + button {
		cursor: pointer;
	}
`;

export default ReferralBanner;
