import styled from 'styled-components';
const Hint = styled.div `
	${p => p.theme.typography['Body.Small']()};
	color: ${p => p.theme.ui.textMinor};
	display: flex;
	align-items: center;
	white-space: nowrap;
	flex: 0 0;
	margin-left: ${p => (p.$hintPosition === 'below-label' ? '0' : p.theme.spacing.small)};
`;
export const ClickableHint = styled.div `
	${p => p.theme.typography['Body.Small']()};
	color: ${p => p.theme.ui.textHighlight};
	cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
export default Hint;
