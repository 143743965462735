import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import ErrorBanner from '../../atoms/ErrorBanner';
import { OwnersData } from '../../types';
import { media } from '~lib/frontend/design-system/breakpoints';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import HeaderBar from '~website/components/atoms/HeaderBar';
import TrustSegment from '~website/components/atoms/TrustSegment';
import VisionaryContext from '~website/contexts/VisionaryContext';
import { trackOwnersEvent, trackScreenView } from '~website/features/analytics/store/actions';
import CheckList from '~website/features/quote/components/molecules/CheckList';
import useCreateAccount from '~website/hooks/use-create-account';
import useQuery from '~website/hooks/use-query';

interface EntryPointLandingPageProps {
	updatePage?: () => void;
	ownersData?: OwnersData;
	onCreateAccountEvent: string;
	onClickSigninEvent: string;
	emailEnteredEvent?: string;
}

interface ExperimentLandingPageFormWrapperProps extends EntryPointLandingPageProps {
	contentTitle: string;
	contentChecklist: string[];
	screenTrackName: string;
	submitBtnLabel: string;
}

const ExperimentLandingPage: React.FCWithChildren<ExperimentLandingPageFormWrapperProps> = props => {
	const { ownersData, onCreateAccountEvent, emailEnteredEvent, onClickSigninEvent, updatePage } = props;
	const { contentTitle, contentChecklist, screenTrackName, submitBtnLabel } = props;

	const [email, setEmail] = useState<string>();
	const history = useHistory();
	const dispatch = useDispatch();
	const query = useQuery();
	const cuvvaSource = query.get('cuvva_source');

	const backgroundImageMobile = useDesignSystemAsset({
		type: 'background',
		value: 'car_on_clouds_mobile.png',
	});

	useEffect(() => {
		dispatch(
			trackScreenView({
				eventName: screenTrackName,
				eventPayload: {
					cuvva_source: cuvvaSource,
				},
			})
		);
	}, [dispatch, cuvvaSource, screenTrackName]);

	const onSuccess = () => {
		updatePage();
	};

	const goToSignin = () => {
		dispatch(
			trackOwnersEvent({
				eventName: onClickSigninEvent,
				cuvvaSource: query.get('cuvva_source'),
			})
		);

		history.push(createAwareUrl('/signin'), { redirectUri: createAwareUrl(location.pathname) });
	};

	const createAccountResponse = useCreateAccount({
		email,
		profile: ownersData?.profile,
		sharedDetails: ownersData?.share,
		onSuccess,
	});

	const { loading, onClick, error, duplicateAccount } = createAccountResponse;

	const registerEmailEvent = () => {
		dispatch(
			trackOwnersEvent({
				eventName: emailEnteredEvent,
				cuvvaSource: query.get('cuvva_source'),
			})
		);
	};

	const trackCTA = () => {
		dispatch(
			trackOwnersEvent({
				eventName: onCreateAccountEvent,
				cuvvaSource: query.get('cuvva_source'),
			})
		);
	};

	const createAccount = () => {
		trackCTA();
		onClick();
	};

	const inputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			trackCTA();
			onClick();
		}
	};

	return (
		<VisionaryContext.Provider value={true}>
			<EntryPointContainer $backgroundImage={backgroundImageMobile}>
				<HeaderBar />
				<Content>
					<Typography $type={'Heading.Large'} $color={'textOnFill'} $align={'center'} as={'h1'}>
						{contentTitle}
					</Typography>

					<CheckList titleAlign={'center'}>{contentChecklist.map(item => item)}</CheckList>

					<Margin $marginTop={'extraLarge'}>
						<InputDecorator error={Boolean(error.error || duplicateAccount)}>
							<TextInput
								value={email}
								type={'email'}
								onKeyDown={inputKeyDown}
								onBlur={registerEmailEvent}
								placeholder={'Enter your email'}
								onChange={e => setEmail(e.target.value)}
							/>
							{duplicateAccount && (
								<ErrorBannerContainer>
									<ErrorBanner onButtonClick={goToSignin} />
								</ErrorBannerContainer>
							)}
							{error.error && error.errorMessage}
						</InputDecorator>

						<Button $type={'primary'} $size={'large'} $stretch onClick={createAccount} $loading={loading}>
							{submitBtnLabel}
						</Button>
						<EntrypointInfo>
							<Typography
								$display={'inline'}
								$type={'Body.XSmall'}
								$color={'textOnFillMuted'}
								$marginRight={'small'}
							>
								{'Already have an account?'}
							</Typography>
							<Typography
								$display={'inline'}
								$type={'Body.Small'}
								$color={'textOnAction'}
								onClick={goToSignin}
							>
								{'Sign in'}
							</Typography>
							<TrustSegment trustProvider={'trustpilot'} />
						</EntrypointInfo>
					</Margin>
				</Content>
			</EntryPointContainer>
		</VisionaryContext.Provider>
	);
};

const EntryPointContainer = styled.div<{ $backgroundImage: string }>`
	padding: 0 ${p => p.theme.spacing.large};
	height: 100vh;
	background:
		radial-gradient(100% 100% at 0% 100%, #a644e2 5.93%, rgba(101, 68, 226, 0.3) 59.9%, rgba(101, 68, 226, 0) 100%),
		url(${p => p.$backgroundImage}) 0% 0% / cover no-repeat,
		rgb(5, 2, 36);
	background-size: contain;
	background-position: bottom;
	${media.greaterThan('tablet')`
		background-position: left 300px;
		background-repeat: no-repeat;
	`}
	& > div:nth-child(1) {
		border: none;

		svg {
			path {
				fill: ${p => p.theme.ui.blankFill};
			}
		}
	}
`;

const Content = styled.div`
	& > div:last-child {
		text-align: center;
	}
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	min-width: 343px;
	max-width: 460px;

	& > div:nth-child(2) {
		margin-left: ${p => p.theme.spacing.large};
		svg + div {
			color: ${p => p.theme.ui.textOnFillMuted};
		}
		path {
			fill: ${p => p.theme.ui.surfaceFill};
		}
	}

	& > div:nth-child(3) {
		input + div {
			min-height: 12px;
		}

		${media.greaterThan('tablet')`
			margin-left: 16px;
		`}
	}
`;

const EntrypointInfo = styled.div`
	margin-top: ${p => p.theme.spacing.large};

	& > div:nth-child(2) {
		cursor: pointer;
	}
`;

const ErrorBannerContainer = styled.div`
	margin-top: ${p => p.theme.spacing.large};
	max-width: 428px;
`;

export default ExperimentLandingPage;
