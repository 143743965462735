import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { startPricing } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherStartPricing() {
    yield takeEvery(LtmActionTypes.START_PRICING, workerStartPricing);
}
function* workerStartPricing({ meta, payload }) {
    const client = yield getContext('api');
    const { ident } = meta;
    const { requestId, ...requestPayload } = payload;
    try {
        const response = yield call(api.startPricing, client, requestPayload);
        yield put(startPricing.success(ident, response));
    }
    catch (error) {
        yield put(startPricing.failure(ident, error));
    }
}
