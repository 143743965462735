import Cher from '@cuvva/cher';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { quoteAutocycle } from '../actions';
import * as api from '../api';
import { LtmActionTypes } from '../types';
export default function* watcherQuoteAutocycle() {
    yield takeEvery(LtmActionTypes.QUOTE_AUTOCYCLE, workerQuoteAutocycle);
}
function* workerQuoteAutocycle({ meta, payload, }) {
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { ident } = meta;
    const { requestId, ...bodyPayload } = payload;
    try {
        const response = yield call(api.quoteAutocycle, client, bodyPayload);
        yield put(quoteAutocycle.success(ident, response));
    }
    catch (error) {
        yield put(quoteAutocycle.failure(ident, error));
        yield put(flashMessages.addError('Unable to quote autocycle', 'The outputted error is below:', Cher.coerce(error)));
    }
}
