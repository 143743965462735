import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import { IconType } from '~lib/frontend/design-system/assets/SvgIcon';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

const BusinessUseDialogHint = () => {
	const [open, setOpen] = useState(false);
	const onClose = () => setOpen(false);

	return (
		<Fragment>
			<Button $type={'text'} type={'button'} onClick={() => setOpen(true)} $size={'medium'}>
				{'Find out what counts as business use'}
			</Button>

			<ComplexDialog.Dialog open={open} title={'About business use'} onClose={onClose}>
				<ComplexDialog.Content>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
						{
							'You can use your Cuvva policy for class 1 business use. This means it covers some work-related things but there’s a few restrictions 👇'
						}
					</Typography>

					<BusinessUseListWrapper>
						<ul>
							{specList.map((item, i, list) => {
								const last = i + 1 === list.length;
								const icon = (last ? 'ic_cross_circle' : 'ic_check_circle_fill') as unknown as IconType;

								return (
									<BusinessUseListItem key={item.title}>
										<Icon
											icon={icon}
											$size={'20px'}
											$color={last ? 'dangerFill' : 'successFill'}
											$marginRight={'small'}
											$marginTop={'extraSmall'}
										/>
										<BusinessUseContainer>
											<Typography
												$align={'left'}
												$marginBottom={'small'}
												$type={'Heading.XSmall'}
												$color={'textOnSurfaceBackground'}
											>
												{item.title}
											</Typography>
											<Typography
												$align={'left'}
												$type={'Body.Medium'}
												$marginBottom={'small'}
												$color={'textOnSurfaceBackgroundMuted'}
											>
												{item.item}
											</Typography>
										</BusinessUseContainer>
									</BusinessUseListItem>
								);
							})}
						</ul>
					</BusinessUseListWrapper>
				</ComplexDialog.Content>
				<ComplexDialog.Actions>
					<Button onClick={onClose}>{'Got it'}</Button>
				</ComplexDialog.Actions>
			</ComplexDialog.Dialog>
		</Fragment>
	);
};

const BusinessUseListWrapper = styled.div`
	margin-top: ${p => p.theme.spacing.extraLarge};

	ul {
		list-style: none;
	}
`;

const BusinessUseListItem = styled.li`
	display: flex;
	align-items: flex-start;
	margin-top: ${p => p.theme.spacing.large};
`;

const BusinessUseContainer = styled.div`
	flex: 1;
`;

export default BusinessUseDialogHint;

const specList = [
	{
		title: 'What’s covered as standard',
		item: "You can use Cuvva for all things social, domestic and pleasure. You're also covered to drive to and from your regular place of work.",
	},
	{
		title: 'What’s covered with business use',
		item: "If you opt-in for business use, you'll be covered if vou need to make journeys for work outside of your usual commute. like going to the bank or visiting a client.",
	},
	{
		title: 'What’s not covered',
		item: "You can't use Cuvva for hire, commercial travelling, racing, pacemaking, speed testing, competitions, rallies, trials or any purpose in connection with the Motor Trade.",
	},
];
