import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { EditableTypographyContext } from '../context/editable-typography';
import { parseMargin } from './atoms/utils/Margin';
export const Typography = styled.div `
	${p => p.theme.typography[p.$type]({ $bold: p.$bold })};
	${p => (p.$align ? `text-align: ${p.$align}` : '')};
	${p => (p.$width ? `width: ${p.$width}` : '')};
	${p => (p.$whiteSpace ? `white-space: ${p.$whiteSpace}` : '')};
	${p => (p.$textTransform ? `text-transform: ${p.$textTransform}` : '')};
	${p => (p.$textDecoration ? `text-decoration: ${p.$textDecoration}` : '')};
	${p => (p.$font ? `font-family: ${p.theme.fonts[p.$font]}` : '')};
	${p => (p.$maxWidth ? `max-width: ${p.$maxWidth}` : '')};

	/*
		This is sad. We need the "&&" override as the ops dash gets styles from bootstrap and we need
		a reliable way to override those without using "!important"
		When we will get rid of bootstrap, just remove the '&, && {' and leave the color property there.
	*/
	&,
	&& {
		color: ${p => (p.$color === 'inherit' ? p.$color : p.theme.ui[p.$color ?? 'textOnSurfaceBackgroundMuted'])};
	}

	${p => (p.$display ? `display: ${p.$display}` : '')};
	${parseMargin};

	// We should move this to just use a general class and define it in a \`createGlobalStyle\`, so we can get this out
	// of the props for the component everywhere.
	${p => p.$__UNSAFE____EDITABLE__ === true &&
    css `
			border-radius: ${p => p.theme.borderRadius.large};

			&:hover {
				background: ${p => p.theme.ui.neutralActionMutedOnFill};
				box-shadow: 0 0 0 0px ${p => p.theme.ui.neutralActionMutedOnFill};
				cursor: text;
			}
		`}
`;
export const EditableTypography = (props) => {
    const mutatedChildrenRef = useRef(null);
    const lastKnownLocalWriteRef = useRef(0);
    const [, setNonce] = useState(0);
    const editableContext = useContext(EditableTypographyContext);
    // TODO(afr): Bring back WYSIWYG editor in the future
    const editable = false; // Boolean(props.setterKey) && Boolean(editableContext);
    const onInput = useCallback((event) => {
        if (!editable)
            return;
        if (props.setterKey)
            editableContext?.updateValue?.(props.setterKey, event.currentTarget.innerText);
        lastKnownLocalWriteRef.current = Date.now();
    }, [editable, props.setterKey, editableContext]);
    // React is very unhappy when we try and take control of reconciling the DOM
    // ourselves, so we need to take control of the child typography component and
    // and reconcile the changes up and down the tree manually.
    useEffect(() => {
        if (!editable || props.children === null)
            return;
        // Don't recreate mutated child if a change happened less than 200ms ago, as it
        // is likely the change we just made being passed back down!
        if (lastKnownLocalWriteRef.current + 200 > Date.now())
            return;
        const mutatedChildren = React.Children.toArray(props.children)
            .filter(c => React.isValidElement(c))
            .map(c => React.cloneElement(c, {
            contentEditable: true,
            suppressContentEditableWarning: true,
            onInput,
            $__UNSAFE____EDITABLE__: true,
        }));
        mutatedChildrenRef.current = mutatedChildren;
        // The "nonce" is used to force a re-render after the mutated child has changed
        setNonce(Date.now());
    }, [editable, onInput, props.children]);
    if (!editable)
        return React.createElement(React.Fragment, null, props.children);
    if (!mutatedChildrenRef.current)
        return null;
    return React.createElement(React.Fragment, null, mutatedChildrenRef.current);
};
Typography.displayName = 'Typography';
export default Typography;
