import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import { PageCtx } from '../../contexts/PageCtx';
import useOnPageError from '../../hooks/use-on-page-error';
import useUserEmailAddress from '../../hooks/use-user-email-address';
import EmailAlreadyExistsModal from '../molecules/EmailAlreadyExistsModal';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import useCheckedTyping from '~website/hooks/use-checked-typing';

const EmailAddress: React.FunctionComponent = () => {
	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const pageFetching = page.fetching;
	const underlyingError = page?.fields?.emailAddress?.error;

	const userId = useSelector(s => s.internal.auth.user.response);
	const userById = useSelector(s => s.platform.auth.userById[`${userId}-false`]);
	const emailAddressAttached = userById?.response?.identifiers.find(i => i.type === 'email' && !i.removedAt);
	const [emailAlreadyExistsModal, setEmailAlreadyExistsModal] = useState(false);

	const { checkStartedTyping, setCheckStartedTyping } = useCheckedTyping({
		action: 'email_input_started',
	});

	const { value, validation, onChange } = useUserEmailAddress();
	const error = useOnPageError(validation, 'email_input');

	useEffect(() => {
		if (pageFetching)
			setEmailAlreadyExistsModal(value && underlyingError && underlyingError.code === 'email_already_exists');
	}, [pageFetching, underlyingError, value]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!checkStartedTyping) setCheckStartedTyping(true);

		onChange(e.target.value);
	};

	return (
		<React.Fragment>
			<QuoteInputDecorator
				label={'Email address'}
				error={emailAlreadyExistsModal ? void 0 : error}
				help={'We’ll save your details to speed things up next time'}
			>
				<TextInput
					type={'email'}
					autoComplete={'email'}
					value={value ?? ''}
					onChange={handleOnChange}
					disabled={Boolean(emailAddressAttached)}
					data-event-onfocus-name={'email_input_selected'}
				/>
			</QuoteInputDecorator>

			<EmailAlreadyExistsModal
				open={emailAlreadyExistsModal}
				emailAddress={value ?? ''}
				onClose={() => setEmailAlreadyExistsModal(false)}
			/>
		</React.Fragment>
	);
};

export default EmailAddress;
