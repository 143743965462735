import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';

const NeedMoreHelpContainer = styled.div`
	margin: 0 auto;
`;

const ButtonWrap = styled.div`
	margin-top: 2rem;
	text-align: center;
`;

const HelpChatToUs: React.FunctionComponent = () => (
	<NeedMoreHelpContainer>
		<Typography
			$type={'Heading.Medium'}
			$color={'textOnSurfaceBackground'}
			$marginBottom={'large'}
			$align={'center'}
		>
			{'Any questions?'}
		</Typography>

		<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $align={'center'}>
			{"We're here to help 24/7. Just ask."}
		</Typography>

		<ButtonWrap>
			<Button
				as={Anchor}
				// @ts-ignore
				href={'mailto:support@cuvva.com'}
				$type={'primaryMuted'}
				className={'intercom-launcher'}
				$display={'inline-flex'}
				$size={'large'}
			>
				{'Chat to us'}
			</Button>
		</ButtonWrap>
	</NeedMoreHelpContainer>
);

export default HelpChatToUs;
