import React, { Children, cloneElement, isValidElement, useEffect, useRef, useState, } from 'react';
import Container from '../atoms/container';
import OverflowScroll from '../atoms/overflow';
import Underline from '../atoms/underline';
import Tab from './Tab';
import { useTabsContext } from './Tabs';
const TabNavigationContext = React.createContext(void 0);
const TabList = ({ children }) => {
    const [left, setLeft] = useState(0);
    const [width, setWidth] = useState(0);
    const [mounted, setMounted] = useState(false);
    const scrollerRef = useRef(null);
    const { handleChangeIndex, selectedIndex, background } = useTabsContext();
    // this handles a smooth scroll to position the tab in the viewport
    useEffect(() => {
        if (!scrollerRef.current)
            return;
        const container = scrollerRef.current;
        const availableWidth = container.offsetWidth;
        const currentScroll = container.scrollLeft;
        const scroll = left - currentScroll;
        // ignore all the scrolls for which the movement would be negligible
        if (scroll > availableWidth / 2 || scroll < 0)
            container.scrollBy({ left: scroll - 16, behavior: 'smooth' });
    }, [left]);
    // We should be rendering the underline only after having some tabs, so to avoid any unnecessary animation
    useEffect(() => setMounted(true), []);
    const updatedChildren = Children.map(children, (child, index) => {
        const item = child;
        if (!isValidElement(item))
            return null;
        if (item.type === Tab) {
            const key = item.key || index;
            const active = index === selectedIndex;
            const onClick = () => {
                handleChangeIndex?.(index);
                if (item.props.onClick)
                    item.props.onClick();
            };
            return cloneElement(item, { key, active, onClick });
        }
        return child;
    });
    return (React.createElement(TabNavigationContext.Provider, { value: { setLeft, setWidth } },
        React.createElement(OverflowScroll, { ref: scrollerRef },
            React.createElement(Container, { "$background": background },
                updatedChildren,
                mounted && React.createElement(Underline, { "$left": left, "$width": width, "$background": background })))));
};
export default TabList;
export function useTabNavigationContext() {
    const context = React.useContext(TabNavigationContext);
    if (context === void 0)
        throw new Error('useTabNavigationContext must be used within a TabNavigationContextProvider');
    return context;
}
