import React from 'react';

const EverybodyGets5Stars: React.FunctionComponent = () => (
	<svg xmlns={'http://www.w3.org/2000/svg'} fill={'none'} viewBox={'0 0 178 32'}>
		<path
			fill={'#FFBC3B'}
			d={
				'M15.05 2.93a1 1 0 011.9 0l2.42 7.43a1 1 0 00.95.7h7.82a1 1 0 01.59 1.8l-6.33 4.6a1 1 0 00-.36 1.12l2.41 7.44a1 1 0 01-1.54 1.12l-6.32-4.6a1 1 0 00-1.18 0l-6.32 4.6a1 1 0 01-1.54-1.12l2.41-7.44a1 1 0 00-.36-1.12l-6.33-4.6a1 1 0 01.6-1.8h7.81a1 1 0 00.95-.7l2.42-7.43zm36.5.1a1 1 0 011.9 0l2.31 7.33a1 1 0 00.95.7h7.53a1 1 0 01.6 1.8l-6.14 4.6a1 1 0 00-.36 1.1l2.34 7.42a1 1 0 01-1.56 1.1l-6.02-4.52a1 1 0 00-1.2 0l-6.02 4.52a1 1 0 01-1.56-1.1l2.34-7.42a1 1 0 00-.36-1.1l-6.14-4.6a1 1 0 01.6-1.8h7.53a1 1 0 00.95-.7l2.3-7.33zm36.5-.1a1 1 0 011.9 0l2.42 7.43a1 1 0 00.95.7h7.82a1 1 0 01.59 1.8l-6.33 4.6a1 1 0 00-.36 1.12l2.41 7.44a1 1 0 01-1.54 1.12l-6.32-4.6a1 1 0 00-1.18 0l-6.32 4.6a1 1 0 01-1.54-1.12l2.41-7.44a1 1 0 00-.36-1.12l-6.33-4.6a1 1 0 01.6-1.8h7.81a1 1 0 00.95-.7l2.42-7.43zm36.5.1a1 1 0 011.9 0l2.31 7.33a1 1 0 00.95.7h7.53a1 1 0 01.6 1.8l-6.14 4.6a1 1 0 00-.36 1.1l2.34 7.42a1 1 0 01-1.56 1.1l-6.02-4.52a1 1 0 00-1.2 0l-6.02 4.52a1 1 0 01-1.56-1.1l2.34-7.42a1 1 0 00-.36-1.1l-6.14-4.6a1 1 0 01.6-1.8h7.53a1 1 0 00.95-.7l2.3-7.33zm36.5-.1a1 1 0 011.9 0l2.42 7.43a1 1 0 00.95.7h7.82a1 1 0 01.59 1.8l-6.33 4.6a1 1 0 00-.36 1.12l2.41 7.44a1 1 0 01-1.53 1.12l-6.33-4.6a1 1 0 00-1.18 0l-6.32 4.6a1 1 0 01-1.54-1.12l2.41-7.44a1 1 0 00-.36-1.12l-6.33-4.6a1 1 0 01.6-1.8h7.81a1 1 0 00.95-.7l2.42-7.43z'
			}
		></path>
	</svg>
);

export default EverybodyGets5Stars;
