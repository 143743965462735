import { initialize } from '@iterable/web-sdk/dist/authorization';
import { track } from '@iterable/web-sdk/dist/events';
export default class IterableSDK {
    _logout;
    initialized;
    constructor() {
        this._logout = () => { };
        this.initialized = false;
    }
    async initializeIterable(userId, apiKey, token) {
        const { setUserID, logout } = initialize(apiKey, () => Promise.resolve(token));
        await setUserID(userId);
        this._logout = logout;
        this.initialized = true;
    }
    logout() {
        this._logout();
        this.initialized = false;
    }
    async trackEvent(event) {
        if (!this.initialized)
            return;
        await track(event);
    }
}
