export const DEFAULT_SHORT_URL_TTL = 1800; // 30 minutes in seconds
export const DEFAULT_LONG_URL_TTL = 3600; // 1 hour in seconds
export var FilePurpose;
(function (FilePurpose) {
    FilePurpose["identityDocument"] = "identity_document";
    FilePurpose["backOfDrivingLicense"] = "back_of_driving_license";
    FilePurpose["verificationSelfie"] = "verification_selfie";
    FilePurpose["vehiclePhoto"] = "vehicle_photo";
    FilePurpose["ncbProof"] = "ncb_proof";
    FilePurpose["residentialAddressProof"] = "residential_address_proof";
    FilePurpose["proofOfAddress"] = "proof_of_address";
})(FilePurpose || (FilePurpose = {}));
export const initialUploadState = {
    fileById: {},
    listByIdentifiers: {},
    uploadFile: {},
    createFileFlag: {},
    listFilesByUserId: {},
};
export const UploadActionTypes = {
    FILE_BY_ID: '@@platform/upload/FILE_BY_ID',
    FILE_BY_ID_SUCCESS: '@@platform/upload/FILE_BY_ID_SUCCESS',
    FILE_BY_ID_FAILURE: '@@platform/upload/FILE_BY_ID_FAILURE',
    LIST_BY_IDENTIFIERS: '@@platform/upload/LIST_BY_IDENTIFIERS',
    LIST_BY_IDENTIFIERS_SUCCESS: '@@platform/upload/LIST_BY_IDENTIFIERS_SUCCESS',
    LIST_BY_IDENTIFIERS_FAILURE: '@@platform/upload/LIST_BY_IDENTIFIERS_FAILURE',
    UPLOAD_FILE: '@@platform/upload/UPLOAD_FILE',
    UPLOAD_FILE_SUCCESS: '@@platform/upload/UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_FAILURE: '@@platform/upload/UPLOAD_FILE_FAILURE',
    CREATE_FILE_FLAG: '@@platform/upload/CREATE_FILE_FLAG',
    LIST_FILES_BY_USER_ID: '@@platform/upload/LIST_FILES_BY_USER_ID',
};
