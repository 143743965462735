import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { createQuoteRPC } from '../actions';
import { MotorCoverageActionTypes } from '../types';
export default function* watcherCreateQuoteRPC() {
    yield takeEvery(MotorCoverageActionTypes.CREATE_QUOTE_RPC, workerCreateQuoteRPC);
}
function* workerCreateQuoteRPC({ payload, meta, }) {
    const { ident } = meta;
    const client = yield getContext('api');
    try {
        const response = yield call([client, client.rpc], 'service-motor-coverage', '2/2017-11-23/create_quote', payload);
        yield put(createQuoteRPC.success(ident, response));
    }
    catch (error) {
        yield put(createQuoteRPC.failure(ident, error));
    }
}
