import React from 'react';
import { IllustrationWrap } from '../atoms/IllustrationWrap';
const Illustration = ({ illustrationUrl, children }) => {
    if (!illustrationUrl) {
        return React.createElement(IllustrationWrap, null, children);
    }
    return (React.createElement(IllustrationWrap, null,
        React.createElement("img", { src: illustrationUrl })));
};
export default Illustration;
