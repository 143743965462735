import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSinglePolicy } from '../context/SinglePolicyContext';
import { BigCardWrapper } from './Card';
import InfoCard from './InfoCard';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';
import { formatYearsMonths } from '~lib/frontend/helpers/format-years-months';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { userVehicleProfile } from '~lib/platform/vehicle-profile/store/actions';
import {
	formatCoords,
	useAbiCodeFormatter,
	useFormatMileage,
} from '~website/features/quote/components/quote-page/formatters';
import useReversiblePostcodeLookup from '~website/features/quote/hooks/use-reversible-postcode-lookup';
import useUser from '~website/hooks/use-user';

const PolicyUserCar = () => {
	const { userId } = useUser();
	const { vehicleId } = useSinglePolicy();

	const dispatch = useDispatch();
	const vehicle = useSelector(s => s.platform.vehicleProfile.userVehicleProfile?.[`${userId}@${vehicleId}`]);
	const vehicleStatus = useAsyncStateStatus(vehicle);
	const loading = vehicleStatus.is('pending') || vehicleStatus.is('fetching');

	const formatter = useAbiCodeFormatter();
	const mileage = useFormatMileage();

	const overnightStorageLocation = vehicle?.response?.userDeclared.storage.location;
	const formattedCoords = formatCoords(overnightStorageLocation);
	const { onLookupCoords, reverseGeocodingResult, reverseGeocodingResultStatus } = useReversiblePostcodeLookup();

	useEffect(() => {
		if (userId && vehicleId) dispatch(userVehicleProfile.request({ vehicleId, userId, revisionId: null }));
	}, [dispatch, vehicleId, userId]);

	useEffect(() => {
		if (!overnightStorageLocation || reverseGeocodingResultStatus !== 'pending') return;

		onLookupCoords(overnightStorageLocation);
	}, [onLookupCoords, overnightStorageLocation, reverseGeocodingResultStatus]);

	if (vehicleStatus.is('error')) return null;

	return (
		<BigCardWrapper>
			<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
				{'Your car'}
			</Typography>

			<Margin $marginTop={'small'}>
				{loading && <InfoCard fetching />}
				{!loading && (
					<InfoCard
						items={[
							[
								'Overnight storage',
								formatter('locationCodes', vehicle?.response.userDeclared.storage.type),
							],
							[
								'Storage location',
								reverseGeocodingResultStatus === 'response'
									? reverseGeocodingResult.response.postcode
									: formattedCoords,
							],
							['Annual mileage', mileage(vehicle?.response.userDeclared.annualMileage)],
							['Car owner', formatter('ownerCodes', vehicle?.response.userDeclared.owner.type)],
							[
								'Length of ownership',
								formatYearsMonths(vehicle?.response.userDeclared.owner.durationMonths),
							],
							[
								'Registered keeper',
								formatter('ownerCodes', vehicle?.response.userDeclared.registeredKeeperCode),
							],
						]}
					/>
				)}
			</Margin>
		</BigCardWrapper>
	);
};

export default PolicyUserCar;
