import React from 'react';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';

interface CardItem {
	title: string;
	body: string;
}

interface CardProps {
	title: string;
	subtitle: string;
	items: CardItem[];
}

const safeLength = (obj: unknown[] | string) => Array.from({ length: Array.isArray(obj) ? obj.length : 4 });

const InfoCards: React.FCWithChildren<CardProps> = ({ title, subtitle, items }) => (
	<CardSection>
		<Typography $type={'Heading.Large'} $color={'textOnSurfaceBackground'} $marginBottom={'large'}>
			{title}
		</Typography>
		<Typography $type={'Body.XLarge'} $color={'textOnSurfaceBackgroundMuted'}>
			{subtitle}
		</Typography>
		<CardGrid>
			{items.map((item, i) => (
				<Card key={i}>
					<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'} $bold $marginBottom={'10px'}>
						{item.title}
					</Typography>
					<Typography
						$type={'Body.Medium'}
						$color={'textOnSurfaceBackgroundMuted'}
						dangerouslySetInnerHTML={{ __html: item.body }}
					/>
				</Card>
			))}
		</CardGrid>
	</CardSection>
);

type GetterType = (key: string, fallback?: string) => string;

export const contentAdapter = (get: GetterType): CardProps => {
	const itemsIterator = safeLength(get('items'));
	const items = itemsIterator.map((_, i) => ({
		title: get(`items[${i}].title`),
		body: get(`items[${i}].body`),
	}));

	return {
		title: get('title'),
		subtitle: get('subtitle'),
		items,
	};
};

const CardSection = styled.div`
	text-align: center;
`;

const CardGrid = styled.div`
	max-width: 690px;
	margin: ${p => p.theme.spacing['3xLarge']} auto ${p => p.theme.spacing.extraLarge} auto;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	grid-column-gap: ${p => p.theme.spacing.large};
	grid-row-gap: ${p => p.theme.spacing.extraLarge};
	justify-items: stretch;

	@media (max-width: 767px) {
		grid-template-columns: 1fr;
	}
`;

const Card = styled.div`
	padding: ${p => p.theme.spacing.extraLarge};
	text-align: left;
	background: ${p => p.theme.ui.surface};
	box-shadow: 0px 4px 25px 0px #ceceea66;
	transition: 0.1s linear;
	border-radius: ${p => p.theme.borderRadius.regular};

	> img {
		width: 100%;
		max-width: 80px;
	}

	&:hover {
		transform: translateY(-2px);
	}
`;

export default InfoCards;
