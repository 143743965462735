import { all, fork, select, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from 'typesafe-actions/dist/types';

import { UserConfigActionTypes } from '~lib/platform/user-config/store/types';
import { UserConfigPayload } from '~lib/platform/user-config/types';
import { addEventObject } from '~website/helpers/gtm';
import { setupSentry } from '~website/helpers/sentry';
import { ApplicationState } from '~website/store';

function* watcherGetConfigByIdSuccess() {
	yield takeEvery(UserConfigActionTypes.GET_CONFIG_BY_ID_SUCCESS, workerGetConfigById);
}

function* workerGetConfigById({ payload }: PayloadAction<string, UserConfigPayload>) {
	const userId: string = yield select((state: ApplicationState) => state.internal.auth.user?.response);

	addEventObject({
		user_config_flags: payload.flags,
		user_config: payload.config,
	});
	setupSentry(userId, payload.flags);

	yield true;
}

export default function* userConfigHooksSaga() {
	yield all([fork(watcherGetConfigByIdSuccess)]);
}
