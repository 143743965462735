import React from 'react';
import styled, { css } from 'styled-components';

import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { DesignSystemAsset } from '~lib/frontend/types/content';
import { generateAssetAltDescription } from '~website/helpers/a11y';

const FirstColumn = styled.div`
	flex: 5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	@media (max-width: 767px) {
		min-width: 100%;
	}
`;

const SecondColumn = styled(FirstColumn)`
	flex: 4;
	align-items: center;
`;

const Wrapper = styled.div<{ $flip: boolean }>`
	display: flex;
	flex-direction: ${p => (p.$flip ? 'row-reverse' : 'row')};
	justify-content: flex-start;
	align-items: center;

	${p =>
		p.$flip &&
		css`
			@media (min-width: 768px) {
				${FirstColumn}:first-child {
					margin-left: 125px;
				}
			}
		`};

	@media (max-width: 767px) {
		flex-direction: column-reverse;

		${FirstColumn}:first-child {
			margin-top: ${p => p.theme.spacing.extraLarge};
		}
	}
`;

export const TwoColumnsImageTag = styled.img`
	width: 100%;
	max-width: 500px;
`;

const ImageAspectRatioDiv = styled.div`
	width: 100%;
	aspect-ratio: 1/1;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
`;

interface TwoColumnsLayoutProps {
	children: [React.ReactNode, React.ReactNode];
	flip?: boolean;
	id?: string;
}

const TwoColumnsLayout: React.FCWithChildren<TwoColumnsLayoutProps> = ({ children, flip, id }) => (
	<Wrapper $flip={flip} id={id}>
		<FirstColumn>{children[0]}</FirstColumn>
		<SecondColumn>{children[1]}</SecondColumn>
	</Wrapper>
);

interface TwoColumnsImageProps {
	asset: DesignSystemAsset;
}

export const TwoColumnsImage: React.FCWithChildren<TwoColumnsImageProps> = ({ asset }) => {
	const image = useDesignSystemAsset(asset);
	const altDescription = asset.description || generateAssetAltDescription(asset);

	return (
		<ImageAspectRatioDiv>
			<TwoColumnsImageTag src={image} alt={altDescription} loading={'lazy'} />
		</ImageAspectRatioDiv>
	);
};

export default TwoColumnsLayout;
