import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Markdown from '~lib/frontend/atoms/Markdown';
import Badge from '~lib/frontend/design-system/components/badge/Badge';
import Button from '~lib/frontend/design-system/components/button/Button';
import { ButtonType as CTAType, ButtonVariant } from '~lib/frontend/design-system/components/button/types';
import { IconType } from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { GetterType } from '~lib/frontend/types/content';
import BrandDesignContext from '~website/contexts/BrandDesignContext';
import { trackEvent } from '~website/features/analytics/store/actions';
import CardChecklist, { ShowChecklist } from '~website/features/builder/molecules/CardChecklist';

interface ButtonType {
	text: string;
	url: string;
	type?: CTAType;
	leading_icon?: IconType;
	trailing_icon?: IconType;
	variant?: ButtonVariant;
	track_click?: boolean;
	event_name?: string;
}

export interface CardProps {
	illustrationName?: string;
	title: string;
	body?: string;
	badge?: string;
	showChecklist?: ShowChecklist[];
	showButton?: ButtonType;
	cardUrl?: string;
}

const Card: React.FCWithChildren<CardProps> = props => {
	const { illustrationName, title, body, showChecklist, showButton, cardUrl, badge } = props;
	const illustration = useDesignSystemAsset({ type: 'illustration', value: illustrationName });
	const dispatch = useDispatch();
	const newDesign = useContext(BrandDesignContext);

	const onButtonClick = (showButton: ButtonType) => {
		if (showButton.track_click) {
			dispatch(
				trackEvent({
					eventName: showButton.event_name,
					eventPayload: {
						action: 'button_clicked',
					},
				})
			);
		}

		return;
	};

	return (
		<Wrapper $newDesign={newDesign} as={cardUrl ? Anchor : void 0} to={cardUrl ? cardUrl : void 0}>
			{illustration && <IllustrationImage src={illustration} alt={title} loading={'lazy'} />}
			{title && (
				<Typography $type={'Heading.XSmall'} $color={'textOnSurfaceBackground'} $marginBottom={'small'}>
					{title}
				</Typography>
			)}
			{badge && (
				<Badge key={'badge'} size={'small'} type={'primary'} muted>
					{badge}
				</Badge>
			)}
			{body && (
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $marginBottom={'small'}>
					<Markdown>{body}</Markdown>
				</Typography>
			)}
			<CardChecklist checklist={showChecklist} />
			{showButton && (
				<ButtonWrap>
					<Button
						as={Anchor}
						$type={showButton.type || 'primaryMuted'}
						// @ts-ignore
						to={showButton.url}
						onClick={() => onButtonClick(showButton)}
						$leadingIcon={showButton.leading_icon}
						$trailingIcon={showButton.trailing_icon}
						$variant={newDesign ? 'pill' : showButton.variant}
					>
						{showButton.text}
					</Button>
				</ButtonWrap>
			)}
		</Wrapper>
	);
};

export const contentAdapter = (get: GetterType): CardProps => {
	const illustrationName = get<string>('illustration_name');
	const title = get<string>('title');
	const body = get<string>('body');
	const badge = get<string>('badge');
	const button = get<string | false>('show_button.text', false);
	const showButton = button ? get<ButtonType>('show_button') : void 0;
	const cardUrl = get<string>('card_url');
	let showChecklist: ShowChecklist[] = void 0;
	const checkList = get<string[] | ShowChecklist[]>('show_checklist', []);

	if (checkList?.length)
		showChecklist = checkList.map(item => (typeof item === 'string' ? { label: item, checked: true } : item));

	return {
		illustrationName,
		title,
		body,
		badge,
		showChecklist,
		showButton,
		cardUrl,
	};
};

const ButtonWrap = styled.div`
	&& {
		flex: 1;
	}

	margin-top: ${p => p.theme.spacing.extraLarge};
	display: flex;
	width: 100%;
	justify-content: flex-end;
	flex-direction: column;
`;

const Wrapper = styled.div<{ $newDesign: boolean }>`
	flex: 1;
	text-align: left;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: ${p => p.theme.spacing.extraLarge};
	background: ${p => p.theme.ui.surface};
	border-radius: ${p => p.theme.borderRadius.regular};
	box-shadow: 0px 4px 25px ${p => p.theme.ui.blackFill}20;

	${p =>
		p.$newDesign &&
		`
		box-shadow: none;
		padding: 0 0;


		img {
			align-self: center;
			width: 150px;
			height: 150px;
		}
	`}

	& > * {
		flex: 0;
	}

	&,
	&:hover,
	&:visited {
		text-decoration: none;
		color: inherit;
	}
`;

const IllustrationImage = styled.img`
	width: 75px;
	height: 75px;
	margin-bottom: ${p => p.theme.spacing.regular};
`;

export default Card;
