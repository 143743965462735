import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import useSubsUtils from '../hooks/use-subs-utils';
import { FlattenPolicySubs } from '../hooks/use-subscriptions';
import { pastEndDate } from '../utils/formatDateTime';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Badge from '~lib/frontend/design-system/components/badge/Badge';
import Icon from '~lib/frontend/design-system/components/Icon';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { getPublicVehicle } from '~lib/platform/vehicle/store/actions';

interface PolicyCoreCardProps {
	sub: FlattenPolicySubs;
}

const PolicyCoreCard: React.FCWithChildren<PolicyCoreCardProps> = props => {
	const { sub } = props;
	const { policyId: subId, vehicleId } = sub;

	const dispatch = useDispatch();
	const vehicle = useSelector(s => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);
	const vehicleStatus = useAsyncStateStatus(vehicle);
	const loading = vehicleStatus.is('fetching');

	const { badgeType, timeline } = useSubsUtils(sub);

	const activePolicy = !pastEndDate(sub.endDate);
	const failed = 'paymentFailed' in sub.subInfo.actionsRequired;

	useEffect(() => {
		dispatch(getPublicVehicle.request({ id: vehicleId, bypassChecks: false }));
	}, [dispatch, vehicleId]);

	return (
		<ContentWrapper to={`/me/policy/${subId}`}>
			<div>
				{activePolicy && (
					<Margin $marginBottom={'regular'}>
						<Badge size={'small'} type={badgeType.type}>
							{badgeType.text}
						</Badge>
					</Margin>
				)}

				<Typography $type={'Label.Medium'} $bold $color={'textOnSurfaceBackground'}>
					{'Monthly subscription'}
				</Typography>

				<Margin $marginTop={'extraSmall'}>
					<Typography $display={'inline'} $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
						{loading && <SkeletonLoader width={'120px'} height={'18px'} />}
						{!loading && [vehicle?.response?.make, vehicle?.response?.model].join(' ')}
						{' · '}
					</Typography>
					<Typography $display={'inline'} $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
						{loading && <SkeletonLoader width={'80px'} height={'18px'} />}
						{!loading && vehicle?.response?.prettyVrm}
					</Typography>
				</Margin>

				<TimelineWrapper>
					<Typography $type={'Body.Medium'} $marginTop={'extraSmall'} $color={'textOnSurfaceBackgroundMuted'}>
						{timeline}
					</Typography>
				</TimelineWrapper>
			</div>

			<Icon
				$size={'18px'}
				$color={failed ? 'dangerFill' : 'primaryFill'}
				icon={activePolicy && failed ? 'ic_exclamation_circle' : 'ic_chevron_right_small'}
			/>
		</ContentWrapper>
	);
};

export default PolicyCoreCard;

const ContentWrapper = styled(Anchor)`
	text-decoration: none;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;

	& > :nth-child(1) {
		flex: 1 0 auto;
	}
`;

const TimelineWrapper = styled.div`
	flex: 1;
	min-width: 0;
	width: 290px;

	@media (min-width: 450px) {
		width: fit-content;
	}

	& > * {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
