import React, { Fragment, useContext } from 'react';
import styled from 'styled-components';

import Container from '../atoms/Container';
import TitleSegment, { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from '../atoms/TitleSegment';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { DesignSystemAsset, GetterType } from '~lib/frontend/types/content';
import VisionaryContext from '~website/contexts/VisionaryContext';

export interface SubHeaderHeroProps {
	asset: DesignSystemAsset;
	mask?: DesignSystemAsset;
	titleSegment: TitleSegmentProps;
}

const SubHeaderHero: React.FCWithChildren<SubHeaderHeroProps> = props => {
	const { asset, mask, titleSegment } = props;
	const isVideoAsset = asset.type === 'video';

	const visionary = useContext(VisionaryContext);
	const dsAsset = useDesignSystemAsset(asset);
	const maskDs = useDesignSystemAsset(mask);
	const maskAsset = isVideoAsset && mask && maskDs ? maskDs : void 0;

	return (
		<Fragment>
			<SubHeaderHeroWrap $visionary={visionary}>
				<Container>
					<Margin $marginLeft={'large'} $marginRight={'large'}>
						<TitleSegment {...titleSegment} centered />
					</Margin>
				</Container>
			</SubHeaderHeroWrap>
			<SubHeaderHeroImageWrap $visionary={visionary}>
				{isVideoAsset && (
					<SubHeaderHeroVideo
						controls={false}
						disablePictureInPicture
						playsInline
						autoPlay
						muted
						loop
						controlsList={'nodownload'}
						$mask={maskAsset}
					>
						<source src={dsAsset} />
						{"Sorry, your browser doesn't support embedded videos."}
					</SubHeaderHeroVideo>
				)}
				{!isVideoAsset && <SubHeaderHeroImage src={dsAsset} />}
			</SubHeaderHeroImageWrap>
		</Fragment>
	);
};

export const contentAdapter = (get: GetterType): SubHeaderHeroProps => {
	const asset = get<DesignSystemAsset>('asset');
	const mask = get<DesignSystemAsset>('mask');
	const titleSegment = titleSegmentContentAdapter((key, fallback) => get(`title_segment.${key}`, fallback));

	return {
		asset,
		mask,
		titleSegment,
	};
};

const SubHeaderHeroWrap = styled.div<{ $visionary: boolean }>`
	padding-top: 50px;
	background: ${p => (p.$visionary ? p.theme.ui.secondaryBackground : p.theme.ui.blankBackground)};
	position: relative;
	color: ${p => p.theme.ui.textOnSurfaceBackground};
`;

const SubHeaderHeroImageWrap = styled.div<{ $visionary: boolean }>`
	background: ${p => (p.$visionary ? p.theme.ui.secondaryBackground : p.theme.ui.blankBackground)};
	min-height: 200px;
	overflow: hidden;
	width: 100%;
	object-fit: contain;

	display: flex;
	justify-content: center;

	position: relative;
`;

const SubHeaderHeroVideo = styled.video<{ $mask?: string }>`
	min-height: 200px;
	height: intrinsic;
	overflow: hidden;
	width: 100%;
	max-width: 1400px;
	object-fit: contain;

	display: flex;
	justify-content: center;

	position: relative;

	${p => p.$mask && `-webkit-mask-box-image: url(${p.$mask})`};
`;

const SubHeaderHeroImage = styled.img`
	width: 1440px;
	margin-top: -80px;

	@media (max-width: 767px) {
		max-width: 800px;
		height: auto;
		height: intrinsic;
	}
`;

export default SubHeaderHero;
