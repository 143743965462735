import { put } from 'redux-saga/effects';

import * as actions from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';

export default createTakeEverySagaSet(actions.referralEmailTriggerEvent, function* referralEmailTriggerSaga() {
	const intercomEvent = 'referral_email_trigger';

	if (!window?.Intercom) return;

	window.Intercom('trackEvent', intercomEvent);

	yield put(
		actions.analyticsTrackingDebug({
			destination: 'intercom',
			payload: {
				event: intercomEvent,
			},
		})
	);
});
