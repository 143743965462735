import styled, { css } from 'styled-components';

interface MobileMenuProps {
	$open?: boolean;
	$visionary?: boolean;
	$fullHeight: boolean;
}

export const MobileMenu = styled.aside<MobileMenuProps>`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: ${p => p.theme.spacing.large};

	position: fixed;
	top: 62px;
	left: 0;
	right: 0;

	${p =>
		p.$fullHeight &&
		css`
			bottom: 0;
		`};

	overflow-y: auto;
	justify-content: flex-start;

	background: ${p => (p.$visionary ? p.theme.ui.secondaryBackground : p.theme.ui.blankBackground)};

	${({ $open }) => !$open && 'pointer-events: none;'};
	opacity: ${({ $open }) => ($open ? '1' : '0')};
	will-change: transform, opacity;
	border-bottom: 1px solid ${p => p.theme.ui.borderSeparatorOnFill};

	transition: all 0.2s ease-in-out;
	z-index: 5;

	transform: ${({ $open }) => ($open ? 'rotateX(0deg)' : 'rotateX(-30deg)')};
	transform-origin: 0px 0px;
	transform-style: preserve-3d;
	backface-visibility: hidden;
`;

export const BurgerWrap = styled.div`
	@media (min-width: 1050px) {
		display: none;
	}
`;

export const MobileItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	padding: ${p => p.theme.borderRadius.large} ${p => p.theme.borderRadius.small};
	border-radius: ${p => p.theme.borderRadius.regular};
	margin-top: ${p => p.theme.borderRadius.small};
	cursor: pointer;

	&:first-child {
		margin-top: 0;
	}

	> a {
		text-decoration: none;
		color: inherit;

		& > div {
			display: flex;
		}
	}
`;

export const MobileItemChild = styled.div<{ $visible?: boolean }>`
	padding: ${({ $visible, theme }) => ($visible ? `${theme.spacing.regular} ${theme.spacing.large}` : '0')};
	border-radius: ${p => p.theme.borderRadius.regular};
	margin-left: 25px;
	transform: ${({ $visible }) => ($visible === false ? 'translateX(-10%) scale(.98)' : 'translateX(0)')};
	opacity: ${({ $visible }) => ($visible === false ? '0' : '1')};
	transition: all 0.2s ease-in-out;
	max-height: ${({ $visible }) => ($visible === false ? '0' : '40px')};

	> a {
		text-align: left;
		text-decoration: none;
		color: inherit;
	}
`;

export const MobileStoresContainer = styled.div`
	margin: ${p => p.theme.spacing.extraLarge} 0 ${p => p.theme.spacing.large} 0;
`;
