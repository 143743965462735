import Cher from '@cuvva/cher';
import CuvvaClient from '@cuvva/cuvva-client';
import { call, getContext, put } from 'redux-saga/effects';

import { importAuthedUser, importRegisterUser } from '../actions';
import { userById } from '~lib/platform/auth/store/actions';
import { getUser } from '~lib/platform/auth/store/api';
import { User } from '~lib/platform/auth/types';
import { getConfigById } from '~lib/platform/user-config/store/actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { AppsFlyerSDK } from '~website/helpers/appsflyer-sdk';
import { addEventVariable } from '~website/helpers/gtm';

export default createTakeEverySagaSet(importAuthedUser.request, function* workerImportAuthedUser() {
	const client: CuvvaClient = yield getContext('api');
	const appsFlyer: AppsFlyerSDK = yield getContext('appsFlyer');

	try {
		if (!client.auth) return;

		const user: User = yield call(getUser, client, {
			userId: client.auth.userId,
			includeRemovedIdentifiers: false,
		});
		const emailVerified = user.identifiers.some(identifier => identifier.value);

		if (!emailVerified) {
			client.auth = null;

			return;
		}

		yield put(userById.success(`${user.id}-false`, user));
		yield put(importRegisterUser.success(user.id));
		yield put(
			getConfigById.request({
				userId: user.id,
			})
		);

		addEventVariable('user_id', user.id);
		appsFlyer.identify(user.id);
	} catch (error) {
		const cher = Cher.coerce(error);

		yield put(importRegisterUser.failure(cher));

		if (['token_used', 'invalid_client_id', 'unauthorized', 'token_not_found'].includes(cher.code)) {
			client.auth = null;

			yield put(importRegisterUser.request());
		}
	}
});
