import React, { useContext } from 'react';

import { LtmQuoteContext } from '../context';
import { formatAddress, formatBool, useAbiCodeFormatter } from '../formatters';
import InfoCard from '~website/features/policy/atoms/InfoCard';

const AboutYouInfoCard: React.FunctionComponent = () => {
	const { asyncStatus, quote } = useContext(LtmQuoteContext);
	const formatter = useAbiCodeFormatter();

	if (asyncStatus !== 'response') return <InfoCard fetching hideSeparator />;

	return (
		<InfoCard
			hideSeparator
			items={[
				['Employment status', formatter('employmentCodes', quote.response.summary.policyholder.employmentCode)],
				['Occupation', formatter('occupationCodes', quote.response.summary.policyholder.occupationCode)],
				['Home address', formatAddress(quote.response.summary.policyholder.residentialAddress)],
				['Home ownership', formatBool(quote.response.summary.policyholder.homeowner)],
				['Marital status', formatter('maritalCodes', quote.response.summary.policyholder.maritalStatus)],
				['Cars you own', quote.response.summary.policyholder.vehiclesOwned],
			]}
		/>
	);
};

export default AboutYouInfoCard;
