import subMonths from 'date-fns/subMonths';
import { formatYearsMonths } from '~lib/frontend/helpers/format-years-months';
import { range } from '~lib/frontend/helpers/numbers';
const fullMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
export const getYearByMonth = (month) => {
    const prevMonth = subMonths(new Date(), month);
    const years = prevMonth.getFullYear();
    return `${years}`;
};
const generateDate = (month) => {
    const prevMonth = subMonths(new Date(), month);
    const months = fullMonths[prevMonth.getMonth()];
    const years = prevMonth.getFullYear();
    return `${months} ${years}`;
};
const fullDateRange = (opt) => {
    if (!opt)
        return [];
    return range(opt.min, opt.max, opt.step).reduce((acc, month) => {
        if ([opt.min, opt.max].includes(month))
            return acc;
        const result = {
            label: generateDate(month),
            value: month,
        };
        return [...acc, result];
    }, []);
};
export const fullYearsRange = (opt) => {
    if (!opt)
        return [];
    return range(opt.min, opt.max, opt.step).reduce((acc, month) => {
        const date = subMonths(new Date(), month);
        const result = {
            label: date.getFullYear().toString(),
            value: date.getFullYear().toString(),
        };
        if (!acc.some(obj => obj.label === result.label))
            acc.push(result);
        return acc;
    }, []);
};
export const fullMonthsRange = (opt, year) => {
    if (!opt)
        return [];
    const dateRange = fullDateRange(opt);
    return dateRange.reduce((acc, item) => {
        const date = subMonths(new Date(), item.value);
        const yearDate = date.getFullYear().toString();
        if (yearDate === year) {
            acc.push({
                label: item.label.split(' ')[0],
                value: item.value,
            });
        }
        return acc;
    }, []);
};
export const getTimeOwnedOptions = (opt) => {
    if (!opt)
        return [];
    return range(opt.min, opt.max, opt.step).map((value, i, { length }) => ({
        label: monthsYearsDistance(value, i === length - 1),
        value,
    }));
};
const monthsYearsDistance = (months, last) => {
    if (months === 0)
        return 'Less than a month ago';
    if (last)
        return `More than ${formatYearsMonths(months)} ago`;
    return `${formatYearsMonths(months)}${last ? ' or more' : ''}`;
};
