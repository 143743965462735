import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import { oneLink, reapplyParamsIfExternalUrl } from '~website/helpers/source-tracking';

interface AppStoreButtonsSegmentProps {
	url?: { appstore: string; playstore: string };
	width?: string;
	align?: 'left' | 'center';
	applyParams?: boolean;
}

const AppStoreButtonsSegment: React.FCWithChildren<AppStoreButtonsSegmentProps> = ({
	url,
	width = '162px',
	align,
	applyParams = true,
}) => {
	const appStore = useContentAsset('website_core', 'stores/appstore.svg');
	const playStore = useContentAsset('website_core', 'stores/playstore.svg');
	const sourceTracking = useSelector(s => s.internal.analytics.sourceTracking);
	const location = useLocation();
	const playstoreUrl = url?.playstore ?? oneLink.playstore;
	const appstoreUrl = url?.appstore ?? oneLink.appstore;
	const playstoreUrlWithParams = reapplyParamsIfExternalUrl(playstoreUrl, sourceTracking, location.pathname);
	const appstoreUrlWithParams = reapplyParamsIfExternalUrl(appstoreUrl, sourceTracking, location.pathname);

	return (
		<Wrapper $width={width} $align={align}>
			<Anchor href={applyParams ? appstoreUrlWithParams : url.appstore} rel={'nofollow'}>
				<img src={appStore} alt={'Download on the App Store'} width={width} height={'auto'} />
			</Anchor>
			<Anchor href={applyParams ? playstoreUrlWithParams : url.playstore} rel={'nofollow'}>
				<img src={playStore} alt={'Get it on Google Play'} width={width} height={'auto'} />
			</Anchor>
		</Wrapper>
	);
};

const Wrapper = styled.div<{ $width: string; $align: string }>`
	display: flex;
	flex-wrap: wrap;
	justify-content: ${p => p.$align};
	gap: ${p => p.theme.spacing.small};
`;

export default AppStoreButtonsSegment;
