import React from 'react';
import styled from 'styled-components';

import { SectionHeaderText } from '../atoms/HiringOfferHeaderWrapper';
import { SectionWrapper } from '../atoms/ReportingToWrapper';
import Typography from '~lib/frontend/design-system/components/Typography';

const HiringOfferFooter: React.FunctionComponent = () => (
	<SectionWrapper>
		<Wrapper>
			<SectionHeaderText>{"We can't wait to work with you"}</SectionHeaderText>
			<Typography $type={'Heading.Small'} $color={'textHighlight'}>
				{'Reply to your offer email to let us know your decision'}
			</Typography>
		</Wrapper>
	</SectionWrapper>
);

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 100%;
`;

export default HiringOfferFooter;
