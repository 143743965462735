import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import SteppedProgress from '../SteppedProgress';
import HeaderBar from './HeaderBar';
import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';
import Typography from '~lib/frontend/design-system/components/Typography';
import { ContentContainer } from '~website/components/SplitUI';
import useAsyncPayable from '~website/features/quote/hooks/use-async-payable';
import { monzoMoney } from '~website/helpers/monies';

export interface PricingHeaderBarProps {
	hideProgress?: boolean;
	hideEstimatedPrice?: boolean;
}

const PricingHeaderBar = ({ hideProgress, hideEstimatedPrice }: PricingHeaderBarProps) => {
	const product = useSelector(s => s.internal.quote.state.product);
	const ltmEstimate = useSelector(s => s.internal.quote.estimate.ltm);
	const { fetching, error, totalPayable, nonSmartPricing } = useAsyncPayable(ltmEstimate);

	if (product !== 'ltm' || error || (!fetching && totalPayable === void 0)) return <HeaderBar sticky />;

	return (
		<React.Fragment>
			<HeaderBar />

			{!hideEstimatedPrice && (
				<EstimateBarWrap>
					<PricingContent>
						<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'} $bold>
							{'Monthly estimate'}
						</Typography>

						<PricingWrap>
							{fetching && <SkeletonLoader width={'64px'} height={'18px'} />}

							{!fetching && typeof nonSmartPricing === 'number' && (
								<Typography
									$type={'Body.Small'}
									$color={'textOnSurfaceBackgroundMuted'}
									$textDecoration={'line-through'}
								>
									{monzoMoney(nonSmartPricing)}
								</Typography>
							)}

							{!fetching && (
								<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
									{monzoMoney(totalPayable)}
								</Typography>
							)}
						</PricingWrap>
					</PricingContent>
				</EstimateBarWrap>
			)}

			{!hideProgress && (
				<StyledContentContainer>
					<SteppedProgress />
				</StyledContentContainer>
			)}
		</React.Fragment>
	);
};

const EstimateBarWrap = styled.div`
	position: sticky;
	top: 0;
	z-index: 10;

	background: ${p => p.theme.ui.surface};
	display: flex;
	flex-direction: column;

	@media (min-width: 1024px) {
		display: none;
	}
`;

const PricingContent = styled.div`
	flex: 1;
	padding: ${p => `${p.theme.spacing.regular} ${p.theme.spacing.large}`};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};
`;

const PricingWrap = styled.div`
	display: flex;
	gap: ${p => p.theme.spacing.regular};
	min-height: 30px;
	align-items: center;

	& > * {
		flex: 0 0 auto;
	}
`;

const StyledContentContainer = styled(ContentContainer)`
	margin: ${p => p.theme.spacing.large} auto 0;
	width: 100%;
	display: block;

	@media (min-width: 1024px) {
		display: none;
	}
`;

export default PricingHeaderBar;
