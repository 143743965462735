import camelCase from 'lodash/camelCase';
import { put, select } from 'redux-saga/effects';

import { trackScreenView } from '../actions';
import * as actions from '../actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { flows } from '~website/features/quote/flows';
import { InternalQuoteState as QuoteState } from '~website/features/quote/store/types';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(actions.trackQuoteScreenView, function* quoteScreenView({ payload }) {
	const qState: QuoteState = yield select((s: ApplicationState) => s.internal.quote);
	const flow = qState.state.flow || qState.state.recentFlow;
	const product = flows?.[flow]?.product;

	const eventPayload = {
		screen_name: payload?.screenName,
		product: product ? product : 'none',
		product_category: 'motor',
		platform: 'website',
	};

	const eventName = `${eventPayload.product}_screenView_${camelCase(eventPayload.screen_name)}`;

	yield put(
		trackScreenView({
			eventName,
			eventPayload,
		})
	);
});
