import { all, call, put, select, take } from 'redux-saga/effects';

import { quoteActions, stepActions } from '../actions';
import { PreloadedVehicle } from '../types';
import { abiCodes, errorCodes } from '~lib/platform/code-mapping/store/actions';
import { AbiCodesResponse, ErrorCode } from '~lib/platform/code-mapping/types';
import { config } from '~lib/platform/ltm/store/actions';
import type { UseClass } from '~lib/platform/ltm/types';
import { getPublicVehicle } from '~lib/platform/vehicle/store/actions';
import { PublicVehicle } from '~lib/platform/vehicle/types';
import { getItem } from '~lib/shared/helpers/local-storage';
import { createTakeLatestSagaSet, requestAndTake } from '~lib/shared/redux/sagas';
import { AsyncState } from '~lib/shared/redux/types/state';
import {
	quoteRecentFlow,
	quoteRecentNCB,
	quoteRecentVehiclesKey,
	quoteStartDate,
	quoteUseClass,
} from '~website/constants/storage';
import { ApplicationState } from '~website/store';
import { GetUserActionTypes } from '~website/store/auth/types';

export default createTakeLatestSagaSet(quoteActions.fetchPreferences.request, function* prefetchSaga() {
	let userState: AsyncState<string> = yield select((state: ApplicationState) => state.internal.auth.user);

	if (userState.fetching || !userState.response) {
		yield take(GetUserActionTypes.IMPORT_REGISTER_USER_SUCCESS);

		userState = yield select((state: ApplicationState) => state.internal.auth.user);
	}

	const userId = userState?.response;

	yield all([
		call(loadAbiCodes),
		call(loadConfigLTM, userId),
		call(loadErrorCodes),
		call(loadRecentFlow),
		call(loadRecentNCB),
		call(loadRecentVehicles),
		call(loadStartDate),
		call(loadUseClass),
	]);

	yield put(quoteActions.fetchPreferences.success());
});

function* loadRecentNCB() {
	const ncb: number = yield select((s: ApplicationState) => s.internal.quote.state.ncb);

	if (ncb) return;

	const ncbStorage = getItem<number | null>(quoteRecentNCB, 'sessionStorage');

	if (!ncbStorage && ncbStorage !== 0) return;

	yield put(stepActions.setNCBYears(ncbStorage));
}

function* loadUseClass() {
	const useClass: string = yield select((s: ApplicationState) => s.internal.quote.state.useClass);

	if (useClass) return;

	const useClassStorage = getItem<UseClass>(quoteUseClass, 'sessionStorage');

	if (!useClassStorage) return;

	yield put(stepActions.setUseClass(useClassStorage));
}

function* loadStartDate() {
	const startDate: string = yield select((s: ApplicationState) => s.internal.quote.state.startDate);

	if (startDate) return;

	const loadStartDate = getItem<{ date: string; createdAt: string }>(quoteStartDate, 'sessionStorage');

	if (!loadStartDate?.date) return;

	// check if we didn't pass the midnight
	const storageDay = loadStartDate.createdAt.split('T')[0];
	const today = new Date().toISOString().split('T')[0];

	if (storageDay !== today) return;

	yield put(stepActions.setStartDate(loadStartDate.date));
}

function* loadRecentVehicles() {
	const recentVehiclesStore: PreloadedVehicle[] = yield select(
		(s: ApplicationState) => s.internal.quote.state.recentVehicles
	);
	const storeVehicleId: string = yield select((s: ApplicationState) => s.internal.quote.state.vehicleId);
	const recentVehiclesStorage = getItem<string[]>(quoteRecentVehiclesKey);

	if (!recentVehiclesStorage || !Array.isArray(recentVehiclesStorage)) {
		yield put(quoteActions.setRecentVehicles([]));

		return;
	}

	if (recentVehiclesStore?.length > 0 && !storeVehicleId)
		yield put(quoteActions.setVehicle({ vehicleId: recentVehiclesStore[0].vehicleId }));

	if (recentVehiclesStore?.length > 0) return;

	const vehicles: PreloadedVehicle[] = [];
	const uniqueVehicles = recentVehiclesStorage.filter((value, index, self) => self.indexOf(value) === index);

	for (const vehicleId of uniqueVehicles) {
		try {
			let vehicle: PublicVehicle;

			vehicle = yield select(
				(s: ApplicationState) => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]?.response
			);

			if (!vehicle) {
				vehicle = yield requestAndTake(getPublicVehicle.request({ id: vehicleId, bypassChecks: false }));
			}

			const qqVehicle: PreloadedVehicle = {
				vrm: vehicle.prettyVrm,
				vehicleId: vehicle.id,
				model: vehicle.model,
				make: vehicle.make,
			};

			vehicles.push(qqVehicle);
		} catch (e) {
			// best effort..
		}
	}

	if (vehicles.length > 0 && !storeVehicleId)
		yield put(quoteActions.setVehicle({ vehicleId: vehicles[0].vehicleId }));

	yield put(quoteActions.setRecentVehicles(vehicles));
}

function* loadRecentFlow() {
	const recentFlow = getItem<string>(quoteRecentFlow, 'sessionStorage');

	if (!recentFlow) return;

	yield put(quoteActions.setRecentFlow(recentFlow));
}

function* loadConfigLTM(userId: string) {
	yield put(
		config.request({
			client: {
				platform: 'web',
				build: '1',
				version: '1.1.1',
			},
			userId,
		})
	);
}

function* loadErrorCodes() {
	const errorCodesState: AsyncState<ErrorCode[]> = yield select(
		(state: ApplicationState) => state.platform.codeMapping.errorCodes
	);

	if (errorCodesState && (errorCodesState.response || errorCodesState.fetching)) return;

	yield put(errorCodes.request(void 0));
}

function* loadAbiCodes() {
	const abiCodesState: AsyncState<AbiCodesResponse> = yield select(
		(state: ApplicationState) => state.platform.codeMapping.abiCodes
	);

	if (abiCodesState && (abiCodesState.response || abiCodesState.fetching)) return;

	yield put(abiCodes.request(void 0));
}
