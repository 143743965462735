/* eslint-disable @typescript-eslint/naming-convention */
import { css } from 'styled-components';
import fonts from './fonts';
export const createTypographyStyles = (style) => ({
    'Heading.XXLarge': () => css `
		font-family: ${fonts.heading};
		font-weight: 900;
		letter-spacing: -0.005em;

		font-size: 64px;
		line-height: 72px;

		@media (min-width: 768px) {
			font-size: 80px;
			line-height: 88px;
		}
	`,
    'Heading.XLarge': () => css `
		font-family: ${fonts.heading};
		font-weight: 900;
		letter-spacing: -0.005em;

		font-size: 48px;
		line-height: 56px;

		@media (min-width: 768px) {
			font-size: 64px;
			line-height: 72px;
		}
	`,
    'Heading.Large': () => css `
		font-family: ${fonts.heading};
		font-weight: 700;
		letter-spacing: -0.005em;

		font-size: 32px;
		line-height: 36px;

		@media (min-width: 768px) {
			font-weight: 900;
			font-size: 48px;
			line-height: 56px;
		}
	`,
    'Heading.Medium': () => css `
		font-family: ${fonts.heading};
		font-weight: 700;
		letter-spacing: -0.005em;

		font-size: 24px;
		line-height: 32px;

		@media (min-width: 768px) {
			font-size: 32px;
			line-height: 36px;
		}
	`,
    'Heading.Small': () => css `
		font-family: ${fonts.heading};
		font-weight: 700;
		letter-spacing: -0.005em;

		font-size: 20px;
		line-height: 28px;

		@media (min-width: 768px) {
			font-size: 24px;
			line-height: 32px;
		}
	`,
    'Heading.XSmall': () => css `
		font-family: ${fonts.heading};
		font-weight: 700;
		letter-spacing: -0.005em;

		font-size: 18px;
		line-height: 24px;

		@media (min-width: 768px) {
			font-size: 20px;
			line-height: 28px;
		}
	`,
    'Body.XLarge': opts => css `
		// add mono
		font-family: ${fonts[style]};
		font-weight: ${bodyBold(opts?.$bold, style)};
		letter-spacing: ${style === 'expressive' ? '0.02em' : '0'};

		font-size: 18px;
		line-height: 28px;

		@media (min-width: 768px) {
			font-size: 20px;
			line-height: 32px;
		}
	`,
    'Body.Large': opts => css `
		// add mono
		font-family: ${fonts[style]};
		font-weight: ${bodyBold(opts?.$bold, style)};
		letter-spacing: ${style === 'expressive' ? '0.02em' : '0'};

		font-size: 16px;
		line-height: 24px;

		@media (min-width: 768px) {
			font-size: 18px;
			line-height: 28px;
		}
	`,
    'Body.Medium': opts => css `
		// add mono
		font-family: ${fonts[style]};
		font-weight: ${bodyBold(opts?.$bold, style)};
		letter-spacing: ${style === 'expressive' ? '0.02em' : '0'};

		font-size: 16px;
		line-height: 24px;
	`,
    'Body.Small': opts => css `
		// add mono
		font-family: ${fonts[style]};
		font-weight: ${bodyBold(opts?.$bold, style)};
		letter-spacing: ${style === 'expressive' ? '0.03em' : '0'};

		font-size: 14px;
		line-height: 20px;
	`,
    'Body.XSmall': opts => css `
		// add mono
		font-family: ${fonts[style]};
		font-weight: ${bodyBold(opts?.$bold, style)};
		letter-spacing: ${style === 'expressive' ? '0.03em' : '0'};

		font-size: 12px;
		line-height: 20px;
	`,
    'Label.Large': () => css `
		font-family: ${fonts[style]};
		font-weight: 500;
		letter-spacing: ${style === 'expressive' ? '0.03em' : '0'};

		font-size: 18px;
		line-height: 24px;
	`,
    'Label.Medium': () => css `
		font-family: ${fonts[style]};
		font-weight: ${style === 'expressive' ? '500' : '600'};
		letter-spacing: ${style === 'expressive' ? '0.02em' : '0'};

		font-size: 16px;
		line-height: 20px;
	`,
    'Label.Small': () => css `
		font-family: ${fonts[style]};
		font-weight: ${style === 'expressive' ? '500' : '600'};
		letter-spacing: ${style === 'expressive' ? '0.02em' : '0'};

		font-size: 14px;
		line-height: 16px;
	`,
    'Label.XSmall': () => css `
		font-family: ${fonts[style]};
		font-weight: ${style === 'expressive' ? '500' : '600'};
		letter-spacing: ${style === 'expressive' ? '0.03em' : '0'};

		font-size: 12px;
		line-height: 16px;
	`,
});
function bodyBold(bold, style) {
    if (!bold)
        return 400;
    if (style === 'expressive')
        return 500;
    return 600;
}
