import React from 'react';

import QuoteInputDecorator from '../../../components/atoms/QuoteInputDecorator';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';
import SelectInput from '~lib/frontend/design-system/components/input/molecules/SelectInput';
import { SelectOption } from '~lib/frontend/design-system/components/input/types';
import { IncidentCategory as IncidentCategoryType } from '~lib/platform/incident/types';
import { useIncidentItemInternalChangeRequest } from '~website/features/quote/hooks/use-incident-change-request';
import useOnPageError from '~website/features/quote/hooks/use-on-page-error';
import notEmptyValidator from '~website/features/quote/validators/not-empty';

const categories: SelectOption<IncidentCategoryType>[] = [
	{
		value: 'accident',
		label: 'Accident',
	},
	{
		value: 'theft',
		label: 'Theft',
	},
	{
		value: 'glass',
		label: 'Glass',
	},
	{
		value: 'fire',
		label: 'Fire',
	},
	{
		value: 'environmental',
		label: 'Environmental',
	},
	{
		value: 'malicious',
		label: 'Malicious',
	},
	{
		value: 'misc',
		label: 'Misc',
	},
];

const IncidentCategory: React.FCWithChildren<{ index: number }> = ({ index }) => {
	const { onChange, validation, value } = useIncidentItemInternalChangeRequest(index, 'category', notEmptyValidator);
	const { onFocus, onBlur, error } = useOnBlurError(validation);
	const pageError = useOnPageError(validation);

	return (
		<QuoteInputDecorator label={'Type of incident'} error={error || pageError} onFocus={onFocus} onBlur={onBlur}>
			<SelectInput
				value={value}
				options={categories}
				placeholder={'Choose from list'}
				onChange={({ value }) => onChange(value)}
			/>
		</QuoteInputDecorator>
	);
};

export default IncidentCategory;
