import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cher from '@cuvva/cher';
import get from 'lodash/get';
import has from 'lodash/has';

import { PageCtx } from '../contexts/PageCtx';
import { pageActions, stepActions } from '../store/actions';
import type { SettableVehicleProfileValue, VehicleProfileValue } from '../store/types';
import useFriendlyError from './use-friendly-error';

interface HookReturn<T = string> {
	value: T;
	validation: string | undefined;
	onChange: (value: T) => void;
	setFieldError: (error: Cher) => void;
}

type Validator<T> = (value: T) => Cher | void;

function useVehicleProfileInternalChangeRequest<T extends VehicleProfileValue, K extends keyof T, K2 extends void>(
	key: K,
	subkey?: void,
	validator?: Validator<T[K]>
): HookReturn<T[K]>;

// eslint-disable-next-line no-redeclare
function useVehicleProfileInternalChangeRequest<
	T extends VehicleProfileValue,
	K extends keyof T,
	K2 extends keyof T[K],
>(key: K, subkey?: K2, validator?: Validator<T[K][K2]>): HookReturn<T[K][K2]>;

// eslint-disable-next-line no-redeclare
function useVehicleProfileInternalChangeRequest<
	T extends VehicleProfileValue,
	K extends keyof T,
	K2 extends keyof T[K] | void,
>(
	key: K,
	subkey?: K2,
	validator?: Validator<K2 extends string ? T[K][K2] : T[K]>
): HookReturn<K2 extends string ? T[K][K2] : T[K]> {
	const dispatch = useDispatch();

	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const internalChangeRequestId = page.internalChangeRequestId;
	const userId = useSelector(s => s.internal.auth.user.response);

	const vehicleId = useSelector(s => s.internal.quote.state.vehicleId);
	const wholeKey = [key, subkey].filter(Boolean).join('.') as SettableVehicleProfileValue;

	const onChange = useCallback(
		(value: K2 extends string ? T[K][K2] : T[K]) => {
			dispatch(
				stepActions.setVehicleProfileValue({
					key: wholeKey,
					value,
					userId,
					vehicleId,
					internalChangeRequestId,
				})
			);
		},
		[dispatch, internalChangeRequestId, vehicleId, userId, wholeKey]
	);

	const value: K2 extends string ? T[K][K2] : T[K] = useVehicleProfileInternalChangeRequestGetter(key, subkey);
	const validation = page?.fields?.[wholeKey]?.error;

	useEffect(() => {
		dispatch(
			pageActions.registerField({
				field: wholeKey,
				pageId,
			})
		);
	}, [dispatch, wholeKey, pageId]);

	useEffect(() => {
		if (!validator) return void 0;

		const error = validator(value);

		dispatch(
			stepActions.setFieldError({
				field: wholeKey,
				pageId,
				error,
			})
		);

		return () => {
			dispatch(
				stepActions.setFieldError({
					field: wholeKey,
					pageId,
					error: void 0,
				})
			);
		};
	}, [dispatch, validator, wholeKey, value, pageId]);

	const setFieldError = useCallback(
		(error: Cher) =>
			dispatch(
				stepActions.setFieldError({
					field: wholeKey,
					pageId,
					error,
				})
			),
		[dispatch, wholeKey, pageId]
	);

	return {
		onChange,
		value,
		validation: useFriendlyError(validation, 'vehicleProfile', wholeKey),
		setFieldError,
	};
}

function useVehicleProfileInternalChangeRequestGetter<
	T extends VehicleProfileValue,
	K extends keyof T,
	K2 extends keyof T[K] | void,
>(key: K, subkey?: K2): K2 extends string ? T[K][K2] : T[K] {
	const wholeKey = [key, subkey].filter(Boolean).join('.') as SettableVehicleProfileValue;

	const pageId = useContext(PageCtx);
	const page = useSelector(s => s.internal.quote.pages[pageId]);
	const internalChangeRequestId = page.internalChangeRequestId;
	const internalChangeRequest = useSelector(s => s.internal.quote.internalChangeRequest);
	const userId = useSelector(s => s.internal.auth.user.response);

	const vehicleId = useSelector(s => s.internal.quote.state.vehicleId);
	const vehicleProfile = useSelector(s => s.platform.vehicleProfile.userVehicleProfile[`${userId}@${vehicleId}`]);

	const cr = internalChangeRequest[internalChangeRequestId];

	if (has(cr?.vehicleProfile?.[vehicleId], wholeKey))
		return get(cr?.vehicleProfile?.[vehicleId], wholeKey) as K2 extends string ? T[K][K2] : T[K];

	return get(vehicleProfile?.response?.userDeclared, wholeKey) as K2 extends string ? T[K][K2] : T[K];
}

export default useVehicleProfileInternalChangeRequest;
