import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import TitleSegment, { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from './TitleSegment';
import Markdown from '~lib/frontend/atoms/Markdown';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useResizeObserver from '~lib/frontend/hooks/use-resize-observer';
import { GetterType } from '~lib/frontend/types/content';

interface Item {
	id?: string;
	title: string;
	items: string[];
}

interface TitleSegmentAccordionProps extends TitleSegmentProps {
	items: Item[];
}

const TitleSegmentAccordion: React.FCWithChildren<TitleSegmentAccordionProps> = props => {
	const { items, ...titleSegmentProps } = props;
	const [open, setOpen] = useState(0);

	return (
		<TitleSegment {...titleSegmentProps}>
			<Wrapper>
				{items.map((item, i) => (
					<AccordionCard
						key={`${i}:${item}`}
						id={item.id}
						open={open === i}
						onToggle={() => setOpen(open === i ? void 0 : i)}
						title={item.title}
					>
						{item.items?.length > 0 && (
							<div>
								{item.items.map((item, i) => (
									<ListWrapper key={i}>
										<Icon
											icon={'ic_check_circle_fill'}
											$color={'textOnSurfaceBackgroundMuted'}
											$size={'10px'}
											$marginRight={'regular'}
											$marginTop={'small'}
										/>
										<Typography $type={'Body.Large'} $color={'textOnSurfaceBackgroundMuted'}>
											<Markdown>{item}</Markdown>
										</Typography>
									</ListWrapper>
								))}
							</div>
						)}
					</AccordionCard>
				))}
			</Wrapper>
		</TitleSegment>
	);
};

export const contentAdapter = (get: GetterType): TitleSegmentAccordionProps => {
	const items = get<Item[]>('items');
	const titleSegment = titleSegmentContentAdapter(get);

	return {
		items,
		...titleSegment,
	};
};

interface AccordionCardProps {
	id?: string;
	title: string;
	onToggle: () => void;
	open: boolean;
}

const AccordionCard: React.FCWithChildren<AccordionCardProps> = ({ id, title, onToggle, open, children }) => {
	const bodyRef = useRef<HTMLDivElement>();
	const [bodyHeight, setBodyHeight] = useState<number>();

	const [, actualHeight] = useResizeObserver(bodyRef);

	useEffect(() => {
		if (actualHeight) setBodyHeight(actualHeight + 24);
	}, [actualHeight]);

	const updateRef = (e: HTMLDivElement) => {
		if (!e) return;

		bodyRef.current = e;

		if (e.offsetHeight) setBodyHeight(e.offsetHeight + 24);
	};

	return (
		<AccordionCardWrap aria-expanded={open} id={id}>
			<AccordionCardHeading $open={open} onClick={onToggle}>
				<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'} $marginRight={'large'}>
					{title}
				</Typography>
				<Icon icon={'ic_chevron_down_sm'} $size={'32px'} $color={'neutralFillMuted'} />
			</AccordionCardHeading>
			<AccordionCardBody $open={open} $height={bodyHeight}>
				<div ref={c => updateRef(c)}>{children}</div>
			</AccordionCardBody>
		</AccordionCardWrap>
	);
};

const Wrapper = styled.div`
	position: relative;
	margin: 50px auto 0 auto;
	text-align: left;
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.extraLarge};
	width: 100%;
`;

const AccordionCardWrap = styled.div`
	border: 1px solid ${p => p.theme.ui.borderSeparator};
	box-shadow: ${p => p.theme.boxShadow.small};
	border-radius: ${p => p.theme.borderRadius.large};
	padding: ${p => p.theme.spacing.extraLarge};
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const AccordionCardHeading = styled.div<{ $open: boolean }>`
	user-select: none;
	display: flex;
	cursor: pointer;
	justify-content: space-between;

	> :first-child {
		flex: 1;
	}

	> :nth-child(2) {
		margin-left: auto;
		flex: 0 0 32px;
		transform: ${p => (p.$open ? 'rotate(180deg)' : 'rotate(0deg)')};
		transition: all 0.2s cubic-bezier(0, 1, 0, 1);
	}
`;

const AccordionCardBody = styled.div<{ $open: boolean; $height: number }>`
	max-height: ${p => (p.$open ? `${p.$height}px` : '0')};
	overflow: hidden;
	transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
`;

const ListWrapper = styled.div`
	display: flex;
	margin-top: ${p => p.theme.spacing.large};
	align-items: flex-start;
`;

export default TitleSegmentAccordion;
