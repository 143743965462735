import React, { useEffect, useState } from 'react';
import Cher from '@cuvva/cher';
import styled from 'styled-components';

import QuoteInputDecorator from '../../../../components/atoms/QuoteInputDecorator';
import Button from '~lib/frontend/design-system/components/button/Button';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { AsyncState } from '~lib/shared/redux/types/state';
import usePostcodeLocationSearch from '~website/features/quote/hooks/use-postcode-location-search';
import { LookupByPostcodeResponse } from '~website/features/quote/store/types';

interface SearchAddressProps {
	label?: string;
	error: string | undefined;
	onEnterManually: () => void;
	onDatasetUpdate: (res: AsyncState<LookupByPostcodeResponse>) => void;
}

const SearchAddress: React.FCWithChildren<SearchAddressProps> = props => {
	const { label = 'Postcode', error, onEnterManually, onDatasetUpdate } = props;
	const [postcodeSearch, setPostcodeSearch] = useState('');
	const { onChange, postcodeFormatted, result } = usePostcodeLocationSearch();
	const searchStatus = useAsyncStateStatus(result);

	// Format the typed postcode to be correct (correct space position, for example)
	useEffect(() => {
		if (!postcodeFormatted) return;

		setPostcodeSearch(postcodeFormatted);
	}, [postcodeFormatted]);

	useEffect(() => {
		onDatasetUpdate(result);

		if (!result?.response) return;

		// If a postcode is valid-looking but in reality it isn't, this will be triggered
		if (result?.response?.addresses.length === 0) {
			onDatasetUpdate({
				error: new Cher('invalid'),
				fetching: false,
			});
		}
	}, [onDatasetUpdate, result]);

	const onFindAddress = () => onChange(postcodeSearch);
	const onKeyDown = (e: React.KeyboardEvent) => {
		if (e.key !== 'Enter') return;

		onFindAddress();
	};

	return (
		<div>
			<QuoteInputDecorator label={label} error={error && 'The postcode should look something like ‘E2 8JF’'}>
				<Aligner>
					<TextInput
						value={postcodeSearch}
						size={8}
						name={'postcode-address-search'}
						disabled={searchStatus.is('fetching')}
						onChange={e => setPostcodeSearch(e.target.value.toLocaleUpperCase())}
						onKeyDown={onKeyDown}
					/>

					<Button
						$type={'primary'}
						$size={'large'}
						onClick={onFindAddress}
						$loading={searchStatus.is('fetching')}
						disabled={!postcodeSearch || searchStatus.is('fetching')}
					>
						{'Find address'}
					</Button>
				</Aligner>
			</QuoteInputDecorator>
			<Button $type={'text'} $size={'medium'} onClick={onEnterManually}>
				{'Enter address manually'}
			</Button>
		</div>
	);
};

const Aligner = styled.div`
	display: flex;
	gap: ${p => p.theme.spacing.small};

	& > * {
		flex: 1;
	}

	& > :last-child {
		flex: 0;
	}
`;

export default SearchAddress;
