import styled from 'styled-components';

export const GridLayout = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: ${p => p.theme.spacing.large};
	grid-column-gap: ${p => p.theme.spacing.extraLarge};
	margin: 0 auto;
	max-width: 688px;

	@media (min-width: 1024px) {
		max-width: 1120px;
		top: 0;
		grid-template-columns: 1fr 400px;
	}
`;

export const MakeChangesDesktopWrap = styled.div`
	display: none;

	a,
	a:hover,
	a:visited {
		text-decoration: none;
	}

	@media (min-width: 1024px) {
		display: block;
		grid-column: 2;
		grid-row: 1;
	}
`;

export const MakeChangesMobileWrap = styled.div`
	grid-column: 1;
	grid-row: 1;

	a,
	a:hover,
	a:visited {
		text-decoration: none;
	}

	& > * {
		margin-bottom: 0; // ffs
	}

	@media (min-width: 1024px) {
		display: none;
	}
`;

export const DownloadAppWrap = styled.div`
	display: none;

	@media (min-width: 1024px) {
		display: block;

		grid-column: 2;
		grid-row: 2;
	}
`;

export const DetailsCard = styled.div`
	grid-column: 1;
	grid-row: 2;
	display: grid;
	grid-template-columns: 1fr;
	gap: ${p => p.theme.spacing.large};

	@media (min-width: 1024px) {
		grid-column: 1;
		grid-row: 1 / span 3;
	}
`;

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};

	grid-column: 1;
	grid-row: 2;

	@media (min-width: 1024px) {
		grid-row: 1 / span 3;
	}
`;
