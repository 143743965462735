import styled, { css } from 'styled-components';
export const AlignDateChildren = styled.div `
	width: 100%;
	max-width: 220px;
	flex: 1;
	display: flex;
	flex-direction: row;

	&& > * {
		text-align: center;
		flex: 1;
		width: 100%;
	}

	&& > :nth-child(3) {
		flex: 2;
	}

	& > * + * {
		margin-left: ${p => p.theme.spacing.small};
	}
`;
export const AlignInlineChildren = styled.div `
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: row;

	& > * + * {
		margin-left: ${p => p.theme.spacing.small};
	}
`;
export const AlignDoubleColChildren = styled.div `
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: ${p => p.theme.spacing.small};
	grid-row-gap: ${p => p.theme.spacing.small};

	${p => p.stack &&
    css `
			@media (max-width: 449px) {
				grid-template-columns: 1fr;
			}
		`}
`;
export const AlignSingleColChildren = styled.div `
	width: 100%;
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: ${p => p.theme.spacing.small};
`;
export const AfterFieldHintAlign = styled.div `
	display: flex;
	align-items: center;

	& > :first-child {
		flex: 1;
	}
`;
