import React, { forwardRef, useState } from 'react';
import ksuid from '@cuvva/ksuid';
import styled from 'styled-components';
import Icon from '../../Icon';
import Switch from '../atoms/Switch';
const SwitchInput = forwardRef((props, ref) => {
    const { $accent = 'primaryFill', checked, $withIcons, ...inputProps } = props;
    const [id] = useState(() => ksuid.generate('switch').toString());
    return (React.createElement(SwitchWrapper, null,
        React.createElement(Switch, { id: id, ref: ref, type: 'checkbox', checked: checked, "$accent": $accent, "$withIcons": $withIcons, ...inputProps }),
        React.createElement("label", { htmlFor: id },
            React.createElement("span", { tabIndex: 0, role: 'checkbox', "aria-labelledby": id, "aria-checked": checked }, $withIcons &&
                (checked ? (React.createElement(Icon, { icon: 'ic_check_small', "$size": '16px', "$color": 'successFill' })) : (React.createElement(Icon, { icon: 'ic_cross_small', "$size": '16px', "$color": 'dangerFill' })))))));
});
const SwitchWrapper = styled.div `
	display: inline-block;

	span {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;
export default SwitchInput;
