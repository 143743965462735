import styled from 'styled-components';
import Text from './Text';
const RegPlate = styled(Text).attrs({ spellCheck: false }) `
	background: ${p => (p.disabled ? `, ${p.theme.ui.surfaceFillMuted};` : '')};
	font-family: ${p => p.theme.fonts.UKNumberPlate};
	letter-spacing: 0;
	background-position: center left;
	background-size: contain;
	background-color: ${p => p.background ?? 'none'};

	font-size: 22px;
	line-height: 24px;
	padding-left: calc(${p => p.theme.spacing.regular} + 27px);
	padding-right: calc(${p => p.theme.spacing.regular} + 27px);
	text-align: center;

	&::placeholder {
		color: ${p => p.theme.ui.textMinor};
	}

	&:focus::placeholder {
		color: transparent;
	}
`;
export default RegPlate;
