import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { push } from 'connected-react-router';
import styled from 'styled-components';

import Duration from '../molecules/Duration';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import VehicleManufacturer from '~lib/frontend/design-system/components/VehicleManufacturer';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import { getPublicVehicle } from '~lib/platform/vehicle/store/actions';
import { ContentContainer } from '~website/components/SplitUI';
import { trackQuoteScreenView } from '~website/features/analytics/store/actions';
import useVehicleQuotable from '~website/features/quote/hooks/use-vehicle-quotable';
import { stepActions } from '~website/features/quote/store/actions';

interface VehicleProfileProps {
	vehicleId: string;
}

const VehicleProfile: React.FCWithChildren<VehicleProfileProps> = ({ vehicleId }) => {
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages.vehicle-profile.${key}`, fallback);
	const dispatch = useDispatch();
	const history = useHistory();
	const vehicle = useSelector(s => s.platform.vehicle.getPublicVehicle[`${vehicleId}:false`]);
	const selectedFlow = useSelector(s => s.internal.quote.state.flow);
	const startDate = useSelector(s => s.internal.quote.state.startDate);
	const vehicleStatus = useAsyncStateStatus(vehicle).status;
	const [submitted, setSubmitted] = useState(false);
	const { error, fetching: fetchingQuotability } = useVehicleQuotable(vehicleId);

	useEffect(() => {
		if (!vehicleId) return;

		if (vehicleStatus === 'pending') dispatch(getPublicVehicle.request({ id: vehicleId, bypassChecks: false }));
	}, [dispatch, vehicleStatus, vehicleId]);

	useEffect(() => {
		dispatch(trackQuoteScreenView({ screenName: 'none_screenView_vehicles' }));

		// This is necessary as if you're looking at this screen, you started the flow again
		// and it's only fair we ask for the start date once again.
		dispatch(stepActions.setStartDate(void 0));
	}, [dispatch]);

	const onContinue = (flow: string) => {
		if (!submitted) setSubmitted(true);

		const monthly = flow === 'monthly';
		const canStart = !monthly || (startDate && monthly && !fetchingQuotability);

		if (!canStart) return;

		if (error && monthly) {
			dispatch(push(createAwareUrl('/quote/cannot-quote'), { error }));

			return;
		}

		if (flow !== 'monthly') dispatch(push(createAwareUrl('/quote/trip-start')));
		else if (flow === 'monthly') dispatch(push(createAwareUrl('/quote/your-details')));
	};
	const redirectToVehicleSearch = () => {
		history.push(createAwareUrl('/vehicles'));
	};

	if (!vehicle?.response) return null;

	return (
		<ContentContainer>
			<VehicleInfo onClick={redirectToVehicleSearch}>
				<VehicleManufacturer make={vehicle.response.make} size={'24px'} color={'neutralFillMuted'} />
				<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'} $bold>
					{vehicle.response.vrm}
				</Typography>
				<Typography $type={'Body.Small'}>
					{vehicle.response.make} {vehicle.response.model}
				</Typography>
			</VehicleInfo>
			<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
				{get('heading', 'How long do you need cover for?')}
			</Typography>

			<Duration />

			{selectedFlow && (
				<Fragment>
					<Margin $marginTop={'extraLarge'}>
						<Button
							data-testid={'vehicle-profile-continue'}
							$stretch
							$size={'large'}
							$loading={fetchingQuotability}
							onClick={() => onContinue(selectedFlow)}
						>
							{'Continue'}
						</Button>
					</Margin>
				</Fragment>
			)}
		</ContentContainer>
	);
};

const VehicleInfo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};
	padding: ${p => p.theme.spacing.large} ${p => p.theme.spacing.large} ${p => p.theme.spacing.extraLarge};
	margin-bottom: ${p => p.theme.spacing.extraLarge};

	cursor: pointer;
`;

export default VehicleProfile;
