import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

const DownloadAppSubtext = () => (
	<DownloadAppWrapper>
		<Icon icon={'ic_device_android_download'} $size={'20px'} $color={'neutralFill'} $marginRight={'small'} />
		<Typography $type={'Body.Small'} $color={'textOnSurfaceBackground'} $display={'inline'}>
			{'You’ll need to download our app to buy this policy.'}{' '}
			<Anchor
				target={'_blank'}
				href={'https://cuvva.onelink.me/4098811385/lr5t3zi4?pid=organic_web&c=Homepage&af_adset=%2F'}
			>
				{'Get the app.'}
			</Anchor>
		</Typography>
	</DownloadAppWrapper>
);

export default DownloadAppSubtext;

const DownloadAppWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: ${p => p.theme.spacing.large};

	a,
	a:hover,
	a:visited {
		text-decoration: none;
		color: ${p => p.theme.ui.textHighlight};
	}
`;
