import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ksuid from '@cuvva/ksuid';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import InputDecorator from '~lib/frontend/design-system/components/input/molecules/InputDecorator';
import TextInput from '~lib/frontend/design-system/components/input/molecules/TextInput';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { replacePrimaryIdentifier } from '~lib/platform/auth/store/actions';

interface IdentifierModalTokenProps {
	userId: string;
	type: 'email' | 'mobile_phone';
	identifier: string;
	onCancel: () => void;
	onComplete: () => void;
}

const generateRequestId = () => ksuid.generate('request').toString();
const genericError = 'There seems to be an error with the code, please try again';

const IdentifierModalToken: React.FCWithChildren<IdentifierModalTokenProps> = props => {
	const { userId, type, identifier, onComplete, onCancel } = props;
	const [token, setToken] = useState('');
	const dispatch = useDispatch();
	const errorCodes = useSelector(s => s.platform.codeMapping.errorCodes);

	const [requestId, setRequestId] = useState(generateRequestId);

	const replacePrimaryIdentifierStore = useSelector(s => s.platform.auth.replacePrimaryIdentifier[requestId]);
	const replacePrimaryIdentifierStatus = useAsyncStateStatus(replacePrimaryIdentifierStore).status;

	const error = errorCodes?.response?.find(e => e.code === replacePrimaryIdentifierStore?.error?.code);

	// Redirect to the next page on success
	useEffect(() => {
		if (replacePrimaryIdentifierStatus === 'response') onComplete();
	}, [onComplete, replacePrimaryIdentifierStatus]);

	const onTokenChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setToken(e.target.value.replace(/[^0-9\s]/gim, ''));

	const onConfirm = () => {
		const requestId = generateRequestId();

		setRequestId(requestId);
		dispatch(
			replacePrimaryIdentifier.request({
				requestId,
				clientId: void 0,
				type,
				value: identifier,
				userId,
				token: token.replace(/[^0-9]/gim, ''),
			})
		);

		// NB: Just to point out, if a request comes to make sure a user can verify their email,
		// at this point - what we want to do is to change the action from replacePrimaryIdentifier to verifyIdentifier.
		// We will then check for failure in the action and if it exist we will call the replacePrimaryIdentifier action.
		// if  it succeeds then it is fine.
	};

	return (
		<React.Fragment>
			<ComplexDialog.Content>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
					{'Enter the code we sent to your '}
					{type === 'email' ? 'email address' : 'mobile'}
				</Typography>

				<Margin $marginTop={'extraLarge'}>
					<InputDecorator
						label={'Enter the 6 digits code'}
						error={error?.message ?? (replacePrimaryIdentifierStore?.error ? genericError : void 0)}
					>
						<TextInput
							type={'text'}
							autoComplete={'one-time-code'}
							onKeyDown={e => {
								if (Boolean(token) && e.key === 'Enter') onConfirm();
							}}
							value={token ?? ''}
							onChange={onTokenChange}
						/>
					</InputDecorator>
				</Margin>
			</ComplexDialog.Content>

			<ComplexDialog.Actions>
				<Button
					$type={'neutralMuted'}
					onClick={onCancel}
					disabled={replacePrimaryIdentifierStatus === 'fetching'}
				>
					{'Cancel'}
				</Button>
				<Button $type={'primary'} onClick={onConfirm} disabled={replacePrimaryIdentifierStatus === 'fetching'}>
					{'Confirm'}
				</Button>
			</ComplexDialog.Actions>
		</React.Fragment>
	);
};

export default IdentifierModalToken;
