import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';

const StyledLink = styled(Typography)`
	transition: color 0.1s ease-in-out;

	&,
	&:visited {
		text-decoration: none;
	}

	&:hover {
		color: ${p => p.theme.ui.textOnFill};
		text-decoration: underline;
	}
`;

export default StyledLink;
