import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { titleCase } from 'title-case';

import { List } from '../atoms/list';
import ListItem from './ListItem';
import Spinner from '~lib/frontend/design-system/components/spinner';
import Typography from '~lib/frontend/design-system/components/Typography';
import { listLatestIncidents } from '~lib/platform/incident/store/actions';
import { Incident } from '~lib/platform/incident/types';
import { plainMoney } from '~website/helpers/monies';

interface IncidentListProps {
	userId: string;
}

const getIncidentDescription = (incident: Incident) => {
	const description: string[] = [titleCase(incident.category)];

	if (incident.cost) description.push(plainMoney(incident.cost));

	return description.join(' · ');
};

const getIncidentDate = (incident: Incident) => {
	if (!incident.date) return '';

	const date = new Date(incident.date);

	if (isNaN(date.getTime())) return '';

	const options: Intl.DateTimeFormatOptions = {
		timeZone: 'Europe/London',
		year: 'numeric',
		month: 'long',
	};

	return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const IncidentList: React.FCWithChildren<IncidentListProps> = ({ userId }) => {
	const dispatch = useDispatch();
	const incidents = useSelector(s => s.platform.incidents.listLatestIncidents[userId]);

	useEffect(() => {
		if (userId) dispatch(listLatestIncidents.request({ userId }));
	}, [dispatch, userId]);

	if (incidents?.fetching || !incidents?.response) {
		return <Spinner $size={'24px'} $color={'primaryFill'} />;
	}

	const incidentList = incidents?.response.incidents;

	if (incidentList === null || incidentList?.length === 0) {
		return (
			<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
				{'There are no incidents on record'}
			</Typography>
		);
	}

	return (
		<List>
			{incidents.response.incidents.map(i => (
				<ListItem
					key={`${i.category}:${i}`}
					label={getIncidentDate(i)}
					body={getIncidentDescription(i)}
					locked
				/>
			))}
		</List>
	);
};

export default IncidentList;
