import React, { useState } from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';
import styled, { css } from 'styled-components';
const Row = styled.tr `
	height: 48px;

	${p => p.$inViewport &&
    css `
			td {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		`};

	&&& {
		${p => p.$customRowStyle && p.$customRowStyle};
	}
`;
const TableRow = ({ children, customRowStyle }) => {
    const [ref, setRef] = useState(null);
    const offset = `${window.innerHeight * 2}px`;
    const { isIntersecting } = useIntersectionObserver(ref, { rootMargin: `${offset} 0px ${offset} 0px` });
    const childrenCount = React.Children.count(children);
    return (React.createElement(Row, { ref: setRef, "$customRowStyle": customRowStyle, "$inViewport": isIntersecting },
        isIntersecting && children,
        !isIntersecting && React.createElement("td", { colSpan: childrenCount })));
};
export default TableRow;
