import { range } from '~lib/frontend/helpers/numbers';
export const getAnnualMileageOptions = (opt) => {
    if (!opt)
        return [];
    // https://cuvva.slack.com/archives/CV8KRG7B7/p1611247551043400
    // https://cuvva.slack.com/archives/CV8KRG7B7/p1618242701434800
    // This was a long battle of inconsistencies between client.
    // This code is the one that works. Use only this.
    return range(opt.min, opt.max, opt.step).map((l, i, array) => {
        const displayValue = l + opt.step;
        const value = String(l + opt.valueOffset);
        let label = `Up to ${displayValue.toLocaleString()} miles`;
        if (i === array.length - 1)
            label = `More than ${l.toLocaleString()} miles`;
        return {
            label,
            value,
        };
    });
};
