import Cher from '@cuvva/cher';
import Mustache from 'mustache';

import { ErrorCode } from '~lib/platform/code-mapping/types';

export const getErrorUniqueReasons = (error: Cher) => {
	const flattenedReasons: Cher[] = [];

	if (error.code && error.code !== 'cannot_quote') flattenedReasons.push(error);

	for (const reason of error.reasons || []) {
		if (!flattenedReasons.find(r => r.code === reason.code)) flattenedReasons.push(reason);
	}

	return flattenedReasons;
};

export const renderFriendlyError = (error: Cher, errorCodes: ErrorCode[]) => {
	const { code, meta } = error;

	const foundCode = (errorCodes || []).find(c => c.code === code);

	if (!code || !foundCode) return `Unknown (${code})`;

	return Mustache.render(foundCode.message, meta || {});
};
