import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BigCardWrapper } from './Card';
import InfoCard from './InfoCard';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';
import useAsyncStateStatus from '~lib/frontend/hooks/use-async-state-status';
import { getProfile } from '~lib/platform/ltm/store/actions';
import {
	formatAddress,
	formatBool,
	useAbiCodeFormatter,
} from '~website/features/quote/components/quote-page/formatters';
import useUser from '~website/hooks/use-user';

const PolicyUserInfo = () => {
	const { userId } = useUser();
	const dispatch = useDispatch();
	const profile = useSelector(s => s.platform.profile.profileById?.[userId]);
	const profileStatus = useAsyncStateStatus(profile);
	const loading = profileStatus.is('pending') || profileStatus.is('fetching');

	const formatter = useAbiCodeFormatter();

	useEffect(() => {
		if (userId) dispatch(getProfile.request({ userId }));
	}, [dispatch, userId]);

	if (profileStatus.is('error')) return null;

	return (
		<BigCardWrapper>
			<Typography $type={'Heading.Small'} $color={'textOnSurfaceBackground'}>
				{'About you'}
			</Typography>

			<Margin $marginTop={'small'}>
				{loading && <InfoCard fetching />}
				{!loading && (
					<InfoCard
						items={[
							['Employment status', formatter('employmentCodes', profile.response?.employmentCode)],
							['Occupation', formatter('occupationCodes', profile.response?.occupationCode)],
							['Home address', formatAddress(profile.response?.residentialAddress)],
							['Home ownership', formatBool(profile.response?.homeowner)],
							['Marital status', formatter('maritalCodes', profile.response?.maritalStatus)],
							['Cars you own', profile.response?.vehiclesOwned],
						]}
					/>
				)}
			</Margin>
		</BigCardWrapper>
	);
};

export default PolicyUserInfo;
