import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import Callout from '~lib/frontend/design-system/components/callout/Callout';
import Typography from '~lib/frontend/design-system/components/Typography';

const LockedOutCallout: React.FunctionComponent = () => (
	<Wrapper>
		<Callout icon={'ic_info_circle'}>
			<Typography $type={'Body.Small'} $color={'textOnSurfaceBackgroundMuted'}>
				{'Need to change your personal details?'}
			</Typography>

			<AnchorWrapper>
				<Anchor href={'https://support.cuvva.com/en/articles/5802923-contacting-support'}>
					{'Contact us'}
				</Anchor>
			</AnchorWrapper>
		</Callout>
	</Wrapper>
);

const Wrapper = styled.div`
	margin-top: ${p => p.theme.spacing.large};
`;

const AnchorWrapper = styled.div`
	margin-top: ${p => p.theme.spacing.extraSmall};

	a {
		${p => p.theme.typography['Label.Small']()};
		text-decoration: none;
	}
`;

export default LockedOutCallout;
