import React, { Fragment, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled, { css } from 'styled-components';

import AppStoreBadgesSegment from './AppStoreBadges';
import AppStoreButtonsSegment from './AppStoreButtonsSegment';
import TrustSegment from './TrustSegment';
import { Anchor } from '~lib/frontend/atoms';
import Markdown from '~lib/frontend/atoms/Markdown';
import { IconType } from '~lib/frontend/design-system/assets/SvgIcon';
import { media } from '~lib/frontend/design-system/breakpoints';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import { ButtonType, ButtonVariant } from '~lib/frontend/design-system/components/button/types';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography, { EditableTypography } from '~lib/frontend/design-system/components/Typography';
import { UIColors } from '~lib/frontend/design-system/types';
import { GetterType } from '~lib/frontend/types/content';
import VehicleSearch from '~website/components/molecules/VehicleSearch';
import BrandDesignContext from '~website/contexts/BrandDesignContext';
import VisionaryContext from '~website/contexts/VisionaryContext';
import { trackEvent } from '~website/features/analytics/store/actions';
import IdContext from '~website/features/builder/id-context';
import CardChecklist, { ShowChecklist } from '~website/features/builder/molecules/CardChecklist';
import CheckList from '~website/features/builder/molecules/Checklist';
import { oneLink, reapplyParamsIfExternalUrl } from '~website/helpers/source-tracking';
import { ApplicationState } from '~website/store';

export interface TitleButtonType {
	text: string;
	url?: string;
	action?: () => void;
	type?: ButtonType;
	leading_icon?: IconType;
	trailing_icon?: IconType;
	variant?: ButtonVariant;
	track_click?: boolean;
	event_name?: string;
}

export interface TitleSegmentProps {
	centered?: boolean;
	id?: string;
	title: string;
	subtitle?: string;
	body?: string;
	checklist?: string[];
	segmentChecklist?: string[];
	isHero?: boolean;
	isFullWidth?: boolean;
	showButton?: TitleButtonType | false;
	allowMoreButtons?: TitleButtonType[];
	showAppStoreButtons?: boolean;
	showTrustpilot?: boolean;
	showAppStoreButtonsWithReview?: boolean;
	fcaLegal?: boolean;
	showVehicleSearch?: boolean;
}

const TitleSegment: React.FCWithChildren<TitleSegmentProps> = props => {
	const {
		body,
		centered,
		children,
		showAppStoreButtons,
		showButton,
		allowMoreButtons,
		showTrustpilot,
		showAppStoreButtonsWithReview,
		id,
		title,
		subtitle,
		checklist,
		segmentChecklist,
		isHero,
		isFullWidth,
		fcaLegal,
		showVehicleSearch,
	} = props;

	const idContext = useContext(IdContext);
	const buttonsLength = useCallback(
		() => (showButton ? 1 : 0) + (allowMoreButtons ? allowMoreButtons.length : 0),
		[showButton, allowMoreButtons]
	);
	const sourceTracking = useSelector((s: ApplicationState) => s.internal.analytics.sourceTracking);
	const isVisionary = useContext(VisionaryContext);
	const newDesign = useContext(BrandDesignContext);
	const location = useLocation();
	const dispatch = useDispatch();

	if (showButton && showButton?.url)
		showButton.url = reapplyParamsIfExternalUrl(showButton.url, sourceTracking, location.pathname);

	const onButtonClick = (showButton: TitleButtonType) => {
		if (showButton.track_click) {
			dispatch(
				trackEvent({
					eventName: showButton.event_name,
					eventPayload: {
						action: 'button_clicked',
					},
				})
			);
		}

		if (!showButton.url) showButton.action();
	};

	let editableChecklist: ShowChecklist[] = void 0;

	if (segmentChecklist?.length)
		editableChecklist = segmentChecklist.map(item =>
			typeof item === 'string' ? { label: item, checked: true } : item
		);

	const getColor = (newDesign: boolean, isVisionary: boolean) => {
		if (newDesign) return isVisionary ? 'textOnFill' : 'primaryAction';

		return isVisionary ? 'textOnFill' : 'textOnSurfaceBackground';
	};

	const color = getColor(newDesign, isVisionary);

	return (
		<Container id={id}>
			<Wrapper $centered={centered} $hero={isHero} $fullWidth={isFullWidth}>
				<Margin $marginBottom={'large'}>
					<EditableTypography setterKey={'title'}>
						<Typography
							$type={isHero ? 'Heading.XLarge' : 'Heading.Large'}
							as={isHero ? 'h1' : 'h2'}
							$color={isVisionary ? 'textOnFill' : 'textOnSurfaceBackground'}
							id={[idContext, 'title'].join('_')}
						>
							{formatNewlines(title, [idContext, 'title'].join('_'))}
						</Typography>
					</EditableTypography>
					{subtitle && (
						<EditableTypography setterKey={'subtitle'}>
							<Typography
								$type={isHero ? 'Heading.Medium' : 'Heading.Small'}
								as={'h2'}
								$color={color}
								$marginTop={'extraLarge'}
								id={[idContext, 'subtitle'].join('_')}
							>
								<Markdown>{formatNewlines(subtitle, [idContext, 'subtitle'].join('_'))}</Markdown>
							</Typography>
						</EditableTypography>
					)}
				</Margin>
				<EditableTypography setterKey={'body'}>
					{formatParagraphs(body, isHero, isVisionary, isFullWidth, idContext)}
				</EditableTypography>
				{!newDesign && (
					<EditableTypography setterKey={'checklist'}>
						{checklist?.length > 0 && (
							<CheckList checklist={checklist} isHero={isHero} isVisionary={isVisionary} />
						)}
					</EditableTypography>
				)}
				<EditableTypography setterKey={'segmentChecklist'}>
					{segmentChecklist?.length > 0 && (
						<Margin $marginBottom={'regular'} $marginTop={'regular'}>
							<CardChecklist checklist={editableChecklist} />
						</Margin>
					)}
				</EditableTypography>
			</Wrapper>

			{children && (
				<ChildrenWrapper $centered={centered} id={[idContext, 'checklist'].join('_')}>
					{children}
				</ChildrenWrapper>
			)}

			<Wrapper
				$centered={centered}
				$showVehicleSearch={showVehicleSearch}
				$hero={isHero}
				$fullWidth={isFullWidth}
			>
				{(showButton || showAppStoreButtons || showTrustpilot || showAppStoreButtonsWithReview) && (
					<FlexContainer $showVehicleSearch={showVehicleSearch} $newDesign={newDesign}>
						{showButton && showVehicleSearch && <VehicleSearch />}
						<ButtonFlexWrapper $horizontalAlign={newDesign} $buttonCount={buttonsLength()}>
							{showButton && !showVehicleSearch && (
								<React.Fragment>
									{showButton?.text && (
										<Button
											// @ts-ignore
											href={showButton.url}
											as={showButton.url ? Anchor : void 0}
											$type={getButtonType(showButton.type, isVisionary)}
											$leadingIcon={showButton.leading_icon}
											$trailingIcon={showButton.trailing_icon}
											$variant={newDesign ? 'pill' : showButton.variant}
											onClick={() => onButtonClick(showButton)}
											$size={'large'}
											id={[idContext, 'cta'].join('_')}
										>
											{showButton.text}
										</Button>
									)}
								</React.Fragment>
							)}

							{allowMoreButtons && (
								<Fragment>
									{allowMoreButtons.map((button, index) => (
										<Fragment key={`extra_button:${index}`}>
											{button.text && (
												<Button
													// @ts-ignore
													href={button.url}
													as={button.url ? Anchor : void 0}
													$type={getButtonType(button.type, isVisionary)}
													$leadingIcon={button.leading_icon}
													$trailingIcon={button.trailing_icon}
													$variant={newDesign ? 'pill' : button.variant}
													onClick={() => onButtonClick(button)}
													$size={'large'}
													id={[idContext, 'cta'].join('_')}
												>
													{button.text}
												</Button>
											)}
										</Fragment>
									))}
								</Fragment>
							)}
						</ButtonFlexWrapper>

						{newDesign && (
							<BottomComponents>
								<EditableTypography setterKey={'checklist'}>
									{checklist?.length > 0 && (
										<CheckList
											checklist={checklist}
											isHero={isHero}
											isVisionary={isVisionary}
											newDesign={newDesign}
										/>
									)}
								</EditableTypography>
								{showTrustpilot && (
									<TrustPilotWrapper>
										<TrustSegment
											trustProvider={'trustpilot'}
											showVehicleSearch={showVehicleSearch}
										/>
									</TrustPilotWrapper>
								)}
							</BottomComponents>
						)}

						{!newDesign && (
							<Fragment>
								{showAppStoreButtons && (
									<Margin $marginTop={'large'} id={[idContext, 'app_stores'].join('_')}>
										<AppStoreButtonsSegment url={oneLink} />
									</Margin>
								)}
								{showAppStoreButtonsWithReview && (
									<Margin $marginTop={'large'} id={[idContext, 'app_stores'].join('_')}>
										<AppStoreBadgesSegment
											trustProvider={'trustpilot'}
											showVehicleSearch={showVehicleSearch}
										/>
									</Margin>
								)}
								{showTrustpilot && (
									<Margin $marginTop={'large'} id={[idContext, 'trustpilot'].join('_')}>
										<TrustSegment
											trustProvider={'trustpilot'}
											showVehicleSearch={showVehicleSearch}
										/>
									</Margin>
								)}{' '}
							</Fragment>
						)}
					</FlexContainer>
				)}
				{fcaLegal && (
					<LegalDisclaimerContainer id={[idContext, 'legal'].join('_')}>
						<LegalDisclaimerBlock>
							<Icon
								icon={'ic_check_circle'}
								$size={'24px'}
								$color={'textOnFill'}
								$marginRight={'small'}
							/>
							<Typography
								$type={'Label.Small'}
								$color={'textOnFill'}
								id={[idContext, 'legal', 'fca'].join('_')}
							>
								{'FCA Authorised and regulated'}
							</Typography>
						</LegalDisclaimerBlock>
						<LegalDisclaimerBlock>
							<Icon
								icon={'ic_question_circle'}
								$size={'24px'}
								$color={'textOnFill'}
								$marginRight={'small'}
							/>
							<Typography
								$type={'Label.Small'}
								$color={'textOnFill'}
								id={[idContext, 'legal', 'support'].join('_')}
							>
								{'9am to 9pm support'}
							</Typography>
						</LegalDisclaimerBlock>
					</LegalDisclaimerContainer>
				)}
			</Wrapper>
		</Container>
	);
};

const formatNewlines = (text: string, id: string) => {
	if (!text || !text.includes('\n')) return text;

	const lines = text.split('\n');

	if (lines.length === 1) return text;

	return lines.map((t, i) => (
		<span key={`${t}:${i}`} id={[id, 'newline', i].join('_')}>
			{t}
			<br />
		</span>
	));
};

const formatParagraphs = (text: string, hero: boolean, visionary: boolean, isFullWidth: boolean, id: string) => {
	if (!text) return null;

	const blocks = text.split('\n\n');

	let color: keyof UIColors = 'textOnSurfaceBackgroundMuted';

	if (visionary) color = 'textOnFillMuted';
	else if (hero) color = 'textOnSurfaceBackground';

	return blocks
		.map((block, blockIndex) => {
			const isTable = block.includes('|');
			const lines = isTable ? [block] : block.split('\n');
			const isLastBlock = blockIndex === blocks.length - 1;

			return lines.map((line, lineIndex) => (
				<Typography
					$type={'Body.XLarge'}
					key={`${line}:${blockIndex}:${lineIndex}`}
					$color={color}
					$maxWidth={isFullWidth ? '100%' : '720px'}
					$width={'100%'}
					id={[id, 'paragraph', blockIndex, lineIndex].join('_')}
				>
					<Markdown>{line}</Markdown>
					{!isTable && !isLastBlock && <br />}
				</Typography>
			));
		})
		.flat();
};

export const getButtonType = (preference: ButtonType | void, visionary: boolean): ButtonType => {
	if (preference) return preference;

	if (visionary) return 'neutral';

	return 'primary';
};

export const contentAdapter = (get: GetterType): TitleSegmentProps => {
	const centered = get<boolean>('centered', false);
	const title = get('title');
	const subtitle = get('subtitle');
	const body = get('body');
	const id = get('id');
	const checklist = get<string[]>('checklist', []);
	const segmentChecklist = get<string[]>('segment_checklist', []);
	const button = get<string | false>('show_button.text', false);
	const showButton = button ? get<TitleButtonType>('show_button') : void 0;
	const allowMoreButtons = get<TitleButtonType[]>('allow_more_buttons', []);
	const showAppStoreButtons = get<boolean>('show_app_store_buttons', false);
	const showVehicleSearch = get<boolean>('show_vehicle_search', false);
	const showTrustpilot = get<boolean>('show_trustpilot', false);
	const showAppStoreButtonsWithReview = get<boolean>('show_app_store_buttons_with_review', false);
	const isHero = get<boolean>('is_hero', false);
	const fcaLegal = get<boolean>('fca_legal', false);

	return {
		centered,
		id,
		title,
		subtitle,
		body,
		checklist,
		segmentChecklist,
		showButton,
		allowMoreButtons,
		showAppStoreButtons,
		showTrustpilot,
		showAppStoreButtonsWithReview,
		isHero,
		fcaLegal,
		showVehicleSearch,
	};
};

const getMaxWidth = (centered: boolean, hero: boolean, fullWidth: boolean) => {
	if (fullWidth) return '100%';

	if (centered && hero) return '840px';

	if (centered) return '560px';

	return '520px';
};

const centeredWrapper = css`
	text-align: center;
	margin: 0 auto;
	flex-direction: column;
	display: flex;
	align-items: center;
`;

interface WrapperProps {
	$centered: boolean;
	$showVehicleSearch?: boolean;
	$hero: boolean;
	$fullWidth?: boolean;
}

const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.large};
`;

const Wrapper = styled.div<WrapperProps>`
	${p => (p.$centered ? centeredWrapper : '')};

	${media.lessThan('tablet')`
		${centeredWrapper};
	`};

	max-width: ${p => getMaxWidth(p.$centered, p.$hero, p.$fullWidth)};
	${p => p.$showVehicleSearch && 'width: 100%'};
`;

const ChildrenWrapper = styled.div<{ $centered: boolean }>`
	${p => (p.$centered ? centeredWrapper : '')};
`;

const FlexContainer = styled.div<{ $showVehicleSearch?: boolean; $newDesign?: boolean }>`
	display: inline-flex;
	flex-direction: column;
	margin-top: ${p => (p.$newDesign ? 0 : p.theme.spacing.extraLarge)};
	${p => p.$showVehicleSearch && 'width: 100%'};

	& > * {
		flex: 1;
	}
`;

const ButtonFlexWrapper = styled.div<{ $buttonCount: number; $horizontalAlign?: boolean }>`
	width: 100%;
	display: grid;
	gap: ${p => p.theme.spacing.regular};

	${p => {
		if (!p.$horizontalAlign || p.$buttonCount === 1) return 'grid-template-columns: 1fr;';

		const isOdd = p.$buttonCount % 2 !== 0;

		return `
			grid-template-columns: 1fr 1fr;
			grid-template-rows: repeat(${Math.ceil(p.$buttonCount / 2)}, auto);
			${isOdd ? ` & > :nth-child(${p.$buttonCount}) { grid-column: span 2 }` : ''}
		`;
	}}

	${media.lessThan('tablet')`
		grid-template-columns: 1fr;
	`};
`;

const LegalDisclaimerContainer = styled.div`
	display: inline-flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: ${p => p.theme.spacing.extraLarge};
	margin-top: ${p => p.theme.spacing.extraLarge};
`;

const LegalDisclaimerBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const BottomComponents = styled.div`
	display: flex;
	flex-direction: column;

	${media.lessThan('tablet')`
		align-items: center;
	`}
`;

const TrustPilotWrapper = styled.div`
	position: absolute;
	right: ${p => p.theme.spacing['4xLarge']};
	bottom: 0;

	${media.lessThan('tablet')`
		right: 0;
		position: relative;
	`}
`;

export default TitleSegment;
