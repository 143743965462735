import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { trackScreenView } from '../analytics/store/actions';
import { GridLayout } from '../me-hub/components/atoms/alignment';
import { Container } from '../me-hub/components/atoms/background';
import { Card, CardHeading } from '../me-hub/components/atoms/card';
import { CardContainer, ReferAFriendWrap } from './components/atoms/alignment';
import ActiveDiscounts from './components/molecules/ActiveDiscounts';
import ApplyADiscount from './components/molecules/ApplyADiscount';
import InactiveDiscounts from './components/molecules/InactiveDiscounts';
import ReferAFriend from './components/molecules/ReferAFriend';
import ReferAFriendCopy from './components/molecules/ReferAFriendCopy';
import useDiscounts from './hooks/use-discounts';

const Discounts = () => {
	const dispatch = useDispatch();
	const { loading, activeDiscounts, previousDiscounts } = useDiscounts();

	useEffect(() => {
		dispatch(
			trackScreenView({
				eventName: 'profile_discounts_viewed',
				eventPayload: {
					platform: 'website',
					screen_name: 'profile_discounts_viewed',
				},
			})
		);
	}, [dispatch]);

	return (
		<Container>
			<GridLayout>
				<CardContainer>
					<Card>
						<CardHeading>{'Apply a discount code'}</CardHeading>
						<ApplyADiscount />
					</Card>

					<Card>
						<CardHeading>{'Active discounts'}</CardHeading>
						<ActiveDiscounts loading={loading} discounts={activeDiscounts} />
					</Card>

					{previousDiscounts.length > 0 && (
						<Card>
							<CardHeading>{'Previous discounts'}</CardHeading>
							<InactiveDiscounts discounts={previousDiscounts} />
						</Card>
					)}
				</CardContainer>

				<ReferAFriendWrap>
					<Card>
						<ReferAFriendCopy />
						<ReferAFriend />
					</Card>
				</ReferAFriendWrap>
			</GridLayout>
		</Container>
	);
};

export default Discounts;
