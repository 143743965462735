import React, { useRef } from 'react';
import Cher from '@cuvva/cher';

import ConfirmScreen, { ConfirmScreenProps } from './ConfirmScreen';
import UploadScreen, { UploadCoreProps, UploadScreenProps } from './UploadScreen';

interface SingleFileUploadProps {
	uploadScreen: UploadScreenProps;
	confirmScreen: ConfirmScreenProps;
	fileId: string | undefined;
	isUploading: boolean;
	onUploadComplete: () => void;
	uploadError: Cher | undefined;
	onRetry?: () => void;
}

const SingleFileUpload = (props: SingleFileUploadProps) => {
	const { fileId, uploadScreen, confirmScreen, onUploadComplete, isUploading, uploadError, onRetry } = props;

	const fileRef = useRef<HTMLInputElement>(void 0);

	const onContinue = () => onUploadComplete();

	const onTryAgain = () => onRetry?.();

	const uploadProps: UploadCoreProps = {
		...uploadScreen,
		isUploading,
		uploadError,
	};

	const confirmProps = {
		...confirmScreen,
		onContinue,
		onTryAgain,
	};

	return fileId ? <ConfirmScreen {...confirmProps} /> : <UploadScreen ref={fileRef} {...uploadProps} />;
};

export default SingleFileUpload;
