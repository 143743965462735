import React from 'react';
import { useSelector } from 'react-redux';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useOnPageError from '../../hooks/use-on-page-error';
import useProfileInternalChangeRequest from '../../hooks/use-profile-internal-change-request';
import notEmptyBooleanValidator from '../../validators/not-empty-numeric-boolean';
import TrackedSelectInput from '../atoms/TrackedSelectInput';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { getOtherVehicleOptions } from '~lib/platform/ltm/helpers/other-vehicles';

const OwnOtherVehicles: React.FunctionComponent = () => {
	const g = useContentKey('strings');

	const { value, validation, onChange } = useProfileInternalChangeRequest('vehiclesOwned', notEmptyBooleanValidator);
	const error = useOnPageError(validation, 'other_vehicles_input');
	const ltmConfig = useSelector(s => s.platform.ltm.config.response);
	const options = getOtherVehicleOptions(ltmConfig?.policyholder?.vehiclesOwned);

	return (
		<QuoteInputDecorator
			label={g(
				'motor.long_term.questions.about_you.other_vehicle_access.question',
				'How many vehicles do you own, including this one?'
			)}
			error={error}
		>
			<TrackedSelectInput
				value={value?.toString()}
				options={options}
				placeholder={'Choose an option'}
				onChange={({ value }) => onChange(parseInt(value, 10))}
				data-event-onfocus-name={'dropdown_selected'}
				data-event-onfocus-value={'other_vehicles'}
				data-event-onchange-name={'other_vehicles_selected'}
			/>
		</QuoteInputDecorator>
	);
};

export default OwnOtherVehicles;
