import styled from 'styled-components';
export const ColumnContainer = styled.div `
	display: flex;
	justify-content: space-between;
	flex-wrap: nowrap;
	margin: 0;
`;
export const Column = styled.div `
	white-space: nowrap;
	margin: 0 0.5rem 0 0;
`;
