import { createAction } from '@reduxjs/toolkit';

import { GetUserActionTypes } from './types';
import { createAsyncAction } from '~lib/shared/redux/actions';

export const importRegisterUser = createAsyncAction<void, string>(GetUserActionTypes.IMPORT_REGISTER_USER);

export const importAuthedUser = createAsyncAction<void, string>(GetUserActionTypes.IMPORT_AUTHED_USER);

export const registerIterableUser = createAsyncAction<void, void>(GetUserActionTypes.REGISTER_ITERABLE_USER);

export const clearAuthUser = createAction(GetUserActionTypes.CLEAR_AUTH_USER);
