import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import IlloWrapper from '../../atoms/IlloWrapper';
import { LtmQuoteContext } from '../../quote-page/context';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import { trackPayOnWebScreenView } from '~website/features/analytics/store/actions';
import { useVerificationContext } from '~website/features/quote/contexts/VerificationContext';

const VerificationEnd = () => {
	const dispatch = useDispatch();
	const { setVerificationIds } = useContext(LtmQuoteContext);
	const { uploadedVerificationFiles } = useVerificationContext();

	const handleContinue = useCallback(() => {
		setVerificationIds(uploadedVerificationFiles);
	}, [setVerificationIds, uploadedVerificationFiles]);

	useEffect(() => {
		dispatch(trackPayOnWebScreenView({ screenName: 'verification_success' }));
	}, [dispatch]);

	return (
		<React.Fragment>
			<IlloWrapper filename={'il_checkmark.svg'} altDescription={'checkmark'} />

			<Margin $marginTop={'large'} $marginBottom={'extraLarge'}>
				<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'} $align={'center'}>
					{'Thanks, we’ve got it'}
				</Typography>

				<Typography
					$type={'Body.Medium'}
					$color={'textOnSurfaceBackground'}
					$align={'center'}
					$marginTop={'large'}
				>
					{'We’ll check everything over in the next 24 hours.'}
				</Typography>
				<Typography
					$type={'Body.Medium'}
					$color={'textOnSurfaceBackground'}
					$align={'center'}
					$marginTop={'extraLarge'}
				>
					{
						'We’ll only reach out if there’s a problem, and we’ll make sure to let you know before the policy starts.'
					}
				</Typography>
			</Margin>

			<Button
				as={Anchor}
				// @ts-ignore
				to={'/quote/payment'}
				$type={'primary'}
				$size={'large'}
				$stretch
				onClick={handleContinue}
			>
				{'Continue to payment'}
			</Button>
		</React.Fragment>
	);
};

export default VerificationEnd;
