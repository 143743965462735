import styled from 'styled-components';

import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';

export const FooterElement = styled.footer<{ $newDesign?: boolean }>`
	background: ${p => p.theme.ui.violetGradientLeading}, ${p => p.theme.ui.secondaryBackground};
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 80px ${p => p.theme.spacing.large} 0 ${p => p.theme.spacing.large};

	${p =>
		p.$newDesign &&
		`
		background: ${p.theme.ui.primaryAction};
	`}

	@media (min-width: 768px) {
		padding-bottom: 20px;
	}

	${p => safeAreaPadded(p.theme.spacing.large, '24px')}
`;

export const FooterWrap = styled.div`
	max-width: 1280px;
	width: 100%;
	margin: auto;
`;
