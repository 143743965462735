import { combineReducers } from 'redux';
import { initialPaymentState, PaymentActionTypes, } from './types';
import createAsyncMapReducer from '~lib/shared/redux/reducers/async-map-reducer';
const attachPaymentMethod = createAsyncMapReducer(PaymentActionTypes.ATTACH_PAYMENT_METHOD, initialPaymentState.attachPaymentMethod);
const completeWebIntent = createAsyncMapReducer(PaymentActionTypes.COMPLETE_WEB_INTENT, initialPaymentState.completeWebIntent);
const createWebIntent = createAsyncMapReducer(PaymentActionTypes.CREATE_WEB_INTENT, initialPaymentState.createWebIntent);
const listIntentsByUser = createAsyncMapReducer(PaymentActionTypes.INTENTS_BY_USER, initialPaymentState.listIntentsByUser);
const listPaymentMethods = createAsyncMapReducer(PaymentActionTypes.LIST_PAYMENT_METHODS, initialPaymentState.listPaymentMethods);
const userById = createAsyncMapReducer(PaymentActionTypes.USER_BY_ID, initialPaymentState.userById);
const getMandates = createAsyncMapReducer(PaymentActionTypes.GET_MANDATES, initialPaymentState.getMandates);
const updateMandate = createAsyncMapReducer(PaymentActionTypes.UPDATE_MANDATE, initialPaymentState.updateMandate);
const createPayment = createAsyncMapReducer(PaymentActionTypes.CREATE_PAYMENT, initialPaymentState.createPayment);
export default combineReducers({
    attachPaymentMethod,
    completeWebIntent,
    createPayment,
    createWebIntent,
    listIntentsByUser,
    listPaymentMethods,
    userById,
    getMandates,
    updateMandate,
});
