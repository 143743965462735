import React from 'react';
import { useSelector } from 'react-redux';
import Cher from '@cuvva/cher';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import { getErrorUniqueReasons, renderFriendlyError } from '~website/helpers/errors';

const titleFallback = "Sorry, we can't insure you";
const detailFallback =
	"{{error_message}}\n\nThere may also be other reasons we can't insure you.\n\nWe work with our underwriters to offer insurance to as many people as possible, but their rules may change from time to time.\n\nAlthough we can't insure you today, we may be able to cover you in the future. In some cases, you may be able to get a quote on a different vehicle.";

const NegativeFeedbackContainer = styled.div`
	text-align: center;

	img {
		width: 84px;
		height: 82px;
	}
`;

interface NegativeFeedbackProps {
	error: Cher;
}

const NegativeFeedback: React.FCWithChildren<NegativeFeedbackProps> = ({ error }) => {
	const errorCodes = useSelector(s => s.platform.codeMapping.errorCodes.response) || [];
	const errorImage = useContentAsset('website_core', 'quote/error.png');
	const reasons = getErrorUniqueReasons(error);
	const g = useContentKey('strings');
	const get: GetterType = (key, fallback) => g(`global.cantinsure_long.${key}`, fallback);

	const parseDetailString = (str: string) => {
		// only show first error in the list
		const errorMsg = renderFriendlyError(reasons[0], errorCodes);
		const res = str.replace('{{error_message}}', errorMsg);

		return res.split('\n').map(s => <p>{s}</p>);
	};

	return (
		<NegativeFeedbackContainer>
			<img src={errorImage} alt={'quote error'} />

			<Typography $bold $marginTop={'24px'} $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
				{get('headline', titleFallback)}
			</Typography>

			<Typography $align={'left'} $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
				{parseDetailString(get('detail', detailFallback))}
			</Typography>

			<Margin $marginTop={'extraLarge'}>
				<Button
					as={Anchor}
					// @ts-ignore
					to={'/vehicles'}
					$stretch
				>
					{'Start a new quote'}
				</Button>
			</Margin>
		</NegativeFeedbackContainer>
	);
};

export default NegativeFeedback;
