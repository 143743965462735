import React from 'react';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import * as Banner from '~lib/frontend/design-system/components/banner/Banner';

interface ErrorBannerProps {
	onButtonClick: () => void;
}

const ErrorBanner: React.FCWithChildren<ErrorBannerProps> = ({ onButtonClick }) => (
	<Margin $marginTop={'small'} $marginBottom={'small'}>
		<Banner.Wrapper type={'error'} muted shadow icon={'ic_important_circle'}>
			{'Looks like there’s already an account with this email. Did you want to log in instead?'}
			<Banner.Button onClick={onButtonClick}>{'Go to login'}</Banner.Button>
		</Banner.Wrapper>
	</Margin>
);

export default ErrorBanner;
