import styled from 'styled-components';
export const Tile = styled.div.attrs(props => ({
    color: props.color || 'default',
})) `
	border-radius: 0.25rem;
	${p => (p.noPadding ? '' : 'padding: 0.687rem 0.937rem')};
	display: block;
	position: relative;
	${p => {
    const borderColor = p.color === 'error' ? p.theme.legacy.error : p.theme.legacy.primary25;
    if (p.noBorder)
        return '';
    return `border: 1px solid ${borderColor}`;
}};
	background-color: ${p => {
    if (p.color === 'error')
        return p.theme.legacy.errorMuted;
    if (p.color === 'blank')
        return p.theme.legacy.blank;
    return p.theme.legacy.primary5;
}};

	font-size: ${props => props.theme.legacy.textSize('md')};

	& + & {
		margin-top: 0.625rem;
	}

	color: ${p => (p.color === 'error' ? p.theme.legacy.error : p.theme.legacy.secondaryDark)};
`;
export const TileAction = styled.div `
	position: absolute;
	top: 6px;
	right: 8px;
`;
export const TileBreak = styled.hr `
	border-color: ${p => p.theme.legacy.primary25};
	margin-top: 0.8rem;
	margin-bottom: 0.8rem;
`;
export default Tile;
