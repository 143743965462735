import React from 'react';
import styled from 'styled-components';

import { Anchor } from '~lib/frontend/atoms';
import { media } from '~lib/frontend/design-system/breakpoints';
import TrustpilotThemeAware from '~website/assets/svgs/TrustpilotThemeAware';

interface TrustSegmentProps {
	trustProvider: 'trustpilot';
	showVehicleSearch?: boolean;
	showVehicleSearchFlag?: boolean;
}

const TrustSegment: React.FCWithChildren<TrustSegmentProps> = ({ showVehicleSearch, showVehicleSearchFlag }) => (
	<Wrapper
		$showVehicleSearch={showVehicleSearch}
		$showVehicleSearchFlag={showVehicleSearchFlag}
		to={'https://uk.trustpilot.com/review/cuvva.com'}
	>
		<TrustpilotThemeAware />
	</Wrapper>
);

const Wrapper = styled(Anchor)<{ $showVehicleSearch?: boolean; $showVehicleSearchFlag?: boolean }>`
	border: none;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${p => (p.$showVehicleSearch && p.$showVehicleSearchFlag ? 'left' : 'center')};
	padding: ${p => p.theme.spacing.regular} 0;
	border-radius: ${p => p.theme.borderRadius.regular};
	color: ${p => p.theme.ui.textOnFill};
	cursor: pointer;

	${media.lessThan('tablet')({
		justifyContent: 'center',
	})};

	&,
	& * {
		text-decoration: none;
	}
`;

export default TrustSegment;
