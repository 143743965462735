import React from 'react';
import styled from 'styled-components';

import { Badge } from '~lib/frontend/design-system/components/atoms/Badge';

interface JumpToItem {
	name: string;
	selector: string;
}

type Align = 'left' | 'right' | 'center';

interface JumpToPros {
	title: string;
	items: JumpToItem[];
	alignTitle?: Align;
	alignBadges?: Align;
}

const jumpTo = (fragment: string) => {
	const element = document.getElementById(fragment);
	const top = element.getBoundingClientRect().top - 95;

	window.scrollBy({ top, behavior: 'smooth' });
};

const SectionGroup = styled.section`
	& + & {
		margin-top: ${p => p.theme.spacing.extraLarge};
	}
`;

const SectionHeading = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: ${p => p.theme.borderRadius.small};
`;

const SectionName = styled.div<{ alignTitle?: Align }>`
	${p => p.theme.typography['Label.Small']()};
	flex: 1;
	color: ${p => p.theme.ui.textOnSurfaceBackgroundMuted};
	text-align: ${p => (p.alignTitle ? p.alignTitle : '')};
`;

const BadgesContainer = styled.div<{ alignBadges?: Align }>`
	${p => `margin: -${p.theme.spacing.small} 0 ${p.theme.spacing.small} -${p.theme.spacing.small}`};
	text-align: ${p => (p.alignBadges ? p.alignBadges : '')};
	& > * {
		cursor: pointer;
		margin-left: ${p => p.theme.spacing.small};
		margin-top: ${p => p.theme.spacing.small};
	}
`;

const JumpTo: React.FCWithChildren<JumpToPros> = ({ title, items, alignTitle, alignBadges }) => (
	<SectionGroup>
		<SectionHeading>
			<SectionName alignTitle={alignTitle}>{title}</SectionName>
		</SectionHeading>
		<BadgesContainer alignBadges={alignBadges}>
			{items.map(item => (
				<Badge
					key={`${item.name}:${item.selector}`}
					onClick={() => jumpTo(item.selector)}
					$style={'default'}
					as={'div'}
				>
					{item.name}
				</Badge>
			))}
		</BadgesContainer>
	</SectionGroup>
);

export default JumpTo;
