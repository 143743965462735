import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import camelize from 'camelize';

import { getPolicyVerificationList, VerificationItem } from '../components/molecules/verifications/verification-flow';
import { LtmQuoteContext } from '../components/quote-page/context';
import SpinnerCover from '../components/quote-page/molecules/SpinnerCover';
import { createAwareUrl } from '~lib/frontend/helpers/uri';

type VerificationPath = {
	currentPath: string;
	nextPath: string;
};

interface VerificationContextProps {
	verificationItems: VerificationItem[];
	verificationPath: VerificationPath;
	uploadedVerificationFiles: Map<string, string>;
	onUploadComplete: (fileId?: string) => void;
}

const VerificationContext = React.createContext<VerificationContextProps>(void 0);

const VerificationContextProvider: React.FCWithChildren = props => {
	const pathname = window.location.pathname;

	const { children } = props;
	const history = useHistory();

	const { quote, asyncStatus, requestQuote } = useContext(LtmQuoteContext);
	const [requested, setRequested] = useState(false);

	const requirements = quote?.response?.requirements?.policy;
	const verificationItems = useMemo(() => getPolicyVerificationList(requirements), [requirements]);

	useEffect(() => {
		if (requested) return;

		if (asyncStatus === 'pending') {
			setRequested(true);
			requestQuote();
		}
	}, [requested, requestQuote, asyncStatus]);

	const verificationPath = useMemo(
		() =>
			verificationItems.reduce((prev, item, index, arr) => {
				if (!pathname.includes(item.path)) return prev;

				const last = arr[arr.length - 1];

				if (last.path === item.path) {
					return {
						currentPath: item.path,
						nextPath: 'end',
					};
				}

				return {
					currentPath: item.path,
					nextPath: verificationItems[index + 1]?.path,
				};
			}, {} as VerificationPath),

		[pathname, verificationItems]
	);

	const uploadedVerificationFiles = useMemo(() => new Map<string, string>(), []);

	const onUploadComplete = useCallback(
		(fileId?: string) => {
			if (typeof fileId === 'string')
				uploadedVerificationFiles.set(camelize(verificationPath.currentPath), fileId);

			history.push(createAwareUrl(`/quote/verifications/${verificationPath.nextPath}`));
		},
		[history, uploadedVerificationFiles, verificationPath]
	);

	const value = useMemo(
		() => ({
			verificationItems,
			verificationPath,
			uploadedVerificationFiles,
			onUploadComplete,
		}),
		[verificationItems, verificationPath, uploadedVerificationFiles, onUploadComplete]
	);

	if (['fetching', 'pending'].includes(asyncStatus)) return <SpinnerCover />;

	return <VerificationContext.Provider value={value}>{children}</VerificationContext.Provider>;
};

function useVerificationContext() {
	const context = React.useContext(VerificationContext);

	if (context === void 0) throw new Error('useVerificationContext must be used within a VerificationContextProvider');

	return context;
}

export { useVerificationContext, VerificationContextProvider };
export default VerificationContext;
