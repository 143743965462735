import React from 'react';

import QuoteInputDecorator from '../../components/atoms/QuoteInputDecorator';
import useOnPageError from '../../hooks/use-on-page-error';
import useVehicleProfileInternalChangeRequest from '../../hooks/use-vehicle-profile-internal-change-request';
import estimatedVehicleValueValidator from '../../validators/estimated-vehicle-value';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';
import PrefilledInput from '~lib/frontend/design-system/components/input/molecules/PrefilledInput';

const formatValue = (value: number | undefined) => {
	if (typeof value !== 'number') return '';

	return String(Math.round(value / 100));
};

const minValue = 1_000_00;
const maxValue = 100_000_00;

const validator = (value: number | undefined) => estimatedVehicleValueValidator(value, minValue, maxValue);

const EstimatedValue: React.FunctionComponent = () => {
	const { onChange, validation, value } = useVehicleProfileInternalChangeRequest('estimatedValue', void 0, validator);
	const { onFocus, onBlur, error } = useOnBlurError(validation);
	const pageError = useOnPageError(validation);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const safeVal = e.target.value.replace(/[^0-9]/g, '');
		const numeric = parseInt(safeVal, 10);

		if (safeVal === '') {
			onChange(void 0);

			return;
		}

		if (Number.isNaN(numeric)) return;

		const safeguarded = Math.round(Math.min(Math.max(numeric, 0), 100_000));

		onChange(safeguarded * 100);
	};

	const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
		const { code, key } = e;

		if (!code || !key)
			// this checks for a Safari bug where e.code is undefined
			return;

		if (e.code.includes('Period') || e.code.includes('Minus') || (key && ['.', '+', '-'].includes(key)))
			e.preventDefault();
	};

	return (
		<QuoteInputDecorator
			label={'How much is the car worth?'}
			error={error || pageError}
			help={"If you're not sure, make a best guess"}
			onFocus={onFocus}
			onBlur={onBlur}
		>
			<PrefilledInput
				prefilled={'£'}
				type={'number'}
				// this feels wrong but this the browser native way to get increase/decrease to align
				// in steps, like 1k -> 2k -> etc ... without large workarounds in JS
				// > If the value before invoking the stepUp() method is invalid—for example,
				// > if it doesn't match the constraints set by the step attribute—invoking the stepUp()
				// > method will return a value that does match the form controls constraints.
				// see https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/stepUp
				min={1_000}
				max={maxValue / 100}
				step={1_000}
				value={formatValue(value)}
				onKeyDown={onKeyDown}
				onChange={handleOnChange}
			/>
		</QuoteInputDecorator>
	);
};

export default EstimatedValue;
