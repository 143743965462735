import React from 'react';

import { BurgerWrap } from '../atoms/mobile';
import IconButton from '~lib/frontend/design-system/components/button/IconButton';

interface BurgerProps {
	open: boolean;
	setOpen: (value: boolean) => void;
}

const Burger: React.FCWithChildren<BurgerProps> = ({ open, setOpen }) => (
	<BurgerWrap>
		<IconButton
			$size={'medium'}
			$type={'neutralMuted'}
			$icon={open ? 'ic_cross' : 'ic_navigation_burger'}
			onClick={() => setOpen(!open)}
			aria-label={open ? 'Close menu' : 'Open menu'}
		/>
	</BurgerWrap>
);

export default Burger;
