import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { parseMargin } from './atoms/utils/Margin';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
const notOnRecord = 'notonrecord';
// 🚨 Editing this list means there must be a file called as such (.svg) in the DS folder
const irregularPrefixed = ['mg', 'mercedes', 'isuzu', 'dodge', 'chevrolet', 'renault'];
const VehicleManufacturer = forwardRef((props, ref) => {
    const { make, display, size, color, as, children, ...otherProps } = props;
    const match = findClosestMatch(make);
    const attemptedIllo = useDesignSystemAsset({ type: 'manufacturer', value: `${match}.svg` });
    const unknownIllo = useDesignSystemAsset({ type: 'manufacturer', value: `${notOnRecord}.svg` });
    const illo = attemptedIllo ?? unknownIllo;
    return React.createElement(Wrap, { ...otherProps, "$src": illo, "$size": size, "$color": color, "$display": display, as: as, ref: ref });
});
VehicleManufacturer.displayName = 'VehicleManufacturer';
const Wrap = styled.div `
	display: ${p => p.$display ?? 'inline-block'};
	width: var(--size, ${p => p.$size});
	height: var(--size, ${p => p.$size});
	background-color: var(--fill, ${p => p.theme.ui[p.$color]});

	/* SC: This is needed as sometimes, in a group of children, we set flex and don't specify how this should behave */
	flex: 0 0 auto;

	${parseMargin};

	mask: url(${p => p.$src}) no-repeat center / contain;
`;
function findClosestMatch(make) {
    if (!make)
        return notOnRecord;
    const safeMake = make.replace(/[\s-]+/g, '').toLowerCase();
    for (const prefix of irregularPrefixed) {
        if (safeMake.startsWith(prefix))
            return prefix;
    }
    return safeMake;
}
export default VehicleManufacturer;
