import React, { useRef } from 'react';
import { Input as ReactstrapInput } from 'reactstrap';
import styled from 'styled-components';
import { useScreenAction } from '~lib/frontend/TrackingProvider';
export const Input = styled.input.attrs(props => ({
    borderColor: props.borderColor || 'default',
    disabled: props.disabled || false,
    size: props.size || 'default',
})) `
	border-radius: 4px;
	padding: 0.6rem 1rem;
	outline: none;

	border: 1px solid
		${props => {
    if (props.borderColor === 'error')
        return props.theme.legacy.error;
    if (props.borderColor === 'primary')
        return props.theme.legacy.primary;
    else if (props.borderColor === 'dark')
        return props.theme.legacy.secondary;
    return props.theme.legacy.primary25;
}};
	font-size: ${props => props.theme.legacy.textSize('md')};
	color: ${props => props.theme.legacy.secondaryDark};
	background-color: ${p => p.theme.legacy.blank};

	transition: 0.1s;

	&:focus {
		${props => props.theme.legacy.levelActive};
	}

	${props => {
    if (props.disabled) {
        return `
				opacity: 0.3;
				pointer-events: none;
			`;
    }
    return '';
}};
`;
export const Select = styled(Input).attrs(_ => ({
    as: 'select',
})) `
	height: 2.8rem;
	background-color: ${p => p.theme.legacy.blank};
`;
export const TextArea = styled(Input).attrs(_ => ({
    as: 'textarea',
})) `
	height: 150px;
`;
export const InputLikeReactstrap = styled(Input) `
	width: 1%;
	flex: 1 1 auto;
	border-radius: 4px !important; /* lols */

	/* This is cause of bootstrap's dropdown */
	.input-group > & {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
`;
export const TrackedInput = ({ trackingParams, ...props }) => {
    const dispatch = useScreenAction();
    const ref = useRef(0);
    const unaryDispatches = ['number', 'text'];
    const handleOnChange = (event) => {
        props.onChange?.(event);
        if (!props.type)
            return;
        if (trackingParams && unaryDispatches.includes(props.type) && ref.current === 0) {
            dispatch(trackingParams);
            ref.current += 1;
            return;
        }
        if (trackingParams && !unaryDispatches.includes(props.type))
            dispatch(trackingParams);
    };
    return React.createElement(ReactstrapInput, { ...props, onChange: handleOnChange });
};
export default Input;
