import React, { FCWithChildren } from 'react';
import styled from 'styled-components';

import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentAsset from '~lib/frontend/hooks/use-content-asset';
import { LiteSteppedProgress, LiteSteppedProgressStep } from '~website/components/molecules/LiteSteppedProgress';

interface DownloadAppBaseProps {
	isMobile?: boolean;
}

const DownloadAppBase: FCWithChildren<DownloadAppBaseProps> = ({ children, isMobile }) => {
	const downloadLogo = useContentAsset('website_core', 'quote/il_hand_with_cuvva_button.svg');

	const steps: LiteSteppedProgressStep[] = [
		{
			title: 'Details',
			icon: 'ic_check_circle_fill',
		},
		{
			title: 'Price',
			icon: 'ic_check_circle_fill',
		},
		{
			title: 'Finish in app',
			emoji: '🏁',
		},
	];

	return (
		<div>
			<HeaderContainer>
				<Margin $marginBottom={'extraLarge'}>
					<LiteSteppedProgress steps={steps} />
				</Margin>

				{isMobile && (
					<Margin $marginBottom={'extraLarge'}>
						<DownloadLogo src={downloadLogo} />{' '}
					</Margin>
				)}

				<Typography
					$type={'Heading.Medium'}
					$color={'textOnSurfaceBackground'}
					$align={'center'}
					$marginBottom={'regular'}
				>
					{'Finish in the app'}
				</Typography>
			</HeaderContainer>

			{children}
		</div>
	);
};

const DownloadLogo = styled.img`
	width: 130px;
	height: 130px;
`;

const HeaderContainer = styled.div`
	text-align: center;
`;

export default DownloadAppBase;
