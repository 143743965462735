import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import vehicleProfileEvents from '../events';
import SharedQuoteLiveLocation from './SharedQuoteLiveLocation';
import { Anchor } from '~lib/frontend/atoms';
import Button from '~lib/frontend/design-system/components/button/Button';
import { trackOwnersScreenView } from '~website/features/analytics/store/actions';
import PolicyDocs from '~website/features/referral/molecules/PolicyDocs';
import { OwnersData } from '~website/features/referral/types';
import useQuery from '~website/hooks/use-query';

interface UnlockedProps {
	ownersData: OwnersData;
	asBackground?: boolean;
}

const CustomPolicyDocs: React.FCWithChildren<{ openDocument: () => void }> = ({ openDocument }) => (
	<Button $type={'neutralMuted'} $size={'large'} $leadingIcon={'ic_document'} $stretch onClick={openDocument}>
		{'Docs'}
	</Button>
);

const OwnersHub: React.FCWithChildren<UnlockedProps> = ({ ownersData, asBackground }) => {
	const dispatch = useDispatch();
	const query = useQuery();

	useEffect(() => {
		if (!asBackground) {
			dispatch(
				trackOwnersScreenView({
					screenName: vehicleProfileEvents.ownersHub.screenName,
					cuvvaSource: query.get('cuvva_source'),
				})
			);
		}
	}, [asBackground, dispatch, query]);

	return (
		<React.Fragment>
			<SharedQuoteLiveLocation ownersData={ownersData} />

			<Actions>
				<PolicyDocs ownersData={ownersData} CustomPolicyDocs={CustomPolicyDocs} />
				<Button
					$type={'destructiveMuted'}
					$size={'large'}
					$leadingIcon={'ic_support'}
					// @ts-ignore
					href={'mailto:support@cuvva.com'}
					className={'intercom-launcher'}
					as={Anchor}
					$stretch
				>
					{'Help'}
				</Button>
			</Actions>
		</React.Fragment>
	);
};

const Actions = styled.div`
	margin-top: ${p => p.theme.spacing.large};
	display: flex;
	justify-content: space-between;

	& > :not(:last-child) {
		margin-right: ${p => p.theme.spacing.regular};
	}
`;

export default OwnersHub;
