import Cher from '@cuvva/cher';
import { put, select, take } from 'redux-saga/effects';

import { runLtmQuote } from '..';
import { quoteActions } from '../actions';
import { InternalQuoteState } from '../types';
import { createQuote } from '~lib/platform/ltm/store/actions';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { gatherClientContext } from '~website/helpers/client-context';
import { ApplicationState } from '~website/store';

export default createTakeEverySagaSet(runLtmQuote.actions.request, function* runLtmQuoteSaga(action) {
	const { ident } = action.meta;

	// wait for other stuff to actually load..
	if ((yield select((s: ApplicationState) => s.internal.quote.state.prefetch.user)) !== true)
		yield take(quoteActions.fetchUser.success);

	if ((yield select((s: ApplicationState) => s.internal.quote.state.prefetch.preferences)) !== true)
		yield take(quoteActions.fetchPreferences.success);

	const qState: InternalQuoteState = yield select((state: ApplicationState) => state.internal.quote);
	const startDate = qState.state.startDate ?? null;
	const ncbYears = qState.state.ncb ?? null;
	const voluntaryExcess = qState.state.voluntaryExcess ?? 0;
	const useClass = qState.state.useClass ?? 'sdpc';
	const smartPricing = qState.state.smartPricing ?? false;

	try {
		const userId: string = yield select((state: ApplicationState) => state.internal.auth.user.response);
		const vehicleId: string = yield select((s: ApplicationState) => s.internal.quote.state.vehicleId);
		const durationMonths = ncbYears > 0 ? ncbYears * 12 : 0;

		yield put(
			createQuote.request({
				requestId: ident,
				userId,
				vehicleId,
				reason: 'user_request',
				type: 'motor_long_term',

				ncb: { durationMonths },
				useClass,
				voluntaryExcess,
				startDate,
				smartPricing,

				location: null,
				clientContext: gatherClientContext(),
			})
		);

		yield put(
			runLtmQuote.actions.success(ident, {
				quoteRequestId: ident,
				vehicleId,
				ncb: durationMonths,
				useClass,
				voluntaryExcess,
				startDate,
				smartPricing,
				createdAt: new Date().toISOString(),
			})
		);
	} catch (error) {
		yield put(runLtmQuote.actions.failure(ident, Cher.coerce(error)));
	}
});
