import Cher from '@cuvva/cher';
import { call, getContext, put, takeEvery } from 'redux-saga/effects';
import { cancelPolicy } from '../actions';
import { MotorCoverageActionTypes } from '../types';
const errors = {
    policy_cancelled_already: {
        title: 'Policy already cancelled',
        desc: 'Unable to cancel the policy as it has already been cancelled.',
    },
    new_end_before_start: {
        title: 'Unable to cancel policy',
        desc: 'The specified new end date is before the start of the policy.',
    },
    new_end_after_end: {
        title: 'Unable to cancel policy',
        desc: 'The specified new end date is after the existing end of the policy.',
    },
    early_end_date_limitation: {
        title: 'Unable to cancel policy',
        desc: "The end date is not an acceptable value. If you're unsure why, please ask an engineer.",
    },
};
export default function* watcherCancelPolicy() {
    yield takeEvery(MotorCoverageActionTypes.CANCEL_POLICY, workerCancelPolicy);
}
function* workerCancelPolicy({ payload }) {
    const client = yield getContext('api');
    const flashMessages = yield getContext('flashMessages');
    const { policyId, earlyEndDate, reason, refund, type } = payload;
    try {
        const cancellation = yield call([client, client.rpc], 'service-motor-coverage', '2/2020-08-05/cancel_policy', { policyId, earlyEndDate, reason, refund, type });
        yield put(cancelPolicy.success(policyId, cancellation));
    }
    catch (error) {
        yield put(cancelPolicy.failure(policyId, error));
        const cher = Cher.coerce(error);
        const errorInfo = errors[cher.code];
        if (errorInfo) {
            yield put(flashMessages.addDanger(errorInfo.title, errorInfo.desc));
            return;
        }
        yield put(flashMessages.addError('Unable to cancel policy', 'There was an unknown issue cancelling the policy', cher));
    }
}
