import React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';

import IllustrationReferred from './atoms/IllustrationReferred';
import Section from './atoms/Section';
import TrustpilotWrap from './atoms/TrustpilotWrap';
import { Anchor } from '~lib/frontend/atoms';
import Typography from '~lib/frontend/design-system/components/Typography';
import useContentKey from '~lib/frontend/hooks/use-content-key';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import Trustpilot from '~website/assets/svgs/referral/Trustpilot';
import WidgetTrustpilot from '~website/components/atoms/WidgetTrustpilot';
import Counter3Confetti from '~website/components/Counter3Confetti';
import HelpChatToUs from '~website/components/HelpChatToUs';
import LackingInspiration, { contentAdapter } from '~website/components/LackingInspiration';
import Meta from '~website/components/Meta';
import HowItGoes from '~website/features/refer-a-friend/organism/HowItGoes';

const HeadingWrap = styled.div`
	text-align: center;
	position: relative;

	${Typography} {
		> strong {
			color: ${p => p.theme.ui.textHighlight};
		}
	}

	> * + * {
		margin-top: 1.5rem;
	}
`;

const GetHelpWrap = styled.div`
	margin: 170px auto 170px auto;
`;

const Referred: React.FunctionComponent = () => {
	const g = useContentKey('website_core');
	const { code } = useParams<{ code: string }>();
	const get = (key: string, fallback?: string) => {
		if (key === 'head.canonical_url') return `https://www.cuvva.com/referred/${code}`;

		return g(`pages[referred].${key}`, fallback);
	};

	const trustpilotURL = g('global.trustpilot.url');

	const star3Asset = useDesignSystemAsset({ type: 'illustration', value: 'il_star03.svg' });

	return (
		<React.Fragment>
			<Meta get={(key, fallback) => get(`head.${key}`, fallback)} />
			<Section>
				<HeadingWrap>
					<IllustrationReferred src={star3Asset} />

					<Typography
						$type={'Heading.Large'}
						$color={'textOnSurfaceBackground'}
						dangerouslySetInnerHTML={{ __html: get('main_title') }}
					/>

					<TrustpilotWrap>
						<Anchor href={trustpilotURL}>
							<Trustpilot />
						</Anchor>
					</TrustpilotWrap>
				</HeadingWrap>
			</Section>
			<Section>
				<Counter3Confetti get={(key, fallback) => get(`how_it_works.${key}`, fallback)} />
			</Section>
			<Section>
				<HowItGoes get={(key, fallback) => get(`how_it_goes.${key}`, fallback)} />
			</Section>
			<Section>
				<LackingInspiration {...contentAdapter((key, fallback) => get(`inspiration.${key}`, fallback))} />
			</Section>
			<WidgetTrustpilot />
			<GetHelpWrap>
				<HelpChatToUs />
			</GetHelpWrap>
		</React.Fragment>
	);
};

export default Referred;
