import React from 'react';

import TrustpilotCard from '../atoms/TrustpilotCard';
import TrustpilotContainer from '../atoms/TrustpilotContainer';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Typography from '~lib/frontend/design-system/components/Typography';
import TrustSegment from '~website/components/atoms/TrustSegment';

interface TrustpilotContentProps {
	name?: string;
}

const TrustpilotContent: React.FCWithChildren<TrustpilotContentProps> = ({ children, name }) => (
	<TrustpilotContainer>
		<TrustpilotCard>
			<Typography $type={'Heading.Large'} $color={'textHighlight'} $display={'block'}>
				{'“'}
			</Typography>
			<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'} $bold>
				{children}
			</Typography>

			{name && (
				<Margin $marginTop={'large'}>
					<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackground'} $display={'inline-block'}>
						{name}

						<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $display={'inline'}>
							{' · UK'}
						</Typography>
					</Typography>
				</Margin>
			)}
		</TrustpilotCard>
		<TrustSegment trustProvider={'trustpilot'} />
	</TrustpilotContainer>
);

export default TrustpilotContent;
