import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';

import { trackEvent } from '../../analytics/store/actions';
import ReferralCard from '../atoms/ReferralCard';
import { OwnersData } from '../types';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import PolicyTimer from '~lib/frontend/design-system/components/policy-timer';
import Typography from '~lib/frontend/design-system/components/Typography';
import { openBlankTab, writeHtmlToWindow } from '~lib/frontend/helpers/window';

interface PolicyDocsProps {
	ownersData: OwnersData;
	CustomPolicyDocs?: React.FCWithChildren<{ openDocument: () => void }>;
}

const PolicyDocs: React.FCWithChildren<PolicyDocsProps> = ({ ownersData, CustomPolicyDocs }) => {
	const policyEndDate = moment(ownersData?.policy?.endDate);
	const policyStartDate = ownersData?.policy?.startDate;

	const dispatch = useDispatch();

	const openDocument = () => {
		dispatch(
			trackEvent({
				eventName: 'stm_referred_lender_owner_policy_docs',
				eventPayload: { product: 'stm', platform: 'website' },
			})
		);
		const win = openBlankTab();

		fetch(ownersData?.policy?.documents?.terms?.contentUrl)
			.then(r => r.text())
			.then(t => {
				writeHtmlToWindow(win, 'Cuvva Policy Documents', t, ownersData?.policy?.documents?.terms?.stylesUrl);
			})
			.catch(() => {
				win.close();
			});
	};

	return CustomPolicyDocs ? (
		<CustomPolicyDocs openDocument={openDocument} />
	) : (
		<ReferralCard>
			<PolicyDocsContainer>
				<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
					{`${policyEndDate.from(policyStartDate, true)} policy`}
				</Typography>
				<Margin $marginTop={'small'} $marginBottom={'large'}>
					<PolicyTimer startDate={ownersData?.policy?.startDate} endDate={ownersData?.policy?.endDate} />
				</Margin>
				<Margin $marginTop={'small'}>
					<Button
						$stretch
						$type={'primaryMuted'}
						$size={'large'}
						$leadingIcon={'ic_document'}
						onClick={openDocument}
					>
						{'See policy documents'}
					</Button>
				</Margin>
			</PolicyDocsContainer>
		</ReferralCard>
	);
};

const PolicyDocsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export default PolicyDocs;
