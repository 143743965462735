import React from 'react';

import QuoteInputDecorator from '../../../components/atoms/QuoteInputDecorator';
import { AlignInlineChildren } from '~lib/frontend/design-system/components/input/atoms/Aligners';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';
import RadioInput from '~lib/frontend/design-system/components/input/molecules/RadioInput';
import { useIncidentItemInternalChangeRequest } from '~website/features/quote/hooks/use-incident-change-request';
import useOnPageError from '~website/features/quote/hooks/use-on-page-error';
import validBooleanValidator from '~website/features/quote/validators/valid-boolean';

const optionsInjuries = [
	{
		value: true,
		display: 'Yes',
	},
	{
		value: false,
		display: 'No',
	},
];

const IncidentInjuries: React.FCWithChildren<{ index: number }> = ({ index }) => {
	const { onChange, validation, value } = useIncidentItemInternalChangeRequest(
		index,
		'bodilyInjury',
		validBooleanValidator
	);
	const { onFocus, onBlur, error } = useOnBlurError(validation);
	const pageError = useOnPageError(validation);

	return (
		<QuoteInputDecorator label={'Was anyone injured?'} onFocus={onFocus} onBlur={onBlur} error={error || pageError}>
			<AlignInlineChildren>
				{optionsInjuries.map(o => (
					<RadioInput
						key={o.display}
						name={`incident-injuries-${index}`}
						checked={value === o.value}
						value={o.display}
						onChange={() => onChange(o.value)}
					/>
				))}
			</AlignInlineChildren>
		</QuoteInputDecorator>
	);
};

export default IncidentInjuries;
