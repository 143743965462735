import React from 'react';
import styled from 'styled-components';

import TitleSegment, { contentAdapter as titleSegmentContentAdapter, TitleSegmentProps } from './TitleSegment';
import { media } from '~lib/frontend/design-system/breakpoints';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import { GetterType } from '~lib/frontend/types/content';

interface TitleSegmentEligibilityProps extends TitleSegmentProps {
	items: string[];
}

const TitleSegmentEligibility: React.FCWithChildren<TitleSegmentEligibilityProps> = props => {
	const { items, ...titleSegmentProps } = props;

	return (
		<TitleSegment {...titleSegmentProps}>
			<Wrapper>
				{items.map((item, i) => (
					<ItemLine key={`${i}:${item}`}>
						<Icon icon={'ic_check'} $size={'30px'} $color={'blackFill'} />
						<Typography $type={'Body.Large'} $color={'textOnSurfaceBackgroundMuted'} $marginLeft={'large'}>
							{item}
						</Typography>
					</ItemLine>
				))}
			</Wrapper>
		</TitleSegment>
	);
};

export const contentAdapter = (get: GetterType): TitleSegmentEligibilityProps => {
	const items = get<string[]>('items');
	const titleSegment = titleSegmentContentAdapter(get);

	return {
		items,
		...titleSegment,
	};
};

const Wrapper = styled.div`
	position: relative;
	display: inline-block;
	max-width: 510px;
	min-width: 290px;
	margin: 50px auto 0 auto;

	${media.greaterThan('mobile')`
		min-width: 400px;
	`}
`;

const ItemLine = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	text-align: left;
	margin-top: ${p => p.theme.spacing.large};

	&:first-child {
		margin-top: 0;
	}

	> * {
		flex: 0 0 25px;

		&:last-child {
			flex: 1;
		}
	}
`;

export default TitleSegmentEligibility;
