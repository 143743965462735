import React from 'react';
import styled from 'styled-components';

import { ReferAFriendSteps, ReferAFriendTitleWrap, ReferAFriendTypography } from '../atoms/alignment';
import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { CardHeadingSide } from '~website/features/me-hub/components/atoms/card';

const referSteps = ['Invite a friend to Cuvva', 'Your friend takes out a policy', 'You both get rewarded'];

const ReferAFriendCopy = () => {
	const illoPhone = useDesignSystemAsset({ type: 'illustration', value: 'il_friends_message.svg' });

	return (
		<React.Fragment>
			<Illo src={illoPhone} />

			<ReferAFriendTitleWrap>
				<CardHeadingSide>{'Refer a friend'}</CardHeadingSide>

				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'} $marginTop={'small'}>
					{'You’ll both get £10 off when they use your link to buy their first ever policy'}
				</Typography>
			</ReferAFriendTitleWrap>

			<ReferAFriendSteps>
				{referSteps.map(step => (
					<li key={step}>
						<ReferAFriendTypography $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
							{step}
						</ReferAFriendTypography>
					</li>
				))}
			</ReferAFriendSteps>
		</React.Fragment>
	);
};

export default ReferAFriendCopy;

const Illo = styled.img`
	width: 224px;
	height: 224px;
	margin: 0 auto;
	margin-bottom: ${p => p.theme.spacing.semiLarge};
	display: inline-block;
`;
