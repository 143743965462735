import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';

import { trackAccountsScreenView } from '../analytics/store/actions';
import { MeCardContainer } from './atoms/Card';
import { MeHeader, MeHero } from './atoms/MeBackground';
import PolicyContainer from './containers/PolicyContainer';
import useSubscriptions from './hooks/use-subscriptions';
import NoSubscriptions from './molecules/NoSubscription';
import SubscriptionList from './molecules/SubscriptionList';
import SubsLoading from './molecules/SubsLoading';
import Typography from '~lib/frontend/design-system/components/Typography';

const Policy = () => {
	const dispatch = useDispatch();
	const { subscriptions, loading } = useSubscriptions();

	useEffect(() => {
		dispatch(
			trackAccountsScreenView({
				screenName: 'policy_list_viewed',
			})
		);
	}, [dispatch]);

	return (
		<React.Fragment>
			<Helmet defer>
				<title>{'Policies | Cuvva'}</title>
			</Helmet>

			<PolicyContainer>
				<MeHeader>
					<MeHero>
						<Typography $type={'Heading.Large'} $color={'textOnFill'}>
							{'Policies'}
						</Typography>
					</MeHero>
				</MeHeader>

				<MeCardContainer>
					{loading && <SubsLoading />}
					{!loading && (
						<>
							{subscriptions.length > 0 ? (
								<SubscriptionList subscriptions={subscriptions} />
							) : (
								<NoSubscriptions />
							)}
						</>
					)}
				</MeCardContainer>
			</PolicyContainer>
		</React.Fragment>
	);
};

export default Policy;
