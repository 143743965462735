import React from 'react';
import styled, { css } from 'styled-components';

import SkeletonLoader from '~lib/frontend/design-system/components/skeleton/SkeletonLoader';
import Typography from '~lib/frontend/design-system/components/Typography';

type InfoCardItem = [string, string | number];

interface InfoCardProps {
	items?: InfoCardItem[];
	fetching?: boolean;
	direction?: 'row' | 'column';
	hideSeparator?: boolean;
}

const InfoCard: React.FCWithChildren<InfoCardProps> = props => {
	const { items, fetching, direction = 'column', hideSeparator = false } = props;

	return (
		<InfoCardContainer>
			{fetching &&
				Array.from({ length: 6 }).map((_key, index) => (
					<InfoItem key={index} $direction={direction}>
						<SkeletonLoader width={'35%'} />
						<SkeletonLoader width={'25%'} />
					</InfoItem>
				))}

			{!fetching &&
				items.map(([key, value]) => (
					<InfoItem key={`${key}:${value}`} $direction={direction} $hideSeparator={hideSeparator}>
						<Typography $bold $type={'Body.Medium'} $color={'textOnSurfaceBackground'}>
							{key}
						</Typography>
						<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
							{value}
						</Typography>
					</InfoItem>
				))}
		</InfoCardContainer>
	);
};

export default InfoCard;

const InfoCardContainer = styled.ul`
	padding: 0;
`;

interface InfoItemStyleProps {
	$direction?: InfoCardProps['direction'];
	$hideSeparator?: InfoCardProps['hideSeparator'];
}

const InfoItem = styled.li<InfoItemStyleProps>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	padding-top: ${p => p.theme.spacing.regular};
	gap: ${p => p.theme.spacing.extraSmall};

	${p =>
		p.$direction === 'row' &&
		css`
			flex-direction: row;
			align-items: center;

			& > :nth-child(1) {
				flex: 1 0 auto;
				white-space: nowrap;
			}

			& > :nth-child(2) {
				text-align: right;
			}
		`}

	&:not(:last-child) {
		padding-bottom: ${p => p.theme.spacing.regular};
		border-bottom: 1px solid ${p => p.theme.ui.borderSeparator};

		${p =>
			p.$hideSeparator &&
			css`
				border-bottom: none;
			`}
	}
`;
