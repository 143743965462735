/* eslint-disable require-yield */
import { stepActions } from '../actions';
import { setItem } from '~lib/shared/helpers/local-storage';
import { createTakeEverySagaSet } from '~lib/shared/redux/sagas';
import { quoteStartDate } from '~website/constants/storage';

export default createTakeEverySagaSet(stepActions.setStartDate, function* setStartDateSaga(action) {
	setItem(
		quoteStartDate,
		{
			date: action.payload ?? null,
			createdAt: new Date().toISOString(),
		},
		'sessionStorage'
	);
});
