import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import useContentKey from '~lib/frontend/hooks/use-content-key';
import { GetterType } from '~lib/frontend/types/content';
import { oneLink, reapplyParamsIfExternalUrl } from '~website/helpers/source-tracking';
import { useHideIntercomLauncher } from '~website/hooks/intercom';

const GetAQuote: React.FunctionComponent = () => {
	const g = useContentKey('website_core');
	const get: GetterType = (key, fallback) => g(`pages[get-a-quote].${key}`, fallback);

	const redirectUrl = get('redirect_url', oneLink.getAQuote);

	const sourceTracking = useSelector(s => s.internal.analytics.sourceTracking);
	const location = useLocation();
	const fullUrl = reapplyParamsIfExternalUrl(redirectUrl, sourceTracking, location.pathname);

	useEffect(() => {
		window.location.assign(fullUrl);
	}, [fullUrl]);

	useHideIntercomLauncher();

	return null;
};

export default GetAQuote;
