import styled from 'styled-components';

import { safeAreaPadded } from '~lib/frontend/design-system/safe-area';

export const Header = styled.div`
	min-height: 288px;
	background: ${p => p.theme.ui.violetGradientLeading}, ${p => p.theme.ui.secondaryBackground};
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: ${p => p.theme.spacing.small};
	padding: 0 ${p => p.theme.spacing.semiLarge};
	position: relative;
`;

export const HeaderTabsContainer = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	max-width: 1120px;
	margin: 0 auto;
	@media screen and (max-width: calc(${p => p.theme.spacing.semiLarge} * 2 + 1120px)) {
		left: auto;
		right: auto;
	}
`;

export const Hero = styled.div`
	display: flex;
	justify-content: flex-start;
	width: 100%;
	max-width: 1120px;
	margin: 0 auto;
	padding-bottom: ${p => p.theme.spacing['4xLarge']};
`;

export const MeSinglePageHero = styled(Hero)`
	width: 100%;
	max-width: 1120px;
	margin-top: ${p => p.theme.spacing['6xLarge']};
`;

export const Container = styled.div`
	background: ${p => p.theme.ui.background};

	padding-top: ${p => p.theme.spacing.extraLarge};
	padding-bottom: ${p => p.theme.spacing.extraLarge};

	/* Handle padding + iPhone top notch in landscape mode */
	${p => safeAreaPadded(p.theme.spacing.large, '16px')}

	@media screen and (min-width: 1024px) {
		padding-top: ${p => p.theme.spacing['3xLarge']};
		padding-bottom: ${p => p.theme.spacing['3xLarge']};
	}
`;
