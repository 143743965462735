import React from 'react';

import QuoteInputDecorator from '../../../components/atoms/QuoteInputDecorator';
import Date from '../../fields/Date';
import useOnBlurError from '~lib/frontend/design-system/components/input/hooks/use-on-blur-error';
import { useIncidentItemInternalChangeRequest } from '~website/features/quote/hooks/use-incident-change-request';
import useOnPageError from '~website/features/quote/hooks/use-on-page-error';
import pastDateWithin5YearsValidator from '~website/features/quote/validators/past-date-within-5-years';

const IncidentDate: React.FCWithChildren<{ index: number }> = ({ index }) => {
	const { onChange, validation, value } = useIncidentItemInternalChangeRequest(
		index,
		'date',
		pastDateWithin5YearsValidator
	);
	const { onFocus, onBlur, error } = useOnBlurError(validation);
	const pageError = useOnPageError(validation);

	return (
		<QuoteInputDecorator
			label={'Date of incident'}
			error={error || pageError}
			help={'DD/MM/YYYY'}
			onFocus={onFocus}
			onBlur={onBlur}
		>
			<Date value={value} disabled={false} onChange={onChange} />
		</QuoteInputDecorator>
	);
};

export default IncidentDate;
