import styled from 'styled-components';
const Tab = styled.button `
	${p => p.theme.typography['Label.Medium']()};
	padding: 0 ${p => p.theme.spacing.small};
	cursor: ${p => (p.disabled ? 'not-allowed' : 'pointer')};

	&&,
	&&:hover,
	&&:visited {
		color: ${p => p.theme.ui[getTextColor(p.$active, p.$background, p.disabled)]};
		text-decoration: none;
	}

	display: flex;
	flex: 0;
	white-space: nowrap;
	align-items: center;

	border: none;
	transition: all 250ms ease-in-out;
	appearance: none;
	background: ${p => (p.$background === 'block' ? p.theme.ui.surface : 'transparent')};
`;
function getTextColor(active, background, disabled) {
    if (disabled)
        return 'textMinorMuted';
    if (active && background === 'block')
        return 'textHighlight';
    if (active && background === 'transparent')
        return 'textOnFill';
    return background === 'transparent' ? 'textOnFillMuted' : 'textOnSurfaceBackgroundMuted';
}
export default Tab;
