import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Typography from '~lib/frontend/design-system/components/Typography';
import useDesignSystemAsset from '~lib/frontend/hooks/use-design-system-asset';
import { trackScreenView } from '~website/features/analytics/store/actions';

interface CheckYourInboxContentProps {
	emailAddress: string;
}

const CheckYourInboxContent: React.FCWithChildren<CheckYourInboxContentProps> = ({ emailAddress }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			trackScreenView({
				eventName: 'sign_in_check_inbox_viewed',
				eventPayload: {},
			})
		);
	}, [dispatch]);

	const illo = useDesignSystemAsset({ type: 'illustration', value: 'il_envelope01.svg' });

	return (
		<Wrapper>
			<Image src={illo} alt={'envelope opening'} />
			<ContentWrap>
				<Typography $type={'Heading.Medium'} $color={'textOnSurfaceBackground'}>
					{'Check your inbox'}
				</Typography>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
					{emailAddress && `We've sent an email to ${emailAddress}`}
					{!emailAddress && "We've sent you an email"}
				</Typography>
				<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
					{'Follow the link in the e-mail to finish signing in.'}
				</Typography>
			</ContentWrap>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.extraLarge};
`;

const Image = styled.img`
	width: 96px;
	height: 96px;
	margin: 0 auto;
`;

const ContentWrap = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	gap: ${p => p.theme.spacing.regular};
`;

export default CheckYourInboxContent;
