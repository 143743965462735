import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { PanelArea } from '../atoms/PanelArea';
import DownloadAppBase from '../molecules/DownloadAppBase';
import { DesignSystemProvider } from '~lib/frontend/design-system';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';
import useMobileDetect from '~lib/frontend/hooks/use-mobile-detect';
import AppStoreButtonsSegment from '~website/components/atoms/AppStoreButtonsSegment';
import ContinueInAppLink from '~website/components/atoms/ContinueInAppLink';
import { QRCode } from '~website/components/atoms/QRCode';
import { SubSteps } from '~website/components/atoms/SubSteps';
import TrustSegment from '~website/components/atoms/TrustSegment';
import Footer from '~website/components/layout/footer';
import VibrantScreen from '~website/components/VibrantScreen';
import VibrantScreenCard from '~website/components/VibrantScreen/VibrantScreenCard';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';

const DownloadApp: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const { isMobile } = useMobileDetect();

	return (
		<Fragment>
			<VibrantScreen>
				<VibrantScreenCard maxWidth={440}>
					<DownloadAppBase isMobile={isMobile}>
						<PanelArea>
							<Typography $type={'Body.Medium'} $color={'textOnSurfaceBackgroundMuted'}>
								{'We’ll do a few last checks in the app, then you can hit the road 🚗'}
							</Typography>

							{!isMobile && (
								<Margin $marginTop={'extraLarge'}>
									<QRCode />
								</Margin>
							)}
						</PanelArea>
						<SubSteps $color={'primaryFill'} $style={'outline'}>
							<li>
								<div
									onClick={() =>
										dispatch(trackQuoteEvent({ action: 'continue_in_app_link_step_selected' }))
									}
								>
									<ContinueInAppLink style={'link'} text={'Download the Cuvva app'} />
								</div>
							</li>
							<li>
								<Typography $type={'Body.Medium'} $bold $color={'textOnSurfaceBackground'}>
									{'Sign in using the same email'}
								</Typography>
							</li>
							<li>
								<Typography $type={'Body.Medium'} $bold $color={'textOnSurfaceBackground'}>
									{'Finish buying your policy'}
								</Typography>
							</li>
						</SubSteps>

						{isMobile && (
							<Margin $marginBottom={'extraLarge'}>
								<ContinueInAppLinkWrapper
									onClick={() =>
										dispatch(trackQuoteEvent({ action: 'continue_in_app_button_selected' }))
									}
								>
									<ContinueInAppLink style={'button'} />
								</ContinueInAppLinkWrapper>
							</Margin>
						)}
						<AppStoreButtonsWrapper
							onClick={() => dispatch(trackQuoteEvent({ action: 'download_cuvva_app_selected' }))}
						>
							<AppStoreButtonsSegment align={'center'} />
						</AppStoreButtonsWrapper>
					</DownloadAppBase>
				</VibrantScreenCard>

				<Margin
					$marginTop={'large'}
					$marginBottom={'large'}
					onClick={() => dispatch(trackQuoteEvent({ action: 'trustpilot_cta_selected' }))}
				>
					<DesignSystemProvider themeKey={'dark'} typographyStyle={'expressive'}>
						<TrustSegment trustProvider={'trustpilot'} />
					</DesignSystemProvider>

					<LegalDisclaimerBlock>
						<Icon icon={'ic_check_circle'} $size={'24px'} $color={'textOnFill'} $marginRight={'small'} />
						<Typography $type={'Label.Small'} $color={'textOnFill'}>
							{'FCA Authorised and regulated'}
						</Typography>
					</LegalDisclaimerBlock>
				</Margin>
			</VibrantScreen>
			<Footer landingPage />
		</Fragment>
	);
};

const LegalDisclaimerBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;

const AppStoreButtonsWrapper = styled.div`
	display: flex;
	justify-content: center;
`;

const ContinueInAppLinkWrapper = styled.div`
	width: 100%;
`;

export default DownloadApp;
