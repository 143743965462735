import { createSlice } from '@reduxjs/toolkit';

import { cookieConsentAction } from '../actions';
import type { CookieConsentType } from '../types';

const consent = createSlice({
	name: '@@internal/cookie-policy/consent',
	initialState: 'pending' as CookieConsentType,
	reducers: {},
	extraReducers: builder => builder.addCase(cookieConsentAction.setCookieConsent, (_, action) => action.payload),
});

export default consent;
