import React, { useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import AnalyticsDebugPopover from './components/AnalyticsDebugPopover';
import AuthenticatedRoute from './components/atoms/AuthenticatedRoute';
import ScrollResetter from './components/atoms/ScrollResetter';
import NoAppBorrowerRequest from './features/no-app-borrower-request/NoAppBorrowerRequest';
import Quote from './features/quote/containers/Quote';
import ReferAFriendStatic from './features/refer-a-friend/ReferAFriendStatic';
import ReferFriends from './features/refer-a-friend/ReferFriends';
import Referred from './features/refer-a-friend/Referred';
import SignIn from './features/sign-in/containers/SignIn';
import VehicleView from './features/vehicle-profile/containers/VehicleView';
import useAuthCover from './hooks/use-auth-cover';
import Authenticate from './pages/Authenticate';
import DownloadQuote from './pages/DownloadQuote';
import CarInsuranceByBrand from './pages/dynamic-seo/CarInsuranceByBrand';
import Goodbye from './pages/Goodbye';
import HiringOffer from './pages/HiringOffer';
import MeHub from './pages/MeHub';
import PageBuilderRenderer from './pages/PageBuilderRenderer';
import Referral from './pages/Referral';
import DetailedErrorBoundary from '~lib/frontend/components/error-boundaries/DetailedErrorBoundary';
import SimpleErrorBoundary from '~lib/frontend/components/error-boundaries/SimpleErrorBoundary';
import { DesignSystemProvider } from '~lib/frontend/design-system';
import ButtonPage from '~lib/frontend/design-system/components/button/ButtonPage';
import DialogPage from '~lib/frontend/design-system/components/dialog/Showcase';
import { Theme } from '~lib/frontend/design-system/types';
import { createAwareUrl } from '~lib/frontend/helpers/uri';
import { useHotkeys } from '~lib/frontend/hooks/use-hotkeys';
import useSegment from '~lib/frontend/hooks/use-segment';
import Normalize from '~lib/frontend/normalize';
import Components from '~website/components/Components';
import Layout from '~website/components/layout/Layout';
import PageBuilder from '~website/features/builder/components/PageBuilder';
import CookiePolicy from '~website/features/cookie-policy';
import NotSupported, { notSupportedMeLinks } from '~website/features/me-hub/containers/NotSupported';
import { useGuideContent } from '~website/hooks/use-segment';
import { NavContext, useStickyNav } from '~website/hooks/use-sticky-nav';
import CarChecker from '~website/pages/CarChecker';
import Download from '~website/pages/Download';
import GetAQuote from '~website/pages/GetAQuote';
import Guides from '~website/pages/Guides';
import InsuranceGroupChecker from '~website/pages/InsuranceGroupChecker';
import Loading from '~website/pages/Loading';
import News from '~website/pages/News';
import NewsPost from '~website/pages/NewsPost';
import NotFound from '~website/pages/NotFound';
import Topic from '~website/pages/Topic';

const GlobalStyle = createGlobalStyle`
	html {
		box-sizing: border-box;
		scroll-padding-top: 80px;
	}
	*, *:before, *:after {
		box-sizing: inherit;
	}

	ol, ul {
		list-style: none;
	}

	html, body {
		position: relative;
		font-family: ${p => p.theme.fonts.default};
		color: ${p => p.theme.ui.textOnSurfaceBackground};
		background: ${p => p.theme.ui.blankBackground};
	}
`;

const konamiCode = [
	'Enter',
	'a',
	'b',
	'ArrowRight',
	'ArrowLeft',
	'ArrowRight',
	'ArrowLeft',
	'ArrowDown',
	'ArrowDown',
	'ArrowUp',
	'ArrowUp',
];
const konami = ['Enter', 'b', 'a', 'ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp'];
const keys: string[] = [];

const topicOptions = {
	header: { contentUnder: true },
	footer: { widgetJoinAppStore: true },
};

const guidesOptions = {
	header: { contentUnder: true },
};

const withAuthCover = (WrappedComponent: React.ComponentType) => () => {
	useAuthCover();

	return <WrappedComponent />;
};

const Routes = React.memo(() => {
	const [theme, setTheme] = useState<Theme>('light');

	const newsSegment = useSegment('website_news');
	const coreSegment = useSegment('website_core');
	const builder = coreSegment?.content?.builder as unknown as Record<string, Record<string, string>>;
	const guidesSegment = useGuideContent();

	const newsUrls = useMemo(
		() => (newsSegment?.content ? Object.keys(newsSegment.content).map(k => newsSegment.content[k].url) : []),
		[newsSegment]
	);

	const guidesUrls = useMemo(() => {
		if (guidesSegment?.content?.pages) {
			return Object.keys(guidesSegment.content.pages)
				.map(k => guidesSegment.content.pages[k].url)
				.filter(p => !p.endsWith('/'));
		}

		return [];
	}, [guidesSegment]);

	const scrollNav = useStickyNav();

	const builderUrls = useMemo(() => (builder ? Object.keys(builder).map(c => builder[c].url) : []), [builder]);

	useHotkeys(
		'*',
		{},
		event => {
			if (!konami.includes(event.key)) return;

			keys.unshift(event.key);

			if (keys.length > 11) keys.length = 11;

			if (equals(keys, konamiCode)) setTheme(theme === 'light' ? 'dark' : 'light');
		},
		[setTheme]
	);

	return (
		<DesignSystemProvider themeKey={theme} typographyStyle={'expressive'}>
			<DetailedErrorBoundary>
				<NavContext.Provider value={scrollNav}>
					<Normalize />
					<GlobalStyle />
					<ScrollResetter />
					<AnalyticsDebugPopover />
					<CookiePolicy />

					<Switch>
						<Route sensitive path={'/get-an-estimate'}>
							<Redirect to={createAwareUrl('/vehicles')} />
						</Route>

						<Route sensitive exact path={'/components'} component={Components} />
						<Route sensitive exact path={'/components/buttons'} component={ButtonPage} />
						<Route sensitive exact path={'/components/dialogs'} component={DialogPage} />

						<Route sensitive path={'/quote'}>
							<SimpleErrorBoundary>
								<Quote />
							</SimpleErrorBoundary>
						</Route>

						<Route sensitive path={'/vehicles/:vehicleId?'} component={VehicleView} />

						<Route sensitive exact path={'/news'}>
							<Layout component={News} />
						</Route>
						<Route sensitive path={newsUrls}>
							<Layout component={NewsPost} />
						</Route>
						{builderUrls.length > 0 && (
							<Route sensitive exact path={builderUrls}>
								<PageBuilderRenderer />
							</Route>
						)}
						<Route sensitive exact path={'/how-insurance-works'}>
							<Layout options={guidesOptions} component={Guides} />
						</Route>
						<Route sensitive exact path={guidesUrls}>
							{() => <Layout options={topicOptions} component={Topic} />}
						</Route>
						<Route sensitive exact path={'/borrower-request:borrowerName?:vrm?'}>
							<Layout component={NoAppBorrowerRequest} />
						</Route>
						<Route exact path={'/refer-a-friend'}>
							<ReferAFriendStatic />
						</Route>
						<Route exact path={'/refer-friends'}>
							<ReferFriends />
						</Route>
						<Route sensitive exact path={'/referred/:code'}>
							<Layout component={Referred} />
						</Route>
						<Route sensitive path={'/referral/:id'} component={Referral} />
						<Route sensitive path={'/offer/:id'} component={HiringOffer} />
						<Route sensitive path={'/car-insurance/brand/:brand'}>
							<CarInsuranceByBrand contentKey={'car-insurance-by-brand'} />
						</Route>
						<Route sensitive exact path={'/free-car-checker'}>
							<Layout>
								<CarChecker contentKey={'car-checker'} />
							</Layout>
						</Route>

						<Route sensitive exact path={notSupportedMeLinks}>
							<NotSupported />
						</Route>

						<AuthenticatedRoute path={'/me'} exact={false} component={MeHub} />

						<Route sensitive exact path={'/mot-check'}>
							<Layout>
								<CarChecker contentKey={'mot-check'} />
							</Layout>
						</Route>
						<Route sensitive exact path={'/insurance-groups'}>
							<Layout component={InsuranceGroupChecker} />
						</Route>
						<Route sensitive exact path={'/loading'}>
							<Layout component={Loading} />
						</Route>
						<Route sensitive exact path={'/content/page-builder'}>
							<PageBuilder />
						</Route>
						<Route sensitive exact path={'/signin'}>
							<SignIn />
						</Route>
						<Route sensitive exact path={'/callback'} component={Authenticate} />
						<Route sensitive exact path={'/goodbye'}>
							<Goodbye />
						</Route>
						<Route sensitive exact path={'/download'}>
							<Download />
						</Route>
						<Route sensitive exact path={'/download-quote'}>
							<DownloadQuote />
						</Route>
						<Route sensitive exact path={'/get-a-quote'}>
							<GetAQuote />
						</Route>
						<Route>
							<NotFound />
						</Route>
					</Switch>
				</NavContext.Provider>
			</DetailedErrorBoundary>
		</DesignSystemProvider>
	);
});

function equals(a: string[], b: string[]) {
	return a.length === b.length && a.every((v, i) => v === b[i]);
}

export default withAuthCover(Routes);
