import styled from 'styled-components';
const Container = styled.div `
	width: fit-content;
	min-width: 100%;
	display: flex;
	position: relative;
	justify-content: flex-start;
	align-items: stretch;
	min-height: 56px; // this is from the design
	padding: 0 ${p => (p.$background === 'block' ? p.theme.spacing.large : '0')};
	flex-direction: row;
	border-bottom: ${p => (p.$background === 'block' ? `1px solid ${p.theme.ui.borderSeparator}` : 'none')};
	background-color: ${p => (p.$background === 'block' ? p.theme.ui.surfaceFill : 'transparent')};
	gap: ${p => p.theme.spacing.large};
`;
export default Container;
