import React from 'react';
import styled from 'styled-components';

import vehicleProfileEvents from '../events';
import SignalInterestCTAs from './SignalInterestCTAs';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import * as ComplexDialog from '~lib/frontend/design-system/components/dialog/molecules/ComplexDialog';
import Icon from '~lib/frontend/design-system/components/Icon';
import Typography from '~lib/frontend/design-system/components/Typography';

interface DrivingHighlightsProps {
	open: boolean;
	closeModal: () => void;
}

const DrivingHighlights: React.FCWithChildren<DrivingHighlightsProps> = ({ open, closeModal }) => (
	<ComplexDialog.Dialog open={open} title={'Driving highlights'} onClose={closeModal}>
		<ComplexDialog.Content>
			<Typography
				$type={'Body.XLarge'}
				$color={'textOnSurfaceBackgroundMuted'}
				$marginTop={'regular'}
				$marginBottom={'extraLarge'}
				$align={'center'}
			>
				{
					'Receive a range of useful and ever evolving highlights based specifically on your driving behaviour all in one space.'
				}
			</Typography>
			<HighlightsContainer>
				<Highlight>
					<div>
						<NumberHighlight>{'287'}</NumberHighlight>
						<Typography $type={'Body.Small'} $bold $display={'inline'} $color={'textOnSurfaceBackground'}>
							{'mi'}
						</Typography>
					</div>
					<Typography
						$type={'Body.Small'}
						$marginTop={'regular'}
						$align={'center'}
						$color={'textOnSurfaceBackgroundMuted'}
					>
						{'Total distance travelled'}
					</Typography>
				</Highlight>
				<Highlight>
					<div>
						<Icon icon={'ic_tree'} $size={'24px'} $color={'neutralFill'} />
					</div>
					<NumberHighlight>{'132'}</NumberHighlight>
					<Typography $type={'Body.Small'} $align={'center'} $color={'textOnSurfaceBackgroundMuted'}>
						{'Trees planted to offset your emissions'}
					</Typography>
				</Highlight>
				<Highlight>
					<Margin $marginBottom={'extraSmall'}>
						<Icon icon={'ic_accelerating'} $size={'24px'} $color={'neutralFill'} />
					</Margin>
					<Typography $type={'Heading.Small'} $align={'center'} $color={'textOnSurfaceBackground'}>
						{'Accelerating'}
					</Typography>
					<Typography
						$type={'Body.Small'}
						$marginTop={'small'}
						$align={'center'}
						$color={'textOnSurfaceBackgroundMuted'}
					>
						{'Most improved driving factor'}
					</Typography>
				</Highlight>
				<Highlight>
					<NumberHighlight>{'17'}</NumberHighlight>
					<Typography
						$type={'Body.Small'}
						$marginTop={'small'}
						$align={'center'}
						$color={'textOnSurfaceBackgroundMuted'}
					>
						{'Trips completed'}
					</Typography>
				</Highlight>
			</HighlightsContainer>
		</ComplexDialog.Content>
		<ComplexDialog.Actions>
			<SignalInterestCTAs
				optinEvent={`${vehicleProfileEvents.ownersHub.drivingHighlightsCta}_yes`}
				optoutEvent={`${vehicleProfileEvents.ownersHub.drivingHighlightsCta}_no`}
				closeModal={closeModal}
			/>
		</ComplexDialog.Actions>
	</ComplexDialog.Dialog>
);

const HighlightsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
`;

const Highlight = styled.div`
	background: ${p => p.theme.ui.surfaceFillMuted};
	border-radius: ${p => p.theme.borderRadius.large};
	padding: ${p => p.theme.spacing.large};
	display: flex;
	width: 164px;
	height: 150px;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0px ${p => p.theme.spacing.large} ${p => p.theme.spacing.large};

	svg {
		width: 24px;
		height: 24px;
	}
`;

const NumberHighlight = styled.div`
	${p => p.theme.typography['Heading.Large']()}
	color: ${p => p.theme.ui.textOnSurfaceBackground};
	font-size: 42px;
	line-height: 42px;
	display: inline;
`;

export default DrivingHighlights;
