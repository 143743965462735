import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { runLtmEstimate } from '../../store';
import HorizontalRule from '../atoms/HorizontalRule';
import CheckList from '../molecules/CheckList';
import { Anchor } from '~lib/frontend/atoms';
import { Margin } from '~lib/frontend/design-system/components/atoms/utils';
import Button from '~lib/frontend/design-system/components/button/Button';
import Typography from '~lib/frontend/design-system/components/Typography';
import Footer from '~website/components/layout/footer';
import VibrantScreen from '~website/components/VibrantScreen';
import VibrantScreenCard from '~website/components/VibrantScreen/VibrantScreenCard';
import { trackQuoteEvent } from '~website/features/analytics/store/actions';
import NegativeFeedback from '~website/features/quote/components/molecules/NegativeFeedback';
import { monzoMoney } from '~website/helpers/monies';

const YourLtmEstimate: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const ltmEstimate = useSelector(s => s.internal.quote.estimate.ltm);

	useEffect(() => {
		if (!ltmEstimate) dispatch(runLtmEstimate.actions.request());
	}, [dispatch, ltmEstimate]);

	const price = ltmEstimate?.response?.pricing?.totalPayable;
	const hasPrice = price !== void 0;
	const error = ltmEstimate?.error;

	useEffect(() => {
		if (price) dispatch(trackQuoteEvent({ action: 'ltm_estimate_received' }));
	}, [dispatch, price]);

	useEffect(() => {
		if (error) dispatch(trackQuoteEvent({ action: 'ltm_estimate_failed' }));
	}, [dispatch, error]);

	return (
		<Fragment>
			<VibrantScreen>
				<VibrantScreenCard>
					<CenteredCardPricing>
						{hasPrice && (
							<Fragment>
								<Typography $type={'Body.Large'} $color={'textOnSurfaceBackground'} $bold>
									{'Get covered for'}
								</Typography>
								<Typography $type={'Heading.XLarge'} $color={'textOnSurfaceBackground'}>
									{monzoMoney(price)}
								</Typography>
								<Typography
									$type={'Body.Medium'}
									$marginBottom={'regular'}
									$color={'textOnSurfaceBackgroundMuted'}
								>
									{'per month'}
								</Typography>
							</Fragment>
						)}
						{error && <NegativeFeedback error={error} />}
						{!hasPrice && !error && (
							<Margin $marginTop={'large'} $marginBottom={'extraLarge'}>
								<Typography
									$type={'Body.XLarge'}
									$color={'textOnSurfaceBackgroundMuted'}
									$display={'block'}
								>
									{'Loading..'}
								</Typography>
							</Margin>
						)}
					</CenteredCardPricing>

					{hasPrice && (
						<React.Fragment>
							<Margin $marginTop={'extraLarge'}>
								<Button
									as={Anchor}
									// @ts-ignore
									to={'/quote/download'}
									$type={'primary'}
									$size={'large'}
									$stretch
									onClick={() =>
										dispatch(trackQuoteEvent({ action: 'ltm_estimate_buy_in_app_selected' }))
									}
								>
									{'Buy in app'}
								</Button>
							</Margin>

							<NewQuoteLinkContainer>
								<Typography
									$type={'Label.Medium'}
									$color={'textHighlight'}
									$bold
									as={Anchor}
									to={'/vehicles'}
									$marginTop={'24px'}
									$marginBottom={'24px'}
									$marginLeft={'auto'}
									$marginRight={'auto'}
									$align={'center'}
									$display={'block'}
								>
									{'Start a new quote'}
								</Typography>
								<HorizontalRule />
							</NewQuoteLinkContainer>

							<Margin $marginTop={'extraLarge'}>
								<CheckList title={'About your cover'}>
									{'Fully comprehensive'}
									{'Pay monthly, no deposit'}
									{'Stop anytime, fee-free'}
									{'Get your no-claims bonus'}
									{'24/7 support'}
								</CheckList>
							</Margin>
						</React.Fragment>
					)}
				</VibrantScreenCard>
			</VibrantScreen>
			<Footer landingPage />
		</Fragment>
	);
};

const NewQuoteLinkContainer = styled.div`
	text-align: center;

	a,
	a:hover,
	a:visited {
		text-decoration: none;
		cursor: pointer;
	}
`;

const CenteredCardPricing = styled.div`
	text-align: center;
`;

export default YourLtmEstimate;
