import ksuid from '@cuvva/ksuid';
import memo from 'memoize-one';
import { UAParser } from 'ua-parser-js';
const getEnv = memo((ua) => new UAParser(ua).getResult());
const eURIc = typeof window === 'undefined' ? global.encodeURIComponent : window.encodeURIComponent;
export default class MixpanelClient {
    _token;
    _userId;
    _disabled;
    _deviceId = ksuid.generate('device').toString();
    _hasContentBlocking = false;
    constructor(opts) {
        this._token = opts.token;
        this._userId = opts.userId;
        this._disabled = Boolean(opts.disabled);
    }
    getUserId() {
        return this._userId;
    }
    setUserId(userId) {
        this._userId = userId;
    }
    async setup() {
        try {
            await doRaceCall(this.track('$identify', { distinct_id: this._userId }));
        }
        catch {
            // If there is an exception, just assume content is blocked 🤷‍
            this._hasContentBlocking = true;
        }
        if (this._hasContentBlocking)
            return;
        document.addEventListener('click', async (event) => {
            const { analyticsEvent: eventName, analyticsProps: eventProps } = event.target.dataset;
            if (!eventName || !eventProps)
                return;
            await this.track(eventName, JSON.parse(eventProps));
        });
    }
    async setUserConfig(config) {
        await this._send('engage', {
            $distinct_id: this._userId,
            $os: getEnv(window.navigator.userAgent).os.name,
            $os_version: getEnv(window.navigator.userAgent).os.version,
            $browser: getEnv(window.navigator.userAgent).browser.name,
            $browser_version: getEnv(window.navigator.userAgent).browser.version,
            $device: getEnv(window.navigator.userAgent).device,
            $set: {
                config_flags: config?.flags,
            },
        });
    }
    async track(event, properties) {
        await this._send('track', {
            event,
            properties: {
                ...properties,
                ip: '1',
                distinct_id: this._userId,
                _: new Date().getTime().toString(),
                $user_id: this._userId,
                $device_id: this._deviceId,
                $current_url: window.location.href,
                $screen_height: window.outerHeight,
                $screen_width: window.outerWidth,
                $referrer: document.referrer,
                $os: getEnv(window.navigator.userAgent).os.name,
                $os_version: getEnv(window.navigator.userAgent).os.version,
                $browser: getEnv(window.navigator.userAgent).browser.name,
                $browser_version: getEnv(window.navigator.userAgent).browser.version,
                $device: getEnv(window.navigator.userAgent).device,
            },
        });
    }
    async _send(path, properties) {
        if (this._disabled === true || this._hasContentBlocking === true)
            return;
        const apps = this._token;
        if (apps.length === 0)
            return;
        await eventRegister(apps, path, properties);
    }
}
async function eventRegister(apps, path, properties) {
    const appsLength = apps.length;
    if (appsLength === 0)
        return;
    const transformedProperties = {
        ...properties,
        properties: {
            ...properties.properties,
            token: apps[apps.length - 1],
        },
    };
    const truncated = truncate(transformedProperties);
    const json = JSON.stringify(truncated);
    const encoded = window.btoa(json);
    const url = `https://api.mixpanel.com/${path}?data=${eURIc(encoded)}`;
    await doRaceCall(window.fetch(new Request(url, { method: 'get' }))).then(_ => {
        eventRegister(apps.slice(0, -1), path, properties);
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function truncate(input) {
    if (typeof input === 'string')
        return input.slice(0, 255);
    if (Array.isArray(input))
        return input.map(i => truncate(i));
    if (input === Object(input)) {
        return Object.keys(input).reduce((acc, key) => ({
            ...acc,
            [key]: truncate(input[key]),
        }), {});
    }
    return input;
}
async function doRaceCall(call, timeout = 600) {
    // Promise.race doesn't cancel shit, so we have to handle it ourselves 👍
    const stupidRaceTimeout = {
        cancel: () => {
            /* */
        },
    };
    // If the content is blocked, the call might hang forever. We want to cancel it if
    // it's taking too long.
    await Promise.race([
        call,
        new Promise(resolve => {
            const handle = setTimeout(resolve, timeout);
            stupidRaceTimeout.cancel = () => clearTimeout(handle);
        }),
    ]);
    stupidRaceTimeout.cancel();
}
