import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PageCtx } from '../contexts/PageCtx';
import { pageActions, stepActions } from '../store/actions';
import { HookReturn, Validator } from '../types';
import useFriendlyError from './use-friendly-error';
import { ApplicationState } from '~website/store';

function useDLRInternalChangeRequest(key: 'dln' | 'postcode', validator?: Validator): HookReturn {
	const dispatch = useDispatch();
	const pageId = useContext(PageCtx);
	const page = useSelector((s: ApplicationState) => s.internal.quote.pages[pageId]);
	const internalChangeRequestId = page.internalChangeRequestId;
	const internalChangeRequest = useSelector((s: ApplicationState) => s.internal.quote.internalChangeRequest);

	const userId = useSelector((s: ApplicationState) => s.internal.auth.user.response);
	const userLicense = useSelector(
		(s: ApplicationState) => s.platform.drivingLicenseRegistration.licenseByUserId[userId]
	);

	const cr = internalChangeRequest[internalChangeRequestId];

	const onChange = (value: string) => {
		dispatch(
			stepActions.setDLRValue({
				key,
				value,
				userId,
				internalChangeRequestId,
			})
		);
	};

	const value = cr?.drivingLicenseRegistration?.[userId]?.[key] ?? userLicense?.response?.[key]?.value ?? void 0;
	const validation = page?.fields?.[key]?.error;

	// idea on how to register the component
	useEffect(() => {
		dispatch(
			pageActions.registerField({
				field: key as string,
				pageId,
			})
		);
	}, [dispatch, key, pageId]);

	useEffect(() => {
		if (!validator) return;

		const error = validator(value);

		dispatch(
			stepActions.setFieldError({
				field: key as string,
				pageId,
				error,
			})
		);
	}, [validator, value, pageId, dispatch, key]);

	return {
		onChange,
		value,
		validation: useFriendlyError(validation, 'dlr', key),
	};
}

export default useDLRInternalChangeRequest;
