import styled, { css } from 'styled-components';
export const CardHeaderWrapper = styled.div `
	${p => p.theme.typography['Body.Large']()};
	display: flex;
	align-items: center;
	font-weight: 500;
	border-bottom: 1px solid inherit;
	padding: ${p => p.theme.spacing.large};

	${p => p.$collapsible &&
    css `
			cursor: pointer;
		`}
`;
export const CardHeaderTextWrapper = styled.div `
	word-wrap: break-word;
`;
