export default function setupEnvironment() {
	const clientId = 'client_000000BPG6Lks9tQoAiJYrBRSXPX6';
	const { env, system, gitCommit, hostEnvironment, redirectUri, baseUrl } = getEnvironmentInfo();

	window.cuvva = {
		client: {
			env,
			baseUrl,
			clientId,
			redirectUri,
		},
		deployment: {
			gitCommit,
			system: hostEnvironment,
		},
		config: {
			mixpanelToken: getMixpanelConfig(env),
			iterableProjectId: getIterableProjectId(env),
		},
	};

	window.intercomSettings = {
		app_id: getIntercomConfig(env),
	};

	return { env, system, hostEnvironment, redirectUri, baseUrl, clientId };
}

function getEnvironmentInfo() {
	const params = new URLSearchParams(window.location.search);
	const hostEnvironment = getHostEnvironment();
	const definedEnv = params.get('env');
	const env = definedEnv || (hostEnvironment === 'prod' ? 'prod' : 'test');
	const system = env === 'prod' ? 'prod' : 'nonprod';
	const safeEnv = env.replace(/[\W_]+/g, '');
	const redirectUri = getRedirectUri(hostEnvironment);
	const gitCommit = getGitCommit();

	return {
		env,
		system,
		gitCommit,
		hostEnvironment,
		redirectUri,
		baseUrl: `https://api.${safeEnv}.cuv-${system}.com/`,
	};
}

function getHostEnvironment() {
	// eslint-disable-next-line no-process-env
	if (process.env.REACT_CUVVA_DEVELOPMENT) return 'local';

	if (window.location.host === 'www.cuvva.com') return 'prod';

	return 'nonprod';
}

function getGitCommit() {
	// eslint-disable-next-line no-process-env
	return process.env.REACT_CUVVA_GIT_COMMIT ?? 'unknown';
}

function getRedirectUri(hostEnvironment: string) {
	switch (hostEnvironment) {
		case 'local':
		case 'nonprod':
			return 'https://website.g.cuv-nonprod.com/callback';

		default:
			return 'https://www.cuvva.com/callback';
	}
}

function getIntercomConfig(appEnvironment: string) {
	return appEnvironment === 'prod' ? 'pftsgka8' : 'ar9yueme';
}

function getMixpanelConfig(appEnvironment: string) {
	if (appEnvironment === 'prod') return ['8854f5d1c783711957c018a7a8e0bc4f'];

	return ['bf03a2920a886e9aa63ad6508ca0f371'];
}

function getIterableProjectId(appEnvironment: string) {
	return appEnvironment === 'prod' ? '18716' : '18717';
}
