type PushEventProps<T> = T & {
	event: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addEventObject<T = Record<string, any>>(obj: T) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	((window as any).dataLayer || []).push(obj);
}

export function addEventVariable<T = string | number>(varName: string, varVal: T) {
	addEventObject({ [varName]: varVal });
}

export const pushEvent = <T>(eventObject: PushEventProps<T>) => addEventObject(eventObject);
