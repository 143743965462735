import Cher from '@cuvva/cher';

import { postcodeRegex } from '~lib/shared/helpers/regex';

const validPostcodeValidator = (value: string | undefined) => {
	if (typeof value !== 'string') return new Cher('invalid');

	const postcode = value.replace(/\s/g, '').toLocaleUpperCase();

	if (!postcodeRegex.exec(postcode)) return new Cher('invalid');

	return void 0;
};

export default validPostcodeValidator;
